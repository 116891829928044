import React, { useContext, useEffect } from "react";
import { useParams } from "react-router";
import * as Sentry from "@sentry/react";
import { useQuery } from "@apollo/client";
import { GET_USER_AUDITS } from "src/api/user";
import { GetUserAudits, GetUserAuditsVariables } from "src/api/generated/GetUserAudits";
import { AuditList } from "src/screens/dashboard/users/user/audits/AuditList";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import * as Screens from "src/screens";

interface Params {
    customerid: string;
    userid: string;
}

const UserAuditsBase = () => {
    const { customerid, userid } = useParams<Params>();

    const { t } = useTranslation();
    const { navStore } = useContext(RootStoreContext);

    useEffect(() => {
        navStore.setTitle("Audits");
        navStore.setBackbutton(`/users/${customerid}/${userid}`, "Benutzer-Details");
    }, [navStore, customerid, userid]);

    const { data, loading, error } = useQuery<GetUserAudits, GetUserAuditsVariables>(GET_USER_AUDITS, {
        variables: {
            userid: userid,
            customerid: customerid
        }
    });

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata")} />;
    }

    return (
        <React.Fragment>
            <UI.Container className="pt-4">
                <UI.Row className="justify-content-md-center">
                    <UI.Col md={8}>
                        <UI.Card>
                            <div>
                                <AuditList audits={data.audits} />
                            </div>
                        </UI.Card>
                    </UI.Col>
                </UI.Row>
            </UI.Container>
        </React.Fragment>
    );
};

export const UserAudits = Sentry.withProfiler(UserAuditsBase);
