import React, { useContext, useCallback } from "react";
import { UI } from "@wwimmo/ui";
import { RootStoreContext } from "src/stores/RootStore";
import { useTranslation } from "react-i18next";
import styles from "./ManageUser.module.css";
import { ImportUsersProgressModal } from "./import-users/ImportUsersProgressModal";
import * as Screens from "src/screens";
import { GET_USERIMPORTPROCESSES } from "src/api/users";
import { GetUserImportprocesses, GetUserImportprocessesVariables } from "src/api/generated/GetUserImportprocesses";
import { useQuery } from "@apollo/client";

interface ManageUserProps {
    customerId?: string;
}

const ManageUser = (props: ManageUserProps) => {
    const { t } = useTranslation();
    const { importUsersProgressModalStore } = useContext(RootStoreContext);

    const { data, loading, error } = useQuery<GetUserImportprocesses, GetUserImportprocessesVariables>(
        GET_USERIMPORTPROCESSES,
        {
            variables: {
                customerid: props.customerId
            }
        }
    );

    const onClickUserImportButton = useCallback(() => {
        importUsersProgressModalStore.setisImportUsersProgressModalDisplayed(true);
    }, [importUsersProgressModalStore]);

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata")} />;
    }

    const importData =
        data.users && data.users[0] && data.users[0].importdata && data.users[0].importdata.users
            ? data.users[0].importdata.users
            : [];

    importUsersProgressModalStore.setUserimportprocessesId(data.users && data.users[0] ? data.users[0].id : "");

    return (
        <>
            <UI.Button
                label={t("user_import.title")}
                className={styles.buttonUserImport}
                onClick={onClickUserImportButton}
            />
            <ImportUsersProgressModal importdata={importData} customerid={props.customerId || ""} />
        </>
    );
};

export default ManageUser;
