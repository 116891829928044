import React, { useContext, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "src/stores/RootStore";
import { UI, ScreenSize } from "@wwimmo/ui";
import "src/components/tiles/charts/GenericList.css";

interface GenericListProps {
    height: number;
    data: GenericListData;
}

interface GenericListData {
    labels: string[];
    datasets: {
        label: string;
        hint: string | null;
    }[];
    centerText?: string;
}

interface DatasetItem {
    label: string;
    hint: string | null;
}

const GenericListBase = (props: GenericListProps) => {
    const { uiStore } = useContext(RootStoreContext);

    const renderItem = useCallback((item: DatasetItem, screenSize: ScreenSize) => {
        if (item.hint) {
            let listRows: React.ReactElement[] = [
                <UI.List.Row key={"r-1"}>
                    <UI.List.Cell key={"c-1"} colspan={3} value={item.label} className={"ListLabel"} />
                    <UI.List.Cell key={"c-2"} colspan={2} value={item.hint} className={"ListHint"} />
                </UI.List.Row>
            ];

            return <UI.List.Item key={item.label} screenSize={screenSize} rows={listRows} />;
        }
    }, []);

    const renderHeader = useCallback(
        (screenSize: ScreenSize) => {
            const headerRows: React.ReactElement[] = [
                <UI.List.Row key={"row-1"}>
                    <UI.List.Cell key={"c-1"} colspan={3} value={props.data.datasets[0].label || "-"} />
                    <UI.List.Cell key={"c-2"} colspan={2} value={props.data.datasets[0].hint || "-"} />
                </UI.List.Row>
            ];

            return <UI.List.Header rows={headerRows} />;
        },
        [props.data.datasets]
    );

    const limit = (props.height - 47.5) / 60;
    const items = [];

    for (let i = 1; i < limit; i++) {
        items.push(props.data.datasets[i]);
    }

    return (
        <div>
            <UI.List.BasicList
                screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
                items={items}
                renderItem={renderItem}
                renderHeader={renderHeader}
            />
        </div>
    );
};

export const GenericList = observer(GenericListBase);
