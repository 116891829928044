import React, { useContext, useEffect } from "react";
import { useParams } from "react-router";
import * as Sentry from "@sentry/react";
import { useQuery, useMutation, ApolloError } from "@apollo/client";
import { GET_CUSTOMER_FAULTY_FILES, DELETE_FILE } from "src/api/customer";
import { GetCustomerFaultyFiles, GetCustomerFaultyFilesVariables } from "src/api/generated/GetCustomerFaultyFiles";
import { DeleteFile, DeleteFileVariables } from "src/api/generated/DeleteFile";
import { FaultyFileList } from "src/screens/dashboard/customers/details/faulty-files/list/FaultyFileList";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { MessageType } from "src/components/notifications/Notifier";
import * as Screens from "src/screens";

interface Params {
    customerid: string;
}

const CustomerFaultyFilesBase = () => {
    const { customerid } = useParams<Params>();

    const { t } = useTranslation();
    const { navStore, uiStore } = useContext(RootStoreContext);

    useEffect(() => {
        navStore.setTitle("Fehlerhafte Dateien");
        navStore.setBackbutton(`/customers/${customerid}`, "Kunde");
    }, [navStore, customerid]);

    const { data, loading, error, refetch } = useQuery<GetCustomerFaultyFiles, GetCustomerFaultyFilesVariables>(
        GET_CUSTOMER_FAULTY_FILES,
        {
            variables: {
                customerid: customerid
            }
        }
    );

    useEffect(() => {
        refetch();
    }, [refetch]);

    const [deleteFileMutation, { loading: isFileDeleting }] = useMutation<DeleteFile, DeleteFileVariables>(
        DELETE_FILE,
        {
            onCompleted: (data: DeleteFile) => {
                uiStore.printStatusMessage("Die Datei wurde erfolgreich gelöscht", MessageType.INFO);
                refetch();
            },
            onError: (error: ApolloError) => {
                uiStore.printStatusMessage(
                    `Es gab einen Fehler beim Löschen der Datei: ${error.message}`,
                    MessageType.ERROR
                );
            },
            update: (cache, { data }) => {
                const deletedId = data?.delete_file_files?.returning[0].id;

                cache.modify({
                    fields: {
                        file_files(existingFileRefs, { readField }) {
                            return existingFileRefs.filter(
                                (existingFileRef: any) => deletedId !== readField("id", existingFileRef)
                            );
                        }
                    }
                });
            }
        }
    );

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata")} />;
    }

    return (
        <React.Fragment>
            <UI.Container className="pt-4">
                <UI.Row className="justify-content-md-center">
                    <UI.Col md={8}>
                        <UI.Card>
                            <div>
                                <FaultyFileList
                                    faultyFiles={data.faulty_files}
                                    deleteFileMutation={deleteFileMutation}
                                    isFileDeleting={isFileDeleting}
                                />
                            </div>
                        </UI.Card>
                    </UI.Col>
                </UI.Row>
            </UI.Container>
        </React.Fragment>
    );
};

export const CustomerFaultyFiles = Sentry.withProfiler(observer(CustomerFaultyFilesBase));
