import React, { useContext, useCallback, FunctionComponent, useState, useMemo, useRef } from "react";
import * as Sentry from "@sentry/react";
import { UI } from "@wwimmo/ui";
import { RootStoreContext } from "src/stores/RootStore";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { UPSERT_UCCM_ANNOUNCEMENT, DELETE_ANNOUNCEMENT } from "src/api/featureAnnouncement";
import { UpsertUccmAnnouncement, UpsertUccmAnnouncementVariables } from "src/api/generated/UpsertUccmAnnouncement";
import { DeleteAnnouncement, DeleteAnnouncementVariables } from "src/api/generated/DeleteAnnouncement";
import { MessageType } from "src/components/notifications/Notifier";
import { Role as CustomerRole } from "src/network/User";
import {
    GetFeatureAnnouncementDetails_announcement as uccmAnnouncement,
    GetFeatureAnnouncementDetails_announcementstates as uccmAnnouncementStates,
    GetFeatureAnnouncementDetails_customeroptions as umsCustomerOptions
} from "src/api/generated/GetFeatureAnnouncementDetails";
import { getDateForInput } from "src/utils/Date";
import styles from "./FeatureAnnouncementForm.module.css";
import { selectRoute, Route } from "src/config/routes";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { FORM_STATUS } from "src/utils/Form";
import { useSaveUnsavedChangesInForm } from "src/hooks/save-unsaved-changes-in-form/useSaveUnsavedChangesInForm";
import { useSaveFormByShortcut } from "src/hooks/form/useSaveFormByShortcut";
import { FeatureAnnouncementContentList } from "src/screens/dashboard/feature-announcements/details/content/list/FeatureAnnouncementContentList";

type FeatureAnnouncementFormProps = {
    featureAnnouncementDetails?: uccmAnnouncement;
    announcementId: string;
    onUpsertFeatureAnnouncement: any;
    userRole?: CustomerRole;
    announcementStates: uccmAnnouncementStates[];
    customerOptions: umsCustomerOptions[];
};

interface FeatureAnnouncementFormFields {
    id: string;
    name: string;
    targetproduct: number;
    state: number;
    startdate: string;
    enddate: string;
    apiversionfrom: number;
    appversionfrom: number;
    targetoption: number;
    announcementroles: number[];
}

enum InspectionAppUserRole {
    "Abnahme Benutzer" = 20,
    "Abnahme Administrator" = 21
}

enum PortalUserRole {
    "Bewirtschafter im Portal" = 30,
    "Eigentümer im Portal" = 31,
    "Stockwerkeigentümer im Portal" = 32,
    "Mieter im Portal" = 33,
    "Bewirtschafter mit Administratorrechten im Portal" = 39
}

enum Product {
    INSPECTION = 1,
    PORTAL = 2
}

enum AnnouncementState {
    NEW = 10,
    PUBLISHED = 20,
    COMPLETED = 30,
    DEACTIVATED = 99
}

const FeatureAnnouncementFormBase: FunctionComponent<FeatureAnnouncementFormProps> = (props) => {
    const { uiStore } = useContext(RootStoreContext);
    const history = useHistory();

    const [isNewFeatureAnnouncement, setIsNewFeatureAnnouncement] = useState<boolean>(
        props.featureAnnouncementDetails ? false : true
    );
    const [currentStatus, setCurrentStatus] = useState<FORM_STATUS>(FORM_STATUS.NONE);

    const {
        announcementId,
        featureAnnouncementDetails,
        userRole,
        onUpsertFeatureAnnouncement,
        announcementStates,
        customerOptions
    } = props;

    const [upsertUccmAnnouncementMutation] = useMutation<UpsertUccmAnnouncement, UpsertUccmAnnouncementVariables>(
        UPSERT_UCCM_ANNOUNCEMENT,
        {
            onCompleted: () => {
                uiStore.printStatusMessage("Announcement wurde erfolgreich gespeichert", MessageType.INFO);
            }
        }
    );

    const [deleteAnnouncementMutation] = useMutation<DeleteAnnouncement, DeleteAnnouncementVariables>(
        DELETE_ANNOUNCEMENT,
        {
            onCompleted: () => {
                uiStore.printStatusMessage("Das Announcement wurde erfolgreich gelöscht", MessageType.INFO);
            }
        }
    );

    const onDeleteAnnouncement = useCallback(async () => {
        await deleteAnnouncementMutation({
            variables: {
                announcementid: announcementId
            }
        });

        uiStore.setHasNewAnnouncementData(true);
        history.replace(selectRoute(Route.featureAnnouncements, null));
    }, [announcementId, deleteAnnouncementMutation, history, uiStore]);

    const announcementDeleteDialog = (
        <UI.ConfirmationDialog
            key="announcementDeleteDialog"
            buttonText="Löschen"
            modalTitle="Announcement löschen"
            confirmationQuestion="Willst du das Announcement wirklich löschen?"
            inProgressText="Announcement wird gelöscht..."
            onConfirmation={onDeleteAnnouncement}
            buttonClasses={`${styles.FormButton} ${styles.Delete} customer-button ml-sm-2 mt-2 mt-sm-0`}
            isDangerousOperation
        />
    );

    const featureAnnouncementRoles = useMemo(
        () =>
            featureAnnouncementDetails?.announcementroles
                ? featureAnnouncementDetails.announcementroles.map((announcementrole) => announcementrole.role)
                : [],
        [featureAnnouncementDetails?.announcementroles]
    );

    const { register, handleSubmit, formState, reset, watch, errors } = useForm<FeatureAnnouncementFormFields>({
        mode: "onBlur",
        defaultValues: {
            id: announcementId,
            name: featureAnnouncementDetails?.name ?? "",
            targetproduct: featureAnnouncementDetails?.targetproduct ?? 0,
            state: featureAnnouncementDetails?.state ?? AnnouncementState.NEW,
            startdate: featureAnnouncementDetails?.startdate
                ? getDateForInput(featureAnnouncementDetails?.startdate)
                : "",
            enddate: featureAnnouncementDetails?.enddate ? getDateForInput(featureAnnouncementDetails?.enddate) : "",
            apiversionfrom: featureAnnouncementDetails?.apiversionfrom ?? 1.0,
            appversionfrom: featureAnnouncementDetails?.appversionfrom ?? 3.0,
            targetoption: featureAnnouncementDetails?.targetoption ?? 0,
            announcementroles: featureAnnouncementRoles
        }
    });

    const { dirtyFields, isDirty } = formState;

    const refSubmitButtom = useRef<HTMLButtonElement>(null);

    const saveForm = useCallback(() => {
        refSubmitButtom?.current?.click();
    }, []);

    useSaveFormByShortcut({ saveFormFunction: saveForm, isFormDirty: isDirty });

    const { saveFormModal, closeSaveFormModalAndGoBack } = useSaveUnsavedChangesInForm({
        saveFormFunction: saveForm,
        currentFormStatus: currentStatus,
        isFormDirty: isDirty
    });

    const watchTargetProduct = watch("targetproduct");

    const resetForm = useCallback(
        (e: any) => {
            e.preventDefault();

            if (props.featureAnnouncementDetails) {
                reset({
                    id: announcementId,
                    name: featureAnnouncementDetails?.name ?? "",
                    targetproduct: featureAnnouncementDetails?.targetproduct ?? 0,
                    state: featureAnnouncementDetails?.state ?? AnnouncementState.NEW,
                    startdate: getDateForInput(featureAnnouncementDetails?.startdate) ?? "",
                    enddate: getDateForInput(featureAnnouncementDetails?.enddate) ?? "",
                    apiversionfrom: featureAnnouncementDetails?.apiversionfrom ?? 1.0,
                    appversionfrom: featureAnnouncementDetails?.appversionfrom ?? 3.0,
                    targetoption: featureAnnouncementDetails?.targetoption ?? 0,
                    announcementroles: featureAnnouncementRoles
                });
            } else {
                reset({
                    id: announcementId,
                    name: "",
                    targetproduct: 0,
                    state: AnnouncementState.NEW,
                    startdate: "",
                    enddate: "",
                    apiversionfrom: 1.0,
                    appversionfrom: 3.0,
                    targetoption: 0,
                    announcementroles: []
                });
            }
        },
        [
            reset,
            announcementId,
            props.featureAnnouncementDetails,
            featureAnnouncementDetails?.name,
            featureAnnouncementDetails?.apiversionfrom,
            featureAnnouncementDetails?.appversionfrom,
            featureAnnouncementDetails?.enddate,
            featureAnnouncementDetails?.startdate,
            featureAnnouncementDetails?.state,
            featureAnnouncementDetails?.targetoption,
            featureAnnouncementDetails?.targetproduct,
            featureAnnouncementRoles
        ]
    );

    const handleFormSubmit = useCallback(
        async (formData: FeatureAnnouncementFormFields) => {
            setCurrentStatus(FORM_STATUS.SAVING_FORM);

            if (Number(formData.targetproduct) === 0) {
                uiStore.printStatusMessage("Bitte wähle ein Produkt aus", MessageType.ERROR);
                return;
            }

            if (formData.startdate === "" || formData.enddate === "") {
                uiStore.printStatusMessage("Bitte wähle ein Start- und/oder Enddatum aus", MessageType.ERROR);
                return;
            }

            const selectedAnnouncementRoles: number[] = [];

            formData.announcementroles.forEach((isAnnouncementRoleSelected, roleId) => {
                if (isAnnouncementRoleSelected) {
                    selectedAnnouncementRoles.push(Number(roleId));
                }
            });

            const announcementRoleObjects = selectedAnnouncementRoles.map((announcementRole) => {
                return {
                    role: announcementRole
                };
            });

            const updatedFeatureAnnouncementDetails: any = {
                ...formData,
                apiversionfrom: Number(formData.apiversionfrom),
                appversionfrom: formData.appversionfrom ? Number(formData.appversionfrom) : 3.0,
                state: isNewFeatureAnnouncement ? AnnouncementState.NEW : Number(formData.state),
                targetoption: Number(formData.targetoption) === 0 ? null : Number(formData.targetoption),
                targetproduct: Number(formData.targetproduct),
                announcementroles: {
                    data: announcementRoleObjects
                }
            };

            if (formData.id === "") {
                delete updatedFeatureAnnouncementDetails.id;
            }

            try {
                await upsertUccmAnnouncementMutation({
                    variables: {
                        announcement: updatedFeatureAnnouncementDetails,
                        announcementid: announcementId
                    }
                });

                onUpsertFeatureAnnouncement();

                if (isNewFeatureAnnouncement) {
                    uiStore.setHasNewAnnouncementData(true);
                    setIsNewFeatureAnnouncement(false);
                }

                reset(
                    {
                        ...formData
                    },
                    {
                        isDirty: false,
                        dirtyFields: false
                    }
                );
            } catch (error) {
                uiStore.printStatusMessage(
                    `Fehler bei der Aktualisierung der Verbindungsdaten: ${error.message}`,
                    MessageType.ERROR
                );
            } finally {
                setCurrentStatus(FORM_STATUS.NONE);
                closeSaveFormModalAndGoBack();
            }
        },
        [
            isNewFeatureAnnouncement,
            onUpsertFeatureAnnouncement,
            reset,
            uiStore,
            announcementId,
            upsertUccmAnnouncementMutation,
            closeSaveFormModalAndGoBack
        ]
    );

    const renderInspectionAppUserRoleCheckboxes = useCallback(() => {
        const checkboxComponents = [];

        for (const inspectionAppUserRoleNumberString in InspectionAppUserRole) {
            if (isNaN(Number(inspectionAppUserRoleNumberString))) {
                continue;
            }
            const inspectionAppUserRoleName = InspectionAppUserRole[inspectionAppUserRoleNumberString];
            const inspectionAppUserRoleNumber = Number(inspectionAppUserRoleNumberString);

            const isRoleIncluded = featureAnnouncementRoles.includes(inspectionAppUserRoleNumber) ? true : false;

            let isDirty = false;
            if (dirtyFields.announcementroles) {
                isDirty = dirtyFields.announcementroles[inspectionAppUserRoleNumber] ? true : false;
            }

            checkboxComponents.push(
                <UI.Checkbox
                    key={inspectionAppUserRoleNumber}
                    ref={register}
                    name="announcementroles"
                    label={inspectionAppUserRoleName}
                    checkboxValue={inspectionAppUserRoleNumber}
                    defaultChecked={isRoleIncluded}
                    className={isDirty ? styles.changed : ""}
                    disabled={userRole && userRole === CustomerRole.CUSTOMERSUPPORT}
                />
            );
        }

        return <>{checkboxComponents}</>;
    }, [dirtyFields.announcementroles, featureAnnouncementRoles, register, userRole]);

    const renderPortalUserRoleCheckboxes = useCallback(() => {
        const checkboxComponents = [];

        for (const portalUserRoleNumberString in PortalUserRole) {
            if (isNaN(Number(portalUserRoleNumberString))) {
                continue;
            }
            const portalUserRoleName = PortalUserRole[portalUserRoleNumberString];
            const portalUserRoleNumber = Number(portalUserRoleNumberString);

            const isRoleIncluded = featureAnnouncementRoles.includes(portalUserRoleNumber) ? true : false;

            let isDirty = false;
            if (dirtyFields.announcementroles) {
                isDirty = dirtyFields.announcementroles[portalUserRoleNumber] ? true : false;
            }

            checkboxComponents.push(
                <UI.Checkbox
                    key={portalUserRoleNumber}
                    ref={register}
                    name="announcementroles"
                    label={portalUserRoleName}
                    checkboxValue={portalUserRoleNumber}
                    defaultChecked={isRoleIncluded}
                    className={isDirty ? styles.changed : ""}
                    disabled={userRole && userRole === CustomerRole.CUSTOMERSUPPORT}
                />
            );
        }

        return <>{checkboxComponents}</>;
    }, [dirtyFields.announcementroles, featureAnnouncementRoles, register, userRole]);

    const getAnnouncementStateOptionElements = useCallback((): JSX.Element[] => {
        const announcementStateOptionElements = announcementStates.map((announcementState) => {
            return (
                <option key={announcementState.id} value={announcementState.key ?? 0}>
                    {announcementState.label}
                </option>
            );
        });

        return announcementStateOptionElements;
    }, [announcementStates]);

    const getCustomerOptionOptionElements = useCallback((): JSX.Element[] => {
        let customerOptionOptionElements: JSX.Element[] = [];

        const defaultOptionElement = (
            <option key="customeroption-default" value={0}>
                -
            </option>
        );
        customerOptionOptionElements.push(defaultOptionElement);

        customerOptions.forEach((customerOption) =>
            customerOptionOptionElements.push(
                <option key={customerOption.id} value={customerOption.key ?? 0}>
                    {customerOption.label}
                </option>
            )
        );

        return customerOptionOptionElements;
    }, [customerOptions]);

    const featureAnnouncementForm = (
        <UI.Form>
            <UI.Input
                label={"Announcement ID"}
                ref={register}
                name="id"
                type="text"
                readOnly={true}
                disabled={userRole === CustomerRole.CUSTOMERADMIN}
            />
            <UI.Input
                label={"Name"}
                ref={register({ required: true })}
                name="name"
                errorMsg={errors.name ? "Pflichtfeld!" : undefined}
                className={dirtyFields.name ? styles.changed : ""}
                type="text"
                disabled={userRole === CustomerRole.CUSTOMERADMIN}
            />
            <UI.Input
                label={"Produkt"}
                ref={register}
                name="targetproduct"
                as="select"
                className={dirtyFields.targetproduct ? styles.changed : ""}
                disabled={userRole === CustomerRole.CUSTOMERADMIN}
                defaultValue={0}
            >
                <option value={0}>Produkt auswählen</option>
                <option value={Product.INSPECTION}>Abnahme App</option>
                <option value={Product.PORTAL}>Portal</option>
            </UI.Input>
            <UI.Input
                label={"Status"}
                ref={register}
                name="state"
                as="select"
                className={dirtyFields.state ? styles.changed : ""}
                disabled={userRole === CustomerRole.CUSTOMERADMIN || isNewFeatureAnnouncement}
            >
                {isNewFeatureAnnouncement ? (
                    <>
                        <option value={AnnouncementState.NEW}>Neu</option>
                    </>
                ) : (
                    getAnnouncementStateOptionElements()
                )}
            </UI.Input>

            <UI.Input
                label={"Startdatum"}
                ref={register}
                name="startdate"
                type="date"
                className={dirtyFields.startdate ? styles.changed : ""}
                disabled={userRole === CustomerRole.CUSTOMERADMIN}
            />

            <UI.Input
                label={"Enddatum"}
                ref={register}
                name="enddate"
                type="date"
                className={dirtyFields.enddate ? styles.changed : ""}
                disabled={userRole === CustomerRole.CUSTOMERADMIN}
            />

            <UI.Input
                label={"Ab API Version"}
                ref={register}
                name="apiversionfrom"
                type="text"
                className={dirtyFields.apiversionfrom ? styles.changed : ""}
                disabled={userRole === CustomerRole.CUSTOMERADMIN}
            />

            {Number(watchTargetProduct) === Product.INSPECTION ? (
                <UI.Input
                    label={"Ab App Version"}
                    ref={register}
                    name="appversionfrom"
                    type="text"
                    className={dirtyFields.appversionfrom ? styles.changed : ""}
                    disabled={userRole === CustomerRole.CUSTOMERADMIN}
                />
            ) : undefined}

            <UI.Input
                label={"Einschränkung auf Customeroption"}
                ref={register}
                name="targetoption"
                as="select"
                className={dirtyFields.targetoption ? styles.changed : ""}
                disabled={userRole === CustomerRole.CUSTOMERADMIN}
                defaultValue={0}
            >
                {getCustomerOptionOptionElements()}
            </UI.Input>
            <UI.Input id="features" type="checkbox" label={"Zielgruppe Userrollen"}>
                {Number(watchTargetProduct) === Product.PORTAL
                    ? renderPortalUserRoleCheckboxes()
                    : renderInspectionAppUserRoleCheckboxes()}
            </UI.Input>
            <UI.Button
                type="submit"
                label={currentStatus === FORM_STATUS.SAVING_FORM ? "Speichert..." : "Speichern"}
                disabled={!formState.isDirty || currentStatus === FORM_STATUS.SAVING_FORM}
                onClick={handleSubmit(handleFormSubmit)}
                className={`${styles.FormButton} customer-button`}
                ref={refSubmitButtom}
            />
            <UI.Button
                label={"Zurücksetzen"}
                disabled={!formState.isDirty || currentStatus === FORM_STATUS.SAVING_FORM}
                onClick={resetForm}
                className={`${styles.FormButton} customer-button ml-sm-2 mt-2 mt-sm-0`}
            />
            {featureAnnouncementDetails?.state === AnnouncementState.NEW ? announcementDeleteDialog : undefined}
            {props.children}
        </UI.Form>
    );

    return (
        <React.Fragment>
            <UI.Container className="pt-4">
                <UI.Row>
                    <UI.Col md={9}>
                        {userRole ? (
                            userRole === CustomerRole.CUSTOMERDEV || userRole === CustomerRole.UCCMADMIN ? (
                                <>
                                    <UI.Card>
                                        {saveFormModal}
                                        {featureAnnouncementForm}
                                    </UI.Card>
                                    {featureAnnouncementDetails?.announcements_mls ? (
                                        <UI.Card title="Inhalte">
                                            <FeatureAnnouncementContentList
                                                announcementsMls={featureAnnouncementDetails?.announcements_mls}
                                            />
                                        </UI.Card>
                                    ) : undefined}
                                </>
                            ) : undefined
                        ) : undefined}
                    </UI.Col>
                    <UI.Col md={3}>
                        <div>Statistiken</div>
                    </UI.Col>
                </UI.Row>
            </UI.Container>
        </React.Fragment>
    );
};

export const FeatureAnnouncementForm = Sentry.withProfiler(observer(FeatureAnnouncementFormBase));
