import { gql } from "@apollo/client";

export const GET_USERS = gql`
    query GetUsers {
        users: ums_v_customer_users(order_by: { name: asc }) {
            customerid
            userid
            email
            name
            customername
            registered
            activated
            system
            blocked
            erpcloud
        }
    }
`;

export const GET_USERIMPORTPROCESSES = gql`
    query GetUserImportprocesses($customerid: uuid) {
        users: ums_userimportprocesses(where: { customerid: { _eq: $customerid }, _and: { state: { _eq: 1 } } }) {
            id
            state
            workflowinstance
            importdata
        }
    }
`;

export const UPDATE_USERIMPORTDATA = gql`
    mutation UpsertUserImportprocesses($id: uuid!, $customerid: uuid, $importdata: jsonb!) {
        update_ums_userimportprocesses(
            where: { id: { _eq: $id }, _and: { customerid: { _eq: $customerid } } }
            _set: { usertoimport: $importdata }
        ) {
            affected_rows
        }
    }
`;

export const INSERT_USERIMPORTDATA = gql`
    mutation InsertUserImportprocesses($userimport: ums_userimportprocesses_insert_input!) {
        insert_ums_userimportprocesses_one(object: $userimport) {
            id
            customerid
        }
    }
`;

export const GET_USEREMAILLISTSTATE = gql`
    query GetUserEmailListState($email: _varchar!, $customerid: uuid!) {
        ums_user_email_state_function: ums_user_email_list_state_function(
            args: { pemail: $email, pcustomerid: $customerid }
        ) {
            email
            user_email_state
        }
    }
`;
