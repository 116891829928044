import React, { useContext, useCallback } from "react";
import { GetServices_services as uccmServices } from "src/api/generated/GetServices";
import { useTranslation } from "react-i18next";
import { ServiceState, ServiceType } from "src/utils/Enums";
import { RootStoreContext } from "src/stores/RootStore";
import { Route } from "src/config/routes";
import { observer } from "mobx-react-lite";
import { UI, ScreenSize } from "@wwimmo/ui";

interface ServiceListProps {
    uccmServices: uccmServices[];
    onSearch: (searchArguments: { searchQuery?: string; filterItemValue?: string; sortItemValue?: string }) => void;
    searchAppendElement?: React.ReactNode;
}

const ServiceList = (props: ServiceListProps) => {
    const { t } = useTranslation();
    const { uiStore, navStore } = useContext(RootStoreContext);

    const listIdentifier = "servicelist";

    if (!navStore.listMap.has(listIdentifier)) {
        navStore.setListMapValue(listIdentifier, {});
    }

    const listParameters = navStore.getListMapValue(listIdentifier);

    let currentSearchQuery: string | undefined = undefined;

    if (listParameters && listParameters.searchQuery) {
        currentSearchQuery = listParameters.searchQuery;
    }

    const onRowClick = useCallback(() => {
        navStore.setListMapValue(
            listIdentifier,
            listParameters
                ? { ...listParameters, scrollPosition: window.pageYOffset }
                : { scrollPosition: window.pageYOffset }
        );

        navStore.setBackbutton(Route.services, t("screens.dashboard.nav.services"));
    }, [navStore, t, listParameters]);

    const onPageChanged = useCallback(
        (currentPage: number) => {
            navStore.setListMapValue(
                listIdentifier,
                listParameters
                    ? { ...listParameters, scrollPosition: window.pageYOffset, paginationPage: currentPage }
                    : { scrollPosition: window.pageYOffset, paginationPage: currentPage }
            );
        },
        [navStore, listParameters]
    );

    const renderItem = useCallback(
        (service: uccmServices, screenSize: ScreenSize) => {
            let listRows: React.ReactElement[] = [];

            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={service.name ?? ""} />
                            <UI.List.Cell key={"c-2"} colspan={1} value={ServiceType[service.type]} />
                            <UI.List.Cell key={"c-3"} colspan={1} value={service.chargeable ? "Ja" : "Nein"} />
                            <UI.List.Cell key={"c-4"} colspan={1} value={ServiceState[service.state]} />
                        </UI.List.Row>
                    ];
                    break;
                case ScreenSize.MOBILE:
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={service.name ? service.name : ""} />
                        </UI.List.Row>
                    ];
                    break;
            }

            const link = `/services/${service.id}`;

            return (
                <UI.List.Item key={service.id} screenSize={screenSize} rows={listRows} to={link} onClick={onRowClick} />
            );
        },
        [onRowClick]
    );

    const renderHeader = useCallback(
        (screenSize: ScreenSize) => {
            let headerRows: React.ReactElement[] = [];

            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    headerRows = [
                        <UI.List.Row key={"row-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={t("screens.dashboard.services.form.name")} />
                            <UI.List.Cell key={"c-2"} colspan={1} value={t("screens.dashboard.services.form.type")} />
                            <UI.List.Cell
                                key={"c-3"}
                                colspan={1}
                                value={t("screens.dashboard.services.form.chargeable")}
                            />
                            <UI.List.Cell key={"c-4"} colspan={1} value={t("screens.dashboard.services.form.state")} />
                        </UI.List.Row>
                    ];
                    break;

                case ScreenSize.MOBILE:
                    headerRows = [
                        <UI.List.Row key={"row-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={t("screens.dashboard.services.form.name")} />
                        </UI.List.Row>
                    ];
                    break;
            }

            return <UI.List.Header rows={headerRows} sticky={true} top={130} />;
        },
        [t]
    );

    return (
        <UI.List.BasicList
            screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
            items={props.uccmServices}
            renderItem={renderItem}
            renderHeader={renderHeader}
            scrollPosition={listParameters?.scrollPosition ?? undefined}
            itemsPerPage={25}
            initialPage={listParameters?.paginationPage ?? undefined}
            onPageChanged={onPageChanged}
            onSearch={props.onSearch}
            searchAppendElement={props.searchAppendElement}
            initialSearchQuery={currentSearchQuery}
            searchCols={8}
            searchAppendElementCols={4}
        />
    );
};

export default observer(ServiceList);
