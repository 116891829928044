import React, { useContext, useCallback, FunctionComponent, useState, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { UPSERT_UMS_CONNECTION, DELETE_CONNECTION } from "src/api/customer";
import { UpsertUmsConnection, UpsertUmsConnectionVariables } from "src/api/generated/UpsertUmsConnection";
import { DeleteConnection, DeleteConnectionVariables } from "src/api/generated/DeleteConnection";
import { MessageType } from "src/components/notifications/Notifier";
import { NetworkConfig } from "src/network/NetworkConfig";
import { getRoleKey, Role as CustomerRole } from "src/network/User";
import {
    GetConnectionDetails_ums_connections as umsConnection,
    GetConnectionDetails_testconnections,
    GetConnectionDetails_existing_connections_existing_types
} from "src/api/generated/GetConnectionDetails";
import { useHistory } from "react-router-dom";
import { Route as CustomerRoutes, selectRoute } from "src/config/routes";
import { InfoContainer } from "src/screens/dashboard/customers/details/detail/info-container/InfoContainer";
import { CustomerType } from "src/utils/Enums";
import styles from "./CustomerConnectionForm.module.css";

type CustomerConnectionFormProps = {
    connectionDetails?: umsConnection;
    customerid: string;
    connectionid: string;
    onUpsertConnection: any;
    userRole?: CustomerRole;
    customerType: CustomerType;
    testConnections?: GetConnectionDetails_testconnections[];
    existingConnectionTypes?: GetConnectionDetails_existing_connections_existing_types[];
};

interface CustomerConnectionFormFields {
    type: number;
    extradata: string;
    filecabinet: string;
    hostname: string;
    id: string;
    password: string;
    username: string;
    category: number;
    identityhost: string;
    elo_body: string;
}

enum TYPE {
    DOCUWARE = 1,
    CANON = 2,
    EMONITOR = 3,
    SERVICE7000 = 4,
    YAROWA = 5,
    COLIBRIEF = 6,
    EDOCSCAN = 7,
    ELO = 8
}

enum CATEGORY {
    DMS = 1,
    RENTAL = 2,
    SERVICEPROVIDER = 3
}

enum STATUS {
    NONE,
    SAVING_FORM,
    TESTING_CONNECTION,
    REGISTERING_WEBHOOKS
}

const CustomerConnectionFormBase: FunctionComponent<CustomerConnectionFormProps> = (props) => {
    const { t } = useTranslation();
    const history = useHistory();

    const { uiStore, authStore } = useContext(RootStoreContext);
    const [isNewConnection, setIsNewConnection] = useState<boolean>(props.connectionDetails ? false : true);
    const [currentStatus, setCurrentStatus] = useState<STATUS>(STATUS.NONE);
    const [isAccountIdEmpty, setisAccountIdEmpty] = useState<boolean>(false);

    const [upsertUmsConnectionMutation] = useMutation<UpsertUmsConnection, UpsertUmsConnectionVariables>(
        UPSERT_UMS_CONNECTION
    );

    const [deleteConnectionMutation] = useMutation<DeleteConnection, DeleteConnectionVariables>(DELETE_CONNECTION, {
        onCompleted: () => {
            uiStore.printStatusMessage("Verbindung erfolgreich gelöscht", MessageType.INFO);
        }
    });

    const { userRole, onUpsertConnection, customerType, testConnections } = props;

    const onDeleteConnection = useCallback(async () => {
        await deleteConnectionMutation({
            variables: {
                connectionid: props.connectionid
            }
        });

        uiStore.setHasNewConnectionData(true);
        history.replace(
            selectRoute(CustomerRoutes.customerDetailsOverview, null, {
                customerid: props.customerid
            })
        );
    }, [deleteConnectionMutation, props.connectionid, history, props.customerid, uiStore]);

    const getFilecabinetValue = useCallback(() => {
        if (props.connectionDetails?.extradata && props.connectionDetails?.type) {
            if (props.connectionDetails?.type === TYPE.DOCUWARE) {
                return props.connectionDetails?.extradata.filecabinet;
            }
        }

        return "";
    }, [props.connectionDetails?.type, props.connectionDetails?.extradata]);

    const getEDocScanIdentityHost = useCallback(() => {
        if (props.connectionDetails?.extradata && props.connectionDetails?.type) {
            if (props.connectionDetails?.type === TYPE.EDOCSCAN) {
                return props.connectionDetails?.extradata.identityhost;
            }
        }

        return "";
    }, [props.connectionDetails?.type, props.connectionDetails?.extradata]);

    const getELOBody = useCallback(() => {
        if (props.connectionDetails?.extradata && props.connectionDetails?.type) {
            if (props.connectionDetails?.type === TYPE.ELO) {
                return props.connectionDetails?.extradata.body;
            }
        }

        return "";
    }, [props.connectionDetails?.type, props.connectionDetails?.extradata]);

    const { register, handleSubmit, formState, reset, setValue, watch } = useForm<CustomerConnectionFormFields>({
        mode: "onBlur",
        defaultValues: {
            id: props.connectionid,
            hostname: props.connectionDetails?.hostname ?? "",
            username: props.connectionDetails?.username ?? "",
            password: props.connectionDetails?.password ?? "",
            extradata: props.connectionDetails?.extradata ? JSON.stringify(props.connectionDetails?.extradata) : "",
            type: props.connectionDetails?.type ?? 1,
            category: props.connectionDetails?.category ?? 1,
            filecabinet: getFilecabinetValue(),
            identityhost: getEDocScanIdentityHost(),
            elo_body: getELOBody()
        }
    });

    const EncryptPassword = useCallback(
        async (text: string) => {
            if (authStore.user && authStore.token) {
                const accessToken = authStore.token;
                const tokenType = authStore.tokenType;
                const role = authStore.user?.role;

                const requestBody = {
                    text: text
                };

                try {
                    const fetchResult = await fetch(NetworkConfig.encryptPasswordUrl, {
                        method: "POST",
                        body: JSON.stringify(requestBody),
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `${tokenType} ${accessToken}`,
                            "x-hasura-role": getRoleKey(role)
                        }
                    });

                    if (fetchResult.status === 200) {
                        try {
                            const response = await fetchResult.json();
                            let encryptedPassword = response.data.encryptedtext;

                            return encryptedPassword;
                        } catch (jsonError) {
                            uiStore.printStatusMessage(`jsonError: ${jsonError}`, MessageType.ERROR);
                        }
                    } else {
                        const returnValue = await fetchResult.json();
                        uiStore.printStatusMessage(returnValue.message, MessageType.ERROR);
                    }
                } catch (fetchError) {
                    uiStore.printStatusMessage(fetchError.error, MessageType.ERROR);
                }
            }
        },
        [authStore.token, authStore.tokenType, authStore.user, uiStore]
    );

    const watchConnectionId = watch("id");
    const watchType = watch("type");
    const watchFilecabinet = watch("filecabinet");
    const watchExtradata = watch("extradata");
    const watchCategory = watch("category");
    const watchUsername = watch("username");
    const watchPassword = watch("password");
    const watchHostname = watch("hostname");
    const { dirtyFields } = formState;

    const setFilecabinetOrExtradata = useCallback(
        (connectionType: number, extradata: any) => {
            if (Number(connectionType) === TYPE.DOCUWARE) {
                if (typeof extradata === "object") {
                    if (extradata.filecabinet) {
                        setValue("filecabinet", extradata.filecabinet);
                    } else {
                        setValue("filecabinet", extradata);
                    }
                } else {
                    try {
                        const currentExtradataObject = JSON.parse(extradata);

                        if (currentExtradataObject.filecabinet) {
                            setValue("filecabinet", currentExtradataObject.filecabinet);
                        } else {
                            setValue("filecabinet", extradata);
                        }
                    } catch (error) {
                        setValue("filecabinet", extradata);
                    }
                }
            } else if (Number(connectionType) === TYPE.EDOCSCAN) {
                if (typeof extradata === "object") {
                    if (extradata.identityhost) {
                        setValue("identityhost", extradata.identityhost);
                    } else {
                        setValue("identityhost", extradata);
                    }
                } else {
                    try {
                        const currentExtradataObject = JSON.parse(extradata);

                        if (currentExtradataObject.identityhost) {
                            setValue("identityhost", currentExtradataObject.identityhost);
                        } else {
                            setValue("identityhost", extradata);
                        }
                    } catch (error) {
                        setValue("identityhost", extradata);
                    }
                }
            } else if (Number(connectionType) === TYPE.ELO) {
                if (typeof extradata === "object") {
                    if (extradata.elo_body) {
                        setValue("elo_body", extradata.elo_body);
                    } else {
                        setValue("elo_body", extradata);
                    }
                } else {
                    try {
                        const currentExtradataObject = JSON.parse(extradata);

                        if (currentExtradataObject.elo_body) {
                            setValue("elo_body", currentExtradataObject.elo_body);
                        } else {
                            setValue("elo_body", extradata);
                        }
                    } catch (error) {
                        setValue("elo_body", extradata);
                    }
                }
            } else {
                setValue("extradata", extradata ? JSON.stringify(extradata) : "");
            }
        },
        [setValue]
    );

    useEffect(() => {
        if (!props.connectionDetails) {
            const connection = props.testConnections?.find(
                (testConnection) => testConnection.type === Number(watchType)
            );

            setValue("hostname", connection?.hostname);
            setValue("username", connection?.username);
            setValue("password", connection?.password);
            setFilecabinetOrExtradata(watchType, connection?.extradata);
        }
    }, [watchType, props.testConnections, props.connectionDetails, setValue, setFilecabinetOrExtradata, customerType]);

    const resetForm = useCallback(() => {
        if (props.connectionDetails) {
            const { id, hostname, password, username, extradata, type, category } = props.connectionDetails;
            const filecabinetValue = getFilecabinetValue();
            const identityhost = getEDocScanIdentityHost();
            const elo_body = getELOBody();

            reset({
                id: id ?? undefined,
                hostname: hostname ?? "",
                password: password ?? "",
                username: username ?? "",
                extradata: extradata ? JSON.stringify(extradata) : "",
                filecabinet: filecabinetValue !== "" ? filecabinetValue : extradata,
                type: type ?? 1,
                category: category ?? 1,
                identityhost: identityhost !== "" ? identityhost : extradata,
                elo_body: elo_body !== "" ? elo_body : extradata
            });
        } else {
            reset({
                id: props.connectionid,
                hostname: "",
                password: "",
                username: "",
                extradata: "",
                type: 1,
                category: 1
            });
        }
    }, [reset, props.connectionDetails, getFilecabinetValue, props.connectionid, getEDocScanIdentityHost, getELOBody]);

    const registerWebhookEvents = useCallback(async () => {
        setCurrentStatus(STATUS.REGISTERING_WEBHOOKS);

        if (authStore.user && authStore.token) {
            const accessToken = authStore.token;
            const tokenType = authStore.tokenType;
            const role = authStore.user?.role;

            try {
                const fetchResult = await fetch(
                    `${NetworkConfig.registerEmonitorEventsForCustomerUrl}/${props.customerid}`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `${tokenType} ${accessToken}`,
                            "x-hasura-role": getRoleKey(role)
                        }
                    }
                );

                if (fetchResult.status === 200) {
                    uiStore.printStatusMessage("Webhook Events wurden erfolgreich registriert", MessageType.INFO);
                } else {
                    const returnValue = await fetchResult.json();
                    uiStore.printStatusMessage(
                        `Fehler beim Registrieren der Webhook Events: ${returnValue.message}`,
                        MessageType.ERROR
                    );
                }
            } catch (fetchError) {
                uiStore.printStatusMessage(
                    `Fehler beim Registrieren der Webhook Events: ${fetchError.error}`,
                    MessageType.ERROR
                );
            } finally {
                setCurrentStatus(STATUS.NONE);
            }
        }

        setCurrentStatus(STATUS.NONE);
    }, [authStore.user, authStore.token, authStore.tokenType, uiStore, props.customerid]);

    const handleFormSubmit = useCallback(
        async (formData: CustomerConnectionFormFields) => {
            setCurrentStatus(STATUS.SAVING_FORM);

            const newType = isNewConnection ? formData.type : props.connectionDetails?.type;
            const newCategory = isNewConnection ? formData.category : props.connectionDetails?.category;

            let extradata = {};

            if (formData.filecabinet && Number(newType) === TYPE.DOCUWARE) {
                try {
                    extradata = JSON.parse(formData.filecabinet);

                    if (typeof extradata === "object") {
                        uiStore.printStatusMessage(
                            "Das Feld 'Extradata' ist nicht korrekt formatiert. Es muss ein String eingegeben werden.",
                            MessageType.ERROR
                        );
                        return;
                    } else {
                        extradata = { filecabinet: formData.filecabinet };
                    }
                } catch (error) {
                    extradata = { filecabinet: formData.filecabinet };
                }
            } else if (formData.extradata && Number(newType) === TYPE.EDOCSCAN) {
                try {
                    extradata = JSON.parse(formData.extradata);

                    if (typeof extradata !== "object") {
                        uiStore.printStatusMessage(
                            "Das Feld 'Extradata' ist nicht korrekt formatiert. Es muss ein Objekt (JSON formatiert) eingegeben werden.",
                            MessageType.ERROR
                        );
                        return;
                    } else {
                        extradata = JSON.parse(formData.extradata);
                    }
                } catch (error) {
                    extradata = JSON.parse(formData.extradata);
                }
            } else if (formData.extradata && Number(newType) === TYPE.ELO) {
                try {
                    extradata = JSON.parse(formData.extradata);

                    if (typeof extradata !== "object") {
                        uiStore.printStatusMessage(
                            "Das Feld 'Extradata' ist nicht korrekt formatiert. Es muss ein Objekt (JSON formatiert) eingegeben werden.",
                            MessageType.ERROR
                        );
                        return;
                    } else {
                        extradata = JSON.parse(formData.extradata);
                    }
                } catch (error) {
                    extradata = JSON.parse(formData.extradata);
                }
            } else if (formData.extradata) {
                try {
                    extradata = JSON.parse(formData.extradata);
                } catch (error) {
                    uiStore.printStatusMessage(
                        "Das Feld 'Extradata' ist nicht korrekt formatiert. Es muss ein JSON-String eingegeben werden.",
                        MessageType.ERROR
                    );

                    setCurrentStatus(STATUS.NONE);
                    return;
                }
            }

            let encryptedPassword = undefined;

            if ((isNewConnection && formData.password.length > 0) || dirtyFields.password) {
                encryptedPassword = await EncryptPassword(formData.password);
            }

            const updatedConnectionDetails: any = {
                ...formData,
                type: newType,
                category: newCategory,
                extradata: extradata,
                customerid: props.customerid,
                password: encryptedPassword ?? formData.password
            };

            delete updatedConnectionDetails.filecabinet;
            delete updatedConnectionDetails.identityhost;
            delete updatedConnectionDetails.elo_body;

            if (formData.id === "") {
                delete updatedConnectionDetails.id;
            }

            try {
                await upsertUmsConnectionMutation({
                    variables: {
                        connection: updatedConnectionDetails
                    }
                });

                onUpsertConnection();

                if (isNewConnection) {
                    uiStore.setHasNewConnectionData(true);
                    setIsNewConnection(false);

                    if (Number(newType) === TYPE.EMONITOR) {
                        await registerWebhookEvents();
                    }
                }

                uiStore.printStatusMessage("Die Verbindungsdaten wurden erfolgreich gespeichert", MessageType.INFO);

                let extradataAfterReset = "";

                try {
                    const currentExtradataObj = JSON.parse(formData.extradata);
                    if (currentExtradataObj.filecabinet) {
                        extradataAfterReset = currentExtradataObj.filecabinet;
                    } else if (currentExtradataObj.identityhost) {
                        extradataAfterReset = currentExtradataObj.identityhost;
                    } else if (currentExtradataObj.elo_body) {
                        extradataAfterReset = currentExtradataObj.elo_body;
                    } else {
                        extradataAfterReset = formData.extradata;
                    }
                } catch (error) {
                    extradataAfterReset = formData.extradata;
                }

                const docuwareExtradatavalue =
                    Number(newType) === TYPE.DOCUWARE ? formData.filecabinet : extradataAfterReset;

                const edocscanExtradatavalue =
                    Number(newType) === TYPE.EDOCSCAN ? formData.extradata : extradataAfterReset;

                const eloExtradatavalue = Number(newType) === TYPE.ELO ? formData.extradata : extradataAfterReset;

                const canonExtradatavalue =
                    Number(newType) === TYPE.CANON ||
                    Number(newType) === TYPE.ELO ||
                    Number(newType) === TYPE.EMONITOR ||
                    Number(newType) === TYPE.SERVICE7000 ||
                    Number(newType) === TYPE.YAROWA ||
                    Number(newType) === TYPE.EDOCSCAN
                        ? formData.extradata
                        : `{"filecabinet": "${formData.filecabinet}"}`;

                reset(
                    {
                        ...formData,
                        type: newType,
                        category: newCategory,
                        filecabinet: docuwareExtradatavalue,
                        extradata: canonExtradatavalue,
                        password: encryptedPassword ?? formData.password,
                        identityhost: edocscanExtradatavalue,
                        elo_body: eloExtradatavalue
                    },
                    {
                        isDirty: false,
                        dirtyFields: false
                    }
                );
            } catch (error) {
                uiStore.printStatusMessage(
                    `Fehler bei der Aktualisierung der Verbindungsdaten: ${error.message}`,
                    MessageType.ERROR
                );
            } finally {
                setCurrentStatus(STATUS.NONE);
            }
        },
        [
            reset,
            upsertUmsConnectionMutation,
            props.customerid,
            EncryptPassword,
            dirtyFields,
            isNewConnection,
            uiStore,
            onUpsertConnection,
            registerWebhookEvents,
            props.connectionDetails?.category,
            props.connectionDetails?.type
        ]
    );

    useEffect(() => {
        if (Number(watchType) === TYPE.YAROWA) {
            if (watchExtradata === "") {
                setisAccountIdEmpty(true);
            } else {
                const extradata = JSON.parse(watchExtradata);
                setisAccountIdEmpty(extradata.accountid ? false : true);
            }
        }
        if (Number(watchType) === TYPE.ELO) {
            if (watchExtradata === "") {
                setisAccountIdEmpty(true);
            } else {
                try {
                    const extradata = JSON.parse(watchExtradata);
                    setisAccountIdEmpty(extradata.elo_body ? false : true);
                } catch {
                    setisAccountIdEmpty(true);
                }
            }
        }
        if (Number(watchType) === TYPE.EDOCSCAN) {
            if (watchExtradata === "") {
                setisAccountIdEmpty(true);
            } else {
                try {
                    const extradata = JSON.parse(watchExtradata);
                    setisAccountIdEmpty(extradata.identityhost ? false : true);
                } catch {
                    setisAccountIdEmpty(true);
                }
            }
        }
    }, [watchExtradata, watchType, isAccountIdEmpty, props.connectionDetails?.type]);

    const onChangeCategory = useCallback(
        (e: any) => {
            const newCategory = e.target.value;
            setValue("category", Number(newCategory));
            if (customerType !== CustomerType.Prod) {
                if (
                    Number(watchType) !== TYPE.SERVICE7000 ||
                    Number(watchType) !== TYPE.EMONITOR ||
                    Number(watchType) !== TYPE.YAROWA
                ) {
                    setValue("hostname", 1);
                }
                setValue("username", "");
                setValue("password", "");
                setFilecabinetOrExtradata(Number(watchType), "");
            }
        },
        [setValue, setFilecabinetOrExtradata, customerType, watchType]
    );

    const onChangeType = useCallback(
        (e: any) => {
            const newType = e.target.value;
            const connection = props.testConnections?.find(
                (testConnection) => testConnection.type === Number(watchType)
            );

            setValue("type", Number(newType));

            setValue("hostname", connection?.hostname);
            setValue("username", connection?.username);
            setValue("password", connection?.password);
            setFilecabinetOrExtradata(Number(watchType), connection?.extradata);
        },
        [setValue, setFilecabinetOrExtradata, props.testConnections, watchType]
    );

    const onChangeHostname = useCallback(
        (e: any) => {
            const selectedHostname = e.target.value;

            const selectedTestConnection = testConnections?.find(
                (testConnection) => testConnection.hostname === selectedHostname
            );

            if (selectedTestConnection) {
                setValue("username", selectedTestConnection.username);
                setValue("password", selectedTestConnection.password);
                setFilecabinetOrExtradata(Number(watchType), selectedTestConnection.extradata);
            } else {
                setValue("username", "");
                setValue("password", "");
                setFilecabinetOrExtradata(Number(watchType), "");
            }
        },
        [testConnections, setValue, setFilecabinetOrExtradata, watchType]
    );

    if (watchConnectionId === "" && props.connectionDetails?.id) {
        setValue("id", props.connectionDetails?.id);
    }

    const connectionDeleteDialog = (
        <UI.ConfirmationDialog
            key="connectionDeleteDialog"
            buttonText="Verbindung löschen"
            modalTitle="Verbindung löschen"
            confirmationQuestion="Willst du die Verbindung wirklich löschen?"
            inProgressText="Verbindung wird gelöscht..."
            onConfirmation={onDeleteConnection}
            buttonClasses="w-100 customer-button"
            isDangerousOperation
        />
    );

    const testConnection = useCallback(async () => {
        setCurrentStatus(STATUS.TESTING_CONNECTION);

        const testConnectionSuccessMessage = "Test Verbindung erfolgreich";
        const testConnectionErrorMessage =
            "Test fehlgeschlagen. Bitte versuche es noch einmal, oder überprüfe die Angaben";

        try {
            if (authStore.user && authStore.token) {
                const accessToken = authStore.token;
                const tokenType = authStore.tokenType;
                const role = authStore.user?.role;

                let isPasswordEncrypted = true;

                if (watchPassword !== props.connectionDetails?.password) {
                    isPasswordEncrypted = false;
                }

                const requestBody = {
                    customerid: props.customerid,
                    connectiontype: Number(watchType),
                    username: watchUsername,
                    password: watchPassword,
                    hostname: watchHostname,
                    extradata: watchExtradata,
                    ispasswordencrypted: isPasswordEncrypted
                };

                const fetchResult = await fetch(NetworkConfig.testConnectionUrl, {
                    method: "POST",
                    body: JSON.stringify(requestBody),
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `${tokenType} ${accessToken}`,
                        "x-hasura-role": getRoleKey(role)
                    }
                });

                if (fetchResult.status === 200) {
                    uiStore.printStatusMessage(testConnectionSuccessMessage, MessageType.INFO);
                } else {
                    uiStore.printStatusMessage(testConnectionErrorMessage, MessageType.ERROR);
                }
            }
        } catch (error) {
            uiStore.printStatusMessage(testConnectionErrorMessage, MessageType.ERROR);
        } finally {
            setCurrentStatus(STATUS.NONE);
        }
    }, [
        uiStore,
        authStore.token,
        authStore.tokenType,
        authStore.user,
        props.connectionDetails?.password,
        props.customerid,
        watchExtradata,
        watchHostname,
        watchPassword,
        watchType,
        watchUsername
    ]);

    let disableTestConnectionButton = true;

    if (watchUsername && (props.connectionDetails?.password || watchPassword) && watchHostname) {
        if (Number(watchType) === TYPE.DOCUWARE && (watchExtradata || watchFilecabinet)) {
            disableTestConnectionButton = false;
        } else if (Number(watchType) === TYPE.CANON || Number(watchType) === TYPE.EMONITOR) {
            disableTestConnectionButton = false;
        }
    }

    const testConnectionButton = (
        <UI.Button
            label={currentStatus === STATUS.TESTING_CONNECTION ? "Wird getestet" : "Verbindung testen"}
            className="mt-2 w-100 customer-button"
            onClick={testConnection}
            disabled={currentStatus === STATUS.TESTING_CONNECTION || disableTestConnectionButton}
        />
    );

    const registerWebhookEventsButton = (
        <UI.Button
            label={currentStatus === STATUS.REGISTERING_WEBHOOKS ? "Werden registriert" : "Webhook Events registrieren"}
            className="mt-2 w-100 customer-button"
            onClick={registerWebhookEvents}
            disabled={currentStatus === STATUS.REGISTERING_WEBHOOKS}
        />
    );
    const extraActionButtons = [
        {
            infoHeading: "Zusätzliche Aktionen",
            infoValue: (
                <>
                    {props.connectionDetails ? connectionDeleteDialog : undefined}
                    {testConnectionButton}
                    {!isNewConnection && Number(watchType) === TYPE.EMONITOR ? registerWebhookEventsButton : undefined}
                </>
            )
        }
    ];

    const hostnameOptions: React.ReactNode[] = [];

    if (
        testConnections &&
        testConnections.filter((testConnection) => testConnection.type === Number(watchType)).length > 0
    ) {
        hostnameOptions.push(
            <option key={"defaultOption"} value={"defaultOption"}>
                Verbindung auswählen
            </option>
        );
        testConnections.forEach((testConnection) => {
            if (testConnection.type === Number(watchType)) {
                hostnameOptions.push(
                    <option key={testConnection.id} value={testConnection.hostname ?? ""}>
                        {testConnection.hostname}
                    </option>
                );
            }
        });
    }

    const customerConnectionForm = (
        <UI.Form>
            <UI.Input
                label={t("screens.customer_details.connection.id")}
                ref={register}
                name="id"
                type="text"
                readOnly={true}
                disabled={userRole === CustomerRole.CUSTOMERADMIN}
            />
            <UI.Input
                label={t("screens.customer_details.connection.category")}
                ref={register}
                name="category"
                as="select"
                className={dirtyFields.category ? styles.changed : ""}
                onChange={onChangeCategory}
                disabled={userRole === CustomerRole.CUSTOMERADMIN || !isNewConnection}
            >
                <option value={CATEGORY.DMS}>DMS</option>
                <option value={CATEGORY.RENTAL}>Wiedervermietung</option>
                <option value={CATEGORY.SERVICEPROVIDER}>Dienstleister</option>
            </UI.Input>
            <UI.Input
                label={t("screens.customer_details.connection.type")}
                ref={register}
                name="type"
                as="select"
                className={dirtyFields.type ? styles.changed : ""}
                onChange={onChangeType}
                disabled={userRole === CustomerRole.CUSTOMERADMIN || !isNewConnection}
            >
                {Number(watchCategory) === CATEGORY.DMS ? (
                    <>
                        <option value={TYPE.DOCUWARE}>DMS Docuware</option>
                        <option value={TYPE.CANON}>DMS Canon Therefore</option>
                        <option value={TYPE.COLIBRIEF}>Colibrief</option>
                        <option value={TYPE.EDOCSCAN}>eDocScan</option>
                        <option value={TYPE.ELO}>ELO</option>
                    </>
                ) : Number(watchCategory) === CATEGORY.RENTAL ? (
                    <option value={TYPE.EMONITOR}>emonitor</option>
                ) : (
                    <>
                        <option value={TYPE.SERVICE7000}>Service7000</option>
                        <option value={TYPE.YAROWA}>Yarowa</option>
                    </>
                )}
            </UI.Input>

            {customerType !== CustomerType.Prod &&
            testConnections &&
            testConnections?.length > 0 &&
            hostnameOptions.length > 0 ? (
                <UI.Input
                    label={t("screens.customer_details.connection.hostname")}
                    ref={register}
                    name="hostname"
                    as="select"
                    className={dirtyFields.hostname ? styles.changed : ""}
                    disabled={userRole === CustomerRole.CUSTOMERADMIN}
                    onChange={onChangeHostname}
                    defaultValue="defaultOption"
                >
                    {hostnameOptions}
                </UI.Input>
            ) : (
                <UI.Input
                    label={t("screens.customer_details.connection.hostname")}
                    ref={register}
                    name="hostname"
                    type="text"
                    className={dirtyFields.hostname ? styles.changed : ""}
                    disabled={userRole === CustomerRole.CUSTOMERADMIN}
                />
            )}

            {Number(watchType) === TYPE.DOCUWARE ||
            Number(watchType) === TYPE.CANON ||
            Number(watchType) === TYPE.EDOCSCAN ||
            Number(watchType) === TYPE.ELO ? (
                <UI.Input
                    label={
                        Number(watchType) === TYPE.EDOCSCAN
                            ? t("screens.customer_details.connection.clientid")
                            : t("screens.customer_details.connection.username")
                    }
                    ref={register}
                    name="username"
                    type="text"
                    autoComplete="off"
                    className={dirtyFields.username ? styles.changed : ""}
                    disabled={userRole === CustomerRole.CUSTOMERADMIN}
                />
            ) : (
                <></>
            )}

            <UI.Input
                label={
                    Number(watchType) === TYPE.COLIBRIEF
                        ? t("screens.customer_details.connection.token")
                        : Number(watchType) === TYPE.EDOCSCAN
                        ? t("screens.customer_details.connection.clientsecret")
                        : t("screens.customer_details.connection.password")
                }
                ref={register}
                name="password"
                type="password"
                autoComplete="new-password"
                className={dirtyFields.password ? styles.changed : ""}
                disabled={userRole === CustomerRole.CUSTOMERADMIN}
            />

            {Number(watchType) === TYPE.DOCUWARE ? (
                <UI.Input
                    label={t("screens.customer_details.connection.filecabinet")}
                    ref={register}
                    name="filecabinet"
                    type="text"
                    className={dirtyFields.filecabinet ? styles.changed : ""}
                    disabled={userRole === CustomerRole.CUSTOMERADMIN}
                />
            ) : Number(watchType) === TYPE.CANON ||
              Number(watchType) === TYPE.YAROWA ||
              Number(watchType) === TYPE.ELO ? (
                <UI.Form.Group controlId="extradata" className="mb-4">
                    <UI.Form.Label>{t("screens.customer_details.connection.extradata")}</UI.Form.Label>
                    <UI.InputGroup>
                        <UI.Form.Control
                            ref={register}
                            name="extradata"
                            as="textarea"
                            type="input"
                            className={dirtyFields.extradata ? styles.changed : ""}
                            disabled={userRole === CustomerRole.CUSTOMERADMIN}
                        />
                    </UI.InputGroup>
                    {isAccountIdEmpty && Number(watchType) === TYPE.CANON ? (
                        <p className="alert text-danger">{t("error.emptyExtradataCanon")}</p>
                    ) : undefined}
                    {isAccountIdEmpty && Number(watchType) === TYPE.ELO ? (
                        <p className="alert text-danger">{t("error.emptyExtradataELO")}</p>
                    ) : undefined}
                    {isAccountIdEmpty && Number(watchType) === TYPE.YAROWA ? (
                        <p className="alert text-danger">{t("error.emptyYarowaAccountId")}</p>
                    ) : undefined}
                </UI.Form.Group>
            ) : Number(watchType) === TYPE.CANON ||
              Number(watchType) === TYPE.EDOCSCAN ||
              Number(watchType) === TYPE.ELO ? (
                <UI.Form.Group controlId="extradata" className="mb-4">
                    <UI.Form.Label>{t("screens.customer_details.connection.extradata")}</UI.Form.Label>
                    <UI.InputGroup>
                        <UI.Form.Control
                            ref={register}
                            name="extradata"
                            as="textarea"
                            type="input"
                            className={dirtyFields.extradata ? styles.changed : ""}
                            disabled={userRole === CustomerRole.CUSTOMERADMIN}
                        />
                    </UI.InputGroup>
                    {isAccountIdEmpty && Number(watchType) === TYPE.EDOCSCAN ? (
                        <p className="alert text-danger">{t("error.eDocScanIdProviderHost")}</p>
                    ) : undefined}
                    {isAccountIdEmpty && Number(watchType) === TYPE.ELO ? (
                        <p className="alert text-danger">{t("error.emptyExtradataELO")}</p>
                    ) : undefined}
                    {isAccountIdEmpty && Number(watchType) === TYPE.CANON ? (
                        <p className="alert text-danger">{t("error.emptyExtradataCanon")}</p>
                    ) : undefined}
                </UI.Form.Group>
            ) : (
                <></>
            )}

            <UI.Button
                type="submit"
                label={currentStatus === STATUS.SAVING_FORM ? "Speichert..." : "Speichern"}
                disabled={!formState.isDirty || currentStatus === STATUS.SAVING_FORM || isAccountIdEmpty}
                onClick={handleSubmit(handleFormSubmit)}
                className={`${styles.dmsConnectionButton} customer-button`}
            />
            <UI.Button
                label={"Zurücksetzen"}
                disabled={!formState.isDirty || currentStatus === STATUS.SAVING_FORM}
                onClick={resetForm}
                className={`${styles.dmsConnectionButton} customer-button ml-sm-2 mt-2 mt-sm-0`}
            />
            {props.children}
        </UI.Form>
    );

    return (
        <React.Fragment>
            <UI.Container className="pt-4">
                <UI.Row>
                    <UI.Col md={9}>
                        {userRole ? (
                            userRole === CustomerRole.CUSTOMERDEV || userRole === CustomerRole.CUSTOMERSUPPORT ? (
                                <UI.Card>{customerConnectionForm}</UI.Card>
                            ) : undefined
                        ) : undefined}
                    </UI.Col>
                    <UI.Col md={3}>
                        <InfoContainer
                            key="extra-action-buttons"
                            infoItems={extraActionButtons}
                            className={styles.ConnectionActionButtons}
                        />
                    </UI.Col>
                </UI.Row>
            </UI.Container>
        </React.Fragment>
    );
};

export const CustomerConnectionForm = Sentry.withProfiler(CustomerConnectionFormBase);
