import React, { useContext, useCallback, useState, useEffect } from "react";
import {
    GetUmsCustomerDetailsById_ums_customer_details_connections as ums_connection,
    GetUmsCustomerDetailsById_connectiontypes as connection_types,
    GetUmsCustomerDetailsById_connectioncategories as connection_categories
} from "src/api/generated/GetUmsCustomerDetailsById";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { UI, ScreenSize } from "@wwimmo/ui";
import * as Sentry from "@sentry/react";
import { useParams } from "react-router";
import { Route, selectRoute } from "src/config/routes";
import { v4 as uuidv4 } from "uuid";

interface ConnectionListProps {
    connections: ums_connection[];
    types: connection_types[];
    categories: connection_categories[];
    searchAppendElement?: React.ReactNode;
}

interface Params {
    customerid: string;
}

const ConnectionListBase = (props: ConnectionListProps) => {
    const { t } = useTranslation();
    const { uiStore, navStore } = useContext(RootStoreContext);
    const { customerid } = useParams<Params>();

    const [connectionTypes, setConnectionTypes] = useState<Map<number | null, { label: string }>>();
    const [connectionCategories, setConnectionCategories] = useState<Map<number | null, { label: string }>>();

    let listIdentifier = `connectionlist-${customerid}`;

    if (!navStore.listMap.has(listIdentifier)) {
        navStore.setListMapValue(listIdentifier, {});
    }

    const listParameters = navStore.getListMapValue(listIdentifier);

    const onRowClick = useCallback(() => {
        navStore.setListMapValue(
            listIdentifier,
            listParameters
                ? { ...listParameters, scrollPosition: window.pageYOffset }
                : { scrollPosition: window.pageYOffset }
        );
    }, [navStore, listParameters, listIdentifier]);

    const getEnumerationMap = (enumerations: Array<any>) => {
        const enumerationMap = new Map();

        enumerations.forEach((enumeration) => {
            if (enumeration.key !== null) {
                enumerationMap.set(enumeration.key, {
                    label: enumeration.label
                });
            }
        });

        return enumerationMap;
    };

    useEffect(() => {
        setConnectionTypes(getEnumerationMap(props.types));
        setConnectionCategories(getEnumerationMap(props.categories));
    }, [props.types, props.categories]);

    const renderItem = useCallback(
        (connection: ums_connection, screenSize: ScreenSize) => {
            let listRows: React.ReactElement[] = [];

            const connectionType = connectionTypes?.get(connection.type)?.label;
            const connectionCategory = connectionCategories?.get(connection.category)?.label;

            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell key={"c-2"} colspan={1} value={connectionCategory} />
                            <UI.List.Cell key={"c-1"} colspan={1} value={connectionType} />
                            <UI.List.Cell key={"c-3"} colspan={1} value={connection.username} />
                        </UI.List.Row>
                    ];
                    break;
                case ScreenSize.MOBILE:
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={connectionCategory} />
                            <UI.List.Cell key={"c-2"} colspan={1} value={connection.username} />
                        </UI.List.Row>
                    ];
                    break;
            }

            const link = `/customers/${customerid}/connection/${connection.id}`;

            return (
                <UI.List.Item
                    key={connection.id}
                    screenSize={screenSize}
                    rows={listRows}
                    to={link}
                    onClick={onRowClick}
                />
            );
        },
        [connectionCategories, connectionTypes, customerid, onRowClick]
    );

    const renderHeader = useCallback(
        (screenSize: ScreenSize) => {
            let headerRows: React.ReactElement[] = [];

            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    headerRows = [
                        <UI.List.Row key={"row-1"}>
                            <UI.List.Cell key={"c-2"} colspan={1} value={t("Kategorie")} />
                            <UI.List.Cell key={"c-1"} colspan={1} value={t("Typ")} />
                            <UI.List.Cell key={"c-3"} colspan={1} value={t("Benutzer")} />
                        </UI.List.Row>
                    ];
                    break;

                case ScreenSize.MOBILE:
                    headerRows = [
                        <UI.List.Row key={"row-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={t("Kategorie")} />
                            <UI.List.Cell key={"c-3"} colspan={1} value={t("Benutzer")} />
                        </UI.List.Row>
                    ];
                    break;
            }

            return <UI.List.Header rows={headerRows} sticky={true} top={130} />;
        },
        [t]
    );

    return (
        <>
            <div>
                <UI.Button
                    label={"+ Verbindung hinzufügen"}
                    to={selectRoute(Route.customerConnectionDetails, null, {
                        customerid: customerid,
                        connectionid: uuidv4()
                    })}
                    className={"customer-button mt-2 mt-sm-0 mb-3 ml-auto"}
                    onClick={onRowClick}
                />
            </div>
            <UI.List.BasicList
                screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
                items={props.connections}
                renderItem={renderItem}
                renderHeader={renderHeader}
                scrollPosition={listParameters?.scrollPosition ?? undefined}
                emptyListMessage="Keine Verbindungen"
            />
        </>
    );
};

export const ConnectionList = Sentry.withProfiler(observer(ConnectionListBase));
