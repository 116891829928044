import React from "react";
import { UIStore } from "src/stores/UIStore";
import { NavigationStore } from "src/stores/NavigationStore";
import { create } from "mobx-persist";
import { AuthStore } from "./AuthStore";
import { ImportUsersProgressModalStore } from "./ImportUsersProgressModalStore";
import { InviteUserProgressModalStore } from "./InviteUserProgressModalStore";

export const hydrate = create({
    storage: localStorage,
    jsonify: true
});

export class RootStore {
    uiStore = new UIStore();
    navStore = new NavigationStore();
    authStore = new AuthStore();

    importUsersProgressModalStore: ImportUsersProgressModalStore;
    inviteUserProgressModalStore: InviteUserProgressModalStore;

    constructor() {
        // Pass 'this' to the ImportUsersProgressModalStore constructor
        this.importUsersProgressModalStore = new ImportUsersProgressModalStore(this);
        this.inviteUserProgressModalStore = new InviteUserProgressModalStore(this);
        hydrate("@portal", this.authStore).finally(() => this.authStore.setLoaded(true));
        hydrate("@nav", this.navStore);
    }
}

export const RootStoreContext = React.createContext<RootStore>(undefined!);
