import { gql } from "@apollo/client";

export const GET_USER_DETAIL = gql`
    query GetUserDetails($customerid: uuid, $userid: uuid) {
        user: ums_users(where: { customerid: { _eq: $customerid }, id: { _eq: $userid } }) {
            id
            customerid
            email
            emailverified
            name1
            name2
            customer {
                name
            }
            registered
            registereddate
            registrationcode
            activated
            system
            erpcloud
            erpid
            userroles(distinct_on: role) {
                role
            }
            invitationid
            state
        }
    }
`;

export const GET_USER_AUDITS = gql`
    query GetUserAudits($userid: uuid!, $customerid: uuid!, $limit: Int = 50) {
        audits: ums_audits(
            where: { userid: { _eq: $userid }, _and: { customerid: { _eq: $customerid } } }
            order_by: { timestamp: desc }
            limit: $limit
        ) {
            event
            reason
            timestamp
            ids4_client {
                clientname: ClientName
            }
        }
    }
`;
