import React, { useContext, useEffect, useState, useCallback } from "react";
import * as Sentry from "@sentry/react";
import { useQuery } from "@apollo/client";
import { GET_USERS } from "src/api/users";
import { GetUsers, GetUsers_users as umsUser } from "src/api/generated/GetUsers";
import UserList from "src/screens/dashboard/users/user/list/UserList";
import { observer } from "mobx-react-lite";
import { UI, DropdownItem } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import * as Screens from "src/screens";
import classes from "./UsersOverview.module.css";
import { withRouter, RouteComponentProps } from "react-router";

enum UserSortKey {
    NAME = "1",
    E_MAIL_ADDRESS = "2",
    CUSTOMER_NAME = "3"
}

const UsersOverviewBase = (props: RouteComponentProps) => {
    const { t } = useTranslation();
    const { navStore, uiStore } = useContext(RootStoreContext);

    const listIdentifier = "userlist";

    if (!navStore.listMap.has(listIdentifier)) {
        navStore.setListMapValue(listIdentifier, {});
    }

    const listParameters = navStore.getListMapValue(listIdentifier);

    const [displayedUsers, setDisplayedUsers] = useState<umsUser[]>([]);

    const { data, loading, error, refetch } = useQuery<GetUsers>(GET_USERS);

    const sortTypeDropdownItems: DropdownItem[] = [
        { value: UserSortKey.NAME, label: "Name" },
        { value: UserSortKey.E_MAIL_ADDRESS, label: "E-Mail-Adresse" },
        { value: UserSortKey.CUSTOMER_NAME, label: "Kunde" }
    ];

    useEffect(() => {
        navStore.setTitle("Benutzer");
        navStore.setBackbutton(null);
    }, [navStore]);

    const searchUsers = useCallback(
        (searchQuery: string): umsUser[] | undefined => {
            if (data) {
                searchQuery.toLowerCase();
                const foundUsers = data.users.filter(
                    (user) =>
                        user.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        user.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        user.customername?.toLowerCase().includes(searchQuery.toLowerCase())
                );

                setDisplayedUsers([...foundUsers]);
                return foundUsers;
            }
        },
        [data]
    );

    const getUserCompareValue = useCallback((userA: umsUser, userB: umsUser, compareType: string) => {
        let valueUserA = "";
        let valueUserB = "";

        switch (compareType) {
            case UserSortKey.CUSTOMER_NAME:
                valueUserA = userA.customername ? userA.customername.toLowerCase().trim() : "";
                valueUserB = userB.customername ? userB.customername.toLowerCase().trim() : "";
                break;
            case UserSortKey.E_MAIL_ADDRESS:
                valueUserA = userA.email ? userA.email.toLowerCase().trim() : "";
                valueUserB = userB.email ? userB.email.toLowerCase().trim() : "";
                break;
            case UserSortKey.NAME:
                valueUserA = userA.name ? userA.name.toLowerCase().trim() : "";
                valueUserB = userB.name ? userB.name.toLowerCase().trim() : "";
                break;
            default:
                break;
        }

        if (valueUserA < valueUserB) {
            return -1;
        }

        if (valueUserA > valueUserB) {
            return 1;
        }

        return 0;
    }, []);

    const searchAndSortUsers = useCallback(
        (searchQuery: string, sortItemValue: string) => {
            const foundUsers = searchUsers(searchQuery);

            let sortedUsers = [];
            if (foundUsers) {
                sortedUsers = foundUsers.sort((userA, userB) => {
                    return getUserCompareValue(userA, userB, sortItemValue);
                });
            } else {
                sortedUsers = displayedUsers.sort((userA, userB) => {
                    return getUserCompareValue(userA, userB, sortItemValue);
                });
            }

            setDisplayedUsers([...sortedUsers]);
        },
        [searchUsers, displayedUsers, getUserCompareValue]
    );

    const handleSearchInput = useCallback(
        ({ searchQuery, sortItemValue }) => {
            searchAndSortUsers(searchQuery, sortItemValue);

            navStore.setListMapValue(
                listIdentifier,
                listParameters
                    ? { ...listParameters, scrollPosition: 0, searchQuery: searchQuery }
                    : { scrollPosition: 0, searchQuery: searchQuery }
            );
        },
        [listParameters, navStore, searchAndSortUsers]
    );

    useEffect(() => {
        if (uiStore.hasNewUserData) {
            refetch();
            uiStore.setHasNewUserData(false);
        }
    }, [data, refetch, uiStore]);

    const onChangeSelection = useCallback(
        ({ searchQuery, sortItemValue }) => {
            navStore.setListMapValue(
                listIdentifier,
                listParameters
                    ? { ...listParameters, sortValue: sortItemValue, searchQuery: searchQuery }
                    : { sortValue: sortItemValue, searchQuery: searchQuery }
            );

            searchAndSortUsers(searchQuery, sortItemValue);
        },
        [navStore, searchAndSortUsers, listParameters]
    );

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata")} />;
    }

    const userSortDropdown = (
        <div className={"d-flex align-items-center"}>
            <div className={classes.DropdownLabel}>
                <span>Sortieren nach:</span>
            </div>
            <UI.Dropdown
                className={classes.UserSortDropDown}
                id="user-filter"
                items={sortTypeDropdownItems}
                label={sortTypeDropdownItems[0].label}
                defaultLabel={sortTypeDropdownItems[0].label}
                onSelect={onChangeSelection}
            />
        </div>
    );

    return (
        <React.Fragment>
            <UI.Container className="pt-4">
                <UI.Row>
                    <UI.Col md={12}>
                        <UI.Card>
                            <div className={classes.customerList}>
                                <UserList
                                    users={displayedUsers}
                                    onSearch={handleSearchInput}
                                    onChangeSort={onChangeSelection}
                                    sortItems={sortTypeDropdownItems}
                                    searchAppendElement={userSortDropdown}
                                />
                            </div>
                        </UI.Card>
                    </UI.Col>
                </UI.Row>
            </UI.Container>
        </React.Fragment>
    );
};

export const UsersOverview = withRouter(Sentry.withProfiler(observer(UsersOverviewBase)));
