import React, { useContext, useEffect, useState, useCallback } from "react";
import * as Sentry from "@sentry/react";
import { useQuery } from "@apollo/client";
import { GET_SERVICES } from "src/api/services";
import { GetServices, GetServices_services as uccmServices } from "src/api/generated/GetServices";
import ServiceList from "src/screens/dashboard/services/list/ServiceList";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import * as Screens from "src/screens";
import styles from "./Services.module.css";
import { withRouter, RouteComponentProps } from "react-router";
import { Role as CustomerRole } from "src/network/User";
import { Route, selectRoute } from "src/config/routes";
import { v4 as uuidv4 } from "uuid";
import { ServiceState, ServiceType } from "src/utils/Enums";

const ServicesBase = (props: RouteComponentProps) => {
    const { t } = useTranslation();
    const { navStore, uiStore, authStore } = useContext(RootStoreContext);

    const listIdentifier = "servicelist";

    if (!navStore.listMap.has(listIdentifier)) {
        navStore.setListMapValue(listIdentifier, {});
    }

    const listParameters = navStore.getListMapValue(listIdentifier);

    const userRole = authStore.user ? authStore.user.role : undefined;

    const [displayedServices, setDisplayedServices] = useState<uccmServices[]>([]);

    const {
        data: dataServices,
        loading: loadingServices,
        error: errorServices,
        refetch: refetchServices
    } = useQuery<GetServices>(GET_SERVICES);

    const filterServicesAndSetSearchQuery = useCallback(
        (searchQuery: string) => {
            if (dataServices) {
                const foundCustomers = dataServices.services.filter((service) => {
                    const currentSearchQuery = searchQuery.toLowerCase();

                    const serviceName = service.name.toLowerCase();
                    const type = ServiceType[service.type].toLowerCase();
                    const state = ServiceState[service.state].toLowerCase();

                    return (
                        serviceName.includes(currentSearchQuery) ||
                        type.includes(currentSearchQuery) ||
                        state.includes(currentSearchQuery)
                    );
                });

                setDisplayedServices([...foundCustomers]);
            }
        },
        [dataServices]
    );

    const handleSearchInput = useCallback(
        ({ searchQuery }) => {
            filterServicesAndSetSearchQuery(searchQuery);

            navStore.setListMapValue(
                listIdentifier,
                listParameters
                    ? { ...listParameters, scrollPosition: 0, searchQuery: searchQuery }
                    : { scrollPosition: 0, searchQuery: searchQuery }
            );
        },
        [filterServicesAndSetSearchQuery, listParameters, navStore]
    );

    useEffect(() => {
        navStore.setTitle("Services");
        navStore.setBackbutton(null);
    }, [navStore]);

    useEffect(() => {
        if (uiStore.hasNewServiceData) {
            refetchServices();

            if (dataServices?.services) {
                setDisplayedServices([...dataServices?.services]);
            }
        }
    }, [refetchServices, uiStore, dataServices?.services]);

    if (loadingServices || displayedServices === []) {
        return <Screens.Loading />;
    }
    if (errorServices) {
        return <Screens.Error message={errorServices.message} networkError={errorServices.networkError} />;
    }
    if (!dataServices) {
        return <Screens.Error message={t("error.nodata")} />;
    }

    const addServiceButton = (
        <div className={styles.serviceAddButton}>
            <UI.Button
                to={selectRoute(Route.serviceDetails, null, {
                    serviceid: uuidv4()
                })}
                label={t("screens.dashboard.services.add_services")}
                className="customer-button float-right w-100"
            />
        </div>
    );

    return (
        <React.Fragment>
            <UI.Container className="pt-4">
                <UI.Row>
                    <UI.Col md={9}>
                        <UI.Card>
                            <div>
                                <ServiceList
                                    uccmServices={displayedServices}
                                    onSearch={handleSearchInput}
                                    searchAppendElement={
                                        userRole !== CustomerRole.CUSTOMERSUPPORT ? addServiceButton : undefined
                                    }
                                />
                            </div>
                        </UI.Card>
                    </UI.Col>
                </UI.Row>
            </UI.Container>
        </React.Fragment>
    );
};

export const Services = withRouter(Sentry.withProfiler(observer(ServicesBase)));
