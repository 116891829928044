import { gql } from "@apollo/client";

export const GET_UMS_CUSTOMER_DETAILS_BY_ID = gql`
    query GetUmsCustomerDetailsById($customerid: uuid!, $lang: String = "de") {
        ums_customer_details: ums_customers_by_pk(id: $customerid) {
            active
            apiversion
            dns
            email
            erp
            id
            name
            number
            partner
            remarks
            subdomain
            type
            erpcloud
            customerfeatures {
                feature
            }
            connections {
                category
                id
                type
                username
            }
            erpuser: users(where: { name1: { _like: "erp@%" }, system: { _eq: true } }) {
                name1
                id
                registered
                userroles {
                    role
                }
            }
            adminuser: users(where: { name1: { _like: "admin@%" }, system: { _eq: true } }) {
                name1
                id
                registered
                userroles {
                    role
                }
            }
            internal_users: users_aggregate(
                where: { userroles: { role: { _in: [20, 21, 30] } }, system: { _eq: false } }
            ) {
                aggregate {
                    count
                }
            }
            external_users: users_aggregate(where: { userroles: { role: { _in: [31, 32] } }, system: { _eq: false } }) {
                aggregate {
                    count
                }
            }
            count_realestates: realestates_aggregate {
                aggregate {
                    count
                }
            }
            logs: customerfiles_aggregate(where: { type: { _eq: 199 } }) {
                aggregate {
                    count
                }
            }
            file_usage: customersettings(where: { key: { _in: ["FILES.USED_KB", "FILES.FILE_COUNT"] } }) {
                key
                value
                updated
            }
            files_with_error_count: files_aggregate(where: { error: { _is_null: false } }) {
                aggregate {
                    count
                }
            }
        }

        inspversions: ums_generalsettings(where: { key: { _in: ["INSP.MINVERSION", "INSP.CURRENTVERSION"] } }) {
            id
            key
            value
        }

        userroles: common_enumerations(
            where: {
                type: { _eq: 91 }
                language: { _eq: $lang }
                key: { _in: [14, 20, 21, 30, 31, 32, 33, 39, 40, 52, 51, 36] }
            }
            order_by: { key: asc }
        ) {
            id
            key
            label
        }

        connectiontypes: common_enumerations(
            where: { type: { _eq: 99 }, language: { _eq: $lang } }
            order_by: { key: asc }
        ) {
            id
            key
            label
        }

        connectioncategories: common_enumerations(
            where: { type: { _eq: 901 }, language: { _eq: $lang } }
            order_by: { key: asc }
        ) {
            id
            key
            label
        }
    }
`;

export const GET_CONNECTION_DETAILS_BY_ID = gql`
    query GetConnectionDetails($connectionid: uuid!, $customerid: uuid!) {
        ums_connections(where: { id: { _eq: $connectionid } }) {
            category
            password
            type
            username
            id
            hostname
            extradata
        }
        testconnections: ums_testconnections(order_by: { customertype: asc }) {
            customertype
            category
            password
            type
            username
            id
            hostname
            extradata
        }
        existing_connections: ums_connections_aggregate(
            distinct_on: [type]
            where: { customerid: { _eq: $customerid } }
        ) {
            existing_types: nodes {
                type
            }
        }
        customer: ums_customers(where: { id: { _eq: $customerid } }) {
            type
        }
    }
`;

export const DELETE_CONNECTION = gql`
    mutation DeleteConnection($connectionid: uuid!) {
        delete_ums_connections(where: { id: { _eq: $connectionid } }) {
            affected_rows
        }
    }
`;

export const UPDATE_UMS_CUSTOMER_WITH_FEATURES = gql`
    mutation UpdateCustomerWithFeatures(
        $customerid: uuid
        $updatedFields: ums_customers_set_input
        $customerFeaturesToRemove: [Int!]
        $customerFeaturesToAdd: [ums_customerfeatures_insert_input!]!
    ) {
        features_removed: delete_ums_customerfeatures(
            where: { customerid: { _eq: $customerid }, _and: { feature: { _in: $customerFeaturesToRemove } } }
        ) {
            affected_rows
        }

        features_added: insert_ums_customerfeatures(objects: $customerFeaturesToAdd) {
            affected_rows
        }

        update_ums_customers(where: { id: { _eq: $customerid } }, _set: $updatedFields) {
            returning {
                active
                email
                erp
                id
                name
                number
                subdomain
                type
                dns
                remarks
                erpcloud
                customerfeatures {
                    feature
                }
            }
        }
    }
`;

export const UPDATE_UMS_CUSTOMER = gql`
    mutation UpdateCustomer($customerid: uuid, $updatedFields: ums_customers_set_input) {
        update_ums_customers(where: { id: { _eq: $customerid } }, _set: $updatedFields) {
            returning {
                id
            }
        }
    }
`;

export const GET_UMS_CUSTOMER_LOGS = gql`
    query GetCustomerLogs($customerid: uuid!) {
        logs: file_customerfiles(
            where: { customerid: { _eq: $customerid }, type: { _eq: 199 } }
            order_by: { file: { filedate: desc } }
        ) {
            id
            file {
                filedate
                id
                inserted
            }
        }
    }
`;

export const GET_CUSTOMER_FAULTY_FILES = gql`
    query GetCustomerFaultyFiles($customerid: uuid!) {
        faulty_files: file_files(where: { customerid: { _eq: $customerid }, error: { _is_null: false } }) {
            id
            name
            extension
            error
        }
    }
`;

export const DELETE_FILE = gql`
    mutation DeleteFile($customerid: uuid!, $fileid: uuid!) {
        delete_file_files(where: { customerid: { _eq: $customerid }, id: { _eq: $fileid } }) {
            returning {
                id
            }
        }
    }
`;

export const UPSERT_DMS_CONNECTION = gql`
    mutation UpsertDmsConnection($dmsconnection: file_dmsconnections_insert_input!) {
        insert_file_dmsconnections_one(
            object: $dmsconnection
            on_conflict: {
                update_columns: [password, username, extradata, hostname, dmstype]
                constraint: dmsconnections_pkey
            }
        ) {
            id
            password
            username
            extradata
            hostname
            dmstype
        }
    }
`;

export const UPSERT_UMS_CONNECTION = gql`
    mutation UpsertUmsConnection($connection: ums_connections_insert_input!) {
        insert_ums_connections_one(
            object: $connection
            on_conflict: {
                update_columns: [password, username, extradata, hostname, type, category]
                constraint: connections_pkey
            }
        ) {
            id
            password
            username
            extradata
            hostname
            type
            category
        }
    }
`;

export const GET_CUSTOMER_INVOICES = gql`
    query GetCustomerInvoices($customerid: uuid!) {
        invoices: ums_customerinvoices(where: { customerid: { _eq: $customerid } }, order_by: { date: desc }) {
            id
            invoiced
            date
            costtype
            costperunit
            count
        }

        costtype_enumeration: common_enumerations(
            where: { type: { _eq: 902 }, language: { _eq: "de" } }
            order_by: { key: asc }
        ) {
            id
            key
            label
        }
    }
`;

export const CALCULATE_TILE_BASEDATA = gql`
    mutation CalculateTileBasedata($customerid: uuid!, $date: timestamp!) {
        portal_calculate_tile_basedata(args: { pcustomerid: $customerid, pdate: $date }) {
            id
        }
    }
`;

export const CALCULATE_TILE_FINANCEDATA = gql`
    mutation CalculateTileFinancedata($customerid: uuid!, $date: timestamp!) {
        portal_calculate_tile_finance(args: { pcustomerid: $customerid, pdate: $date }) {
            id
        }
    }
`;
