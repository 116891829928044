import React, { useContext, useCallback } from "react";
import { GetFeatureAnnouncements_announcements as uccmAnnouncements } from "src/api/generated/GetFeatureAnnouncements";
import { useTranslation } from "react-i18next";
import { AnnouncementTargetProduct, AnnouncementState } from "src/utils/Enums";
import { RootStoreContext } from "src/stores/RootStore";
import { Route } from "src/config/routes";
import { observer } from "mobx-react-lite";
import { UI, ScreenSize } from "@wwimmo/ui";
import { getDate } from "src/utils/Date";

interface FeatureAnnouncementListProps {
    uccmAnnouncements: uccmAnnouncements[];
    onSearch: (searchArguments: { searchQuery?: string; filterItemValue?: string; sortItemValue?: string }) => void;
    searchAppendElement?: React.ReactNode;
}

const FeatureAnnouncementList = (props: FeatureAnnouncementListProps) => {
    const { t } = useTranslation();
    const { uiStore, navStore } = useContext(RootStoreContext);

    const listIdentifier = "featureAnnouncementlist";

    if (!navStore.listMap.has(listIdentifier)) {
        navStore.setListMapValue(listIdentifier, {});
    }

    const listParameters = navStore.getListMapValue(listIdentifier);

    let currentSearchQuery: string | undefined = undefined;

    if (listParameters && listParameters.searchQuery) {
        currentSearchQuery = listParameters.searchQuery;
    }

    const onRowClick = useCallback(() => {
        navStore.setListMapValue(
            listIdentifier,
            listParameters
                ? { ...listParameters, scrollPosition: window.pageYOffset }
                : { scrollPosition: window.pageYOffset }
        );

        navStore.setBackbutton(Route.featureAnnouncements, t("screens.dashboard.nav.feature_announcements"));
    }, [navStore, t, listParameters]);

    const onPageChanged = useCallback(
        (currentPage: number) => {
            navStore.setListMapValue(
                listIdentifier,
                listParameters
                    ? { ...listParameters, scrollPosition: window.pageYOffset, paginationPage: currentPage }
                    : { scrollPosition: window.pageYOffset, paginationPage: currentPage }
            );
        },
        [navStore, listParameters]
    );

    const renderItem = useCallback(
        (announcement: uccmAnnouncements, screenSize: ScreenSize) => {
            let listRows: React.ReactElement[] = [];

            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={announcement.name ?? ""} />
                            <UI.List.Cell
                                key={"c-2"}
                                colspan={1}
                                value={announcement.startdate ? getDate(announcement.startdate) : ""}
                            />
                            <UI.List.Cell
                                key={"c-3"}
                                colspan={1}
                                value={announcement.enddate ? getDate(announcement.enddate) : ""}
                            />
                            <UI.List.Cell
                                key={"c-4"}
                                colspan={1}
                                value={AnnouncementTargetProduct[announcement.targetproduct]}
                            />
                            <UI.List.Cell key={"c-5"} colspan={1} value={AnnouncementState[announcement.state]} />
                        </UI.List.Row>
                    ];
                    break;
                case ScreenSize.MOBILE:
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={announcement.name ? announcement.name : ""} />
                        </UI.List.Row>
                    ];
                    break;
            }

            const link = `/feature-announcements/${announcement.id}`;

            return (
                <UI.List.Item
                    key={announcement.id}
                    screenSize={screenSize}
                    rows={listRows}
                    to={link}
                    onClick={onRowClick}
                />
            );
        },
        [onRowClick]
    );

    const renderHeader = useCallback(
        (screenSize: ScreenSize) => {
            let headerRows: React.ReactElement[] = [];

            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    headerRows = [
                        <UI.List.Row key={"row-1"}>
                            <UI.List.Cell
                                key={"c-1"}
                                colspan={1}
                                value={t("screens.dashboard.feature_announcements.form.name")}
                            />
                            <UI.List.Cell
                                key={"c-2"}
                                colspan={1}
                                value={t("screens.dashboard.feature_announcements.form.startdate")}
                            />
                            <UI.List.Cell
                                key={"c-3"}
                                colspan={1}
                                value={t("screens.dashboard.feature_announcements.form.enddate")}
                            />
                            <UI.List.Cell
                                key={"c-4"}
                                colspan={1}
                                value={t("screens.dashboard.feature_announcements.form.product")}
                            />
                            <UI.List.Cell
                                key={"c-5"}
                                colspan={1}
                                value={t("screens.dashboard.feature_announcements.form.state")}
                            />
                        </UI.List.Row>
                    ];
                    break;

                case ScreenSize.MOBILE:
                    headerRows = [
                        <UI.List.Row key={"row-1"}>
                            <UI.List.Cell
                                key={"c-1"}
                                colspan={1}
                                value={t("screens.dashboard.feature_announcements.form.name")}
                            />
                        </UI.List.Row>
                    ];
                    break;
            }

            return <UI.List.Header rows={headerRows} sticky={true} top={130} />;
        },
        [t]
    );

    return (
        <UI.List.BasicList
            screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
            items={props.uccmAnnouncements}
            renderItem={renderItem}
            renderHeader={renderHeader}
            scrollPosition={listParameters?.scrollPosition ?? undefined}
            itemsPerPage={25}
            initialPage={listParameters?.paginationPage ?? undefined}
            onPageChanged={onPageChanged}
            onSearch={props.onSearch}
            searchAppendElement={props.searchAppendElement}
            initialSearchQuery={currentSearchQuery}
            searchCols={8}
            searchAppendElementCols={4}
        />
    );
};

export default observer(FeatureAnnouncementList);
