import React, { useContext, useState, useCallback } from "react";
import * as Sentry from "@sentry/react";
import { UI } from "@wwimmo/ui";
import { RootStoreContext } from "src/stores/RootStore";
import { getRoleKey } from "src/network/User";
import { GetUmsCustomers_customers as umsCustomers } from "src/api/generated/GetUmsCustomers";
import styles from "src/screens/dashboard/customers/overview/CustomersOverview.module.css";

enum STATE {
    INIT = 0,
    RUNNING = 1,
    DONE = 2
}

interface errorListType {
    text: any;
}

interface componentProps {
    onCloseModal: any;
    customers: umsCustomers[];
    serviceEndpoint: string;
    serviceMethod: string;
    modalTitle: string;
    finishedMessage: string;
}

const CallServiceForAllCustomersBase = (props: componentProps) => {
    const { authStore } = useContext(RootStoreContext);

    const [statusMessage, setStatusMessage] = useState<string>("");
    const [errorList, setErrorList] = useState<Array<errorListType>>([]);

    const [currentState, setCurrentState] = useState<STATE>(STATE.INIT);

    const onActualizeRepx = useCallback(
        async (e: any) => {
            e.preventDefault();

            setCurrentState(STATE.RUNNING);

            if (authStore.user && authStore.token && props.customers && props.customers.length > 0) {
                const accessToken = authStore.token;
                const tokenType = authStore.tokenType;
                const role = authStore.user?.role;

                let count = 1;

                for (const customer of props.customers) {
                    setStatusMessage(`Aktualisiere Kunden: ${count} von ${props.customers.length}`);

                    try {
                        const fetchResult = await fetch(`${props.serviceEndpoint}/${customer.id}`, {
                            method: props.serviceMethod,
                            headers: {
                                Authorization: `${tokenType} ${accessToken}`,
                                "x-hasura-role": getRoleKey(role)
                            }
                        });

                        if (fetchResult.status !== 200) {
                            const response = await fetchResult.json();

                            setErrorList((oldErrorList) => {
                                const newErrorList = [...oldErrorList];
                                newErrorList.push({
                                    text: (
                                        <>
                                            <span className="d-block">{`Fehler: ${response.message}`}</span>
                                            <span className="d-block">{`Kunde: ${customer.name} - ${customer.id} `}</span>
                                        </>
                                    )
                                });
                                return newErrorList;
                            });
                        }
                    } catch (fetchError) {
                        setErrorList((oldErrorList) => {
                            const newErrorList = [...oldErrorList];
                            newErrorList.push({ text: `Fehler beim Funktionsaufruf: ${fetchError}.` });
                            return newErrorList;
                        });
                    }

                    count++;
                }
            }

            setCurrentState(STATE.DONE);
        },
        [
            authStore.user,
            authStore.token,
            authStore.tokenType,
            props.customers,
            props.serviceMethod,
            props.serviceEndpoint
        ]
    );

    const onCloseModal = useCallback(() => {
        setStatusMessage("");
        setErrorList([]);
        props.onCloseModal();
    }, [props]);

    return (
        <div>
            {currentState === STATE.INIT ? (
                <>
                    <p>{props.modalTitle}</p>
                    <UI.Button
                        label={"Ja"}
                        disabled={currentState !== STATE.INIT}
                        onClick={onActualizeRepx}
                        className={`${styles.modalBtn} mb-2 mb-sm-0 mr-0 mr-sm-2 d-inline customer-button`}
                        variant="danger"
                    />
                    <UI.Button
                        label={"Nein"}
                        disabled={currentState !== STATE.INIT}
                        onClick={onCloseModal}
                        className={`${styles.modalBtn} d-inline customer-button`}
                        variant="danger"
                    />
                </>
            ) : undefined}

            {currentState !== STATE.INIT ? (
                <div style={{ display: "flex", flexDirection: "column" }}>
                    {statusMessage}

                    <ul style={{ listStyle: "none", padding: "0px", margin: "10px 0px 0px 0px" }}>
                        {errorList.map((item, i) => (
                            <li key={i} style={{ marginTop: "5px" }}>
                                {item.text}
                            </li>
                        ))}
                    </ul>

                    {currentState === STATE.RUNNING ? (
                        <UI.RotatingSpinner noLogo={true} size={35} className={"align-items-start"} />
                    ) : undefined}
                    {currentState === STATE.DONE ? (
                        <div className="mt-3 d-inline bold">
                            <p>{props.finishedMessage}</p>
                            <UI.Button
                                label="Fenster schliessen"
                                variant="link"
                                onClick={onCloseModal}
                                className="p-0"
                            />
                        </div>
                    ) : undefined}
                </div>
            ) : undefined}
        </div>
    );
};

export const CallServiceForAllCustomers = Sentry.withProfiler(CallServiceForAllCustomersBase);
