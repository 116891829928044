import React, { useContext, useEffect } from "react";
import { useParams } from "react-router";
import * as Sentry from "@sentry/react";
import { useQuery } from "@apollo/client";
import { GET_CUSTOMER_INVOICES } from "src/api/customer";
import { GetCustomerInvoices, GetCustomerInvoicesVariables } from "src/api/generated/GetCustomerInvoices";
import { InvoiceList } from "./list/InvoiceList";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import * as Screens from "src/screens";

interface Params {
    customerid: string;
}

const CustomerInvoicesBase = () => {
    const { customerid } = useParams<Params>();

    const { t } = useTranslation();
    const { navStore } = useContext(RootStoreContext);

    useEffect(() => {
        navStore.setTitle("Laufende Kosten");
        navStore.setBackbutton(`/customers/${customerid}`, "Kunde");
    }, [navStore, customerid]);

    const { data, loading, error } = useQuery<GetCustomerInvoices, GetCustomerInvoicesVariables>(
        GET_CUSTOMER_INVOICES,
        {
            variables: {
                customerid: customerid
            }
        }
    );

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata")} />;
    }

    return (
        <React.Fragment>
            <UI.Container className="pt-4">
                <UI.Row className="justify-content-md-center">
                    <UI.Col md={10}>
                        <UI.Card>
                            <div>
                                <InvoiceList invoices={data.invoices} costTypeEnumeration={data.costtype_enumeration} />
                            </div>
                        </UI.Card>
                    </UI.Col>
                </UI.Row>
            </UI.Container>
        </React.Fragment>
    );
};

export const CustomerInvoices = Sentry.withProfiler(observer(CustomerInvoicesBase));
