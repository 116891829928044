import React, { useContext, useCallback } from "react";
import * as Sentry from "@sentry/react";
import { ScreenSize, UI } from "@wwimmo/ui";
import { RootStoreContext } from "src/stores/RootStore";
import { ActiveAnnouncementListItem } from "src/api/featureAnnouncements";

interface ActiveFeatureAnnouncementListProps {
    activeAnnouncementListItems: ActiveAnnouncementListItem[];
}

const ActiveFeatureAnnouncementListBase = (props: ActiveFeatureAnnouncementListProps) => {
    const { uiStore } = useContext(RootStoreContext);

    const renderActiveAnnouncementItem = useCallback(
        (activeAnnouncementListItem: ActiveAnnouncementListItem, screenSize: ScreenSize) => {
            let listRows: React.ReactElement[] = [
                <UI.List.Row key={"r-1"}>
                    <UI.List.Cell key={"c-1"} colspan={1} value={activeAnnouncementListItem.product ?? ""} />
                    <UI.List.Cell
                        key={"c-2"}
                        colspan={1}
                        value={activeAnnouncementListItem.activeAnnouncements ?? ""}
                    />
                </UI.List.Row>
            ];

            return <UI.List.Item key={activeAnnouncementListItem.product} screenSize={screenSize} rows={listRows} />;
        },
        []
    );

    const renderActiveAnnouncementHeader = useCallback((screenSize: ScreenSize) => {
        let headerRows: React.ReactElement[] = [
            <UI.List.Row key={"row-1"}>
                <UI.List.Cell key={"c-1"} colspan={1} value={"Produkt"} />
                <UI.List.Cell key={"c-2"} colspan={1} value={"Auswahl"} />
            </UI.List.Row>
        ];

        return <UI.List.Header rows={headerRows} sticky={true} top={130} />;
    }, []);

    return (
        <UI.List.BasicList
            screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
            items={props.activeAnnouncementListItems}
            renderItem={renderActiveAnnouncementItem}
            renderHeader={renderActiveAnnouncementHeader}
        />
    );
};

export const ActiveFeatureAnnouncementList = Sentry.withProfiler(ActiveFeatureAnnouncementListBase);
