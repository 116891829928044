import React, { useContext, useCallback } from "react";
import * as Sentry from "@sentry/react";
import { Redirect, Switch } from "react-router";
import * as Screens from "src/screens";
import { Header } from "src/components";
import { Route } from "src/config/routes";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { PrivateRoute } from "src/components/routes/PrivateRoutes";
import { RootStoreContext } from "src/stores/RootStore";
import { Role as CustomerRole } from "src/network/User";

const DashboardBase = () => {
    const { t } = useTranslation();
    const { authStore } = useContext(RootStoreContext);

    const userRole = authStore.user ? authStore.user.role : undefined;
    const isUserUccmAdmin = authStore.user?.isUccmAdmin;

    const navLinks = [
        {
            to: `${Route.customers}`,
            label: t("common.customers"),
            icon: UI.SVGIcon.ListView,
            disabled: userRole === CustomerRole.UCCMADMIN
        },
        {
            to: Route.users,
            label: t("screens.dashboard.nav.users"),
            icon: UI.SVGIcon.Profile,
            disabled: userRole === CustomerRole.CUSTOMERADMIN || userRole === CustomerRole.UCCMADMIN
        },
        {
            to: Route.featureAnnouncements,
            label: t("screens.dashboard.nav.feature_announcements"),
            icon: UI.SVGIcon.Comment,
            disabled: !isUserUccmAdmin
        },
        {
            to: Route.services,
            label: t("screens.dashboard.nav.services"),
            icon: UI.SVGIcon.Comment,
            disabled: !isUserUccmAdmin
        }
    ];

    const renderNotFoundPage = useCallback(() => <Screens.Error type="notfound" />, []);

    return (
        <>
            <Header.Navigationbar navLinks={navLinks} />
            <div style={{ marginTop: 52 }}>
                <Switch>
                    <PrivateRoute path={Route.dashboard} exact>
                        {userRole === CustomerRole.UCCMADMIN ? (
                            <Redirect to={`${Route.featureAnnouncements}`} />
                        ) : (
                            <Redirect to={`${Route.customers}`} />
                        )}
                    </PrivateRoute>
                    <PrivateRoute path={Route.dashboard} exact>
                        {userRole === CustomerRole.UCCMADMIN ? (
                            <Redirect to={`${Route.services}`} />
                        ) : (
                            <Redirect to={`${Route.customers}`} />
                        )}
                    </PrivateRoute>
                    <PrivateRoute path={Route.customerDetails} component={Screens.CustomerDetails} />
                    <PrivateRoute path={Route.customers} exact component={Screens.CustomersOverview} />
                    <PrivateRoute
                        path={Route.users}
                        allowedRoles={[CustomerRole.CUSTOMERDEV, CustomerRole.CUSTOMERSUPPORT]}
                        exact
                        component={Screens.UsersOverview}
                    />
                    <PrivateRoute
                        path={Route.featureAnnouncementContentDetails}
                        component={Screens.FeatureAnnouncementContentDetails}
                        exact
                    />
                    <PrivateRoute
                        path={Route.featureAnnouncementDetails}
                        component={Screens.FeatureAnnouncementDetails}
                    />
                    <PrivateRoute
                        path={Route.featureAnnouncements}
                        allowedRoles={[CustomerRole.UCCMADMIN]}
                        exact
                        component={Screens.FeatureAnnouncements}
                    />

                    <PrivateRoute path={Route.serviceContentDetails} component={Screens.ServiceContentDetails} exact />
                    <PrivateRoute path={Route.serviceDetails} component={Screens.ServiceDetails} />
                    <PrivateRoute
                        path={Route.services}
                        allowedRoles={[CustomerRole.UCCMADMIN]}
                        exact
                        component={Screens.Services}
                    />

                    <PrivateRoute path={Route.userAudits} component={Screens.UserAudits} />
                    <PrivateRoute path={Route.userDetails} component={Screens.UserDetails} />
                    <PrivateRoute render={renderNotFoundPage} />
                </Switch>
            </div>
        </>
    );
};

export const Dashboard = Sentry.withProfiler(DashboardBase);
