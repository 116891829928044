import React, { useContext, useCallback, FunctionComponent, useState, useRef, ChangeEvent } from "react";
import * as Sentry from "@sentry/react";
import { UI } from "@wwimmo/ui";
import { RootStoreContext } from "src/stores/RootStore";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { UPSERT_UCCM_SERVICE_MLS, DELETE_SERVICE_MLS } from "src/api/service";
import { UpsertServiceMls, UpsertServiceMlsVariables } from "src/api/generated/UpsertServiceMls";
import { DeleteServiceMls, DeleteServiceMlsVariables } from "src/api/generated/DeleteServiceMls";
import { MessageType } from "src/components/notifications/Notifier";
import { getRoleKey, Role as CustomerRole } from "src/network/User";
import { GetServiceContentDetails_servicecontent as servicecontent } from "src/api/generated/GetServiceContentDetails";
import styles from "./ServiceContentDetailsForm.module.css";
import { selectRoute, Route } from "src/config/routes";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { FORM_STATUS } from "src/utils/Form";
import { useSaveUnsavedChangesInForm } from "src/hooks/save-unsaved-changes-in-form/useSaveUnsavedChangesInForm";
import { useSaveFormByShortcut } from "src/hooks/form/useSaveFormByShortcut";
import { ColorStyle } from "src/utils/Colors";
import { getEnvironment } from "src/utils/Customer";
import { NetworkConfig } from "src/network/NetworkConfig";

type ServiceContentDetailsFormProps = {
    serviceContentDetails?: servicecontent;
    serviceId: string;
    contentId: string;
    onUpsertServiceContent: any;
    userRole?: CustomerRole;
};

interface ServiceContentDetailsFormFields {
    id: string;
    language: string;
    teaser: string;
    title: string;
    content: string;
    price: string;
    activate: string;
    afteractivate: string;
    deactivate: string;
}

interface MediaFile {
    fileName: string;
    fileId: string;
    fileExtension: string;
    fileUrl: string;
}

interface FileHTMLInputEvent extends ChangeEvent {
    target: HTMLInputElement & EventTarget;
}

const CONTENT_TEXT_PLACEHOLDER = `Hier Text als HTML Eingeben. 
Bsp.:
<p>Erster Paragraph</p>
<p>Zweiter Paragraph</p>

Medien-Elemente via 'Medien hochladen' + Kopieren Button einfügen`;

const ServiceContentDetailsFormBase: FunctionComponent<ServiceContentDetailsFormProps> = (props) => {
    const { uiStore, authStore } = useContext(RootStoreContext);
    const history = useHistory();

    const [isNewServiceContent, setIsNewServiceContent] = useState<boolean>(props.serviceContentDetails ? false : true);
    const [currentStatus, setCurrentStatus] = useState<FORM_STATUS>(FORM_STATUS.NONE);
    const [isUploadingMediaFile, setIsUploadingMediaFile] = useState<boolean>(false);

    const { serviceId, contentId, serviceContentDetails, userRole, onUpsertServiceContent } = props;

    const [upsertUccmServiceMlsMutation] = useMutation<UpsertServiceMls, UpsertServiceMlsVariables>(
        UPSERT_UCCM_SERVICE_MLS,
        {
            onCompleted: () => {
                uiStore.printStatusMessage("Content wurde erfolgreich gespeichert", MessageType.INFO);
            }
        }
    );

    const [deleteServiceMlsMutation] = useMutation<DeleteServiceMls, DeleteServiceMlsVariables>(DELETE_SERVICE_MLS, {
        onCompleted: () => {
            uiStore.printStatusMessage("Content wurde erfolgreich gelöscht", MessageType.INFO);
        }
    });

    const getTitle = useCallback((content: string): string => {
        const titleElements = /<h1>(.*?)<\/h1>/g.exec(content ?? "");
        const title = titleElements && titleElements.length > 0 ? titleElements[1] : "";

        return title;
    }, []);

    const getContent = useCallback((fullContent: string): string => {
        const content = fullContent.replace(/<div>|<\/div>|<h1>.*?<\/h1>|<h2>.*?<\/h2>/gim, "").trim();

        return content;
    }, []);

    const { register, handleSubmit, formState, reset, errors, watch } = useForm<ServiceContentDetailsFormFields>({
        mode: "onBlur",
        defaultValues: {
            id: contentId,
            language: serviceContentDetails?.language ?? "",
            teaser: serviceContentDetails?.teaser ?? "",
            price: serviceContentDetails?.price ?? "",
            title: getTitle(serviceContentDetails?.content ?? ""),
            content: getContent(serviceContentDetails?.content ?? ""),
            activate: getContent(serviceContentDetails?.activate ?? ""),
            afteractivate: getContent(serviceContentDetails?.afteractivate ?? ""),
            deactivate: getContent(serviceContentDetails?.deactivate ?? "")
        }
    });

    const { dirtyFields, isDirty } = formState;

    const watchLanguage = watch("language");

    const [mediaFiles, setMediaFiles] = useState<Array<MediaFile>>(
        props.serviceContentDetails && props.serviceContentDetails.service.servicefiles
            ? props.serviceContentDetails.service.servicefiles
                  .filter((file) => file.language === watchLanguage)
                  .map((file) => {
                      return {
                          fileId: file.id,
                          fileName: file.name,
                          fileExtension: file.extension,
                          fileUrl: `https://${getEnvironment()}.wwportal.ch/files/services/${serviceId}/${watchLanguage}/${
                              file.id
                          }.${file.extension}`
                      };
                  })
            : []
    );

    const refSubmitButtom = useRef<HTMLButtonElement>(null);
    const hiddenMediaInputButton = useRef<HTMLInputElement>(null);

    const saveForm = useCallback(() => {
        refSubmitButtom?.current?.click();
    }, []);

    useSaveFormByShortcut({ saveFormFunction: saveForm, isFormDirty: isDirty });

    const { saveFormModal, closeSaveFormModalAndGoBack } = useSaveUnsavedChangesInForm({
        saveFormFunction: saveForm,
        currentFormStatus: currentStatus,
        isFormDirty: isDirty
    });

    const resetForm = useCallback(
        (e: any) => {
            e.preventDefault();

            if (serviceContentDetails) {
                reset({
                    id: contentId,
                    language: serviceContentDetails?.language ?? "",
                    teaser: serviceContentDetails?.teaser ?? "",
                    price: serviceContentDetails?.teaser ?? "",
                    title: getTitle(serviceContentDetails?.content ?? ""),
                    content: getContent(serviceContentDetails?.content ?? ""),
                    activate: getContent(serviceContentDetails?.activate ?? ""),
                    afteractivate: getContent(serviceContentDetails?.afteractivate ?? ""),
                    deactivate: getContent(serviceContentDetails?.deactivate ?? "")
                });
            } else {
                reset({
                    id: contentId,
                    language: "de",
                    teaser: "",
                    price: "",
                    title: "",
                    content: "",
                    activate: "",
                    afteractivate: "",
                    deactivate: ""
                });
            }
        },
        [contentId, serviceContentDetails, getContent, getTitle, reset]
    );

    const handleFormSubmit = useCallback(
        async (formData: ServiceContentDetailsFormFields) => {
            setCurrentStatus(FORM_STATUS.SAVING_FORM);

            const content = `<div>\r\n<h1>${formData.title}</h1>\r\n${formData.content}\r\n</div>`;
            const activate = `<div>${formData.activate}</div>`;
            const afteractivate = `<div>${formData.afteractivate}</div>`;
            const deactivate = `<div>${formData.deactivate}</div>`;

            const updatedServiceMlsDetails: any = {
                id: formData.id,
                serviceid: serviceId,
                language: formData.language ?? watchLanguage,
                teaser: formData.teaser,
                price: formData.price,
                title: formData.title,
                content: content,
                activate: activate,
                afteractivate: afteractivate,
                deactivate: deactivate
            };

            try {
                await upsertUccmServiceMlsMutation({
                    variables: {
                        servicemls: updatedServiceMlsDetails
                    }
                });

                onUpsertServiceContent();

                if (isNewServiceContent) {
                    uiStore.setHasNewServiceContentData(true);
                    setIsNewServiceContent(false);
                }

                reset(
                    {
                        ...formData
                    },
                    {
                        isDirty: false,
                        dirtyFields: false
                    }
                );
            } catch (error) {
                uiStore.printStatusMessage(`Fehler beim Speichern des Contents: ${error.message}`, MessageType.ERROR);
            } finally {
                setCurrentStatus(FORM_STATUS.NONE);
                closeSaveFormModalAndGoBack();
            }
        },
        [
            serviceId,
            closeSaveFormModalAndGoBack,
            isNewServiceContent,
            onUpsertServiceContent,
            reset,
            uiStore,
            upsertUccmServiceMlsMutation,
            watchLanguage
        ]
    );

    const isImage = useCallback((extension: string) => {
        if (extension === "jpg" || extension === "jpeg" || extension === "gif" || extension === "png") {
            return true;
        } else {
            return false;
        }
    }, []);

    const onCopyMedia = useCallback(
        (fileId: string, extension: string) => () => {
            let mediaUrl = `https://${getEnvironment()}.wwportal.ch/files/services/${serviceId}/${watchLanguage}/${fileId}.${extension}`;
            let copyElementText = "";

            if (isImage(extension)) {
                copyElementText = `<img onclick="function openInNewWindow(url){window.open(url, '_blank');};openInNewWindow(this.src);" src="${mediaUrl}" />`;
            } else {
                copyElementText = `<video width="100%" height="auto" controls><source src="${mediaUrl}" type="video/mp4">Your browser does not support the video tag.</video>`;
            }

            const elem = document.createElement("textarea");
            elem.value = copyElementText;
            document.body.appendChild(elem);
            elem.select();
            document.execCommand("copy");
            document.body.removeChild(elem);

            uiStore.printStatusMessage("HTML-Medien-Element in Zwischenablage gespeichert", MessageType.INFO);
        },
        [serviceId, uiStore, isImage, watchLanguage]
    );

    const onDeleteMedia = useCallback(
        (fileId: string) => async () => {
            if (authStore.user && authStore.token) {
                const accessToken = authStore.token;
                const tokenType = authStore.tokenType;
                const role = authStore.user?.role;

                try {
                    const fetchResult = await fetch(
                        `${NetworkConfig.deleteServiceFileUrl}/${fileId}?lang=${watchLanguage}`,
                        {
                            method: "PUT",
                            body: JSON.stringify({}),
                            headers: {
                                "Content-type": "multipart/form-data",
                                Authorization: `${tokenType} ${accessToken}`,
                                "x-hasura-role": getRoleKey(role)
                            }
                        }
                    );

                    if (fetchResult.status === 200) {
                        uiStore.printStatusMessage("Datei erfolgreich gelöscht", MessageType.INFO);
                        const newMediaFilesArray = mediaFiles.filter((file) => file.fileId !== fileId);
                        setMediaFiles(newMediaFilesArray);
                        onUpsertServiceContent();
                    } else {
                        const returnValue = await fetchResult.json();
                        uiStore.printStatusMessage(returnValue.message, MessageType.ERROR);
                    }
                } catch (fetchError) {
                    uiStore.printStatusMessage(fetchError.error, MessageType.ERROR);
                }
            }
        },
        [
            authStore.token,
            authStore.tokenType,
            authStore.user,
            uiStore,
            mediaFiles,
            watchLanguage,
            onUpsertServiceContent
        ]
    );

    const onDeleteServiceContent = useCallback(async () => {
        for (const mediaFile of mediaFiles) {
            await onDeleteMedia(mediaFile.fileId)();
        }

        await deleteServiceMlsMutation({
            variables: {
                servicemlsid: contentId
            }
        });

        uiStore.setHasNewServiceContentData(true);
        history.replace(selectRoute(Route.serviceDetails, null, { serviceid: serviceId }));
    }, [contentId, deleteServiceMlsMutation, history, uiStore, mediaFiles, onDeleteMedia, serviceId]);

    const serviceContentDeleteDialog = (
        <UI.ConfirmationDialog
            key="serviceMlsDeleteDialog"
            buttonText="Löschen"
            modalTitle="Content löschen"
            confirmationQuestion="Willst du den Content wirklich löschen?"
            inProgressText="Content wird gelöscht..."
            onConfirmation={onDeleteServiceContent}
            buttonClasses={`${styles.FormButton} ${styles.Delete} customer-button ml-sm-2 mt-2 mt-sm-0`}
            isDangerousOperation
        />
    );

    const serviceContentForm = (
        <UI.Form>
            <UI.Input
                label={"Service Content ID (service_mls)"}
                ref={register}
                name="id"
                type="text"
                readOnly={true}
                disabled={userRole === CustomerRole.CUSTOMERADMIN}
            />
            <UI.Input
                label={"Sprache"}
                ref={register}
                name="language"
                as="select"
                className={dirtyFields.language ? styles.changed : ""}
                disabled={!isNewServiceContent || userRole === CustomerRole.CUSTOMERADMIN}
                defaultValue={"de"}
            >
                <option value={"de"}>de</option>
                <option value={"fr"}>fr</option>
                <option value={"it"}>it</option>
                <option value={"en"}>en</option>
            </UI.Input>
            <UI.Input
                label={"Teasertext*"}
                ref={register({ required: true })}
                name="teaser"
                errorMsg={errors.teaser ? "Pflichtfeld!" : undefined}
                className={dirtyFields.teaser ? styles.changed : ""}
                type="text"
                as="textarea"
                disabled={userRole === CustomerRole.CUSTOMERADMIN}
            />
            <UI.Input
                label={"Titel*"}
                ref={register({ required: true })}
                name="title"
                errorMsg={errors.title ? "Pflichtfeld!" : undefined}
                className={dirtyFields.title ? styles.changed : ""}
                type="text"
                disabled={userRole === CustomerRole.CUSTOMERADMIN}
            />
            <UI.Input
                label={"Preise*"}
                ref={register({ required: true })}
                name="price"
                className={dirtyFields.price ? styles.changed : ""}
                type="text"
                disabled={userRole === CustomerRole.CUSTOMERADMIN}
            />
            <UI.Input
                label={"Text und Medien"}
                ref={register({ required: true })}
                name="content"
                errorMsg={errors.content ? "Pflichtfeld!" : undefined}
                className={`${styles.ContentTextArea} ${dirtyFields.content ? styles.changed : ""}`}
                type="text"
                disabled={userRole === CustomerRole.CUSTOMERADMIN}
                as="textarea"
                placeholder={CONTENT_TEXT_PLACEHOLDER}
            />
            <UI.Input
                label={"Introtext Aktivierung"}
                ref={register({ required: true })}
                name="activate"
                errorMsg={errors.activate ? "Pflichtfeld!" : undefined}
                className={`${styles.ContentTextArea} ${dirtyFields.activate ? styles.changed : ""}`}
                type="text"
                disabled={userRole === CustomerRole.CUSTOMERADMIN}
                as="textarea"
                placeholder={CONTENT_TEXT_PLACEHOLDER}
            />
            <UI.Input
                label={"Infobox nach Aktivierung"}
                ref={register({ required: true })}
                name="afteractivate"
                errorMsg={errors.afteractivate ? "Pflichtfeld!" : undefined}
                className={`${styles.ContentTextArea} ${dirtyFields.afteractivate ? styles.changed : ""}`}
                type="text"
                disabled={userRole === CustomerRole.CUSTOMERADMIN}
                as="textarea"
                placeholder={CONTENT_TEXT_PLACEHOLDER}
            />
            <UI.Input
                label={"Introtext Deaktivierung"}
                ref={register({ required: true })}
                name="deactivate"
                errorMsg={errors.deactivate ? "Pflichtfeld!" : undefined}
                className={`${styles.ContentTextArea} ${dirtyFields.deactivate ? styles.changed : ""}`}
                type="text"
                disabled={userRole === CustomerRole.CUSTOMERADMIN}
                as="textarea"
                placeholder={CONTENT_TEXT_PLACEHOLDER}
            />
            <UI.Button
                type="submit"
                label={currentStatus === FORM_STATUS.SAVING_FORM ? "Speichert..." : "Speichern"}
                disabled={!formState.isDirty || currentStatus === FORM_STATUS.SAVING_FORM}
                onClick={handleSubmit(handleFormSubmit)}
                className={`${styles.FormButton} customer-button`}
                ref={refSubmitButtom}
            />
            <UI.Button
                label={"Zurücksetzen"}
                disabled={!formState.isDirty || currentStatus === FORM_STATUS.SAVING_FORM}
                onClick={resetForm}
                className={`${styles.FormButton} customer-button ml-sm-2 mt-2 mt-sm-0`}
            />
            {!isNewServiceContent ? serviceContentDeleteDialog : undefined}
            {props.children}
        </UI.Form>
    );

    const onClickUploadMedia = useCallback((e: any) => {
        e.preventDefault();
        hiddenMediaInputButton?.current?.click();
    }, []);

    const onHandleChangeHiddenInput = useCallback(
        async (event: FileHTMLInputEvent) => {
            setIsUploadingMediaFile(true);

            if (event?.target?.files) {
                const files = Array.from(event.target.files);

                if (files.length > 0) {
                    const file: File = files[0];

                    if (authStore.user && authStore.token) {
                        const accessToken = authStore.token;
                        const tokenType = authStore.tokenType;
                        const role = authStore.user?.role;

                        try {
                            let formData = new FormData();
                            formData.append(file.name, file);

                            const fetchResult = await fetch(
                                `${NetworkConfig.uploadServiceFileUrl}/${serviceId}?lang=${watchLanguage}`,
                                {
                                    method: "POST",
                                    body: formData,
                                    headers: {
                                        "Content-type": "multipart/form-data",
                                        Authorization: `${tokenType} ${accessToken}`,
                                        "x-hasura-role": getRoleKey(role)
                                    }
                                }
                            );

                            if (fetchResult.status === 200) {
                                try {
                                    const result = await fetchResult.json();

                                    setMediaFiles((oldMediaFiles) => {
                                        const fileId = result.data.FileId;
                                        const fileName = result.data.FileName;
                                        const fileExtension = result.data.FileExtension;

                                        return [
                                            ...oldMediaFiles,
                                            {
                                                fileId,
                                                fileName,
                                                fileExtension,
                                                fileUrl: `https://${getEnvironment()}.wwportal.ch/files/services/${serviceId}/${watchLanguage}/${fileId}.${fileExtension}`
                                            }
                                        ];
                                    });

                                    onUpsertServiceContent();
                                } catch (jsonError) {
                                    console.log("jsonError: ", jsonError);
                                    setIsUploadingMediaFile(false);
                                }

                                uiStore.printStatusMessage("Datei erfolgreich hochgeladen", MessageType.INFO);
                            } else {
                                const returnValue = await fetchResult.json();
                                uiStore.printStatusMessage(returnValue.message, MessageType.ERROR);
                            }
                        } catch (fetchError) {
                            uiStore.printStatusMessage(fetchError.error, MessageType.ERROR);
                        } finally {
                            setIsUploadingMediaFile(false);
                        }
                    }
                }
            }
        },
        [
            serviceId,
            authStore.token,
            authStore.tokenType,
            authStore.user,
            uiStore,
            watchLanguage,
            onUpsertServiceContent
        ]
    );

    const renderMediaInformation = (mediaFile: MediaFile, index: number) => {
        return (
            <div key={mediaFile.fileId}>
                {index > 0 ? <hr className={styles.ImageDivider} /> : undefined}

                {isImage(mediaFile.fileExtension) ? (
                    <div>
                        <img className="w-100" src={mediaFile.fileUrl} alt="Media-Preview" />
                    </div>
                ) : undefined}

                <div className="my-2">FileId: {mediaFile.fileId}</div>
                <div className="my-2">FileName: {mediaFile.fileName}</div>
                <div className="d-flex">
                    <div className="d-flex align-items-center mr-4">
                        <UI.Icon
                            onClick={onCopyMedia(mediaFile.fileId, mediaFile.fileExtension)}
                            icon={UI.SVGIcon.Documents}
                            color={ColorStyle("black")}
                        />
                        <div>Kopieren</div>
                    </div>
                    <div className="d-flex align-items-center">
                        <UI.Icon
                            onClick={onDeleteMedia(mediaFile.fileId)}
                            icon={UI.SVGIcon.CloseCircle}
                            color={ColorStyle("black")}
                        />
                        <div>Löschen</div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <React.Fragment>
            <UI.Container className="pt-4">
                <UI.Row>
                    <UI.Col md={9}>
                        {userRole ? (
                            userRole === CustomerRole.CUSTOMERDEV || userRole === CustomerRole.UCCMADMIN ? (
                                <UI.Card>
                                    {saveFormModal}
                                    {serviceContentForm}
                                </UI.Card>
                            ) : undefined
                        ) : undefined}
                    </UI.Col>
                    <UI.Col md={3}>
                        <div className={`${styles.MediaContainer} p-3`}>
                            <div className="mb-2">Zusätzliche Aktionen</div>
                            <UI.Button
                                label={isUploadingMediaFile ? "Wird hochgeladen" : "Medien hochladen"}
                                disabled={isUploadingMediaFile}
                                onClick={onClickUploadMedia}
                                className="customer-button w-100"
                            />
                            <input
                                ref={hiddenMediaInputButton}
                                type="file"
                                className="d-none"
                                onChange={onHandleChangeHiddenInput}
                            />
                        </div>
                        <div className={`${styles.MediaContainer} mt-3 p-3`}>
                            <div className="mb-2">Media</div>
                            <div>{mediaFiles.map(renderMediaInformation)}</div>
                        </div>
                    </UI.Col>
                </UI.Row>
            </UI.Container>
        </React.Fragment>
    );
};

export const ServiceContentDetailsForm = Sentry.withProfiler(observer(ServiceContentDetailsFormBase));
