import { gql } from "@apollo/client";

export const GET_SERVICES = gql`
    query GetServices {
        services: uccm_services {
            id
            name
            state
            type
            chargeable
        }
    }
`;
