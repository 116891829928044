import * as Sentry from "@sentry/react";
import i18next from "i18next";

interface UserFeedbackData {
    name: string;
    email: string;
}

const resetPlaceholder = (id: string, placeholder: string) => {
    const input = document.getElementById(id);
    input && input.setAttribute("placeholder", placeholder);
};

export const submitUserFeedback = (user: UserFeedbackData, commentPlaceholder = "") => {
    Sentry.captureMessage("Feedback");
    Sentry.showReportDialog({
        user: {
            email: user.email,
            name: user.name
        },
        title: i18next.t("error.feedback.title"),
        subtitle: i18next.t("error.feedback.subtitle"),
        subtitle2: "",
        lang: i18next.language,
        labelComments: "Was möchten Sie uns mitteilen?",
        labelClose: i18next.t("labels.close"),
        labelSubmit: i18next.t("labels.submit"),
        onLoad: () => {
            resetPlaceholder("id_comments", "");
            resetPlaceholder("id_name", "Martin Muster");
            resetPlaceholder("id_email", "martin.muster@beispiel.com");
        }
    });
};
