import React from "react";
import * as Sentry from "@sentry/react";
import { UI } from "@wwimmo/ui";
import { Route as CustomerRoutes } from "src/config/routes";
import CustomerLoginInfo from "src/screens/dashboard/customers/customer/login-info/CustomerLoginInfo";
import styles from "./CustomerERPConnectionInfo.module.css";

interface CustomerERPConnectionInfoProps {
    hostname: string;
    username: string;
    password: string;
    title?: string;
    okButtonRoute?: string;
    onOkButtonClick?: any;
}

const CustomerERPConnectionInfoBase = (props: CustomerERPConnectionInfoProps) => {
    return (
        <UI.Card flex>
            <div className={styles.successNotification}>
                <div className={`${styles.successIcon} mb-2`}>
                    <UI.Icon icon={UI.SVGIcon.Check} size="large" />
                </div>
                {props.title ? <h2 className="my-3">{props.title}</h2> : undefined}

                <div>
                    <CustomerLoginInfo hostname={props.hostname} username={props.username} password={props.password} />
                </div>
                <div className="mt-2 text-center">
                    <UI.Button
                        label="Ok"
                        to={props.okButtonRoute ?? CustomerRoutes.dashboard}
                        variant="link"
                        className={styles.successBtn}
                        onClick={props.onOkButtonClick}
                    />
                </div>
            </div>
        </UI.Card>
    );
};

export const CustomerERPConnectionInfo = Sentry.withProfiler(CustomerERPConnectionInfoBase);
