import React, { useCallback, useContext, useState } from "react";
import * as Sentry from "@sentry/react";
import { useForm } from "react-hook-form";
import { UI } from "@wwimmo/ui";
import { ApolloError, useMutation } from "@apollo/client";
import { CALCULATE_TILE_BASEDATA, CALCULATE_TILE_FINANCEDATA } from "src/api/customer";
import { CalculateTileBasedata, CalculateTileBasedataVariables } from "src/api/generated/CalculateTileBasedata";
import {
    CalculateTileFinancedata,
    CalculateTileFinancedataVariables
} from "src/api/generated/CalculateTileFinancedata";
import { RootStoreContext } from "src/stores/RootStore";
import { MessageType } from "src/components/notifications/Notifier";
import { observer } from "mobx-react-lite";
import styles from "src/screens/dashboard/customers/overview/CustomersOverview.module.css";
import { dateFormat } from "src/utils/Date";

interface CalculateTilesFormProps {
    customerid: string;
}

interface CalculateTilesFormFields {
    tiletypes: number[];
}

enum TileDataType {
    Stammdaten = 10,
    Finanzen = 20
}

const CalculateTilesFormBase = (props: CalculateTilesFormProps) => {
    const { register, handleSubmit } = useForm<CalculateTilesFormFields>();
    const { uiStore } = useContext(RootStoreContext);
    const [isModalDisplayed, setIsModalDisplayed] = useState<boolean>(false);

    const [calculateTileBasedataMutation, { loading: isCalculateTileBasedataMutationLoading }] = useMutation<
        CalculateTileBasedata,
        CalculateTileBasedataVariables
    >(CALCULATE_TILE_BASEDATA, {
        onCompleted: () => {
            uiStore.printStatusMessage(
                "Die Berechnung der Stammdaten wurde erfolgreich durchgeführt",
                MessageType.INFO
            );
        },
        onError: (error: ApolloError) => {
            uiStore.printStatusMessage(
                `Bei der Berechnung der Stammdaten ist ein Fehler aufgetreten: ${error.name} - ${error.message}`,
                MessageType.ERROR
            );
        }
    });

    const [calculateTileFinancedataMutation, { loading: isCalculateTileFinancedataMutationLoading }] = useMutation<
        CalculateTileFinancedata,
        CalculateTileFinancedataVariables
    >(CALCULATE_TILE_FINANCEDATA, {
        onCompleted: () => {
            uiStore.printStatusMessage(
                "Die Berechnung der Finanzdaten wurde erfolgreich durchgeführt",
                MessageType.INFO
            );
        },
        onError: (error: ApolloError) => {
            uiStore.printStatusMessage(
                `Bei der Berechnung der Finanzdaten ist ein Fehler aufgetreten: ${error.name} - ${error.message}`,
                MessageType.ERROR
            );
        }
    });

    const handleFormSubmit = useCallback(
        async (formData: CalculateTilesFormFields) => {
            let calculateBasedata = false;
            let calculateFinancedata = false;

            for (const [key, value] of Object.entries(formData.tiletypes)) {
                if (value && Number(key) === TileDataType.Stammdaten) {
                    calculateBasedata = true;
                } else if (value && Number(key) === TileDataType.Finanzen) {
                    calculateFinancedata = true;
                }
            }

            const currentDate = `${dateFormat(new Date(), "yyyy-MM-dd")}T00:00:00.000Z`;

            const mutationVariables = {
                customerid: props.customerid,
                date: currentDate
            };

            if (calculateBasedata && calculateFinancedata) {
                await calculateTileBasedataMutation({
                    variables: mutationVariables
                });
                await calculateTileFinancedataMutation({
                    variables: mutationVariables
                });
            } else if (calculateBasedata) {
                await calculateTileBasedataMutation({
                    variables: mutationVariables
                });
            } else if (calculateFinancedata) {
                await calculateTileFinancedataMutation({
                    variables: mutationVariables
                });
            }
        },
        [calculateTileBasedataMutation, calculateTileFinancedataMutation, props.customerid]
    );

    const toggleModalDisplayState = useCallback(() => {
        setIsModalDisplayed((isModalDisplayed) => !isModalDisplayed);
    }, []);

    const renderTileDataTypeCheckboxes = () => {
        const featureComponents = [];

        for (const featureNumberStr in TileDataType) {
            if (isNaN(Number(featureNumberStr))) {
                continue;
            }
            const featureName = TileDataType[featureNumberStr];
            const featureNumber = Number(featureNumberStr);

            featureComponents.push(
                <UI.Checkbox
                    key={featureNumber}
                    ref={register}
                    name="tiletypes"
                    label={featureName}
                    checkboxValue={featureNumber}
                />
            );
        }

        return <>{featureComponents}</>;
    };

    return (
        <>
            <UI.Button
                label={"Kacheln aktualisieren"}
                className="customer-button mt-2 w-100"
                variant="danger"
                onClick={toggleModalDisplayState}
            />
            <UI.Modal
                backdrop={"static"}
                title={"Kacheln aktualisieren"}
                show={isModalDisplayed}
                onClose={toggleModalDisplayState}
            >
                <UI.Form>
                    <UI.Input
                        id="tiletypes"
                        type="checkbox"
                        label={"Wähle aus, welche Kacheldaten du neu berechnen lassen willst"}
                    >
                        {renderTileDataTypeCheckboxes()}
                    </UI.Input>
                    <UI.Button
                        type="submit"
                        label={
                            isCalculateTileBasedataMutationLoading || isCalculateTileFinancedataMutationLoading
                                ? "Berechnung läuft"
                                : "Berechnen"
                        }
                        disabled={isCalculateTileBasedataMutationLoading || isCalculateTileFinancedataMutationLoading}
                        onClick={handleSubmit(handleFormSubmit)}
                        className={`${styles.apiDialogButton} w-100 customer-button`}
                    />
                </UI.Form>
            </UI.Modal>
        </>
    );
};

export const CalculateTilesForm = Sentry.withProfiler(observer(CalculateTilesFormBase));
