import React from "react";
import { useTranslation } from "react-i18next";
import { PortalDashboard_portal_tilescreens_tile } from "src/api/generated/PortalDashboard";
import { Tiles } from "src/components";
import { ErpType } from "src/utils/Enums";

export interface CustomerOverviewStatisticsProps {
    statistics: {
        customersCount: {
            rimo: number;
            it2: number;
        };
        usersCount: {
            rimo: number;
            it2: number;
        };
        inspectionsCount: {
            rimo: number;
            it2: number;
        };
    };
}
export const CustomerOverviewStatistics = (props: CustomerOverviewStatisticsProps) => {
    const { t } = useTranslation();
    const { statistics } = props;

    const getDoughnutTileData = (
        title: string,
        rimoValue: number,
        it2Value: number
    ): PortalDashboard_portal_tilescreens_tile => {
        return {
            charttype: 2,
            __typename: "portal_tiles",
            id: "",
            tiles_mls: [{ __typename: "portal_tiles_mls", title: title }],
            realestateid: "",
            tiledatasets: [
                {
                    __typename: "portal_tiledatasets",
                    id: "",
                    sort: 0,
                    tiledatasets_mls: [],
                    tiledatavalues: [
                        {
                            __typename: "portal_tiledatavalues",
                            sort: 0,
                            id: "",
                            valuetype: 0,
                            value: rimoValue,
                            tiledatavalues_mls: [
                                {
                                    label: String(rimoValue),
                                    __typename: "portal_tiledatavalues_mls",
                                    hint: ErpType[ErpType["Rimo R5"]]
                                }
                            ]
                        },
                        {
                            __typename: "portal_tiledatavalues",
                            sort: 1,
                            id: "",
                            valuetype: 0,
                            value: it2Value,
                            tiledatavalues_mls: [
                                {
                                    label: String(it2Value),
                                    __typename: "portal_tiledatavalues_mls",
                                    hint: ErpType[ErpType["ImmoTop2"]]
                                }
                            ]
                        }
                    ]
                }
            ]
        };
    };

    return (
        <>
            <Tiles.Tile
                data={getDoughnutTileData(
                    t("screens.dashboard.customers.clients_count"),
                    statistics.customersCount.rimo,
                    statistics.customersCount.it2
                )}
            />
            <Tiles.Tile
                data={getDoughnutTileData(
                    t("screens.dashboard.customers.inspections_count"),
                    statistics.inspectionsCount.rimo,
                    statistics.inspectionsCount.it2
                )}
            />
            <Tiles.Tile
                data={getDoughnutTileData(
                    t("screens.dashboard.customers.users_count"),
                    statistics.usersCount.rimo,
                    statistics.usersCount.it2
                )}
            />
        </>
    );
};
