import React, { useContext, useEffect, useState, useCallback } from "react";
import * as Sentry from "@sentry/react";
import { useQuery, useMutation } from "@apollo/client";
import { GET_UMS_CUSTOMER_DETAILS_BY_ID, UPDATE_UMS_CUSTOMER } from "src/api/customer";
import {
    GetUmsCustomerDetailsById,
    GetUmsCustomerDetailsByIdVariables,
    GetUmsCustomerDetailsById_ums_customer_details as query_customer_details
} from "src/api/generated/GetUmsCustomerDetailsById";
import { UpdateCustomer, UpdateCustomerVariables } from "src/api/generated/UpdateCustomer";
import { UI } from "@wwimmo/ui";
import { InfoContainer } from "./info-container/InfoContainer";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Link, useHistory } from "react-router-dom";
import { RootStoreContext } from "src/stores/RootStore";
import * as Screens from "src/screens";
import ImagePlaceholder from "src/assets/images/image_placeholder.svg";
import CustomerLoginInfo from "src/screens/dashboard/customers/customer/login-info/CustomerLoginInfo";
import { getCustomerHostname } from "src/utils/Customer";
import { NetworkConfig } from "src/network/NetworkConfig";
import { MessageType } from "src/components/notifications/Notifier";
import { CustomerDetailsForm } from "src/screens/dashboard/customers/customer/details-form/CustomerDetailsForm";
import { getRoleKey, Role as CustomerRole } from "src/network/User";
import { observer } from "mobx-react-lite";
import { Route as CustomerRoutes, selectRoute } from "src/config/routes";
import CustomerFileUsage from "src/screens/dashboard/customers/customer/file-usage/CustomerFileUsage";
import CustomerAdminuserInfo from "src/screens/dashboard/customers/customer/adminuser-info/CustomerAdminuserInfo";
import { CustomerType } from "src/utils/Enums";
import { getFormattedVersionNumber } from "src/utils/Common";
import { ConnectionList } from "src/screens/dashboard/customers/customer/connection/list/ConnectionList";
import { CustomerERPConnectionInfo } from "src/screens/dashboard/customers/customer/erpconnectioninfo/CustomerERPConnectionInfo";
import styles from "./CustomerDetail.module.css";
import { CalculateTilesForm } from "src/screens/dashboard/customers/overview/additional-actions/CalculateTilesForm";
import ManagerUser from "./manage-user/ManageUser";
import InviteUser from "./invite-user/InviteUser";

interface Params {
    customerid: string;
}

export interface CustomerDetailsType {
    active: boolean;
    email: string | null;
    erp: number;
    id: string;
    name: string | null;
    number: string | null;
    subdomain: string;
    type: number;
    features: number[];
    dns: boolean;
    remarks: string | null;
    partner: number;
    erpusername: string;
    erpcloud: boolean;
}

interface CustomerStatisticsType {
    internalUsers: number | null | undefined;
    externalUsers: number | null | undefined;
    countRealestates: number | null | undefined;
}

export enum ACTION {
    NONE = 0,
    RESETTING = 1,
    DELETING = 2,
    ACTUALIZING_REPX = 3,
    CLEANING_FILES = 4,
    RESETTING_DESIGN = 5,
    ACTIVATING_DNS = 6,
    DEACTIVATING_DNS = 7,
    ACTUALIZE_ZIPCODES = 8
}

export enum MODAL {
    NONE = 0,
    RESET = 1,
    DELETE = 2,
    ACTUALIZE_REPX = 3,
    CLEAN_FILES = 4,
    RESET_DESIGN = 5,
    ACTIVATE_DNS = 6,
    DEACTIVATE_DNS = 7
}

interface NewCustomerERPCredentials {
    hostname: string;
    username: string;
    password: string;
    title: string;
}

const CustomerDetailBase = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const { navStore, uiStore, authStore } = useContext(RootStoreContext);

    const { customerid } = useParams<Params>();

    const [customerDetails, setCustomerDetails] = useState<CustomerDetailsType | null>(null);
    const [customerHostname, setCustomerHostname] = useState<string | null>(null);
    const [customerStatistics, setCustomerStatistics] = useState<CustomerStatisticsType | null>(null);
    const [wasCustomerDeleted, setWasCustomerDeleted] = useState(false);

    const [newCustomerERPCredentials, setNewCustomerERPCredentials] = useState<NewCustomerERPCredentials | undefined>();

    const [deactivateCustomerMutation] = useMutation<UpdateCustomer, UpdateCustomerVariables>(UPDATE_UMS_CUSTOMER, {
        update: (cache, { data }) => {
            const deletedId = data?.update_ums_customers?.returning[0].id;

            cache.modify({
                fields: {
                    ums_customers(existingCustomerRefs, { readField }) {
                        return existingCustomerRefs.filter(
                            (existingCustomerRef: any) => deletedId !== readField("id", existingCustomerRef)
                        );
                    }
                }
            });
        }
    });

    const deleteCustomer = useCallback(async () => {
        if (authStore.user && authStore.token && customerDetails?.id) {
            const accessToken = authStore.token;
            const tokenType = authStore.tokenType;
            const role = authStore.user?.role;

            try {
                const fetchResult = await fetch(`${NetworkConfig.customerBaseUrl}/${customerDetails?.id}`, {
                    method: "DELETE",
                    headers: {
                        Authorization: `${tokenType} ${accessToken}`,
                        "x-hasura-role": getRoleKey(role)
                    }
                });

                if (fetchResult.status === 200) {
                    uiStore.printStatusMessage("Der Kunde wurde erfolgreich gelöscht", MessageType.INFO);
                    uiStore.setHasNewCustomerData(true);
                    setWasCustomerDeleted(true);
                    history.replace(CustomerRoutes.customers);
                } else {
                    uiStore.printStatusMessage(
                        "Beim Löschen ist ein Fehler aufgetreten. Versuche es bitte noch einmal.",
                        MessageType.ERROR
                    );
                }
            } catch (fetchError) {
                uiStore.printStatusMessage(
                    `Beim Löschen des Kunden ist ein Fehler aufgetreten: ${fetchError.error}`,
                    MessageType.ERROR
                );
            }
        }
    }, [authStore.token, authStore.tokenType, authStore.user, customerDetails?.id, uiStore, history]);

    useEffect(() => {
        if (navStore.back && navStore.back.to.includes("/customers")) {
            navStore.setTitle("Kunden");
            navStore.setBackbutton("/customers", "Kunden");
        }
    }, [navStore]);

    const {
        loading,
        error,
        data,
        refetch: refetchCustomerData
    } = useQuery<GetUmsCustomerDetailsById, GetUmsCustomerDetailsByIdVariables>(GET_UMS_CUSTOMER_DETAILS_BY_ID, {
        variables: {
            customerid: customerid
        }
    });

    useEffect(() => {
        if (uiStore.hasNewCustomerData) {
            refetchCustomerData();
            uiStore.setHasNewCustomerData(false);
        }

        if (uiStore.hasNewConnectionData) {
            refetchCustomerData();
            uiStore.setHasNewConnectionData(false);
        }
    }, [refetchCustomerData, uiStore, uiStore.hasNewCustomerData]);

    const setCustomerDetailStates = useCallback(
        (queryCustomerDetails: query_customer_details) => {
            const featuresArray = queryCustomerDetails.customerfeatures.map(
                (customerFeature) => customerFeature.feature
            );

            const erpUserName =
                queryCustomerDetails.erpuser.length > 0 && queryCustomerDetails.erpuser[0].name1
                    ? queryCustomerDetails.erpuser[0].name1
                    : "";

            if (queryCustomerDetails.erpuser.length === 0 && queryCustomerDetails.erpcloud) {
                uiStore.printStatusMessage("Dieser Kunde hat keinen ERP-Benutzer!", MessageType.ERROR);
            }

            const customerDetails: CustomerDetailsType = {
                active: queryCustomerDetails.active,
                email: queryCustomerDetails.email,
                erp: queryCustomerDetails.erp,
                id: queryCustomerDetails.id,
                name: queryCustomerDetails.name,
                number: queryCustomerDetails.number,
                subdomain: queryCustomerDetails.subdomain,
                type: queryCustomerDetails.type,
                features: featuresArray,
                dns: queryCustomerDetails.dns,
                remarks: queryCustomerDetails.remarks,
                partner: queryCustomerDetails.partner,
                erpusername: erpUserName,
                erpcloud: queryCustomerDetails.erpcloud
            };

            setCustomerDetails({
                ...customerDetails,
                features: [...featuresArray]
            });

            setCustomerStatistics({
                internalUsers: queryCustomerDetails.internal_users.aggregate?.count,
                externalUsers: queryCustomerDetails.external_users.aggregate?.count,
                countRealestates: queryCustomerDetails.count_realestates.aggregate?.count
            });

            const hostname = getCustomerHostname(
                customerDetails.subdomain,
                customerDetails.partner,
                customerDetails.dns
            );
            setCustomerHostname(hostname);
        },
        [uiStore]
    );

    useEffect(() => {
        if (!wasCustomerDeleted) {
            navStore.setTitle(t("common.customer"));

            if (data?.ums_customer_details) {
                navStore.setTitle(t("common.customer") + ": " + data.ums_customer_details.name);
                setCustomerDetailStates(data.ums_customer_details);
            }
        }
    }, [data, navStore, t, setCustomerDetailStates, wasCustomerDeleted]);

    const onDeleteCustomer = useCallback(async () => {
        await deactivateCustomerMutation({
            variables: {
                customerid: customerid,
                updatedFields: {
                    active: false
                }
            }
        });

        await deleteCustomer();
    }, [customerid, deactivateCustomerMutation, deleteCustomer]);

    const callCustomerService = useCallback(
        async (
            serviceEndpointUrl: string,
            httpMethod: string,
            successMessage: string,
            errorMessage: string,
            body: string = ""
        ): Promise<Boolean> => {
            if (authStore.user && authStore.token) {
                const accessToken = authStore.token;
                const tokenType = authStore.tokenType;
                const role = authStore.user?.role;

                try {
                    const fetchResult = await fetch(serviceEndpointUrl, {
                        method: httpMethod,
                        body: body,
                        headers: {
                            Authorization: `${tokenType} ${accessToken}`,
                            "x-hasura-role": getRoleKey(role)
                        }
                    });

                    if (fetchResult.status === 200) {
                        uiStore.printStatusMessage(successMessage, MessageType.INFO);
                        return true;
                    } else {
                        const response = await fetchResult.json();
                        uiStore.printStatusMessage(`${errorMessage}: ${response.message}`, MessageType.ERROR);
                        return false;
                    }
                } catch (fetchError) {
                    uiStore.printStatusMessage(`${errorMessage}: ${fetchError}`, MessageType.ERROR);
                    return false;
                }
            } else {
                uiStore.printStatusMessage(
                    "Keine Authorisierungs-Informationen vorhanden. Bitte lade die Seite neu.",
                    MessageType.ERROR
                );
                return false;
            }
        },
        [authStore.token, authStore.tokenType, authStore.user, uiStore]
    );

    const onResetCustomer = useCallback(async () => {
        if (customerDetails?.id) {
            const resetCustomerEndpointUrl = `${NetworkConfig.resetCustomerUrl}/${customerDetails?.id}`;

            await callCustomerService(
                resetCustomerEndpointUrl,
                "PATCH",
                "Der Kunde wurde erfolgreich zurückgesetzt",
                "Beim Zurücksetzen des Kunden ist ein Fehler aufgetreten."
            );

            refetchCustomerData();
        }
    }, [customerDetails?.id, callCustomerService, refetchCustomerData]);

    const onActualizeRepx = useCallback(async () => {
        if (customerDetails?.id) {
            const actualizeRepxEndpointUrl = `${NetworkConfig.actualizeCustomerRepxUrl}/${customerDetails?.id}`;

            await callCustomerService(
                actualizeRepxEndpointUrl,
                "POST",
                "Die Rexp-Datei wurde erfolgreich aktualisiert",
                "Beim Aktualisieren der Repx-Datei ist ein Fehler aufgetreten"
            );
        }
    }, [customerDetails?.id, callCustomerService]);

    const onCleanupFiles = useCallback(async () => {
        if (customerDetails?.id) {
            const cleanupFilesEndpointUrl = `${NetworkConfig.cleanupFilesUrl}/${customerDetails?.id}`;

            await callCustomerService(
                cleanupFilesEndpointUrl,
                "POST",
                "Die Dateibereinigung wurde erfolgreich abgeschlossen.",
                "Bei der Dateibereinigung ist ein Fehler aufgetreten"
            );

            refetchCustomerData();
        }
    }, [customerDetails?.id, refetchCustomerData, callCustomerService]);

    const onResetDesign = useCallback(async () => {
        if (customerDetails?.subdomain) {
            const resetDesignEndpointUrl = `${NetworkConfig.resetDesignUrl}/${customerDetails?.subdomain}`;

            await callCustomerService(
                resetDesignEndpointUrl,
                "POST",
                "Die Design-Dateien wurden erfolgreich zurückgesetzt",
                "Es ist ein Fehler aufgetreten"
            );
        }
    }, [customerDetails?.subdomain, callCustomerService]);

    const onActivateDns = useCallback(async () => {
        if (customerDetails?.id && customerDetails?.subdomain) {
            const activateDnsEndpointUrl = `${NetworkConfig.activateDnsUrl}`;

            const requestBody = {
                customerid: customerDetails?.id,
                subdomain: customerDetails?.subdomain
            };

            const success = await callCustomerService(
                activateDnsEndpointUrl,
                "POST",
                "DNS wurde erfolgreich aktiviert",
                "Es ist ein Fehler aufgetreten",
                JSON.stringify(requestBody)
            );

            if (success) {
                const hostname = getCustomerHostname(customerDetails.subdomain, customerDetails.partner, true);

                setNewCustomerERPCredentials({
                    hostname: hostname,
                    password: "gleich wie bisher",
                    username: customerDetails.erpusername
                        ? customerDetails.erpusername
                        : `erp@${customerDetails.subdomain}.wwportal.ch`,
                    title: "DNS wurde erfolgreich aktiviert"
                });

                navStore.setTitle("DNS aktivieren");
            }

            uiStore.setHasNewCustomerData(true);
        }
    }, [
        uiStore,
        customerDetails?.id,
        customerDetails?.subdomain,
        callCustomerService,
        customerDetails?.partner,
        navStore,
        customerDetails?.erpusername
    ]);

    const onDeactivateDns = useCallback(async () => {
        if (customerDetails?.id && customerDetails?.subdomain) {
            const deactivateDnsEndpointUrl = `${NetworkConfig.deactivateDnsUrl}`;

            const requestBody = {
                customerid: customerDetails?.id,
                subdomain: customerDetails?.subdomain
            };

            const success = await callCustomerService(
                deactivateDnsEndpointUrl,
                "POST",
                "DNS wurde erfolgreich deaktiviert",
                "Es ist ein Fehler aufgetreten",
                JSON.stringify(requestBody)
            );

            if (success) {
                const hostname = getCustomerHostname(customerDetails.subdomain, customerDetails.partner, false);

                setNewCustomerERPCredentials({
                    hostname: hostname,
                    password: "gleich wie bisher",
                    username: customerDetails.erpusername
                        ? customerDetails.erpusername
                        : `erp@${customerDetails.subdomain}.wwportal.ch`,
                    title: "DNS wurde erfolgreich deaktiviert"
                });

                navStore.setTitle("DNS deaktivieren");
            }

            uiStore.setHasNewCustomerData(true);
        }
    }, [
        uiStore,
        customerDetails?.id,
        customerDetails?.subdomain,
        callCustomerService,
        customerDetails?.partner,
        navStore,
        customerDetails?.erpusername
    ]);

    const emptyNewCustomerERPCredentialsState = useCallback(async () => {
        setNewCustomerERPCredentials(undefined);
        navStore.setTitle(`Kunde: ${customerDetails?.name}`);
    }, [customerDetails?.name, navStore]);

    if (loading || customerDetails === null) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata")} />;
    }
    if (data.ums_customer_details === null) {
        return <Screens.Error message={t("error.emptydata")} />;
    }

    const customerStatisticInfos = customerStatistics
        ? [
              { infoHeading: "Interne Benutzer", infoValue: customerStatistics.internalUsers },
              { infoHeading: "Externe Benutzer", infoValue: customerStatistics.externalUsers },
              { infoHeading: "Anzahl Liegenschaften", infoValue: customerStatistics.countRealestates }
          ]
        : null;

    const userRole = authStore.user ? authStore.user.role : undefined;
    const userCustomerNumber = authStore.user ? authStore.user.customerNumber : "";
    const isSupportUserAllowedToEditCustomer =
        userRole === CustomerRole.CUSTOMERSUPPORT && userCustomerNumber === customerDetails.number;

    const dialogButtonCssClasses = "w-100 customer-button";

    const customerDeleteDialog = (
        <UI.ConfirmationDialog
            key="customerDeleteDialog"
            buttonText="Kunde löschen"
            modalTitle="Kunde löschen"
            confirmationQuestion="Willst du den Kunden wirklich löschen?"
            inProgressText="Kunde wird gelöscht..."
            onConfirmation={onDeleteCustomer}
            buttonClasses={dialogButtonCssClasses}
            isDangerousOperation
        />
    );

    const customerResetDialog = (
        <UI.ConfirmationDialog
            key="customerResetDialog"
            buttonText="Kunde zurücksetzen"
            modalTitle="Kunde zurücksetzen"
            confirmationQuestion="Willst du den Kunden wirklich zurücksetzen?"
            inProgressText="Kunde wird zurückgesetzt..."
            onConfirmation={onResetCustomer}
            buttonClasses={dialogButtonCssClasses}
            isDangerousOperation
        />
    );

    const customerActualizeRepxDialog = (
        <UI.ConfirmationDialog
            key="customerActualizeRepxDialog"
            buttonText="Repx aktualisieren"
            modalTitle="Repx-Datei aktualisieren"
            confirmationQuestion="Willst du die Repx-Datei wirklich aktualisieren?"
            inProgressText="Rexp-Datei wird aktualisiert..."
            onConfirmation={onActualizeRepx}
            buttonClasses={dialogButtonCssClasses}
            isDangerousOperation
        />
    );

    const customerCleanupFilesDialog = (
        <UI.ConfirmationDialog
            key="customerCleanupFilesDialog"
            buttonText="Dateibereinigung"
            modalTitle="Dateibereinigung"
            confirmationQuestion="Willst du die Dateibereinigung wirklich starten?"
            inProgressText="Dateibereinigung wird ausgeführt..."
            onConfirmation={onCleanupFiles}
            buttonClasses={dialogButtonCssClasses}
            isDangerousOperation
        />
    );

    const customerResetDesignDialog = (
        <UI.ConfirmationDialog
            key="customerResetDesignDialog"
            buttonText="Design zurücksetzen"
            modalTitle="Design zurücksetzen"
            confirmationQuestion="Willst du die Design-Dateien wirklich zurücksetzen?"
            inProgressText="Design-Dateien werden zurückgesetzt..."
            onConfirmation={onResetDesign}
            buttonClasses={dialogButtonCssClasses}
            isDangerousOperation
        />
    );

    const customerActivateDnsDialog = (
        <UI.ConfirmationDialog
            key="customerActivatednsDialog"
            buttonText="DNS aktivieren"
            modalTitle="DNS aktivieren"
            confirmationQuestion="Willst du die DNS-Einstellungen wirklich aktivieren?"
            inProgressText="DNS-Einstellungen werden aktiviert..."
            onConfirmation={onActivateDns}
            buttonClasses={dialogButtonCssClasses}
            isDangerousOperation
        />
    );

    const customerDeactivateDnsDialog = (
        <UI.ConfirmationDialog
            key="customerDeactivatednsDialog"
            buttonText="DNS deaktivieren"
            modalTitle="DNS deaktivieren"
            confirmationQuestion="Willst du die DNS-Einstellungen wirklich deaktivieren?"
            inProgressText="DNS-Einstellungen werden deaktiviert..."
            onConfirmation={onDeactivateDns}
            buttonClasses={dialogButtonCssClasses}
            isDangerousOperation
        />
    );

    const customerLogLink = <Link to={"logs"}>{"Logs ansehen >"}</Link>;
    const customerLogInfoElement = [{ infoHeading: "Logs", infoValue: customerLogLink }];
    const customerLogCount = data.ums_customer_details?.logs.aggregate?.count
        ? data.ums_customer_details?.logs.aggregate?.count
        : 0;

    const isDnsActivated = data.ums_customer_details?.dns ?? false;
    const isCustomerProd = data.ums_customer_details?.type === CustomerType.Prod ?? false;

    const extraActionButtons = [
        {
            infoHeading: "Zusätzliche Aktionen",
            infoValue: (
                <>
                    {isCustomerProd ? undefined : customerDeleteDialog}
                    {customerResetDialog}
                    {customerActualizeRepxDialog}
                    {customerCleanupFilesDialog}
                    {customerResetDesignDialog}
                    {isDnsActivated ? customerDeactivateDnsDialog : customerActivateDnsDialog}
                    {<CalculateTilesForm customerid={customerDetails.id} />}
                </>
            )
        }
    ];

    const extraActionButtonsAdmin = [
        {
            infoHeading: "Zusätzliche Aktionen",
            infoValue: <>{isDnsActivated ? customerDeactivateDnsDialog : customerActivateDnsDialog}</>
        }
    ];

    const extraActionButtonsSupport = [
        {
            infoHeading: "Zusätzliche Aktionen",
            infoValue: <>{customerResetDialog}</>
        }
    ];

    const inspectionMinversion =
        data.inspversions.length > 0
            ? data.inspversions.find((version) => version.key === "INSP.MINVERSION")?.value
            : undefined;

    let formattedInspectionMinVersion = undefined;

    if (inspectionMinversion) {
        formattedInspectionMinVersion = getFormattedVersionNumber(inspectionMinversion);
    }

    const inspectionCurrentVersion =
        data.inspversions.length > 0
            ? data.inspversions.find((version) => version.key === "INSP.CURRENTVERSION")?.value
            : undefined;

    let formattedInspectionCurrentVersion = undefined;

    if (inspectionCurrentVersion) {
        formattedInspectionCurrentVersion = getFormattedVersionNumber(inspectionCurrentVersion);
    }

    const versionInfo = [
        { infoHeading: "Inspection Minversion", infoValue: formattedInspectionMinVersion },
        { infoHeading: "Inspection CurrentVersion", infoValue: formattedInspectionCurrentVersion }
    ];

    const adminuser =
        data.ums_customer_details.adminuser.length > 0 ? data.ums_customer_details.adminuser[0] : undefined;

    const customerInvoicesLink = <Link to={"invoices"}>{"Laufende Kosten"}</Link>;
    const customerInvoicesInfoElement = [{ infoHeading: "", infoValue: customerInvoicesLink }];

    const connections = data.ums_customer_details?.connections.length > 0 ? data.ums_customer_details.connections : [];

    const renderCustomerDetails = () => (
        <UI.Row>
            <UI.Col md={9}>
                <UI.Card>
                    <CustomerDetailsForm
                        customerDetails={customerDetails}
                        userRole={userRole}
                        setNewCustomerERPCredentials={setNewCustomerERPCredentials}
                    />
                </UI.Card>
                {userRole ? (
                    userRole === CustomerRole.CUSTOMERDEV || userRole === CustomerRole.CUSTOMERSUPPORT ? (
                        <UI.Card title="Verbindungen">
                            <ConnectionList
                                connections={connections}
                                types={data.connectiontypes}
                                categories={data.connectioncategories}
                            />
                        </UI.Card>
                    ) : undefined
                ) : undefined}
            </UI.Col>
            <UI.Col md={3}>
                <div style={{ padding: "20px", backgroundColor: "white" }}>
                    <UI.Thumbnail src={ImagePlaceholder} />
                </div>
                {customerStatistics ? <InfoContainer infoItems={customerStatisticInfos} /> : undefined}

                {inspectionMinversion ? (
                    <InfoContainer key="info-container-insp-minversion" infoItems={versionInfo} />
                ) : undefined}

                {data.ums_customer_details?.apiversion ? (
                    <InfoContainer
                        key="info-container-apiversion"
                        infoItems={[{ infoHeading: "API-Version", infoValue: data.ums_customer_details?.apiversion }]}
                    />
                ) : undefined}

                <InfoContainer key="invoices-container" infoItems={customerInvoicesInfoElement} />

                {userRole ? (
                    userRole === CustomerRole.CUSTOMERDEV ||
                    userRole === CustomerRole.CUSTOMERADMIN ||
                    isSupportUserAllowedToEditCustomer ? (
                        //Customeradmin zu rollen hinzugefügt welche die Kachel sehen können.
                        <>
                            {customerDetails && customerHostname ? (
                                <div className={styles.customerLoginInformation}>
                                    <CustomerLoginInfo
                                        hostname={customerHostname}
                                        username={customerDetails.erpusername}
                                        passwordHidden={true}
                                        customerId={customerDetails.id}
                                    />
                                </div>
                            ) : undefined}
                        </>
                    ) : undefined
                ) : undefined}
                {userRole ? (
                    userRole === CustomerRole.CUSTOMERDEV ? (
                        <div className={styles.customerLoginInformation}>
                            <CustomerAdminuserInfo
                                adminuser={adminuser}
                                userroleEnumerations={data.userroles}
                                customerId={customerDetails.id}
                            />
                        </div>
                    ) : undefined
                ) : undefined}
                {userRole ? (
                    (userRole === CustomerRole.CUSTOMERSUPPORT && customerDetails.erpcloud) ||
                    userRole === CustomerRole.CUSTOMERDEV ? (
                        <UI.Card title={t("user_import.title")} className={styles.manageUserCard}>
                            <InviteUser customerDetails={customerDetails} />
                            <ManagerUser customerId={customerDetails.id} />
                        </UI.Card>
                    ) : undefined
                ) : undefined}
                {userRole ? (
                    (userRole === CustomerRole.CUSTOMERDEV || userRole === CustomerRole.CUSTOMERSUPPORT) &&
                    customerLogCount > 0 ? (
                        <>
                            <InfoContainer key="info-container-1" infoItems={customerLogInfoElement} />
                        </>
                    ) : undefined
                ) : undefined}
                {userRole ? (
                    userRole === CustomerRole.CUSTOMERDEV ? (
                        <>
                            <CustomerFileUsage
                                customerFileUsage={data.ums_customer_details?.file_usage}
                                filesWithErrors={data.ums_customer_details?.files_with_error_count}
                            />

                            <InfoContainer
                                key="extra-action-buttons"
                                infoItems={extraActionButtons}
                                className={styles.customerActionBtns}
                            />
                        </>
                    ) : undefined
                ) : undefined}
                {userRole ? (
                    userRole === CustomerRole.CUSTOMERADMIN ? (
                        <InfoContainer
                            key="extra-action-buttons-admin"
                            infoItems={extraActionButtonsAdmin}
                            className={styles.customerActionBtns}
                        />
                    ) : undefined
                ) : undefined}
                {isSupportUserAllowedToEditCustomer ? (
                    <InfoContainer
                        key="extra-action-buttons-support"
                        infoItems={extraActionButtonsSupport}
                        className={styles.customerActionBtns}
                    />
                ) : undefined}
            </UI.Col>
        </UI.Row>
    );

    return (
        <React.Fragment>
            <UI.Container className="pt-4">
                {newCustomerERPCredentials ? (
                    <UI.Row>
                        <UI.Col md={{ span: 6, offset: 3 }}>
                            <CustomerERPConnectionInfo
                                {...newCustomerERPCredentials}
                                okButtonRoute={selectRoute(CustomerRoutes.customerDetailsOverview, null, {
                                    customerid: customerid
                                })}
                                onOkButtonClick={emptyNewCustomerERPCredentialsState}
                            />
                        </UI.Col>
                    </UI.Row>
                ) : (
                    renderCustomerDetails()
                )}
            </UI.Container>
        </React.Fragment>
    );
};

export const CustomerDetail = Sentry.withProfiler(observer(CustomerDetailBase));
