import { gql } from "@apollo/client";

export interface ActiveAnnouncementListItem {
    product: string;
    activeAnnouncements: number;
}

export const GET_FEATURE_ANNOUNCEMENTS = gql`
    query GetFeatureAnnouncements {
        announcements: uccm_announcements(order_by: { startdate: desc }) {
            id
            name
            startdate
            enddate
            state
            targetproduct
        }

        active_announcements_inspectionapp: uccm_announcements_aggregate(
            where: {
                state: { _eq: 20 }
                startdate: { _lt: "now()" }
                enddate: { _gt: "now()" }
                targetproduct: { _eq: 1 }
            }
        ) {
            aggregate {
                count(columns: targetproduct)
            }
        }

        active_announcements_portal: uccm_announcements_aggregate(
            where: {
                state: { _eq: 20 }
                startdate: { _lt: "now()" }
                enddate: { _gt: "now()" }
                targetproduct: { _eq: 2 }
            }
        ) {
            aggregate {
                count(columns: targetproduct)
            }
        }
    }
`;
