import React from "react";
import { observer } from "mobx-react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { useCallback, useContext, useEffect, useState } from "react";
import { RootStoreContext } from "src/stores/RootStore";
import { UI } from "@wwimmo/ui";
import { startCase } from "lodash";
import {
    IMPORT_USERS_PROCESS,
    IMPORT_USERS_PROCESS_STEP,
    IInviteUserForm
} from "src/stores/InviteUserProgressModalStore";
import styles from "./InviteUserProgressModal.module.css";
import { getRoleKey } from "src/network/User";
import { NetworkConfig } from "src/network/NetworkConfig";
import { useForm } from "react-hook-form";
import { CustomerDetailsType } from "src/screens/dashboard/customers/details/detail/CustomerDetail";
import { Patterns } from "src/utils/Patterns";
import { useMutation } from "@apollo/client";
import { INSERT_USERIMPORTDATA } from "src/api/users";
import {
    InsertUserImportprocesses,
    InsertUserImportprocessesVariables
} from "src/api/generated/InsertUserImportprocesses";

interface InviteUserProgressModalProps {
    customerDetails?: CustomerDetailsType;
}

const InviteUserProgressModalBase = (props: InviteUserProgressModalProps) => {
    const { t } = useTranslation();
    const { uiStore, inviteUserProgressModalStore, authStore } = useContext(RootStoreContext);
    const {
        setCurrentProcessType,
        setisInviteUserProgressModalDisplayed,
        currentStepType,
        currentStep,
        isInviteUserProgressModalDisplayed,
        IMPORT_USERS_MODAL_MAX_NUMBER_OF_STEPS,
        setCurrentStepType
    } = inviteUserProgressModalStore;

    const { register, handleSubmit, watch } = useForm<IInviteUserForm>();

    const [insertUserImportMutation] = useMutation<InsertUserImportprocesses, InsertUserImportprocessesVariables>(
        INSERT_USERIMPORTDATA
    );

    useEffect(() => {
        inviteUserProgressModalStore.setUsersAllowedToImport(false);
        // eslint-disable-next-line
    }, []);

    const [isSuccessfullyImported, setIsSuccessfullyImported] = useState<boolean>(false);
    const [errorMsgStartInviteUserProcess, setErrorMsgStartInviteUserProcess] = useState<string>("");

    const fetchStartImportUserProcess = useCallback(async () => {
        if (authStore.user && authStore.token) {
            const accessToken = authStore.token;
            const tokenType = authStore.tokenType;
            const role = authStore.user?.role;
            const body = {
                customerid: inviteUserProgressModalStore.customerId,
                userimportprocessid: inviteUserProgressModalStore.userimportprocessesId
            };

            try {
                const fetchResult = await fetch(NetworkConfig.startimportuserprocess, {
                    method: "POST",
                    body: JSON.stringify(body),
                    headers: {
                        Authorization: `${tokenType} ${accessToken}`,
                        "x-hasura-role": getRoleKey(role)
                    }
                });

                if (fetchResult.status === 200) {
                    setIsSuccessfullyImported(true);
                    inviteUserProgressModalStore.setisInviteUserSuccessfullySend(true);
                } else {
                    const response = await fetchResult.json();
                    setErrorMsgStartInviteUserProcess(response.message);
                    setIsSuccessfullyImported(false);
                    inviteUserProgressModalStore.setisInviteUserSuccessfullySend(true);
                }
            } catch (fetchError) {
                setErrorMsgStartInviteUserProcess(`${fetchError}`);
                setIsSuccessfullyImported(false);
                inviteUserProgressModalStore.setisInviteUserSuccessfullySend(true);
            }
        }
    }, [authStore, inviteUserProgressModalStore]);

    const onCloseModal = useCallback(() => {
        setisInviteUserProgressModalDisplayed(false);
        setIsSuccessfullyImported(false);
        setCurrentStepType(0);
        inviteUserProgressModalStore.setUsersAllowedToImport(false);
    }, [setisInviteUserProgressModalDisplayed, setCurrentStepType, inviteUserProgressModalStore]);

    useEffect(() => {
        if (watch("firstname") && watch("lastname") && watch("email")) {
            inviteUserProgressModalStore.setUsersAllowedToImport(Patterns.EMAILADDRESS.test(watch("email")));
        } else {
            inviteUserProgressModalStore.setUsersAllowedToImport(false);
        }
    }, [watch, inviteUserProgressModalStore]);

    const onSubmit = async (formData: IInviteUserForm) => {
        setCurrentProcessType(IMPORT_USERS_PROCESS.IMPORT_USERS_RESULT);

        try {
            const transformedImportUser = {
                users: [
                    {
                        email: formData.email,
                        name1: formData.firstname,
                        name2: formData.lastname,
                        isadmin: formData.erpAdmin,
                        toimport: true,
                        erpid: "1",
                        state: 1,
                        portaluserid: ""
                    }
                ]
            };

            const { data } = await insertUserImportMutation({
                variables: {
                    userimport: {
                        customerid: props.customerDetails!.id || "",
                        importdata: transformedImportUser
                    }
                }
            });

            inviteUserProgressModalStore.setUserimportprocessesId(data?.insert_ums_userimportprocesses_one?.id);
            inviteUserProgressModalStore.setCustomerId(data?.insert_ums_userimportprocesses_one?.customerid);

            fetchStartImportUserProcess();
        } catch (error: any) {
            inviteUserProgressModalStore.setErrorMsgUpdateUserImportDb(error.message);
            console.error(error.message);
            inviteUserProgressModalStore.setisInviteUserSuccessfullySend(true);
            setIsSuccessfullyImported(false);
        }
    };

    const renderStep = () => {
        switch (currentStepType) {
            case IMPORT_USERS_PROCESS_STEP.START_IMPORT_USERS:
            default:
                return (
                    <>
                        <UI.Row className={"mt-2"}>
                            <UI.Form className={styles.FormContainer}>
                                <UI.Input
                                    label={t("user.customer")}
                                    value={`${props.customerDetails?.name} [${props.customerDetails?.number}]` || ""}
                                    type="text"
                                    disabled={true}
                                />
                                <UI.Input
                                    label={t("user_import.firstname")}
                                    ref={register}
                                    name="firstname"
                                    as="input"
                                ></UI.Input>
                                <UI.Input
                                    label={t("user_import.lastname")}
                                    ref={register}
                                    name="lastname"
                                    as="input"
                                ></UI.Input>
                                <UI.Input
                                    label={t("user_import.email_address")}
                                    ref={register}
                                    name="email"
                                    as="input"
                                    type="email"
                                    errorMsg={
                                        Patterns.EMAILADDRESS.test(watch("email")) ? undefined : "Invalid email address"
                                    }
                                ></UI.Input>

                                <UI.Checkbox name="erpAdmin" label={t("invite_user.erp_admin")} ref={register} />
                            </UI.Form>
                        </UI.Row>
                        <UI.Row className={"mt-5"}>
                            <UI.Col xs={6} md={6}>
                                <UI.Button
                                    className={`ticket-button mt-2 ${styles.cancelButton}`}
                                    label={startCase(t("labels.cancel").toString())}
                                    variant={"outline-primary"}
                                    onClick={onCloseModal}
                                ></UI.Button>
                            </UI.Col>
                            <UI.Col xs={6} md={6} className="d-flex justify-content-end">
                                <UI.Button
                                    className={`ticket-button mt-2 ${styles.ImportButton}`}
                                    label={t("invite_user.invite")}
                                    disabled={!inviteUserProgressModalStore.usersAllowedToImport}
                                    onClick={handleSubmit(onSubmit)}
                                ></UI.Button>
                            </UI.Col>
                        </UI.Row>
                    </>
                );
            case IMPORT_USERS_PROCESS_STEP.IMPORT_USERS_RESULT:
                return (
                    <>
                        {!inviteUserProgressModalStore.isInviteUserSuccessfullySend ? (
                            <>
                                <UI.Col className={styles.DivImageLoadSpinner}>
                                    <div className={styles.LoadSpinnerDiv}>
                                        <UI.RotatingSpinner noLogo size={50} className={styles.ImageLoadSpinner} />
                                        <div className={styles.CreateUser}>{t("invite_user.creating_user")}</div>
                                    </div>
                                </UI.Col>
                                <UI.Row className={"mt-5 justify-content-end"}>
                                    <UI.Col xs={12} md={3}>
                                        <UI.Button
                                            className={`ticket-button mt-2 ${styles.cancelButton}`}
                                            label={startCase(t("labels.cancel").toString())}
                                            variant={"outline-primary"}
                                            onClick={onCloseModal}
                                        ></UI.Button>
                                    </UI.Col>
                                </UI.Row>
                            </>
                        ) : (
                            <>
                                <UI.Row className={"mt-4"}>
                                    <UI.Col className={styles.ColAfterActivate}>
                                        <UI.Icon
                                            icon={isSuccessfullyImported ? UI.SVGIcon.Check : UI.SVGIcon.Close}
                                            color={"black"}
                                            size={80}
                                        />
                                    </UI.Col>
                                </UI.Row>
                                <UI.Row className={"mt-5"}>
                                    <UI.Col className={styles.ColAfterActivate}>
                                        {isSuccessfullyImported ? (
                                            <div className={styles.ResultMessage}>
                                                <div className={styles.FirstResaultMsg}>
                                                    {t("invite_user.successfull_imported")}
                                                </div>
                                                <div className={styles.SecondResaultMsg}>
                                                    {t("invite_user.process_started")}
                                                </div>
                                            </div>
                                        ) : (
                                            <div className={styles.ResultMessage}>
                                                <div className={styles.FirstResaultMsg}>
                                                    {inviteUserProgressModalStore.errorMsgUpdateUserImportDb}
                                                </div>
                                                <div className={styles.SecondResaultMsg}>
                                                    {errorMsgStartInviteUserProcess}
                                                </div>
                                            </div>
                                        )}
                                    </UI.Col>
                                </UI.Row>
                                <UI.Row className={"mt-5 justify-content-end"}>
                                    <UI.Col xs={12} md={3}>
                                        <UI.Button
                                            className={`d-flex align-self-end ${styles.ImportButton}`}
                                            label={t("labels.finish")}
                                            onClick={onCloseModal}
                                        ></UI.Button>
                                    </UI.Col>
                                </UI.Row>
                            </>
                        )}
                    </>
                );
        }
    };

    return (
        <UI.ProgressModal
            currentStep={currentStep?.stepNumber ?? 1}
            totalSteps={IMPORT_USERS_MODAL_MAX_NUMBER_OF_STEPS}
            title={`${t("invite_user.title")}`}
            show={isInviteUserProgressModalDisplayed}
            onDismissModal={onCloseModal}
            centered={uiStore.isMobile ? true : false}
            size={uiStore.isMobile ? undefined : "lg"}
        >
            {renderStep()}
        </UI.ProgressModal>
    );
};

export const InviteUserProgressModal = Sentry.withProfiler(observer(InviteUserProgressModalBase));
