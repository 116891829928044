import React, { useCallback, useContext, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { UI } from "@wwimmo/ui";
import { submitUserFeedback } from "src/utils/Feedback";
import { RootStoreContext } from "src/stores/RootStore";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";
import ImageMove from "src/assets/images/HG_0109.jpg";
import styles from "./Error.module.css";

export interface ErrorProps {
    message?: string;
    networkError?: Error & any;
    componentStack?: string | null;
    resetError?: () => void;
    error?: Error | null;
    type?: "error" | "nodata" | "notfound";
}

const ErrorBase = (props: ErrorProps) => {
    const { t } = useTranslation();
    const { navStore, authStore, uiStore } = useContext(RootStoreContext);

    useEffect(() => {
        if (props.type === "nodata") {
            Sentry.captureMessage("Empty Data: " + props.message);
        }

        if (props.type === "notfound") {
            navStore.setTitle("404");
            Sentry.captureMessage("404 Error: " + window.location.href + " not found");
        }
    });

    const handleSubmitFeedback = useCallback(() => {
        submitUserFeedback(navStore.customer);
    }, [navStore.customer]);

    const onHistoryBack = useCallback(() => {
        window.history.back();
    }, []);

    let cardElement = null;
    let containerClasses = ["pt-4"];
    let containerStyles = {};
    const defaultErrorElement = <UI.ErrorCard onSubmitFeedback={handleSubmitFeedback} {...props} />;

    switch (props.type) {
        case "error":
            cardElement = defaultErrorElement;
            break;
        case "nodata":
            cardElement = <UI.Card className="my-auto">{props.message}</UI.Card>;
            break;
        case "notfound":
            cardElement = (
                <UI.Card className="text-center align-items-center justify-content-center my-auto">
                    <h1 className="mb-2">{t("error.404-body")}</h1>
                    {authStore.isLoggedIn && (
                        <>
                            <UI.Button className="mb-3 mt-3" label={t("labels.goback")} onClick={onHistoryBack} />
                        </>
                    )}
                    <Link to="/">
                        <Trans>labels.to_startpage</Trans>
                    </Link>
                </UI.Card>
            );

            containerClasses.push(styles.ErrorNotFound);

            containerStyles = {
                backgroundImage: `url(${ImageMove})`,
                minHeight: `calc(100vh - ${uiStore.headerHeight}px)`
            };
            break;
        default:
            cardElement = defaultErrorElement;
            break;
    }

    return (
        <UI.Container className={containerClasses.join(" ")} style={containerStyles}>
            <UI.Row>
                <UI.Col className="text-center mt-4 pt-4">{cardElement}</UI.Col>
            </UI.Row>
        </UI.Container>
    );
};

export const Error = Sentry.withProfiler(ErrorBase);
