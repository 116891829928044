import React, { useContext, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { useQuery } from "@apollo/client";
import { GET_SERVICE_DETAILS } from "src/api/service";
import { GetServiceDetails, GetServiceDetailsVariables } from "src/api/generated/GetServiceDetails";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { RootStoreContext } from "src/stores/RootStore";
import * as Screens from "src/screens";
import { ServiceForm } from "./form/ServiceForm";
import { observer } from "mobx-react-lite";
import { Route as CustomerRoutes, selectRoute } from "src/config/routes";

interface Params {
    serviceid: string;
}

const ServiceDetailsBase = () => {
    const { t } = useTranslation();
    const { navStore, uiStore, authStore } = useContext(RootStoreContext);

    const { serviceid } = useParams<Params>();

    useEffect(() => {
        navStore.setTitle("Service Details");
        navStore.setBackbutton(selectRoute(CustomerRoutes.services, null), "Services");
    }, [navStore]);

    const {
        loading,
        error,
        data,
        refetch: refetchServiceData
    } = useQuery<GetServiceDetails, GetServiceDetailsVariables>(GET_SERVICE_DETAILS, {
        variables: {
            serviceid: serviceid
        }
    });

    useEffect(() => {
        if (uiStore.hasNewServiceData) {
            refetchServiceData();
        }
    }, [refetchServiceData, uiStore, uiStore.hasNewServiceData]);

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata")} />;
    }

    const userRole = authStore.user ? authStore.user.role : undefined;
    const serviceDetails = data.service.length > 0 ? data.service[0] : undefined;

    return (
        <ServiceForm
            serviceDetails={serviceDetails}
            serviceId={serviceid}
            onUpsertService={refetchServiceData}
            userRole={userRole}
            serviceStates={data.servicestates}
            serviceTypes={data.servicetypes}
            features={data.features}
        />
    );
};

export const ServiceDetails = Sentry.withProfiler(observer(ServiceDetailsBase));
