import React, { useContext, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { useQuery } from "@apollo/client";
import { GET_CONNECTION_DETAILS_BY_ID } from "src/api/customer";
import { GetConnectionDetails, GetConnectionDetailsVariables } from "src/api/generated/GetConnectionDetails";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { RootStoreContext } from "src/stores/RootStore";
import * as Screens from "src/screens";
import { CustomerConnectionForm } from "src/screens/dashboard/customers/customer/connection/details/form/CustomerConnectionForm";
import { observer } from "mobx-react-lite";
import { Route as CustomerRoutes, selectRoute } from "src/config/routes";
import { CustomerType } from "src/utils/Enums";

interface Params {
    customerid: string;
    connectionid: string;
}

const CustomerConnectionDetailsBase = () => {
    const { t } = useTranslation();
    const { navStore, uiStore, authStore } = useContext(RootStoreContext);

    const { customerid, connectionid } = useParams<Params>();

    useEffect(() => {
        navStore.setTitle("Verbindungsdetails");

        navStore.setBackbutton(
            selectRoute(CustomerRoutes.customerDetailsOverview, null, {
                customerid
            }),
            "Kunde"
        );
    }, [navStore, authStore.user?.role, customerid]);

    const {
        loading,
        error,
        data,
        refetch: refetchConnectionData
    } = useQuery<GetConnectionDetails, GetConnectionDetailsVariables>(GET_CONNECTION_DETAILS_BY_ID, {
        variables: {
            connectionid,
            customerid
        }
    });

    useEffect(() => {
        if (uiStore.hasNewConnectionData) {
            refetchConnectionData();
            uiStore.setHasNewConnectionData(false);
        }
    }, [refetchConnectionData, uiStore, uiStore.hasNewCustomerData]);

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata")} />;
    }

    const userRole = authStore.user ? authStore.user.role : undefined;
    const connectionDetails = data.ums_connections.length > 0 ? data.ums_connections[0] : undefined;
    const existingConnectionTypes =
        data.existing_connections.existing_types.length > 0 ? data.existing_connections.existing_types : undefined;

    const customerType = data.customer.length > 0 ? data.customer[0].type : CustomerType.Prod;

    const testConnections = data.testconnections.filter(
        (testconnection) => testconnection.customertype === customerType
    );

    return (
        <CustomerConnectionForm
            connectionDetails={connectionDetails}
            existingConnectionTypes={existingConnectionTypes}
            customerid={customerid}
            connectionid={connectionid}
            onUpsertConnection={refetchConnectionData}
            userRole={userRole}
            customerType={customerType}
            testConnections={testConnections}
        />
    );
};

export const CustomerConnectionDetails = Sentry.withProfiler(observer(CustomerConnectionDetailsBase));
