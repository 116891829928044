import { action, computed, makeObservable, observable } from "mobx";
import { RootStore } from "src/stores/RootStore";
import { Patterns } from "src/utils/Patterns";
import { GET_USEREMAILLISTSTATE } from "src/api/users";
import { GetUserEmailListState, GetUserEmailListStateVariables } from "src/api/generated/GetUserEmailListState";
import { apolloClientInstance } from "src/network/apolloClientInstance";

export enum IMPORT_USERS_PROCESS {
    START_IMPORT_USERS,
    LOAD_IMPORT_USERS,
    IMPORT_USERS_RESULT
}

export enum IMPORT_USERS_PROCESS_STEP {
    START_IMPORT_USERS,
    LOAD_IMPORT_USERS,
    IMPORT_USERS_RESULT
}

export enum IMPORT_USERS_STATUS {
    NONE,
    ACTIVATING,
    SUCCESS,
    FAILURE
}

export interface IImportUser {
    email: string;
    erpid: string;
    name1: string;
    name2: string;
    isadmin: boolean;
    toimport: boolean;
    portaluserid: string;
    state: number;
}

interface IProcessStep {
    stepNumber: number;
    stepType: IMPORT_USERS_PROCESS_STEP;
    next?: () => IProcessStep | null;
    prev?: () => IProcessStep | null;
    parent: IProcess | null;
}

interface IProcess {
    type: IMPORT_USERS_PROCESS;
    processSteps: IProcessStep[];
}

interface IImportUsersProcess {
    stepNumber: number;
    stepType: IMPORT_USERS_PROCESS_STEP;
    processes: IProcess[];
}

export class ImportUsersProgressModalStore {
    rootStore: RootStore;

    IIMPORT_USERS_MODAL_MAX_NUMBER_OF_STEPS = 3;

    currentProcessType: IMPORT_USERS_PROCESS = IMPORT_USERS_PROCESS.START_IMPORT_USERS;
    currentStepType: IMPORT_USERS_PROCESS_STEP = IMPORT_USERS_PROCESS_STEP.START_IMPORT_USERS;
    currentImportUsersStatus: IMPORT_USERS_STATUS = IMPORT_USERS_STATUS.NONE;
    isImportUsersProgressModalDisplayed = false;
    isImportUsersSucceeded = false;
    inputFeedback = "";
    updatedUserImportList: IImportUser[] = [];
    usersAllowedToImport = false;
    userimportprocessesId = "";
    ImportUsersProcess: IImportUsersProcess = {
        stepNumber: 1,
        stepType: IMPORT_USERS_PROCESS_STEP.START_IMPORT_USERS,
        processes: [
            {
                type: IMPORT_USERS_PROCESS.LOAD_IMPORT_USERS,
                processSteps: [
                    {
                        stepNumber: 2,
                        stepType: IMPORT_USERS_PROCESS_STEP.LOAD_IMPORT_USERS,
                        next: function () {
                            return this.parent ? this.parent.processSteps[1] : null;
                        },
                        prev: function () {
                            return this.parent ? this.parent.processSteps[0] : null;
                        },
                        parent: null
                    }
                ]
            },
            {
                type: IMPORT_USERS_PROCESS.IMPORT_USERS_RESULT,
                processSteps: [
                    {
                        stepNumber: 3,
                        stepType: IMPORT_USERS_PROCESS_STEP.IMPORT_USERS_RESULT,
                        next: function () {
                            return this.parent ? this.parent.processSteps[1] : null;
                        },
                        prev: function () {
                            return this.parent ? this.parent.processSteps[0] : null;
                        },
                        parent: null
                    }
                ]
            }
        ]
    };

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        // Assign parents post object creation
        for (let process of this.ImportUsersProcess.processes) {
            for (let step of process.processSteps) {
                step.parent = process;
            }
        }

        makeObservable(this, {
            currentProcessType: observable,
            currentStepType: observable,
            currentImportUsersStatus: observable,
            isImportUsersProgressModalDisplayed: observable,
            isImportUsersSucceeded: observable,
            inputFeedback: observable,
            isEveryUserSelected: computed,
            updatedUserImportList: observable,
            usersAllowedToImport: observable,
            userimportprocessesId: observable,

            getProcess: action,
            resetImportUsersModal: action,
            resetCurrentProcessType: action,
            setCurrentProcessType: action,
            setCurrentStepType: action,
            setCurrentImportUsersStatus: action,
            setisImportUsersProgressModalDisplayed: action,
            setIsImportUsersSucceeded: action,
            setInputFeedback: action,
            setUserimportprocessesId: action,
            toggleAllUsersSelected: action,
            toggleUsersSelected: action,
            toggleUserAdmin: action,
            setUpdatedUserImportList: action,
            loadUserImportList: action,
            updateUserEmail: action,
            updateUserName: action,
            checkIfEmailExists: action,

            processes: computed,
            currentProcess: computed,
            currentStep: computed
        });
    }

    getProcess = (processType: IMPORT_USERS_PROCESS) => {
        return this.ImportUsersProcess.processes.find((process) => {
            return process.type === processType;
        })!;
    };

    resetImportUsersModal = () => {
        this.resetCurrentProcessType();
        this.currentImportUsersStatus = IMPORT_USERS_STATUS.NONE;
    };

    resetCurrentProcessType() {
        this.currentProcessType = IMPORT_USERS_PROCESS.IMPORT_USERS_RESULT;
        this.currentStepType = IMPORT_USERS_PROCESS_STEP.IMPORT_USERS_RESULT;
    }

    setCurrentProcessType = (processType: IMPORT_USERS_PROCESS) => {
        this.currentProcessType = processType;
        this.currentStepType = this.getProcess(processType).processSteps[0].stepType;
    };

    setCurrentStepType = (stepType: IMPORT_USERS_PROCESS_STEP) => {
        this.currentStepType = stepType;
    };

    setisImportUsersProgressModalDisplayed = (isModalDisplayed: boolean) => {
        this.isImportUsersProgressModalDisplayed = isModalDisplayed;
    };

    setCurrentImportUsersStatus = (status: IMPORT_USERS_STATUS) => {
        this.currentImportUsersStatus = status;
    };
    setIsImportUsersSucceeded = (isImportUsersSucceeded: boolean) => {
        this.isImportUsersSucceeded = isImportUsersSucceeded;
    };

    setInputFeedback = (inputFeedback: "") => {
        this.inputFeedback = inputFeedback;
    };

    setUpdatedUserImportList = (updatedUserImportList: IImportUser[]) => {
        this.updatedUserImportList = updatedUserImportList;
    };

    toggleUsersSelected = (email: string) => {
        this.updatedUserImportList = this.updatedUserImportList.map((user) => {
            const isEmailPatternValid = Patterns.EMAILADDRESS.test(user.email);

            if (user.email === email) {
                let localSelected = user.toimport ?? false;

                if (!localSelected && (!isEmailPatternValid || user.name1.trim() === "" || user.name2.trim() === "")) {
                    return { ...user };
                } else {
                    return {
                        ...user,
                        toimport: !localSelected
                    };
                }
            } else {
                return { ...user };
            }
        });
    };

    toggleAllUsersSelected = (customerid: string) => {
        this.updatedUserImportList = this.updatedUserImportList.map((user) => {
            const isEmailPatternValid = Patterns.EMAILADDRESS.test(user.email);
            const areNamesValid = user.name1.trim() !== "" && user.name2.trim() !== "";

            if (isEmailPatternValid) {
                this.checkIfEmailExists(customerid, user.email);
            }

            if (!user.toimport && (!isEmailPatternValid || !areNamesValid)) {
                return { ...user };
            } else {
                return {
                    ...user,
                    toimport: user.email === "" ? false : !this.isEveryUserSelected
                };
            }
        });
    };

    toggleUserAdmin = (email: string) => {
        this.updatedUserImportList = this.updatedUserImportList.map((user) => {
            if (user.email === email) {
                return {
                    ...user,
                    isadmin: !user.isadmin
                };
            } else {
                return { ...user };
            }
        });
    };

    loadUserImportList = (importdata: IImportUser[]) => {
        this.updatedUserImportList = importdata.map((user) => {
            return {
                ...user,
                email: user.email || "",
                initiallyEmptyEmail: !user.email,
                initiallyEmptyName: !user.name2,
                toimport: user.portaluserid ? true : false
            };
        });
    };

    updateUserEmail(importUser: IImportUser, newEmail: string, customerid: string) {
        const isEmailPatternValid = Patterns.EMAILADDRESS.test(newEmail);

        this.updatedUserImportList = this.updatedUserImportList.map((user) => {
            if (importUser === user) {
                return {
                    ...user,
                    email: newEmail,
                    isEmailPatternValid: isEmailPatternValid,
                    toimport: isEmailPatternValid,
                    state: isEmailPatternValid === false ? 1 : user.state
                };
            }
            return user;
        });
        if (isEmailPatternValid) {
            this.checkIfEmailExists(customerid, newEmail);
        }
    }

    updateUserName(importUser: IImportUser, name2: string) {
        this.updatedUserImportList = this.updatedUserImportList.map((user) => {
            if (user === importUser) {
                return {
                    ...user,
                    name2: name2,
                    toimport: name2.trim() === "" ? false : user.toimport
                };
            }
            return user;
        });
    }

    setUserimportprocessesId = (userimportprocessesId: string) => {
        this.userimportprocessesId = userimportprocessesId;
    };

    checkIfEmailExists = async (customerid: string, email: string) => {
        let emailString: string;
        if (email === "") {
            emailString = `{${this.updatedUserImportList
                .filter((user) => user.email)
                .map((user) => user.email)
                .join(", ")}}`;
        } else {
            emailString = `{${email}}`;
        }

        try {
            const { data } = await apolloClientInstance.query<GetUserEmailListState, GetUserEmailListStateVariables>({
                query: GET_USEREMAILLISTSTATE,
                variables: {
                    email: emailString,
                    customerid: customerid
                }
            });

            if (data.ums_user_email_state_function.length > 0) {
                this.updatedUserImportList = this.updatedUserImportList.map((user) => {
                    const userEmailState = data.ums_user_email_state_function.find(
                        (emailState) => emailState.email === user.email
                    );
                    if (userEmailState) {
                        const updatedState = userEmailState.user_email_state ?? 0;
                        return { ...user, state: updatedState, toimport: updatedState > 1 ? false : true };
                    }
                    return user;
                });
            }
            return true;
        } catch (error) {
            console.error("Error reloading orders: ", error);
            return false;
        }
    };

    get isEveryUserSelected(): boolean {
        return this.updatedUserImportList.filter((e) => e.toimport === undefined || e.toimport === false).length === 0;
    }

    /* COMPUTED */
    get processes() {
        return this.ImportUsersProcess.processes;
    }

    get currentProcess(): IProcess | undefined {
        return this.ImportUsersProcess.processes.find((process) => process.type === this.currentProcessType);
    }

    get currentStep(): IProcessStep | undefined {
        return this.currentProcess?.processSteps.find((step) => step.stepType === this.currentStepType);
    }
}
