import { gql } from "@apollo/client";

export const GET_FEATURE_ANNOUNCEMENT_DETAILS = gql`
    query GetFeatureAnnouncementDetails($announcementid: uuid!, $lang: String = "de") {
        announcement: uccm_announcements(where: { id: { _eq: $announcementid } }) {
            id
            name
            startdate
            enddate
            state
            targetproduct
            apiversionfrom
            appversionfrom
            targetoption
            announcementroles(order_by: { role: asc }) {
                id
                role
            }
            announcements_mls {
                id
                language
                content
            }
        }

        announcementstates: common_enumerations(
            where: { type: { _eq: 800 }, language: { _eq: $lang } }
            order_by: { key: asc }
        ) {
            id
            key
            label
        }

        customeroptions: common_enumerations(
            where: { type: { _eq: 96 }, language: { _eq: $lang } }
            order_by: { key: asc }
        ) {
            id
            key
            label
        }

        products: common_enumerations(where: { type: { _eq: 801 }, language: { _eq: $lang } }, order_by: { key: asc }) {
            id
            key
            label
        }
    }
`;

export const GET_FEATURE_ANNOUNCEMENT_CONTENT_DETAILS = gql`
    query GetFeatureAnnouncementContentDetails($contentid: uuid!) {
        announcementcontent: uccm_announcements_mls(where: { id: { _eq: $contentid } }) {
            id
            language
            teaser
            content
            announcement {
                announcementfiles {
                    id
                    name
                    extension
                    mimetype
                    language
                }
            }
        }
    }
`;

export const UPSERT_UCCM_ANNOUNCEMENT = gql`
    mutation UpsertUccmAnnouncement($announcement: uccm_announcements_insert_input!, $announcementid: uuid!) {
        delete_uccm_announcementroles(where: { announcementid: { _eq: $announcementid } }) {
            affected_rows
        }

        insert_uccm_announcements_one(
            object: $announcement
            on_conflict: {
                update_columns: [
                    name
                    startdate
                    enddate
                    state
                    targetproduct
                    apiversionfrom
                    appversionfrom
                    targetoption
                ]
                constraint: PK_announcements
            }
        ) {
            id
            name
            startdate
            enddate
            state
            targetproduct
            apiversionfrom
            appversionfrom
            targetoption
            announcementroles(order_by: { role: asc }) {
                id
                role
            }
        }
    }
`;

export const DELETE_ANNOUNCEMENT = gql`
    mutation DeleteAnnouncement($announcementid: uuid!) {
        delete_uccm_announcements(where: { id: { _eq: $announcementid } }) {
            affected_rows
        }
    }
`;

export const UPSERT_UCCM_ANNOUNCEMENT_MLS = gql`
    mutation UpsertAnnouncementsMls($announcementsmls: uccm_announcements_mls_insert_input!) {
        insert_uccm_announcements_mls_one(
            object: $announcementsmls
            on_conflict: { constraint: PK_announcements_mls, update_columns: [language, teaser, content] }
        ) {
            id
            teaser
            language
            content
        }
    }
`;

export const DELETE_ANNOUNCEMENT_MLS = gql`
    mutation DeleteAnnouncementMls($announcementmlsid: uuid!) {
        delete_uccm_announcements_mls(where: { id: { _eq: $announcementmlsid } }) {
            affected_rows
        }
    }
`;
