import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import { UI } from "@wwimmo/ui";

interface NavigationbarItemProps {
    to: string;
    icon: React.ReactNode;
    label: string;
    disabled: boolean;
    onClick?: any;
}

export const NavigationbarItem = (props: NavigationbarItemProps) => (
    <LinkContainer className="NavigationbarItem" to={props.to}>
        <UI.Nav.Link disabled={props.disabled} onClick={props.onClick}>
            <UI.Icon icon={props.icon} />
            <span className="d-none d-md-block ml-2">{props.label}</span>
        </UI.Nav.Link>
    </LinkContainer>
);

NavigationbarItem.defaultProps = {
    disabled: false
};
