import React from "react";
import classes from "./InfoContainer.module.css";

interface InfoContainerProps {
    infoItems: { infoHeading: string; infoValue: any }[] | null;
    className?: string;
}

export const InfoContainer = (props: InfoContainerProps) => {
    const infoItems = props.infoItems?.map((infoItem, index) => {
        let infoElement = undefined;

        if (infoItem.infoValue || infoItem.infoValue === 0) {
            infoElement = (
                <div key={`${infoItem.infoHeading}-${index}`} className={`classes.info ${props.className ?? ""}`}>
                    <p>{infoItem.infoHeading}</p>
                    <p className={classes.infoText}>{infoItem.infoValue}</p>
                </div>
            );
        }

        return infoElement;
    });

    return <div className={classes.infoContainer}>{infoItems && infoItems?.length > 0 ? infoItems : null}</div>;
};
