import React, { useContext, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { RootStoreContext } from "src/stores/RootStore";
import { Loading } from "src/screens";
import { Link, Redirect } from "react-router-dom";
import { runInAction } from "mobx";
import { Trans, useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { Route } from "src/config/routes";
import { useApolloClient } from "@apollo/client";

const countDownInSeconds = 5;

enum LOGOUTSTATES {
    PENDING = 0,
    ACTIVE = 1,
    DONE = 2
}

const LogoutBase = () => {
    const { navStore, uiStore, authStore } = useContext(RootStoreContext);
    const { t } = useTranslation();
    const [logout, setLogout] = useState<LOGOUTSTATES>(LOGOUTSTATES.PENDING);
    const [redirect, setRedirect] = useState(false);
    const [countdown, setCountdown] = useState(countDownInSeconds);
    const apollo = useApolloClient();

    useEffect(() => {
        runInAction(() => {
            navStore.setBackbutton(null);
            navStore.setTitle(t("screens.auth.logout"));
        });
    }, [navStore, t]);

    useEffect(() => {
        if (logout === LOGOUTSTATES.PENDING) {
            setLogout(LOGOUTSTATES.ACTIVE);
            authStore.authClient
                .logout()
                .then(() => apollo.clearStore())
                .then(() => navStore.reset())
                .then(() => uiStore.reset())
                .then(() => setLogout(LOGOUTSTATES.DONE))
                .then(() => setCountdown(countDownInSeconds));
        }
        const countdownInterval = setInterval(() => {
            setCountdown(countdown - 1);
            if (countdown <= 1) {
                setRedirect(true);
            }
        }, 1000);
        return () => {
            clearInterval(countdownInterval);
        };
    }, [logout, authStore.authClient, apollo, navStore, uiStore, setCountdown, countdown]);

    if (authStore.user) {
        return <Loading fullscreen />;
    } else if (redirect) {
        return <Redirect to="/" />;
    } else if (logout === LOGOUTSTATES.DONE) {
        return (
            <UI.Container className="flex-grow-1 d-flex justify-content-center align-items-center flex-column">
                <UI.Row>
                    <UI.Col>{t("screens.auth.logout_process", { countdown: String(countdown) })}</UI.Col>
                </UI.Row>
                <UI.Row className="mt-2">
                    <UI.Col>
                        <Link to={Route.login}>
                            <Trans>labels.continue</Trans>...
                        </Link>
                    </UI.Col>
                </UI.Row>
            </UI.Container>
        );
    } else {
        return <Loading fullscreen />;
    }
};

export const Logout = Sentry.withProfiler(observer(LogoutBase));
