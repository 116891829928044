export interface ConfigSettings {
    authority: string;
    graphqlUrl: string;
    graphqlRestUrl: string;
    datafileUrl: string;
    uploadAnnouncementFileUrl: string;
    deleteAnnouncementFileUrl: string;
    uploadServiceFileUrl: string;
    uploadServiceLogoUrl: string;
    deleteServiceFileUrl: string;
    customerBaseUrl: string;
    rentalBaseUrl: string;
    createCustomerUrl: string;
    resetCustomerUrl: string;
    generateNewERPPasswordUrl: string;
    actualizeCustomerRepxUrl: string;
    cleanupFilesUrl: string;
    resetDesignUrl: string;
    activateDnsUrl: string;
    deactivateDnsUrl: string;
    activateAdminUrl: string;
    deactivateAdminUrl: string;
    encryptPasswordUrl: string;
    emonitorApiTestUrl: string;
    updatecustomercssinfo: string;
    testConnectionUrl: string;
    registerEmonitorEventsForCustomerUrl: string;
    brandAssets: {
        logoImage: string;
        css: string;
    };
    sentryUrl: string;
    actualizeZipcodesUrl: string;
    copyBlobItemsFromCustomer: string;
    timeout: number;
    proxy: string;
    clientId: string;
    scope: string;
    responseType: string;
    environment: string;
    customerAuthSubdomain: string;
    authWebsiteUrl: string;
    updateUserStatusUrl: string;
    startimportuserprocess: string;
    graphqlWssUrl: string;
    resendInvitation: string;

    // cidaas
    wwCustomerManagementClientId: string;
    cidaasBaseUrl: string;
    cidaasChangePasswordRessource: string;
    cidaasAuthorisationServerRessource: string;
    cidaasGetTokenRessource: string;
    cidaasEndSessionRessource: string;
}
const host = window.location.host;
const isLocal = host.indexOf("localhost") === 0;
const protocol = isLocal ? "http:" : window.location.protocol;
const proxy = isLocal ? "https://localhost.wwportal.ch" : `${protocol}//${host}`;
const customerAuthSubdomain = isLocal ? "dev" : window.location.host.split(".")[0];

// Microservice Base Urls
const customerBaseUrl = `${proxy}/api/customer`;
const rentalBaseUrl = `${proxy}/api/rental`;
const securityBaseUrl = `${proxy}/auth/security`;
const filesBaseUrl = `${proxy}/api/v2/files`;
const designBaseUrl = `${proxy}/api/designs`;
const wwCidaasBaseUrl = `${proxy}/api/cidaas-webhook`;

export const NetworkConfig = {
    graphqlUrl: "https://ww-prod-hasura-cidaas-wa.azurewebsites.net/v1/graphql",
    graphqlRestUrl: `${proxy}/api/rest`,
    customerAuthSubdomain: customerAuthSubdomain,
    authWebsiteUrl: "https://auth.wwportal.ch",
    datafileUrl: `${filesBaseUrl}/open/`,
    uploadAnnouncementFileUrl: `${filesBaseUrl}/uploadannouncementfile`,
    deleteAnnouncementFileUrl: `${filesBaseUrl}/deleteannouncementfile`,
    uploadServiceFileUrl: `${filesBaseUrl}/uploadservicefile`,
    uploadServiceLogoUrl: `${filesBaseUrl}/uploadservicelogo`,
    deleteServiceFileUrl: `${filesBaseUrl}/deleteservicefile`,
    brandAssets: {
        logoImage: `${designBaseUrl}/logo/brand.jpg`,
        css: `${designBaseUrl}/css/brand.css`
    },
    customerBaseUrl: customerBaseUrl,
    createCustomerUrl: `${customerBaseUrl}/create`,
    resetCustomerUrl: `${customerBaseUrl}/reset`,
    generateNewERPPasswordUrl: `${customerBaseUrl}/generateerppassword`,
    actualizeCustomerRepxUrl: `${customerBaseUrl}/actualize`,
    cleanupFilesUrl: `${customerBaseUrl}/cleanupfiles`,
    resetDesignUrl: `${customerBaseUrl}/resetdesign`,
    activateDnsUrl: `${customerBaseUrl}/activatedns`,
    deactivateDnsUrl: `${customerBaseUrl}/deactivatedns`,
    activateAdminUrl: `${customerBaseUrl}/activateAdmin`,
    deactivateAdminUrl: `${customerBaseUrl}/deactivateAdmin`,
    emonitorApiTestUrl: "https://wundw.api.melon.training/api/objects/erp-apartments",
    updatecustomercssinfo: `${customerBaseUrl}/updatecustomercssinfo`,
    sentryUrl: "https://2bfd113035c24b8da7e83044d81a1796@o421140.ingest.sentry.io/5523085",
    actualizeZipcodesUrl: `${customerBaseUrl}/actualizezipcodes`,
    copyBlobItemsFromCustomer: `${customerBaseUrl}/copyblobs`,
    testConnectionUrl: `${customerBaseUrl}/testconnection`,
    startimportuserprocess: `${customerBaseUrl}/startimportuserprocess`,
    proxy,
    encryptPasswordUrl: `${securityBaseUrl}/encrypt`,
    registerEmonitorEventsForCustomerUrl: `${rentalBaseUrl}/emonitor/registeralleventsforcustomer`,
    resendInvitation: `${customerBaseUrl}/resendInvitation`,
    environment: "<placeholder>",
    wwCustomerManagementClientId: "<placeholder>",
    cidaasBaseUrl: "<placeholder>",
    cidaasChangePasswordRessource: "/users-srv/changepassword",
    cidaasEndSessionRessource: "/session/end_session",
    cidaasAuthorisationServerRessource: "/authz-srv/authz",
    cidaasGetTokenRessource: "/token-srv/token",
    updateUserStatusUrl: `${wwCidaasBaseUrl}/update-user-status`,
    graphqlWssUrl: "wss://ww-prod-hasura-wa.azurewebsites.net/v1/graphql"
} as ConfigSettings;
