import { Tile } from "./Tile";
import { GenericList } from "./charts/GenericList";
import { Pie } from "./charts/Pie";
import { Doughnut } from "./charts/Doughnut";
import { Line } from "./charts/Line";
import { VerticalBar, HorizontalBar } from "./charts/Bar";
import { Comparsion } from "./charts/Comparsion";

export const Tiles = {
    Tile,
    Pie,
    Doughnut,
    Line,
    VerticalBar,
    HorizontalBar,
    Comparsion,
    GenericList
};