import React from "react";
import * as Sentry from "@sentry/react";
import { Redirect, Switch, useParams } from "react-router";
import * as Screens from "src/screens";
import { Route, selectRoute } from "src/config/routes";
import { PrivateRoute } from "src/components/routes/PrivateRoutes";

interface Params {
    customerid: string;
}
const CustomerDetailsBase = () => {
    const { customerid } = useParams<Params>();

    return (
        <div>
            <Switch>
                <PrivateRoute path={Route.customerDetailsOverview} exact component={Screens.CustomerDetail} />
                <PrivateRoute path={Route.customerDetailsBills} exact component={Screens.CustomerDetail} />
                <PrivateRoute path={Route.customerInvoices} exact component={Screens.CustomerInvoices} />
                <PrivateRoute path={Route.customerLogs} exact component={Screens.CustomerLogs} />
                <PrivateRoute path={Route.customerFaultyFiles} exact component={Screens.CustomerFaultyFiles} />
                <PrivateRoute
                    path={Route.customerConnectionDetails}
                    exact
                    component={Screens.CustomerConnectionDetails}
                />
                <PrivateRoute path={Route.customerDetails} exact>
                    <Redirect to={selectRoute(Route.customerDetailsOverview, null, { customerid: customerid })} />
                </PrivateRoute>
            </Switch>
        </div>
    );
};

export const CustomerDetails = Sentry.withProfiler(CustomerDetailsBase);
