import React, { useContext, useEffect, useState, useCallback, useMemo } from "react";
import * as Sentry from "@sentry/react";
import { useQuery } from "@apollo/client";
import { GET_UMS_CUSTOMERS } from "src/api/customers";
import { GetUmsCustomers, GetUmsCustomers_customers as umsCustomers } from "src/api/generated/GetUmsCustomers";
import CustomerList from "src/screens/dashboard/customers/customer/list/CustomerList";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import * as Screens from "src/screens";
import { Route } from "src/config/routes";
import styles from "./CustomersOverview.module.css";
import { CustomerOverviewStatistics, CustomerOverviewStatisticsProps } from "./CustomerOverviewStatistics";
import { withRouter, RouteComponentProps } from "react-router";
import { Role as CustomerRole } from "src/network/User";
import { CallServiceForAllCustomers } from "./additional-actions/CallServiceForAllCustomers";
import { CopyCustomer } from "./additional-actions/CopyCustomer";
import { UpdateGeneralSettingDialog } from "./additional-actions/UpdateGeneralSettingsDialog";
import { NetworkConfig } from "src/network/NetworkConfig";
import { getRoleKey } from "src/network/User";
import { MessageType } from "src/components/notifications/Notifier";
import { getEnvironment } from "src/utils/Customer";

enum MODAL {
    NONE = 0,
    ACTUALIZE_REPX = 1,
    CLEANUP_FILES = 2,
    UPDATE_API_MINVERSION = 3,
    UPDATE_API_CURRENTVERSION = 4,
    ACTUALIZE_ZIPCODES = 5,
    COPY_CUSTOMER = 6
}

interface CustomerDialogProps {
    onToggleModal: any;
    buttonLabel: string;
    show: boolean;
    modalTitle: string;
    children?: React.ReactNode;
    modalSize?: "sm" | "lg" | "xl" | undefined;
}

const CustomersOverviewBase = (props: RouteComponentProps) => {
    const { t } = useTranslation();
    const { navStore, uiStore, authStore } = useContext(RootStoreContext);

    const listIdentifier = "customerlist";

    if (!navStore.listMap.has(listIdentifier)) {
        navStore.setListMapValue(listIdentifier, {});
    }

    const listParameters = navStore.getListMapValue(listIdentifier);

    const userRole = authStore.user ? authStore.user.role : undefined;

    const [displayedCustomers, setDisplayedCustomers] = useState<umsCustomers[]>([]);
    const [activeModal, setActiveModal] = useState<MODAL>(MODAL.NONE);

    const { data, loading, error, refetch } = useQuery<GetUmsCustomers>(GET_UMS_CUSTOMERS);

    const onActualizeZipcodes = useCallback(async () => {
        const actualizeZipCodesEndpointUrl = `${NetworkConfig.actualizeZipcodesUrl}`;

        if (authStore.user && authStore.token) {
            const accessToken = authStore.token;
            const tokenType = authStore.tokenType;
            const role = authStore.user?.role;

            try {
                const fetchResult = await fetch(actualizeZipCodesEndpointUrl, {
                    method: "POST",
                    headers: {
                        Authorization: `${tokenType} ${accessToken}`,
                        "x-hasura-role": getRoleKey(role)
                    }
                });

                if (fetchResult.status === 200) {
                    uiStore.printStatusMessage("Die Postleitzahlen wurden erfolgreich aktualisiert.", MessageType.INFO);
                } else {
                    const response = await fetchResult.json();

                    uiStore.printStatusMessage(`Es ist ein Fehler aufgetreten: ${response.message}`, MessageType.ERROR);
                }
            } catch (fetchError) {
                uiStore.printStatusMessage(`Es ist ein Fehler aufgetreten: ${fetchError}`, MessageType.ERROR);
            }
        } else {
            uiStore.printStatusMessage(
                "Keine Authorisierungs-Informationen vorhanden. Bitte lade die Seite neu.",
                MessageType.ERROR
            );
        }
        setActiveModal(MODAL.NONE);
    }, [authStore.token, authStore.tokenType, authStore.user, uiStore]);

    const filterCustomersAndSetSearchQuery = useCallback(
        (searchQuery: string) => {
            if (data) {
                const foundCustomers = data.customers.filter(
                    (customer) =>
                        customer.number?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        customer.name?.toLowerCase().includes(searchQuery.toLowerCase())
                );
                setDisplayedCustomers([...foundCustomers]);
            }
        },
        [data]
    );

    const handleSearchInput = useCallback(
        ({ searchQuery }) => {
            filterCustomersAndSetSearchQuery(searchQuery);

            navStore.setListMapValue(
                listIdentifier,
                listParameters
                    ? { ...listParameters, scrollPosition: 0, searchQuery: searchQuery }
                    : { scrollPosition: 0, searchQuery: searchQuery }
            );
        },
        [filterCustomersAndSetSearchQuery, listParameters, navStore]
    );

    useEffect(() => {
        navStore.setTitle("Kunden");
        navStore.setBackbutton(null);
    }, [navStore]);

    useEffect(() => {
        if (uiStore.hasNewCustomerData) {
            refetch();
            uiStore.setHasNewCustomerData(false);
        }
    }, [refetch, uiStore]);

    const statisticsData: CustomerOverviewStatisticsProps | undefined = useMemo(() => {
        if (data) {
            const {
                inspectioncountR5,
                inspectioncountIT2,
                usercountR5,
                usercountIT2,
                customerscountR5,
                customerscountIT2
            } = data;
            return {
                statistics: {
                    customersCount: {
                        rimo: customerscountR5.aggregate?.count || 0,
                        it2: customerscountIT2.aggregate?.count || 0
                    },
                    usersCount: {
                        rimo: usercountR5.aggregate?.count || 0,
                        it2: usercountIT2.aggregate?.count || 0
                    },
                    inspectionsCount: {
                        rimo: inspectioncountR5.aggregate?.count || 0,
                        it2: inspectioncountIT2.aggregate?.count || 0
                    }
                }
            };
        } else {
            return undefined;
        }
    }, [data]);

    const toggleAcualizeRepxModal = useCallback(() => {
        setActiveModal((currentModal) => (currentModal === MODAL.ACTUALIZE_REPX ? MODAL.NONE : MODAL.ACTUALIZE_REPX));
    }, []);

    const toggleCleanupFilesModal = useCallback(() => {
        setActiveModal((currentModal) => (currentModal === MODAL.CLEANUP_FILES ? MODAL.NONE : MODAL.CLEANUP_FILES));
    }, []);

    const toggleUpdateApiMinversionModal = useCallback(() => {
        setActiveModal((currentModal) =>
            currentModal === MODAL.UPDATE_API_MINVERSION ? MODAL.NONE : MODAL.UPDATE_API_MINVERSION
        );
    }, []);

    const toggleUpdateApiCurrentVersionModal = useCallback(() => {
        setActiveModal((currentModal) =>
            currentModal === MODAL.UPDATE_API_CURRENTVERSION ? MODAL.NONE : MODAL.UPDATE_API_CURRENTVERSION
        );
    }, []);

    const toggleCopyCustomerModal = useCallback(() => {
        setActiveModal((currentModal) => (currentModal === MODAL.COPY_CUSTOMER ? MODAL.NONE : MODAL.COPY_CUSTOMER));
    }, []);

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata")} />;
    }

    const addCustomerButton = (
        <div className={styles.customerAddBtn}>
            <UI.Button
                to={Route.customersNew}
                label={t("screens.dashboard.customers.add_customer")}
                className="customer-button float-right w-100"
            />
        </div>
    );

    const CustomerOverviewDialog = (props: CustomerDialogProps) => {
        return (
            <>
                <UI.Button
                    label={props.buttonLabel}
                    className="customer-button mt-2"
                    variant="danger"
                    onClick={props.onToggleModal}
                />

                <UI.Modal
                    backdrop={"static"}
                    title={props.modalTitle}
                    show={props.show}
                    onClose={props.onToggleModal}
                    size={props.modalSize}
                >
                    {props.children}
                </UI.Modal>
            </>
        );
    };

    const ActualizeRepxDialog = () => {
        return (
            <CustomerOverviewDialog
                buttonLabel="Repx aktualisieren"
                onToggleModal={toggleAcualizeRepxModal}
                modalTitle="Repx-Dateien aktualisieren"
                show={activeModal === MODAL.ACTUALIZE_REPX}
            >
                <CallServiceForAllCustomers
                    onCloseModal={toggleAcualizeRepxModal}
                    customers={data.customers}
                    serviceEndpoint={NetworkConfig.actualizeCustomerRepxUrl}
                    serviceMethod="POST"
                    modalTitle="Willst du die Repx-Datei aller Kunden aktualisieren?"
                    finishedMessage="Die Aktualisierung ist abgeschlossen"
                />
            </CustomerOverviewDialog>
        );
    };

    const CleanupFilesDialog = () => {
        return (
            <CustomerOverviewDialog
                buttonLabel="Dateibereinigung"
                onToggleModal={toggleCleanupFilesModal}
                modalTitle="Dateien bereinigen"
                show={activeModal === MODAL.CLEANUP_FILES}
            >
                <CallServiceForAllCustomers
                    onCloseModal={toggleCleanupFilesModal}
                    customers={data.customers}
                    serviceEndpoint={NetworkConfig.cleanupFilesUrl}
                    serviceMethod="POST"
                    modalTitle="Willst du die Dateien aller Kunden bereinigen?"
                    finishedMessage="Die Dateibereinigung ist abgeschlossen"
                />
            </CustomerOverviewDialog>
        );
    };

    const UpdateInspMinversionDialog = () => {
        return (
            <CustomerOverviewDialog
                buttonLabel="App Minversion erhöhen"
                onToggleModal={toggleUpdateApiMinversionModal}
                modalTitle="Neue Inspection Minversion"
                show={activeModal === MODAL.UPDATE_API_MINVERSION}
            >
                <UpdateGeneralSettingDialog
                    settingsKey="INSP.MINVERSION"
                    emptyInputErrorMessage="Bitte eine neue Inspection Minversion eingeben"
                    inputLabel="Format: xxx.yyy.zzzz (Beispiel: 3.1.162)"
                    operationSuccessMessage="Die Inspection Minversion wurde erfolgreich aktualisiert"
                    closeModal={toggleUpdateApiMinversionModal}
                />
            </CustomerOverviewDialog>
        );
    };

    const UpdateInspCurrentVersionDialog = () => {
        return (
            <CustomerOverviewDialog
                buttonLabel="App CurrentVersion erhöhen"
                onToggleModal={toggleUpdateApiCurrentVersionModal}
                modalTitle="Neue Inspection CurrentVersion"
                show={activeModal === MODAL.UPDATE_API_CURRENTVERSION}
            >
                <UpdateGeneralSettingDialog
                    settingsKey="INSP.CURRENTVERSION"
                    emptyInputErrorMessage="Bitte eine neue Inspection Current Version eingeben"
                    inputLabel="Format: xxx.yyy.zzzz (Beispiel: 3.1.162 )"
                    operationSuccessMessage="Die Inspection CurrentVersion wurde erfolgreich aktualisiert"
                    closeModal={toggleUpdateApiCurrentVersionModal}
                />
            </CustomerOverviewDialog>
        );
    };

    const ActualizeZipCodesDialog = () => (
        <UI.ConfirmationDialog
            key="actualizeZipCodesDialog"
            buttonText="Postleitzahlen aktualisieren"
            modalTitle="Aktualisieren der Postleitzahlen"
            confirmationQuestion="Willst du die Postleitzahlen wirklich aktualisieren?"
            inProgressText="Postleitzahlen werden aktualisiert..."
            onConfirmation={onActualizeZipcodes}
            buttonClasses={"mr-2 d-inline mb-2 mb-sm-0 customer-button w-100"}
            isDangerousOperation
        />
    );

    const CopyCustomerDialog = () => {
        return (
            <CustomerOverviewDialog
                buttonLabel="Kunde kopieren"
                onToggleModal={toggleCopyCustomerModal}
                modalTitle="Kunde von der produktiven Umgebung kopieren"
                show={activeModal === MODAL.COPY_CUSTOMER}
                modalSize="lg"
            >
                <CopyCustomer
                    onCloseModal={toggleCopyCustomerModal}
                    serviceEndpoint={NetworkConfig.graphqlRestUrl}
                    serviceCopyBlobFromCustomerEndpoint={NetworkConfig.copyBlobItemsFromCustomer}
                    emptyInputErrorMessage="Es muss eine Kunden-Id eingegeben werden!"
                    finishedMessage="Der Kopierprozess ist abgeschlossen"
                />
            </CustomerOverviewDialog>
        );
    };

    return (
        <React.Fragment>
            <UI.Container className="pt-4">
                <UI.Row>
                    <UI.Col md={9}>
                        <UI.Card>
                            <div className={styles.customerList}>
                                <CustomerList
                                    umsCustomers={displayedCustomers}
                                    onSearch={handleSearchInput}
                                    searchAppendElement={
                                        userRole !== CustomerRole.CUSTOMERSUPPORT ? addCustomerButton : undefined
                                    }
                                />
                            </div>
                        </UI.Card>
                    </UI.Col>
                    <UI.Col md={3}>
                        {statisticsData === undefined ? (
                            <UI.LoadingCard />
                        ) : (
                            <CustomerOverviewStatistics statistics={statisticsData.statistics} />
                        )}

                        {userRole ? (
                            userRole === CustomerRole.CUSTOMERDEV ? (
                                <UI.Card
                                    title={"Zusätzliche Aktionen"}
                                    className={`CardInner ${styles.additionalActions}`}
                                >
                                    <ActualizeRepxDialog />
                                    <CleanupFilesDialog />
                                    <UpdateInspMinversionDialog />
                                    <UpdateInspCurrentVersionDialog />
                                    <ActualizeZipCodesDialog />
                                    {getEnvironment() !== "prod" ? <CopyCustomerDialog /> : undefined}
                                </UI.Card>
                            ) : null
                        ) : null}
                    </UI.Col>
                </UI.Row>
            </UI.Container>
        </React.Fragment>
    );
};

export const CustomersOverview = withRouter(Sentry.withProfiler(observer(CustomersOverviewBase)));
