import { Partner } from "src/utils/Enums";

export const getEnvironment = (): string => {
    let envStr = "";

    const hostName = window.location.host;
    const hostNameParts = hostName.split(".");
    const hostNameEnvPart = hostNameParts[1];
    const isLocal = hostName.indexOf("localhost") === 0;

    if (hostNameEnvPart === "dev" || hostNameEnvPart === "test") {
        envStr = hostNameEnvPart;
    } else if (isLocal) {
        envStr = "dev";
    } else {
        envStr = "prod";
    }

    return envStr;
};

export const getCustomerHostname = (subdomain: string, partner: Partner, isDnsConfigured: boolean): string => {
    let hostname = "";

    if (isDnsConfigured) {
        hostname = `https://${subdomain}.wwportal.ch`;
    } else {
        const environment = getEnvironment();

        if (partner === Partner.WW) {
            hostname = `https://${environment}.wwportal.ch`;
        } else if (partner === Partner.EXTENSO) {
            if (environment === "prod") {
                hostname = "https://extenso.wwportal.ch";
            } else {
                hostname = `https://extenso-${environment}.wwportal.ch`;
            }
        } else if (partner === Partner.FAIRWALTER) {
            if (environment === "prod") {
                hostname = "https://fairwalter.wwportal.ch";
            } else {
                hostname = `https://fairwalter-${environment}.wwportal.ch`;
            }
        }
    }

    return hostname;
};

export const MAX_CUSTOMER_NUMBER = 999999;
export const MAX_CUSTOMER_NUMBER_LENGTH = 6;

export const formatCustomerNumber = (customerNumber: string) => {
    let number = new Array(MAX_CUSTOMER_NUMBER_LENGTH - customerNumber.length + 1).join("0") + customerNumber;
    return number;
};
