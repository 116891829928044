import React, { useCallback, useState } from "react";
import { UI } from "@wwimmo/ui";
import { Tiles } from "src/components/tiles";
import {
    PortalDashboard_portal_tilescreens_tile as tile,
    PortalDashboard_portal_tilescreens_tile_tiledatasets as tiledatasets
} from "src/api/generated/PortalDashboard";
import { Chart } from "chart.js";
import "./Tile.css";
import { contrastColor, getCssRootValue } from "src/utils/Colors";
import { round } from "lodash";
import { formatCurrency } from "src/utils/Currency";
import { isNumeric } from "src/utils/Common";
import { PieHint } from "./charts/Pie";
import i18n from "src/utils/i18n";
import { DoughnutHint } from "./charts/Doughnut";

export interface TileProps {
    title?: string;
    subtitle?: string;
    data: tile;
}

export enum ChartDataType {
    VALUE = 0,
    PERCENTAGE = 1,
    AMOUNT = 2,
    DAYS = 3
}

enum ChartType {
    COMPARSION = 1,
    DONUT = 2,
    PIE = 3,
    LINE = 4,
    H_BAR = 5,
    V_BAR = 6,
    GENERICLIST = 7
}
const getHint = (array: any) => {
    let unformattedHintStr = array?.length > 0 ? array[0].hint : "";

    if (isNumeric(unformattedHintStr)) {
        return formatCurrency(parseFloat(unformattedHintStr));
    } else {
        return unformattedHintStr;
    }
};
const getLabel = (array: any) => {
    return array && array.length > 0 ? array[0].label : "";
};
const getTitle = (array: any) => {
    return array && array.length > 0 ? array[0].title : "";
};
const getSubtitle = (array: any) => {
    return array && array.length > 0 ? array[0].subtitle : "";
};
const getValueType = (array: any): ChartDataType => {
    return array && array.length > 0 ? (array[0].valuetype as ChartDataType) : ChartDataType.AMOUNT;
};
export const formatValue = (value: number, type: ChartDataType): string => {
    switch (type) {
        case ChartDataType.PERCENTAGE:
            return String(value) + "%";
        case ChartDataType.AMOUNT:
            return formatCurrency(value) + " " + i18n.t("others.units.chf");
        case ChartDataType.DAYS:
            return String(value) + "  " + i18n.t("others.units.days");
        default:
            return String(value);
    }
};
export const getLabelOptions = (
    type: ChartDataType
): { render: "percentage" | "value" | "label" | ((args: any) => string) } => {
    switch (type) {
        case ChartDataType.PERCENTAGE:
            return { render: "percentage" };
        case ChartDataType.DAYS:
            return {
                render: (args: any) => {
                    const value = args.value as number | 0;
                    return formatValue(value, ChartDataType.DAYS);
                }
            };
        case ChartDataType.AMOUNT:
            return {
                render: (args: any) => {
                    const value = args.value as number | 0;
                    return formatValue(value, ChartDataType.AMOUNT);
                }
            };
        default:
            return { render: "label" };
    }
};

export const Tile = (props: TileProps) => {
    const [height, setHeight] = useState<number>(0);
    const [subtitle, setSubtitle] = useState<string>("");

    const newSubtitle = useCallback((newSubtitle: string) => {
        setSubtitle(newSubtitle);
    }, []);

    const ref = useCallback((node) => {
        if (node !== null) {
            setHeight(node.getBoundingClientRect().height);
        }
    }, []);
    const colors = [
        getCssRootValue("primary"),
        getCssRootValue("primary100"),
        getCssRootValue("primary400"),
        getCssRootValue("primary900")
    ];

    Chart.defaults.global.plugins = { labels: false };

    const comparsion = (data: tiledatasets) => {
        return {
            labels: [],
            datasets: data.tiledatavalues.map((d) => {
                return {
                    label: getLabel(d.tiledatavalues_mls),
                    data: roundNumberOrAny(d.value),
                    valuetype: d.valuetype || ChartDataType.AMOUNT
                };
            })
        };
    };

    const doughnut = (data: tiledatasets) => {
        let tiledatavalues = [...data.tiledatavalues];
        let centerText = "";
        if (tiledatavalues.length === 1) {
            const rest = { ...data.tiledatavalues[0], value: 100 - data.tiledatavalues[0].value };
            tiledatavalues = [...data.tiledatavalues, rest];
            centerText = formatValue(roundNumberOrAny(tiledatavalues[0].value), ChartDataType.PERCENTAGE);
        } else {
            const sum = tiledatavalues.map((a) => a.value).reduce((a, b) => a + b);
            centerText = formatValue(sum, ChartDataType.VALUE);
        }

        return {
            labels: tiledatavalues.map((value) => getHint(value.tiledatavalues_mls)),
            datasets: [
                {
                    label: props.data.id,
                    data: tiledatavalues.map((value) => value.value),
                    backgroundColor: colors,
                    hint: tiledatavalues.map((value) => {
                        const hintTitle = getLabel(value.tiledatavalues_mls);
                        const hint = getHint(value.tiledatavalues_mls);
                        const dougnhnutHint: DoughnutHint = { title: hintTitle, label: hint };
                        return dougnhnutHint;
                    })
                }
            ],

            centerText
        };
    };

    const pie = (data: tiledatasets) => {
        return {
            labels: data.tiledatavalues.map((value) => {
                const label = getLabel(value.tiledatavalues_mls);
                return label;
            }),
            datasets: [
                {
                    label: props.data.id,
                    data: data.tiledatavalues.map((value) => roundNumberOrAny(value.value)),
                    backgroundColor: colors,
                    textColor: colors.map((color) => contrastColor(color)),
                    hint: data.tiledatavalues.map((value) => {
                        const hintTitle = getLabel(value.tiledatavalues_mls);
                        const hint = getHint(value.tiledatavalues_mls);

                        const pieHint: PieHint = { title: hintTitle, label: hint };

                        return pieHint;
                    })
                }
            ],
            valuetype: getValueType(data.tiledatavalues)
        };
    };

    const line = (data: tiledatasets) => {
        return {
            labels: data.tiledatavalues.map((value) => {
                return value.tiledatavalues_mls.length !== 0 ? value.tiledatavalues_mls[0].label || "-" : "-";
            }),
            datasets: [
                {
                    label: props.data.id,
                    data: data.tiledatavalues.map((value) => roundNumberOrAny(value.value))
                }
            ],
            valuetype: getValueType(data.tiledatavalues)
        };
    };

    const bar = (ds: tiledatasets) => {
        return {
            labels: ds.tiledatavalues.map((value) => getLabel(value.tiledatavalues_mls)),
            datasets: [
                {
                    label: ds.id,
                    data: ds.tiledatavalues.map((value) => roundNumberOrAny(value.value))
                }
            ],
            valuetype: getValueType(ds.tiledatavalues)
        };
    };

    const genericList = (data: tiledatasets) => {
        return {
            labels: data.tiledatavalues.map((value) => getLabel(value.tiledatavalues_mls)),
            datasets: props.data.tiledatasets[0].tiledatavalues.map((valueItem, index) => {
                return {
                    label: (valueItem.tiledatavalues_mls[0].label || "-") + index,
                    hint: valueItem.tiledatavalues_mls[0].hint
                };
            })
        };
    };

    const chart = (type: number, data: tiledatasets) => {
        switch (type) {
            case ChartType.COMPARSION:
                return <Tiles.Comparsion data={comparsion(data)} />;
            case ChartType.DONUT:
                return <Tiles.Doughnut data={doughnut(data)} />;
            case ChartType.PIE:
                return <Tiles.Pie data={pie(data)} />;
            case ChartType.LINE:
                return <Tiles.Line data={line(data)} colors={colors} />;
            case ChartType.H_BAR:
                return <Tiles.HorizontalBar data={bar(data)} colors={colors} />;
            case ChartType.V_BAR:
                return <Tiles.VerticalBar data={bar(data)} colors={colors} />;
            case ChartType.GENERICLIST:
                return <Tiles.GenericList height={height} data={genericList(data)} />;
            default:
                return <span>default</span>;
        }
    };

    const tabs = (type: number, ds: tiledatasets[]) => {
        if (ds.length <= 1) {
            return chart(type, ds[0]);
        }
        const contents = ds.map((ds) => {
            const c = chart(type, ds);
            return {
                label: getLabel(ds.tiledatasets_mls),
                subtitle: getSubtitle(ds.tiledatasets_mls),
                content: c
            };
        });
        return <UI.Tabs contents={contents} updateSubtitle={newSubtitle} />;
    };
    const roundNumberOrAny = (input: any | null, decimal: number = 2): number => {
        return !isNaN(input) ? round(input, decimal) : input;
    };

    let st = subtitle;
    if (st === "" && props.data.tiledatasets.length === 1) {
        st = getSubtitle(props.data.tiledatasets[0].tiledatasets_mls);
    }

    return (
        <div ref={ref}>
            <UI.Card title={props.title || getTitle(props.data.tiles_mls)} square className="CardInner" subtitle={st}>
                {tabs(props.data.charttype || 0, props.data.tiledatasets)}
            </UI.Card>
        </div>
    );
};
