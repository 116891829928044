import React, { useContext, useEffect } from "react";
import { Row, Container, Col } from "react-bootstrap";
import * as Sentry from "@sentry/react";
import { UI } from "@wwimmo/ui";
import { RootStoreContext } from "src/stores/RootStore";
import { runInAction } from "mobx";
import { ColorStyle } from "src/utils/Colors";

interface LoadingScreenProps {
    fullscreen?: boolean;
    noLogo?: boolean;
    noBackgroundChange?: boolean;
}

const LoadingBase = (props: LoadingScreenProps) => {
    const { uiStore } = useContext(RootStoreContext);
    useEffect(() => {
        if (uiStore && !props.noBackgroundChange) {
            runInAction(() => {
                uiStore.setBackgroundColor(ColorStyle("white"));
            });
        }
        return () => {
            if (uiStore && !props.noBackgroundChange) {
                runInAction(() => {
                    uiStore.setBackgroundColor(ColorStyle("background"));
                });
            }
        };
    }, [uiStore, props.noBackgroundChange]);
    return (
        <Container
            className={`d-flex flex-fill justify-content-center align-items-center ${props.fullscreen && "fullscreen"}`}
        >
            <Row>
                <Col className="text-center">
                    <UI.RotatingSpinner noLogo={props.noLogo} />
                </Col>
            </Row>
        </Container>
    );
};

export const Loading = Sentry.withProfiler(LoadingBase);
