import React, { useCallback, useContext } from "react";
import * as Sentry from "@sentry/react";
import { useForm } from "react-hook-form";
import { UI } from "@wwimmo/ui";
import { useMutation } from "@apollo/client";
import { UPDATE_GENERALSETTINGS } from "src/api/customers";
import { RootStoreContext } from "src/stores/RootStore";
import { MessageType } from "src/components/notifications/Notifier";
import { observer } from "mobx-react-lite";
import styles from "src/screens/dashboard/customers/overview/CustomersOverview.module.css";
import { UpdateGeneralsettings, UpdateGeneralsettingsVariables } from "src/api/generated/UpdateGeneralsettings";

interface componentProps {
    closeModal: any;
    operationSuccessMessage: string;
    settingsKey: string;
    inputLabel: string;
    emptyInputErrorMessage: string;
}

interface UpdateGeneralSettingsFields {
    newValue: string;
}
const UpdateGeneralSettingDialogBase = (props: componentProps) => {
    const { register, handleSubmit, errors } = useForm<UpdateGeneralSettingsFields>();
    const { uiStore } = useContext(RootStoreContext);
    const { closeModal, operationSuccessMessage, settingsKey, inputLabel, emptyInputErrorMessage } = props;

    const [updateInspVersionMutation, { loading: isMutationLoading }] = useMutation<
        UpdateGeneralsettings,
        UpdateGeneralsettingsVariables
    >(UPDATE_GENERALSETTINGS, {
        onCompleted: () => {
            uiStore.printStatusMessage(operationSuccessMessage, MessageType.INFO);
            uiStore.setHasNewCustomerData(true);
        }
    });

    const handleFormSubmit = useCallback(
        async (formData: UpdateGeneralSettingsFields) => {
            const { newValue } = formData;

            const versionParts = newValue.split(".");
            const majorVersion = versionParts[0];
            const minorVersion = versionParts[1].padStart(3, "0");
            const patchVersion = versionParts[2].padStart(4, "0");

            const newVersionNumber = `${majorVersion}${minorVersion}${patchVersion}`;

            await updateInspVersionMutation({
                variables: {
                    inspversion: settingsKey,
                    input: {
                        value: newVersionNumber
                    }
                }
            });

            closeModal();
        },
        [closeModal, updateInspVersionMutation, settingsKey]
    );

    return (
        <UI.Form>
            <UI.Input
                label={inputLabel}
                ref={register({
                    required: emptyInputErrorMessage,
                    pattern: {
                        value: /^([0-9]{1,3}\.)?([0-9]{1,3}\.)?([0-9]{1,4})$/,
                        message: "Das eingegebene Format ist falsch"
                    }
                })}
                name="newValue"
                type="text"
                errorMsg={errors.newValue ? errors.newValue.message : undefined}
            />
            <UI.Button
                type="submit"
                label={isMutationLoading ? "Wird aktualisiert..." : "Speichern"}
                onClick={handleSubmit(handleFormSubmit)}
                className={`${styles.apiDialogButton} w-100 customer-button`}
            />
        </UI.Form>
    );
};

export const UpdateGeneralSettingDialog = Sentry.withProfiler(observer(UpdateGeneralSettingDialogBase));
