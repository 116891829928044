import { gql } from "@apollo/client";

export const GET_UMS_CUSTOMERS = gql`
    query GetUmsCustomers {
        customers: ums_customers(where: { type: { _neq: 99 } }, order_by: { name: asc }) {
            id
            name
            number
            users_aggregate(where: { system: { _eq: false } }) {
                aggregate {
                    count
                }
            }
            type
            erp
            erpcloud
        }

        customerscountR5: ums_customers_aggregate(where: { type: { _eq: 1 }, erp: { _eq: 1 } }) {
            aggregate {
                count
            }
        }

        customerscountIT2: ums_customers_aggregate(where: { type: { _eq: 1 }, erp: { _eq: 2 } }) {
            aggregate {
                count
            }
        }

        usercountR5: ums_users_aggregate(
            where: { customer: { erp: { _eq: 1 }, type: { _eq: 1 } }, system: { _eq: false } }
        ) {
            aggregate {
                count
            }
        }

        usercountIT2: ums_users_aggregate(
            where: { customer: { erp: { _eq: 2 }, type: { _eq: 1 } }, system: { _eq: false } }
        ) {
            aggregate {
                count
            }
        }

        inspectioncountR5: insp_inspections_aggregate(where: { customer: { erp: { _eq: 1 }, type: { _eq: 1 } } }) {
            aggregate {
                count
            }
        }

        inspectioncountIT2: insp_inspections_aggregate(where: { customer: { erp: { _eq: 2 }, type: { _eq: 1 } } }) {
            aggregate {
                count
            }
        }
    }
`;

export const UPDATE_GENERALSETTINGS = gql`
    mutation UpdateGeneralsettings($inspversion: String, $input: ums_generalsettings_set_input) {
        updategeneralsettings: update_ums_generalsettings(where: { key: { _eq: $inspversion } }, _set: $input) {
            affected_rows
        }
    }
`;

export const GET_GENERALSETTINGS = gql`
    query GetGeneralSettingsVersion {
        ums_generalsettings(where: { key: { _in: ["INSP.MINVERSION", "INSP.CURRENTVERSION"] } }) {
            id
            key
            value
        }
    }
`;
