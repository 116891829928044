import React from "react";
import { Switch } from "react-router";
import { Redirect } from "react-router-dom";
import { observer } from "mobx-react";
import { PrivateRoute } from "src/components/routes/PrivateRoutes";
import { Route } from "src/config/routes";
import * as Screens from "src/screens";

const CustomerRoutesBase = () => {
    const entryRoute = Route.dashboard;
    return (
        <>
            <Switch>
                <PrivateRoute path={Route.login} exact>
                    <Redirect to={entryRoute} />
                </PrivateRoute>
                <PrivateRoute path={Route.customersNew} exact component={Screens.CustomerAdd} />
                <PrivateRoute path={Route.dashboard} component={Screens.Dashboard} />
            </Switch>
        </>
    );
};
export const CustomerRoutes = observer(CustomerRoutesBase);
