import React, { useContext, useState, useCallback, useMemo } from "react";
import * as Sentry from "@sentry/react";
import { UI } from "@wwimmo/ui";
import { useForm } from "react-hook-form";
import { RootStoreContext } from "src/stores/RootStore";
import styles from "src/screens/dashboard/customers/overview/CustomersOverview.module.css";

enum STATE {
    INIT = 0,
    RUNNING = 1,
    DONE = 2
}

interface errorListType {
    text: any;
}

interface CopyFunctionProps {
    name: any;
    endpoint: any;
    tables: any;
}

interface componentProps {
    onCloseModal: any;
    serviceEndpoint: string;
    serviceCopyBlobFromCustomerEndpoint: string;
    finishedMessage: string;
    emptyInputErrorMessage: string;
}

interface CopyCustomerFields {
    customerId: string;
}

const CopyCustomerBase = (props: componentProps) => {
    const { register, handleSubmit, errors } = useForm<CopyCustomerFields>();
    const { authStore } = useContext(RootStoreContext);
    const { onCloseModal, serviceEndpoint, serviceCopyBlobFromCustomerEndpoint, emptyInputErrorMessage } = props;
    const [statusMessage, setStatusMessage] = useState<JSX.Element | undefined>();
    const [errorList, setErrorList] = useState<Array<errorListType>>([]);
    const [currentState, setCurrentState] = useState<STATE>(STATE.INIT);

    const copyFunctionList: Array<CopyFunctionProps> = useMemo(() => {
        return [
            {
                name: "Kopieren wird vorbereitet",
                endpoint: "ums/preparecustomer",
                tables: "Prozess wird vorbereitet"
            },
            {
                name: "Tabellen von Schema 'ums' werden kopiert (1)",
                endpoint: "ums/copyums0",
                tables: "customers"
            },
            {
                name: "Tabellen von Schema 'ums' werden kopiert (2)",
                endpoint: "ums/copyums1",
                tables: "users, userroles, customerfeatures, customersettings and usersettings"
            },
            {
                name: "Tabellen von Schema 'ums' werden kopiert (3)",
                endpoint: "ums/copyums2",
                tables: "applicationusers"
            },
            {
                name: "Tabellen von Schema 'common' werden kopiert (1)",
                endpoint: "ums/copycommon1",
                tables: "persons"
            },
            {
                name: "Tabellen von Schema 'common' werden kopiert (2)",
                endpoint: "ums/copycommon2",
                tables: "addresses"
            },
            {
                name: "Tabellen von Schema 'common' werden kopiert (3)",
                endpoint: "ums/copycommon3",
                tables: "owners"
            },
            {
                name: "Tabellen von Schema 'common' werden kopiert (4)",
                endpoint: "ums/copycommon4",
                tables: "managements"
            },
            {
                name: "Tabellen von Schema 'common' werden kopiert (5)",
                endpoint: "ums/copycommon5",
                tables: "realestates"
            },
            {
                name: "Tabellen von Schema 'common' werden kopiert (6)",
                endpoint: "ums/copycommon6",
                tables: "realestateaccess"
            },
            {
                name: "Tabellen von Schema 'common' werden kopiert (7)",
                endpoint: "ums/copycommon7",
                tables: "realestatepersons"
            },
            {
                name: "Tabellen von Schema 'common' werden kopiert (8)",
                endpoint: "ums/copycommon8",
                tables: "houses"
            },
            {
                name: "Tabellen von Schema 'common' werden kopiert (9)",
                endpoint: "ums/copycommon9",
                tables: "units"
            },
            {
                name: "Tabellen von Schema 'common' werden kopiert (10)",
                endpoint: "ums/copycommon10",
                tables: "appliances"
            },
            {
                name: "Tabellen von Schema 'common' werden kopiert (11)",
                endpoint: "ums/copycommon11",
                tables: "tenants"
            },
            {
                name: "Tabellen von Schema 'common' werden kopiert (12)",
                endpoint: "ums/copycommon12",
                tables: "tenancys"
            },
            {
                name: "Tabellen von Schema 'common' werden kopiert (13)",
                endpoint: "ums/copycommon13",
                tables: "tenancys_history"
            },
            {
                name: "Tabellen von Schema 'common' werden kopiert (14)",
                endpoint: "ums/copycommon14",
                tables: "tenancypersons"
            },
            {
                name: "Tabellen von Schema 'common' werden kopiert (15)",
                endpoint: "ums/copycommon15",
                tables: "conditions"
            },
            {
                name: "Tabellen von Schema 'insp' werden kopiert (1)",
                endpoint: "ums/copyinsp1",
                tables: "items, item_mls, groups, group_mls, groupitems, groupitem_mls, templates, templategroups, templategroup_mls and reportlayouts"
            },
            {
                name: "Tabellen von Schema 'insp' werden kopiert (2)",
                endpoint: "ums/copyinsp2",
                tables: "inspections"
            },
            {
                name: "Tabellen von Schema 'insp' werden kopiert (3)",
                endpoint: "ums/copyinsp3",
                tables: "inspectiongroups"
            },
            {
                name: "Tabellen von Schema 'insp' werden kopiert (4)",
                endpoint: "ums/copyinsp4",
                tables: "inspectionitems"
            },
            {
                name: "Tabellen von Schema 'insp' werden kopiert (5)",
                endpoint: "ums/copyinsp5",
                tables: "inspectionitemnotes"
            },
            {
                name: "Tabellen von Schema 'insp' werden kopiert (6)",
                endpoint: "ums/copyinsp6",
                tables: "inspectionpersons"
            },
            {
                name: "Tabellen von Schema 'insp' werden kopiert (7)",
                endpoint: "ums/copyinsp7",
                tables: "tenancychanges"
            },
            {
                name: "Tabellen von Schema 'portal' werden kopiert (1)",
                endpoint: "ums/copyportal1",
                tables: "accountlists"
            },
            {
                name: "Tabellen von Schema 'portal' werden kopiert (2)",
                endpoint: "ums/copyportal2",
                tables: "accounts"
            },
            {
                name: "Tabellen von Schema 'portal' werden kopiert (3)",
                endpoint: "ums/copyportal3",
                tables: "bookings"
            },
            {
                name: "Tabellen von Schema 'portal' werden kopiert (4)",
                endpoint: "ums/copyportal4",
                tables: "tiles"
            },
            {
                name: "Tabellen von Schema 'portal' werden kopiert (5)",
                endpoint: "ums/copyportal5",
                tables: "tiles_mls"
            },
            {
                name: "Tabellen von Schema 'portal' werden kopiert (6)",
                endpoint: "ums/copyportal6",
                tables: "tilescreens"
            },
            {
                name: "Tabellen von Schema 'portal' werden kopiert (7)",
                endpoint: "ums/copyportal7",
                tables: "tiledatasets"
            },
            {
                name: "Tabellen von Schema 'portal' werden kopiert (8)",
                endpoint: "ums/copyportal8",
                tables: "tiledatasets_mls"
            },
            {
                name: "Tabellen von Schema 'portal' werden kopiert (9)",
                endpoint: "ums/copyportal9",
                tables: "tiledatavalues"
            },
            {
                name: "Tabellen von Schema 'portal' werden kopiert (10)",
                endpoint: "ums/copyportal10",
                tables: "tiledatavalues_mls"
            },
            {
                name: "Tabellen von Schema 'portal' werden kopiert (11)",
                endpoint: "ums/copyportal11",
                tables: "tenantbookings"
            },
            {
                name: "Tabellen von Schema 'portal' werden kopiert (12)",
                endpoint: "ums/copyportal12",
                tables: "tenantaccess"
            },
            {
                name: "Tabellen von Schema 'cond' werden kopiert (1)",
                endpoint: "ums/copycond1",
                tables: "assemblies"
            },
            {
                name: "Tabellen von Schema 'cond' werden kopiert (2)",
                endpoint: "ums/copycond2",
                tables: "agendaitems"
            },
            {
                name: "Tabellen von Schema 'cond' werden kopiert (3)",
                endpoint: "ums/copycond3",
                tables: "agendaitems_mls"
            },
            {
                name: "Tabellen von Schema 'mail' werden kopiert (1)",
                endpoint: "ums/copymail1",
                tables: "emails"
            },
            {
                name: "Tabellen von Schema 'mail' werden kopiert (2)",
                endpoint: "ums/copymail2",
                tables: "inbounds"
            },
            {
                name: "Tabellen von Schema 'mail' werden kopiert (3)",
                endpoint: "ums/copymail3",
                tables: "emailrecipients"
            },
            {
                name: "Tabellen von Schema 'mail' werden kopiert (4)",
                endpoint: "ums/copymail4",
                tables: "emaillinks"
            },
            {
                name: "Tabellen von Schema 'mail' werden kopiert (5)",
                endpoint: "ums/copymail5",
                tables: "emaillogs"
            },
            {
                name: "Tabellen von Schema 'files' werden kopiert (1)",
                endpoint: "ums/copyfiles1",
                tables: "files"
            },
            {
                name: "Tabellen von Schema 'files' werden kopiert (2)",
                endpoint: "ums/copyfiles2",
                tables: "bookingfiles"
            },
            {
                name: "Tabellen von Schema 'files' werden kopiert (3)",
                endpoint: "ums/copyfiles3",
                tables: "customerfiles"
            },
            {
                name: "Tabellen von Schema 'files' werden kopiert (4)",
                endpoint: "ums/copyfiles4",
                tables: "emailfiles"
            },
            {
                name: "Tabellen von Schema 'files' werden kopiert (5)",
                endpoint: "ums/copyfiles5",
                tables: "inspectionfiles"
            },
            {
                name: "Tabellen von Schema 'files' werden kopiert (6)",
                endpoint: "ums/copyfiles6",
                tables: "inspectionitemfiles"
            },
            {
                name: "Tabellen von Schema 'files' werden kopiert (7)",
                endpoint: "ums/copyfiles7",
                tables: "inspectionpersonfiles"
            },
            {
                name: "Tabellen von Schema 'files' werden kopiert (8)",
                endpoint: "ums/copyfiles8",
                tables: "realestatefiles"
            },
            {
                name: "Tabellen von Schema 'files' werden kopiert (9)",
                endpoint: "ums/copyfiles9",
                tables: "accountlistfiles"
            },
            {
                name: "Tabellen von Schema 'files' werden kopiert (10)",
                endpoint: "ums/copyfiles10",
                tables: "tenantfiles"
            },
            {
                name: "Tabellen von Schema 'files' werden kopiert (11)",
                endpoint: "ums/copyfiles11",
                tables: "unitfiles"
            },
            {
                name: "Tabellen von Schema 'files' werden kopiert (12)",
                endpoint: "ums/copyfiles12",
                tables: "assemblyfiles"
            },
            {
                name: "Tabellen von Schema 'files' werden kopiert (13)",
                endpoint: "ums/copyfiles13",
                tables: "agendaitemfiles"
            }
        ];
    }, []);

    const handleFormSubmit = useCallback(
        async (formData: CopyCustomerFields) => {
            const { customerId } = formData;
            setCurrentState(STATE.RUNNING);

            let customerCreated = true;

            if (authStore.user && authStore.token) {
                const accessToken = authStore.token;
                const tokenType = authStore.tokenType;
                let count = 1;

                for (const copyFunction of copyFunctionList) {
                    setStatusMessage(
                        <>
                            <span className="d-block">{`Schritt: ${count} / ${copyFunctionList.length + 1}`}</span>
                            <span className="d-block">{`${copyFunction.name} `}</span>
                            <span className="d-block">{`${copyFunction.tables}`}</span>
                        </>
                    );

                    try {
                        let urlEndpoint =
                            copyFunction.endpoint === "ums/copyums0" || copyFunction.endpoint === "ums/preparecustomer"
                                ? `${serviceEndpoint}/${copyFunction.endpoint}?customerid=${customerId}`
                                : `${serviceEndpoint}/${copyFunction.endpoint}?fromcustomerid=${customerId}&tocustomerid=${customerId}`;

                        const fetchResult = await fetch(`${urlEndpoint}`, {
                            method: "GET",
                            headers: {
                                Authorization: `${tokenType} ${accessToken}`
                            }
                        });

                        if (fetchResult.status !== 200) {
                            const response = await fetchResult.json();
                            setErrorList((oldErrorList) => {
                                const newErrorList = [...oldErrorList];
                                newErrorList.push({
                                    text: (
                                        <>
                                            <span className="d-block">{`Fehler: ${response.code}: ${response.error}`}</span>
                                            <span className="d-block">{`Endpoint: ${urlEndpoint} `}</span>
                                        </>
                                    )
                                });
                                return newErrorList;
                            });
                        } else {
                            const response = await fetchResult.json();
                            // asking if customer was created and if this exists into database
                            if (copyFunction.endpoint === "ums/copyums0") {
                                // if customer was not created, list is empty and process will be break
                                // customer with id was not found in prod environment
                                const ums_copy_schema_ums_0 = response.ums_copy_schema_ums_0;
                                const length = ums_copy_schema_ums_0.length;
                                if (length === 0) {
                                    setErrorList((oldErrorList) => {
                                        const newErrorList = [...oldErrorList];
                                        newErrorList.push({
                                            text: (
                                                <>
                                                    <span className="d-block">
                                                        {`Fehler: der Kunden mit Id "${customerId}" existiert nicht in der produktiven Umgebung`}
                                                    </span>
                                                    <span className="d-block">
                                                        {
                                                            "Bitte gib eine korrekte Kunden-Id der produktiven Umgebung ein"
                                                        }
                                                    </span>
                                                </>
                                            )
                                        });
                                        return newErrorList;
                                    });
                                    customerCreated = false;
                                    break;
                                }
                            }
                        }
                        count++;
                    } catch (fetchError) {
                        setErrorList((oldErrorList) => {
                            const newErrorList = [...oldErrorList];
                            newErrorList.push({ text: `Fehler beim Funktionsaufruf: ${fetchError}.` });
                            return newErrorList;
                        });
                    }
                }

                if (customerCreated) {
                    setStatusMessage(
                        <>
                            <span className="d-block">{`Schritt: ${copyFunctionList.length + 1} / ${
                                copyFunctionList.length + 1
                            }`}</span>
                            <span className="d-block">{"Blobs-Items werden kopiert"}</span>
                            <span className="d-block">{""}</span>
                        </>
                    );

                    const fetchResult = await fetch(serviceCopyBlobFromCustomerEndpoint, {
                        method: "POST",
                        headers: {
                            Authorization: `${tokenType} ${accessToken}`
                        },
                        body: JSON.stringify({
                            fromcustomerid: customerId,
                            tocustomerid: customerId
                        })
                    });

                    if (fetchResult.status === 404) {
                        setErrorList((oldErrorList) => {
                            const newErrorList = [...oldErrorList];
                            newErrorList.push({
                                text: (
                                    <>
                                        <span className="d-block">{`Fehler: ${serviceCopyBlobFromCustomerEndpoint} Url existiert nicht oder ist nicht erreichbar`}</span>
                                        <span className="d-block">{`Kunde: ${customerId} `}</span>
                                    </>
                                )
                            });
                            return newErrorList;
                        });
                    } else if (fetchResult.status !== 200) {
                        const response = await fetchResult.json();

                        setErrorList((oldErrorList) => {
                            const newErrorList = [...oldErrorList];
                            newErrorList.push({
                                text: (
                                    <>
                                        <span className="d-block">{`Fehler: ${response.message}`}</span>
                                        <span className="d-block">{`Kunde: ${customerId} `}</span>
                                    </>
                                )
                            });
                            return newErrorList;
                        });
                    } else {
                        setStatusMessage(
                            <span className="d-block">{"Der Kopier-Vorgang wurde erfolgreich beendet"}</span>
                        );
                    }
                }
            }
            setCurrentState(STATE.DONE);
        },
        [
            authStore.token,
            authStore.tokenType,
            authStore.user,
            copyFunctionList,
            serviceEndpoint,
            serviceCopyBlobFromCustomerEndpoint
        ]
    );

    return (
        <div>
            {currentState === STATE.INIT ? (
                <UI.Form>
                    <UI.Input
                        label="Bitte gib die Kunden-Id der produktiven Umgebung ein"
                        ref={register({
                            required: emptyInputErrorMessage
                        })}
                        name="customerId"
                        type="text"
                        errorMsg={errors.customerId ? errors.customerId.message : undefined}
                    />
                    <UI.Button
                        label={"Ok"}
                        disabled={currentState !== STATE.INIT}
                        onClick={handleSubmit(handleFormSubmit)}
                        className={`${styles.modalBtn} mb-2 mb-sm-0 mr-0 mr-sm-2 d-inline customer-button`}
                        variant="danger"
                    />
                </UI.Form>
            ) : undefined}

            {currentState !== STATE.INIT ? (
                <div style={{ display: "flex", flexDirection: "column" }}>
                    {statusMessage}

                    <ul style={{ listStyle: "none", padding: "0px", margin: "10px 0px 0px 0px" }}>
                        {errorList.map((item, i) => (
                            <li key={i} style={{ marginTop: "5px" }}>
                                {item.text}
                            </li>
                        ))}
                    </ul>

                    {currentState === STATE.RUNNING ? (
                        <UI.RotatingSpinner noLogo={true} size={35} className={"align-items-start"} />
                    ) : undefined}
                    {currentState === STATE.DONE ? (
                        <div className="mt-3 d-inline bold">
                            <p>{props.finishedMessage}</p>
                            <UI.Button
                                label="Fenster schliessen"
                                variant="link"
                                onClick={onCloseModal}
                                className="p-0"
                            />
                        </div>
                    ) : undefined}
                </div>
            ) : undefined}
        </div>
    );
};

export const CopyCustomer = Sentry.withProfiler(CopyCustomerBase);
