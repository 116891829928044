import { UI } from "@wwimmo/ui";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, Prompt } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FORM_STATUS } from "src/utils/Form";
import styles from "./useSaveUnsavedChangesInForm.module.css";

export interface UseSaveUnsavedChangesInFormArguments {
    isFormDirty: boolean;
    saveFormFunction: any;
    currentFormStatus: FORM_STATUS;
}

export const useSaveUnsavedChangesInForm = (
    useSaveUnsavedChangesInFormArguments: UseSaveUnsavedChangesInFormArguments
) => {
    const history = useHistory();
    const { t } = useTranslation();

    const [isSaveFormModalDisplayed, setIsSaveFormModalDisplayed] = useState<boolean>(false);
    const [canLeaveForm, setCanLeaveForm] = useState<boolean>(false);
    const [formLeaveUrl, setFormLeaveUrl] = useState<string>("");

    const { isFormDirty, saveFormFunction, currentFormStatus } = useSaveUnsavedChangesInFormArguments;

    const toggleDisplaySaveFormModal = useCallback(() => {
        setIsSaveFormModalDisplayed((isDisplayed) => !isDisplayed);
    }, []);

    const closeSaveFormModal = useCallback(() => {
        setIsSaveFormModalDisplayed(false);
    }, []);

    useEffect(() => {
        if (canLeaveForm) {
            history.push(formLeaveUrl ?? "/");
        }
    }, [canLeaveForm, history, formLeaveUrl]);

    const beforeReload = useCallback(
        (e: any) => {
            if (isFormDirty) {
                e.preventDefault();
                const returnValue = "";
                e.returnValue = returnValue;
                return returnValue;
            }
        },
        [isFormDirty]
    );

    useEffect(() => {
        window.addEventListener("beforeunload", beforeReload, { capture: true });

        return () => {
            window.removeEventListener("beforeunload", beforeReload, { capture: true });
        };
    }, [beforeReload]);

    const handleInputAndAndExit = useCallback(
        (isSaveForm: boolean) => () => {
            if (isSaveForm) {
                saveFormFunction();
            } else {
                setCanLeaveForm(true);
                toggleDisplaySaveFormModal();
            }
        },
        [toggleDisplaySaveFormModal, saveFormFunction]
    );

    const closeSaveFormModalAndGoBack = useCallback(() => {
        if (isSaveFormModalDisplayed) {
            closeSaveFormModal();
            history.push(formLeaveUrl ?? "/");
        }
    }, [closeSaveFormModal, history, isSaveFormModalDisplayed, formLeaveUrl]);

    const onTryToLeaveForm = useCallback(
        (location, action) => {
            setFormLeaveUrl(location.pathname);

            if (isFormDirty) {
                if (canLeaveForm) {
                    return true;
                } else {
                    toggleDisplaySaveFormModal();
                    return false;
                }
            } else {
                return true;
            }
        },
        [isFormDirty, toggleDisplaySaveFormModal, canLeaveForm]
    );

    const saveFormModal = (
        <>
            <Prompt when={isFormDirty} message={onTryToLeaveForm} />
            <UI.Modal show={isSaveFormModalDisplayed} onClose={toggleDisplaySaveFormModal}>
                <div>
                    <div className={styles.SaveFormModalText}>
                        <div>{t("form.has_unsaved_changes")}</div>
                        {currentFormStatus === FORM_STATUS.SAVING_FORM ? (
                            <div className="d-flex align-items-center">
                                <span>{t("form.saving_changes")}</span>
                                <UI.RotatingSpinner noLogo size={20} className="loading-items-spinner" />
                            </div>
                        ) : (
                            <div style={{ height: "36px" }} />
                        )}
                    </div>
                    <div className={styles.SaveFormModalButtonContainer}>
                        <UI.Button
                            className="FormSaveButtonYes"
                            label={t("labels.yes")}
                            onClick={handleInputAndAndExit(true)}
                        />
                        <UI.Button
                            className="FormSaveButtonNo"
                            label={t("labels.no")}
                            onClick={handleInputAndAndExit(false)}
                        />
                        <UI.Button
                            className="FormSaveButtonCancel"
                            label={t("labels.cancel")}
                            onClick={toggleDisplaySaveFormModal}
                        />
                    </div>
                </div>
            </UI.Modal>
        </>
    );

    return {
        saveFormModal,
        closeSaveFormModalAndGoBack
    };
};
