import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.css";
import * as serviceWorker from "src/serviceWorker";
import App from "src/App";
import "src/utils/i18n";
import "src/utils/Date";

ReactDOM.render(<App />, document.getElementById("root"));

serviceWorker.unregister();
