import React, { useContext, useState } from "react";
import { Header } from "src/components";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { RootStoreContext } from "src/stores/RootStore";
import { Route } from "src/config/routes";
import { AppbarButtonProps } from "src/components/header/appbarButtons/AppbarButtons";
import { RouteComponentProps } from "react-router-dom";
import { withRouter } from "react-router";
import { Helmet } from "react-helmet";

import "./Appheader.css";

interface MatchParams {}

const AppheaderBase = (props: RouteComponentProps<MatchParams>) => {
    const { uiStore, navStore, authStore } = useContext(RootStoreContext);
    const [showSearchInput] = useState<boolean>(false);
    const iconSize = 42;
    const corner = (style: { left?: number; right?: number; transform?: string }) => {
        return (
            <svg
                className="Corner"
                style={style}
                width={`${uiStore.isMobile ? 20 : 40}px`}
                height={`${uiStore.isMobile ? 20 : 40}px`}
                viewBox="0 0 20 20"
            >
                <path
                    d="M0,0 L40,0 L40,40 L0,40 L0,0 Z M20,40 C31.045695,40 40,31.045695 40,20 C40,8.954305 31.045695,0 20,0 C8.954305,0 0,8.954305 0,20 C0,31.045695 8.954305,40 20,40 Z"
                    fill="var(--primary)"
                />
            </svg>
        );
    };

    const appbarButtons: AppbarButtonProps[] = [{ svgIcon: UI.SVGIcon.Logout, to: Route.logout }];

    return (
        <div className="Appheader">
            <Helmet>
                <title>{navStore.currentTitle}</title>
            </Helmet>
            <UI.Container
                className="Container"
                style={{
                    height: uiStore.headerHeight,
                    paddingTop: 20
                }}
                fluid
            >
                {uiStore.closeAppbar ? (
                    <UI.Row>
                        <UI.Col xs={{ span: 6 }} md={{ span: 3, order: 1 }}>
                            <Header.Backnavigation />
                        </UI.Col>
                        <UI.Col
                            xs={{ span: 12, order: 3 }}
                            md={{ span: 6, order: 2 }}
                            className="pt-1 text-left text-md-center"
                        >
                            <div className="Titlebar">
                                <h1>{navStore.appheaderTitle}</h1>
                            </div>
                        </UI.Col>
                        <UI.Col xs={{ span: 6, order: 2 }} md={{ span: 3, order: 3 }} className="text-right">
                            <Header.Close />
                        </UI.Col>
                    </UI.Row>
                ) : (
                    // Show standard Header
                    <UI.Row>
                        {!showSearchInput && (
                            <UI.Col xs={6} md={showSearchInput ? 4 : 3}>
                                <Header.Backnavigation />
                            </UI.Col>
                        )}
                        <UI.Col
                            className="pt-1 text-left text-md-center"
                            xs={{ span: 12, order: 3, offset: 0 }}
                            md={{ span: showSearchInput ? 4 : 6, order: 2, offset: showSearchInput ? 4 : 0 }}
                        >
                            {!showSearchInput && (
                                <div className="Titlebar">
                                    <h1
                                        style={
                                            uiStore.isMobile
                                                ? { display: "block", paddingRight: iconSize * 3 }
                                                : { display: "inline", paddingRight: 0 }
                                        }
                                    >
                                        <span className="ellipsise">{navStore.appheaderTitle}</span>
                                        <Header.ActionButtons iconSize={iconSize} isMobile={uiStore.isMobile} />
                                    </h1>
                                </div>
                            )}
                            {navStore.currentSubtitle ? <h2>{navStore.currentSubtitle}</h2> : <></>}
                        </UI.Col>
                        <UI.Col
                            className="d-flex flex-nowrap justify-content-end"
                            xs={{ span: showSearchInput ? 12 : 6, order: 2 }}
                            md={{ span: showSearchInput ? 4 : 3, order: 3, offset: showSearchInput ? 4 : 0 }}
                        >
                            {authStore.isLoggedIn && (
                                <React.Fragment>
                                    <Header.AppbarButtons buttons={appbarButtons} />
                                </React.Fragment>
                            )}
                        </UI.Col>
                    </UI.Row>
                )}
            </UI.Container>
            <div className="CornerContainer">
                {corner({ left: 0 })}
                {corner({ right: 0, transform: "scaleX(-1)" })}
            </div>
        </div>
    );
};
export const Appheader = withRouter(observer(AppheaderBase));
