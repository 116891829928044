import { gql } from "@apollo/client";

export const GET_SERVICE_DETAILS = gql`
    query GetServiceDetails($serviceid: uuid!, $lang: String = "de") {
        service: uccm_services(where: { id: { _eq: $serviceid } }) {
            id
            name
            type
            state
            chargeable
            pricelink
            remarks
            enableable
            feature
            service_mls {
                id
                language
                content
                title
                teaser
                price
                activate
                deactivate
                afteractivate
            }
            logo: servicefiles(where: { islogo: { _eq: true } }, limit: 1, order_by: { filedate: desc }) {
                id
                extension
                filedate
                language
                mimetype
                name
                type
            }
        }

        servicestates: common_enumerations(
            where: { type: { _eq: 804 }, language: { _eq: $lang } }
            order_by: { key: asc }
        ) {
            id
            key
            label
        }

        servicetypes: common_enumerations(
            where: { type: { _eq: 803 }, language: { _eq: $lang } }
            order_by: { key: asc }
        ) {
            id
            key
            label
        }

        features: common_enumerations(where: { type: { _eq: 90 }, language: { _eq: $lang } }, order_by: { key: asc }) {
            id
            key
            label
        }
    }
`;

export const GET_SERVICE_CONTENT_DETAILS = gql`
    query GetServiceContentDetails($contentid: uuid!) {
        servicecontent: uccm_service_mls(where: { id: { _eq: $contentid } }) {
            id
            language
            content
            title
            teaser
            price
            activate
            deactivate
            afteractivate
            service {
                servicefiles(where: { islogo: { _eq: false } }) {
                    id
                    name
                    extension
                    mimetype
                    language
                }
            }
        }
    }
`;

export const UPSERT_UCCM_SERVICE = gql`
    mutation UpsertUccmService($service: uccm_services_insert_input!) {
        insert_uccm_services_one(
            object: $service
            on_conflict: {
                update_columns: [name, type, state, chargeable, pricelink, remarks, enableable, feature]
                constraint: PK_services
            }
        ) {
            id
            name
            type
            state
            chargeable
            pricelink
            remarks
            enableable
            feature
        }
    }
`;

export const DELETE_SERVICE = gql`
    mutation DeleteService($serviceid: uuid!) {
        delete_uccm_services(where: { id: { _eq: $serviceid } }) {
            affected_rows
        }
    }
`;

export const UPSERT_UCCM_SERVICE_MLS = gql`
    mutation UpsertServiceMls($servicemls: uccm_service_mls_insert_input!) {
        insert_uccm_service_mls_one(
            object: $servicemls
            on_conflict: {
                constraint: PK_service_mls
                update_columns: [language, content, title, teaser, price, activate, deactivate, afteractivate]
            }
        ) {
            id
            language
            content
            title
            teaser
            price
            activate
            deactivate
            afteractivate
        }
    }
`;

export const DELETE_SERVICE_MLS = gql`
    mutation DeleteServiceMls($servicemlsid: uuid!) {
        delete_uccm_service_mls(where: { id: { _eq: $servicemlsid } }) {
            affected_rows
        }
    }
`;
