import React, { useContext, useEffect, useState, useCallback } from "react";
import * as Sentry from "@sentry/react";
import { useQuery } from "@apollo/client";
import { GET_FEATURE_ANNOUNCEMENTS } from "src/api/featureAnnouncements";
import {
    GetFeatureAnnouncements,
    GetFeatureAnnouncements_announcements as uccmAnnouncements
} from "src/api/generated/GetFeatureAnnouncements";
import FeatureAnnouncementList from "src/screens/dashboard/feature-announcements/list/FeatureAnnouncementList";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import * as Screens from "src/screens";
import styles from "./FeatureAnnouncements.module.css";
import { withRouter, RouteComponentProps } from "react-router";
import { Role as CustomerRole } from "src/network/User";
import { AnnouncementState, AnnouncementTargetProduct } from "src/utils/Enums";
import { getDate } from "src/utils/Date";
import { Route, selectRoute } from "src/config/routes";
import { v4 as uuidv4 } from "uuid";
import { ActiveAnnouncementListItem } from "src/api/featureAnnouncements";
import { ActiveFeatureAnnouncementList } from "src/components/feature-announcements/statistics/active-announcement-list/ActiveFeatureAnnouncementList";

const FeatureAnnouncementsBase = (props: RouteComponentProps) => {
    const { t } = useTranslation();
    const { navStore, uiStore, authStore } = useContext(RootStoreContext);

    const listIdentifier = "featureAnnouncementlist";

    if (!navStore.listMap.has(listIdentifier)) {
        navStore.setListMapValue(listIdentifier, {});
    }

    const listParameters = navStore.getListMapValue(listIdentifier);

    const userRole = authStore.user ? authStore.user.role : undefined;

    const [displayedAnnouncements, setDisplayedAnnouncements] = useState<uccmAnnouncements[]>([]);

    const {
        data: dataAnnouncements,
        loading: loadingAnnouncements,
        error: errorAnnouncements,
        refetch: refetchAnnouncements
    } = useQuery<GetFeatureAnnouncements>(GET_FEATURE_ANNOUNCEMENTS);

    const filterAnnouncementsAndSetSearchQuery = useCallback(
        (searchQuery: string) => {
            if (dataAnnouncements) {
                const foundCustomers = dataAnnouncements.announcements.filter((announcement) => {
                    const currentSearchQuery = searchQuery.toLowerCase();

                    const announcementName = announcement.name.toLowerCase();
                    const productName = AnnouncementTargetProduct[announcement.targetproduct].toLowerCase();
                    const state = AnnouncementState[announcement.state].toLowerCase();
                    const startDate = getDate(announcement.startdate);
                    const endDate = getDate(announcement.enddate);

                    return (
                        announcementName.includes(currentSearchQuery) ||
                        productName.includes(currentSearchQuery) ||
                        state.includes(currentSearchQuery) ||
                        startDate?.includes(currentSearchQuery) ||
                        endDate?.includes(currentSearchQuery)
                    );
                });

                setDisplayedAnnouncements([...foundCustomers]);
            }
        },
        [dataAnnouncements]
    );

    const handleSearchInput = useCallback(
        ({ searchQuery }) => {
            filterAnnouncementsAndSetSearchQuery(searchQuery);

            navStore.setListMapValue(
                listIdentifier,
                listParameters
                    ? { ...listParameters, scrollPosition: 0, searchQuery: searchQuery }
                    : { scrollPosition: 0, searchQuery: searchQuery }
            );
        },
        [filterAnnouncementsAndSetSearchQuery, listParameters, navStore]
    );

    useEffect(() => {
        navStore.setTitle("Feature Announcements");
        navStore.setBackbutton(null);
    }, [navStore]);

    useEffect(() => {
        if (uiStore.hasNewAnnouncementData) {
            refetchAnnouncements();

            if (dataAnnouncements?.announcements) {
                setDisplayedAnnouncements([...dataAnnouncements?.announcements]);
            }
        }
    }, [refetchAnnouncements, uiStore, dataAnnouncements?.announcements]);

    if (loadingAnnouncements || displayedAnnouncements === []) {
        return <Screens.Loading />;
    }
    if (errorAnnouncements) {
        return <Screens.Error message={errorAnnouncements.message} networkError={errorAnnouncements.networkError} />;
    }
    if (!dataAnnouncements) {
        return <Screens.Error message={t("error.nodata")} />;
    }

    const addFeatureAnnouncementButton = (
        <div className={styles.featureAnnouncementAddButton}>
            <UI.Button
                to={selectRoute(Route.featureAnnouncementDetails, null, {
                    announcementid: uuidv4()
                })}
                label={t("screens.dashboard.feature_announcements.add_feature_announcements")}
                className="customer-button float-right w-100"
            />
        </div>
    );

    const activeAnnouncementListItems: ActiveAnnouncementListItem[] = [
        {
            product: "Abnahme App",
            activeAnnouncements: dataAnnouncements.active_announcements_inspectionapp.aggregate?.count ?? 0
        },
        { product: "Portal", activeAnnouncements: dataAnnouncements.active_announcements_portal.aggregate?.count ?? 0 }
    ];

    return (
        <React.Fragment>
            <UI.Container className="pt-4">
                <UI.Row>
                    <UI.Col md={9}>
                        <UI.Card>
                            <div>
                                <FeatureAnnouncementList
                                    uccmAnnouncements={displayedAnnouncements}
                                    onSearch={handleSearchInput}
                                    searchAppendElement={
                                        userRole !== CustomerRole.CUSTOMERSUPPORT
                                            ? addFeatureAnnouncementButton
                                            : undefined
                                    }
                                />
                            </div>
                        </UI.Card>
                    </UI.Col>
                    <UI.Col md={3}>
                        <UI.Card title="Aktive Announcements">
                            <ActiveFeatureAnnouncementList activeAnnouncementListItems={activeAnnouncementListItems} />
                        </UI.Card>
                    </UI.Col>
                </UI.Row>
            </UI.Container>
        </React.Fragment>
    );
};

export const FeatureAnnouncements = withRouter(Sentry.withProfiler(observer(FeatureAnnouncementsBase)));
