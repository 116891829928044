import { de, fr, enGB, it } from "date-fns/locale";
import { format } from "date-fns";
import { getLang } from "./i18n";
export const months = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];

export const dateFormat = (date: any, formatStr = "PP") => {
    let locale: Locale = de;
    if (getLang().indexOf("de") !== -1) locale = de;
    if (getLang().indexOf("en") !== -1) locale = enGB;
    if (getLang().indexOf("fr") !== -1) locale = fr;
    if (getLang().indexOf("it") !== -1) locale = it;
    return format(date, formatStr, { locale });
};

export const getDate = (inputDate: string) => {
    if (inputDate) {
        return dateFormat(new Date(inputDate), "dd.MM.yyyy");
    }
};

export const getDateForInput = (inputDate: string) => {
    if (inputDate) {
        return dateFormat(new Date(inputDate), "yyyy-MM-dd");
    }
};
