import React, { useContext, useEffect } from "react";
import { useParams } from "react-router";
import * as Sentry from "@sentry/react";
import { useQuery } from "@apollo/client";
import { GET_UMS_CUSTOMER_LOGS } from "src/api/customer";
import { GetCustomerLogs, GetCustomerLogsVariables } from "src/api/generated/GetCustomerLogs";
import { LogList } from "./list/LogList";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import * as Screens from "src/screens";

interface Params {
    customerid: string;
}

const CustomerLogsBase = () => {
    const { customerid } = useParams<Params>();

    const { t } = useTranslation();
    const { navStore } = useContext(RootStoreContext);

    useEffect(() => {
        navStore.setTitle("Logs");
        navStore.setBackbutton(`/customers/${customerid}`, "Kunde");
    }, [navStore, customerid]);

    const { data, loading, error } = useQuery<GetCustomerLogs, GetCustomerLogsVariables>(GET_UMS_CUSTOMER_LOGS, {
        variables: {
            customerid: customerid
        }
    });

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata")} />;
    }

    return (
        <React.Fragment>
            <UI.Container className="pt-4">
                <UI.Row className="justify-content-md-center">
                    <UI.Col md={6}>
                        <UI.Card>
                            <div>
                                <LogList logs={data.logs} />
                            </div>
                        </UI.Card>
                    </UI.Col>
                </UI.Row>
            </UI.Container>
        </React.Fragment>
    );
};

export const CustomerLogs = Sentry.withProfiler(observer(CustomerLogsBase));
