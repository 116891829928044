import { Role, RolesMap } from "src/network/User";
import { isUUID } from "src/utils/Common";
import { generatePath } from "react-router";

export enum Route {
    customersNew = "/customers/new",
    customers = "/customers",
    users = "/users",
    featureAnnouncements = "/feature-announcements",
    featureAnnouncementDetails = "/feature-announcements/:announcementid",
    featureAnnouncementContentDetails = "/feature-announcements/:announcementid/content/:contentid",
    services = "/services",
    serviceDetails = "/services/:serviceid",
    serviceContentDetails = "/services/:serviceid/content/:contentid",
    userDetails = "/users/:customerid/:userid",
    userAudits = "/users/:customerid/:userid/audits",
    dashboard = "/",
    customerDetails = "/customers/:customerid",
    customerDetailsOverview = "/customers/:customerid/overview",
    customerDetailsBills = "/customers/:customerid/bills",
    customerInvoices = "/customers/:customerid/invoices",
    customerLogs = "/customers/:customerid/logs",
    customerFaultyFiles = "/customers/:customerid/faulty-files",
    customerConnectionDetails = "/customers/:customerid/connection/:connectionid",
    profile = "/profile",
    theming = "/profile/theming",
    login = "/login",
    logout = "/logout",
    register = "/register",
    activate = "/activate",
    passwordchange = "/passwordchange",
    passwordrecovery = "/passwordrecovery",
    passwordreset = "/passwordreset"
}

export enum SearchRoute {
    searchItem = "/"
}

export const getRootRoute = (role: Role) => {
    const rootRoute = RolesMap.find((r) => r.role === role)?.rootRoute;
    return rootRoute ? rootRoute : RolesMap.find((r) => r.role === Role.NONE)?.rootRoute || "/";
};

export const getRoute = (url: string, role: Role): Route | undefined => {
    const routes = Route as any;
    const rootRoute = getRootRoute(role) || "";
    for (const r in routes) {
        const lhs = rootRoute.concat(
            routes[r]
                .split("/")
                .map((s: string) => {
                    return s.indexOf(":") === 0 ? "var" : s;
                })
                .join("/")
        );
        const rhs = url
            .split("/")
            .map((s: string) => {
                return isUUID(s) ? "var" : s;
            })
            .join("/");
        if (lhs === rhs) {
            return routes[r] as Route;
        }
    }
    return undefined;
};

export const selectRoute = (
    route: Route | SearchRoute,
    role?: Role | null,
    params?: { [key: string]: string }
): string => {
    if (params) {
        return generatePath((role ? getRootRoute(role) : "") + route, params);
    } else if (role) {
        return getRootRoute(role) + route;
    } else {
        return route;
    }
};
