import React, { Suspense, useCallback, useEffect, useState } from "react";
import { createBrowserHistory } from "history";
import { Router } from "react-router";
import * as Sentry from "@sentry/react";
import { configure } from "mobx";
import { Integrations } from "@sentry/tracing";
import { Routes } from "src/screens/Routes";
import { RootStore, RootStoreContext } from "src/stores/RootStore";
import "src/App.css";
import { Loading } from "src/screens";
import { Error } from "src/screens";
import { Notifier } from "./components/notifications/Notifier";
import { ToastContainer as NotificationContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NetworkConfig } from "./network/NetworkConfig";
import { GraphqlClient } from "./network/GraphqlClient";
import "@wwimmo/ui/dist/index.css";
import { initUI, UI } from "@wwimmo/ui";
import { Config } from "./config/config";
import { initializeApolloClient } from "src/network/apolloClientInstance";

initUI({
    logo: NetworkConfig.brandAssets.logoImage,
    appname: Config.appname
});

configure({ enforceActions: "always" });

const history = createBrowserHistory();
const stores = new RootStore();
stores.navStore.setTitle("");

const sentryInitObject = {
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_ENVIRONMENT,
    debug: false,
    dsn: NetworkConfig.sentryUrl,
    integrations: [
        new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
        })
    ]
};

const App: React.FC = () => {
    const [isInitializing, setIsInitializing] = useState(true);

    useEffect(() => {
        const initialize = async () => {
            try {
                const response = await fetch(`${window.location.origin}/config.json`);
                if (response.ok) {
                    const config = await response.json();

                    NetworkConfig.environment = config.env;
                    NetworkConfig.wwCustomerManagementClientId = config.wwCustomerManagementClientId;
                    NetworkConfig.cidaasBaseUrl = config.cidaasBaseUrl;

                    if (config.env !== "prod") {
                        NetworkConfig.authWebsiteUrl = `https://${config.env}.auth.wwportal.ch`;
                    }

                    NetworkConfig.graphqlWssUrl = `wss://ww-${config.env}-hasura-cidaas-wa.azurewebsites.net/v1/graphql`;
                    NetworkConfig.graphqlUrl = `https://ww-${config.env}-hasura-cidaas-wa.azurewebsites.net/v1/graphql`;

                    initializeApolloClient(stores);

                    if (config.Sentry.Active) {
                        const sampleRate = config.Sentry.SampleRate;
                        Sentry.init({ ...sentryInitObject, tracesSampleRate: sampleRate ?? 1 });
                    }
                }
            } catch (error) {
                Sentry.init(sentryInitObject);
                console.error("Error fetching config:", error);
            } finally {
                setIsInitializing(false);
            }
        };

        initialize();
    }, []);

    const fallback = useCallback(
        ({ error, componentStack }: { error: Error; componentStack: string | null }) => (
            <Error error={error} componentStack={componentStack} />
        ),
        []
    );

    if (isInitializing) {
        return (
            <UI.Container className="d-flex flex-fill justify-content-center align-items-center fullscreen">
                <UI.Row>
                    <UI.Col className="text-center">
                        <UI.RotatingSpinner noLogo />
                    </UI.Col>
                </UI.Row>
            </UI.Container>
        );
    }

    return (
        <Suspense fallback={<Loading fullscreen />}>
            <RootStoreContext.Provider value={stores}>
                <Sentry.ErrorBoundary fallback={fallback}>
                    <GraphqlClient>
                        <Notifier />
                        <NotificationContainer
                            position="bottom-left"
                            newestOnTop={true}
                            closeOnClick
                            autoClose={3000}
                        />
                        <Router history={history}>
                            <Routes />
                        </Router>
                    </GraphqlClient>
                </Sentry.ErrorBoundary>
            </RootStoreContext.Provider>
        </Suspense>
    );
};

export default Sentry.withProfiler(App);
