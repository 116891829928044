import React from "react";
import { Nav, Container } from "react-bootstrap";
import { NavigationbarItem } from "src/components/header/navigationbar/NavigationbarItem";
import { Row } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import "./Navigationbar.css";

interface NavigationbarProps {
    navLinks: { to: string; label: string; icon: React.ReactNode; disabled?: boolean; onClick?: any }[];
}

const NavigationbarBase = (props: NavigationbarProps) => {
    const { navLinks } = props;
    return (
        <Nav className="Navigationbar">
            <Container>
                <Row className="NavigationbarContainer">
                    {navLinks.map((navLink, index) => (
                        <NavigationbarItem
                            key={`item-${index}`}
                            label={navLink.label}
                            to={navLink.to}
                            icon={navLink.icon}
                            disabled={navLink.disabled}
                            onClick={navLink.onClick}
                        />
                    ))}
                </Row>
            </Container>
        </Nav>
    );
};

export const Navigationbar = observer(NavigationbarBase);
