import React from "react";
import { observer } from "mobx-react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { useCallback, useContext, useEffect, useState } from "react";
import { RootStoreContext } from "src/stores/RootStore";
import { ScreenSize, UI } from "@wwimmo/ui";
import { startCase } from "lodash";
import { IMPORT_USERS_PROCESS, IMPORT_USERS_PROCESS_STEP, IImportUser } from "src/stores/ImportUsersProgressModalStore";
import styles from "./ImportUsersProgressModal.module.css";
import { v4 as uuidv4 } from "uuid";
import { useMutation } from "@apollo/client";
import { UPDATE_USERIMPORTDATA } from "src/api/users";
import {
    UpsertUserImportprocesses,
    UpsertUserImportprocessesVariables
} from "src/api/generated/UpsertUserImportprocesses";
import { getRoleKey } from "src/network/User";
import { NetworkConfig } from "src/network/NetworkConfig";

interface ImportUsersProgressModalProps {
    importdata: IImportUser[];
    customerid: string;
}

const ImportUsersProgressModalBase = (props: ImportUsersProgressModalProps) => {
    const { t } = useTranslation();
    const { uiStore, importUsersProgressModalStore, authStore } = useContext(RootStoreContext);
    const {
        setCurrentProcessType,
        setisImportUsersProgressModalDisplayed,
        currentStepType,
        currentStep,
        isImportUsersProgressModalDisplayed,
        IIMPORT_USERS_MODAL_MAX_NUMBER_OF_STEPS,
        isImportUsersSucceeded,
        setCurrentStepType
    } = importUsersProgressModalStore;

    const { importdata = [] } = props;

    const [upsertUserImportMutation] = useMutation<UpsertUserImportprocesses, UpsertUserImportprocessesVariables>(
        UPDATE_USERIMPORTDATA
    );

    useEffect(() => {
        if (importUsersProgressModalStore.isImportUsersProgressModalDisplayed) {
            importUsersProgressModalStore.loadUserImportList(importdata);
            importUsersProgressModalStore.checkIfEmailExists(props.customerid, "");
        }
        // eslint-disable-next-line
    }, [importUsersProgressModalStore.isImportUsersProgressModalDisplayed]);

    const [isSuccessfullyImported, setIsSuccessfullyImported] = useState<boolean>(false);
    const [errorMsgUpdateUserImportDb, setErrorMsgUpdateUserImportDb] = useState<string>("");
    const [errorMsgStartImportUsersProcess, setErrorMsgStartImportUsersProcess] = useState<string>("");
    const [editedValue, setEditedValue] = useState<string>(" ");

    const onClickStep = useCallback(
        (processType: IMPORT_USERS_PROCESS) => () => {
            setCurrentProcessType(processType);
        },
        [setCurrentProcessType]
    );

    const fetchStartImportUserProcess = useCallback(async () => {
        if (authStore.user && authStore.token) {
            const accessToken = authStore.token;
            const tokenType = authStore.tokenType;
            const role = authStore.user?.role;
            const body = {
                customerid: props.customerid,
                userimportprocessid: importUsersProgressModalStore.userimportprocessesId
            };

            try {
                const fetchResult = await fetch(NetworkConfig.startimportuserprocess, {
                    method: "POST",
                    body: JSON.stringify(body),
                    headers: {
                        Authorization: `${tokenType} ${accessToken}`,
                        "x-hasura-role": getRoleKey(role)
                    }
                });

                if (fetchResult.status === 200) {
                    setIsSuccessfullyImported(true);
                    setCurrentProcessType(IMPORT_USERS_PROCESS.IMPORT_USERS_RESULT);
                    return true;
                } else {
                    const response = await fetchResult.json();
                    setErrorMsgStartImportUsersProcess(response.message);
                    setIsSuccessfullyImported(false);
                    setCurrentProcessType(IMPORT_USERS_PROCESS.IMPORT_USERS_RESULT);
                    return false;
                }
            } catch (fetchError) {
                setErrorMsgStartImportUsersProcess(`${fetchError}`);
                setIsSuccessfullyImported(false);
                setCurrentProcessType(IMPORT_USERS_PROCESS.IMPORT_USERS_RESULT);
                return false;
            }
        }
    }, [authStore, setCurrentProcessType, props.customerid, importUsersProgressModalStore]);

    const upsertUserImportData = useCallback(async () => {
        try {
            const transformedImportUsers = {
                users: importUsersProgressModalStore.updatedUserImportList.map((user) => ({
                    email: user.email,
                    erpid: user.erpid,
                    name1: user.name1,
                    name2: user.name2,
                    state: user.state,
                    isadmin: user.isadmin,
                    toimport: user.toimport,
                    portaluserid: user.portaluserid
                }))
            };

            await upsertUserImportMutation({
                variables: {
                    id: importUsersProgressModalStore.userimportprocessesId,
                    customerid: props.customerid,
                    importdata: transformedImportUsers
                }
            });

            fetchStartImportUserProcess();
        } catch (error: any) {
            setErrorMsgUpdateUserImportDb(error.message);
            setIsSuccessfullyImported(false);
            setCurrentProcessType(IMPORT_USERS_PROCESS.IMPORT_USERS_RESULT);
            console.error(error.message);
        }
    }, [
        importUsersProgressModalStore,
        upsertUserImportMutation,
        setCurrentProcessType,
        props.customerid,
        fetchStartImportUserProcess
    ]);

    useEffect(() => {
        if (currentStepType === IMPORT_USERS_PROCESS_STEP.LOAD_IMPORT_USERS) {
            upsertUserImportData();
        }
    }, [currentStepType, upsertUserImportData]);

    const onCloseModal = useCallback(() => {
        setisImportUsersProgressModalDisplayed(false);
        setIsSuccessfullyImported(false);
        setCurrentStepType(0);
        importUsersProgressModalStore.setUpdatedUserImportList(importdata);
        importUsersProgressModalStore.loadUserImportList(importdata);
    }, [setisImportUsersProgressModalDisplayed, setCurrentStepType, importUsersProgressModalStore, importdata]);

    const handleUserCheckboxChange = useCallback(
        (importUser: IImportUser) => (e: React.ChangeEvent<HTMLInputElement>) => {
            const email = importUser.email;
            if (email && importUser.state === 1) {
                importUsersProgressModalStore.toggleUsersSelected(email);
            } else {
                const inputEmail = document.querySelector(`.${styles.importEmail}`) as HTMLInputElement;
                if (inputEmail) {
                    inputEmail.focus();
                }
            }
        },
        [importUsersProgressModalStore]
    );

    const handleUserAdminCheckboxChange = useCallback(
        (e: any) => {
            importUsersProgressModalStore.toggleUserAdmin(e.target.name);
        },
        [importUsersProgressModalStore]
    );

    const handleEmailInputChange = useCallback(
        (importUser: IImportUser) => (e: React.ChangeEvent<HTMLInputElement>) => {
            importUsersProgressModalStore.updateUserEmail(importUser, e.target.value, props.customerid);
            setEditedValue(e.target.value);
        },
        [importUsersProgressModalStore, props.customerid]
    );

    const handleNameInputChange = useCallback(
        (importUser: IImportUser) => (e: React.ChangeEvent<HTMLInputElement>) => {
            setEditedValue(e.target.value);
            importUsersProgressModalStore.updateUserName(importUser, e.target.value);
        },
        [importUsersProgressModalStore]
    );

    const renderImportUserItem = useCallback(
        (
            importUserData: IImportUser & {
                initiallyEmptyEmail: boolean;
                initiallyEmptyName: boolean;
                isEmailPatternValid: boolean;
            },
            screenSize: ScreenSize
        ) => {
            const checkItem = (
                <div>
                    {importUserData.state <= 1 ? (
                        <UI.Checkbox
                            name={importUserData.email}
                            onChange={handleUserCheckboxChange(importUserData)}
                            key={"chk-isUserSelected"}
                            checked={importUserData.toimport ? importUserData.toimport : false}
                        />
                    ) : undefined}
                </div>
            );

            const isEmailPatternValid = importUserData.isEmailPatternValid ?? false;

            let listRows: React.ReactElement[] = [
                <UI.List.Row key={"r-1"}>
                    <UI.List.Cell key={"c-1"} colspan={0.2} value={checkItem} />
                    <UI.List.Cell key={"c-2"} colspan={1} value={importUserData.name1 ?? ""} />
                    <UI.List.Cell
                        key={"c-3"}
                        colspan={1}
                        value={
                            importUserData.initiallyEmptyName ? (
                                <input
                                    className={styles.imporName}
                                    value={importUserData.name2}
                                    onChange={handleNameInputChange(importUserData)}
                                    autoFocus={editedValue === importUserData.name2}
                                />
                            ) : (
                                importUserData.name2
                            )
                        }
                    />
                    <UI.List.Cell
                        key={"c-4"}
                        colspan={2}
                        value={
                            importUserData.initiallyEmptyEmail ? (
                                <>
                                    <input
                                        className={styles.importEmail}
                                        value={importUserData.email}
                                        onChange={handleEmailInputChange(importUserData)}
                                        autoFocus={editedValue === importUserData.email}
                                    />
                                    <>
                                        {importUserData.email === "" ? undefined : isEmailPatternValid ? undefined : (
                                            <div className={styles.IncorrectEmail}>
                                                {t("user_import.incorrect_email_pattern")}
                                            </div>
                                        )}
                                    </>
                                </>
                            ) : (
                                importUserData.email
                            )
                        }
                    />

                    <UI.List.Cell
                        key={"c-5"}
                        colspan={0.5}
                        className={styles.centerCheckbox}
                        value={
                            <UI.Checkbox
                                name={importUserData.email}
                                checked={importUserData.isadmin}
                                onChange={handleUserAdminCheckboxChange}
                            />
                        }
                    />
                    <UI.List.Cell
                        key={"c-6"}
                        colspan={1}
                        className={importUserData.state >= 2 ? styles.foundState : undefined}
                        value={
                            importUserData.state === 1
                                ? t("user_import.new")
                                : importUserData.state === 2
                                ? t("user_import.found")
                                : importUserData.state === 3
                                ? t("user_import.found_other_customer")
                                : ""
                        }
                    />
                </UI.List.Row>
            ];

            return <UI.List.Item key={uuidv4()} screenSize={screenSize} rows={listRows} />;
        },
        [
            handleUserCheckboxChange,
            handleUserAdminCheckboxChange,
            handleEmailInputChange,
            editedValue,
            t,
            handleNameInputChange
        ]
    );

    const getRenderItem = (screenSize: ScreenSize) => {
        return (item: unknown) =>
            renderImportUserItem(
                item as IImportUser & {
                    initiallyEmptyEmail: boolean;
                    initiallyEmptyName: boolean;
                    isEmailPatternValid: boolean;
                },
                screenSize
            );
    };

    const onToggleHeader = useCallback(() => {
        importUsersProgressModalStore.toggleAllUsersSelected(props.customerid);
    }, [importUsersProgressModalStore, props.customerid]);

    const renderImportUsersHeader = useCallback(
        (screenSize: ScreenSize) => {
            const checkBoxHeader = (
                <UI.Checkbox
                    name={"chk-areAllUsersSelected"}
                    onChange={onToggleHeader}
                    key={"chk-areAllUsersSelected"}
                    checked={importUsersProgressModalStore.isEveryUserSelected}
                />
            );

            let headerRows: React.ReactElement[] = [
                <UI.List.Row key={"row-1"}>
                    <UI.List.Cell key={"c-1"} colspan={0.2} value={checkBoxHeader} />
                    <UI.List.Cell key={"c-2"} colspan={1} value={"Vorname"} />
                    <UI.List.Cell key={"c-3"} colspan={1} value={"Nachname"} />
                    <UI.List.Cell key={"c-4"} colspan={2} value={"E-Mail-Adresse"} />
                    <UI.List.Cell key={"c-5"} colspan={0.4} value={"Admin"} />
                    <UI.List.Cell key={"c-6"} colspan={1} value={"Status"} />
                </UI.List.Row>
            ];

            return <UI.List.Header rows={headerRows} sticky={false} top={130} />;
        },
        [importUsersProgressModalStore.isEveryUserSelected, onToggleHeader]
    );

    const renderUserImportList = () => {
        if (importdata.length === 0) {
            return <div>{t("user_import.no_data")}</div>;
        }

        return (
            <UI.List.BasicList
                screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
                items={importUsersProgressModalStore.updatedUserImportList}
                renderItem={getRenderItem(uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP)}
                renderHeader={renderImportUsersHeader}
            />
        );
    };

    const renderStep = () => {
        switch (currentStepType) {
            case IMPORT_USERS_PROCESS_STEP.START_IMPORT_USERS:
            default:
                return (
                    <>
                        <UI.Row className={"mt-2"}>
                            <UI.Col>
                                <div className={styles.modalText}>{t("user_import.modal_text")}</div>
                                {renderUserImportList()}
                            </UI.Col>
                        </UI.Row>
                        <UI.Row className={"mt-5"}>
                            <UI.Col xs={6} md={6}>
                                <UI.Button
                                    className={`ticket-button mt-2 ${styles.cancelButton}`}
                                    label={startCase(t("labels.cancel").toString())}
                                    variant={"outline-primary"}
                                    onClick={onCloseModal}
                                ></UI.Button>
                            </UI.Col>
                            <UI.Col xs={6} md={6} className="d-flex justify-content-end">
                                <UI.Button
                                    className={`ticket-button mt-2 ${styles.ImportButton}`}
                                    label={t("user_import.import")}
                                    disabled={
                                        !importUsersProgressModalStore.updatedUserImportList.some(
                                            (user) =>
                                                user.toimport && user.name1.trim() !== "" && user.name2.trim() !== ""
                                        )
                                    }
                                    onClick={onClickStep(IMPORT_USERS_PROCESS.LOAD_IMPORT_USERS)}
                                ></UI.Button>
                            </UI.Col>
                        </UI.Row>
                    </>
                );
            case IMPORT_USERS_PROCESS_STEP.LOAD_IMPORT_USERS:
                return (
                    <>
                        {!isImportUsersSucceeded ? (
                            <>
                                <UI.Row className={styles.DivImageLoadSpinner}>
                                    <div className={styles.SpinnerContainer}>
                                        <UI.RotatingSpinner noLogo size={50} className={styles.ImageLoadSpinner} />
                                        <div className={styles.SpinnerText}>
                                            {t("user_import.user_going_to_be_imported")}.
                                        </div>
                                    </div>
                                </UI.Row>
                                <UI.Row className={"mt-2 justify-content-end"}>
                                    <UI.Col xs={12} md={3}>
                                        <UI.Button
                                            className={`ticket-button mt-2 ${styles.cancelButton}`}
                                            label={startCase(t("labels.cancel").toString())}
                                            variant={"outline-primary"}
                                            onClick={onCloseModal}
                                        ></UI.Button>
                                    </UI.Col>
                                </UI.Row>
                            </>
                        ) : undefined}
                    </>
                );
            case IMPORT_USERS_PROCESS_STEP.IMPORT_USERS_RESULT:
                return (
                    <>
                        <UI.Row className={"mt-4"}>
                            <UI.Col className={styles.ColAfterActivate}>
                                <UI.Icon
                                    icon={isSuccessfullyImported ? UI.SVGIcon.Check : UI.SVGIcon.Close}
                                    color={"black"}
                                    size={80}
                                />
                            </UI.Col>
                        </UI.Row>
                        <UI.Row className={"mt-5"}>
                            <UI.Col className={styles.ColAfterActivate}>
                                {isSuccessfullyImported ? (
                                    <div className={styles.ResultMessage}>
                                        <div className={styles.FirstResaultMsg}>
                                            {t("user_import.successfull_imported")}
                                        </div>
                                        <div className={styles.SecondResaultMsg}>
                                            {t("user_import.all_users_were_invited")}
                                        </div>
                                    </div>
                                ) : (
                                    <div className={styles.ResultMessage}>
                                        <div className={styles.FirstResaultMsg}>{errorMsgUpdateUserImportDb}</div>
                                        <div className={styles.SecondResaultMsg}>{errorMsgStartImportUsersProcess}</div>
                                    </div>
                                )}
                            </UI.Col>
                        </UI.Row>
                        <UI.Row className={"mt-5 justify-content-end"}>
                            <UI.Col xs={12} md={3}>
                                <UI.Button
                                    className={`d-flex align-self-end ${styles.ImportButton}`}
                                    label={t("labels.finish")}
                                    onClick={onCloseModal}
                                ></UI.Button>
                            </UI.Col>
                        </UI.Row>
                    </>
                );
        }
    };

    return (
        <UI.ProgressModal
            currentStep={currentStep?.stepNumber ?? 1}
            totalSteps={IIMPORT_USERS_MODAL_MAX_NUMBER_OF_STEPS}
            title={`${t("user_import.title")}`}
            show={isImportUsersProgressModalDisplayed}
            onDismissModal={onCloseModal}
            centered={uiStore.isMobile ? true : false}
            size={uiStore.isMobile ? undefined : "lg"}
        >
            {renderStep()}
        </UI.ProgressModal>
    );
};

export const ImportUsersProgressModal = Sentry.withProfiler(observer(ImportUsersProgressModalBase));
