import * as yup from "yup";
import { MAX_CUSTOMER_NUMBER_LENGTH, MAX_CUSTOMER_NUMBER } from "src/utils/Customer";

export const CUSTOMER_SCHEMA = yup.object().shape({
    number: yup
        .number()
        .typeError("Es sind nur Nummern erlaubt!")
        .positive()
        .integer()
        .max(MAX_CUSTOMER_NUMBER, `Die Kundennummer darf höchstens ${MAX_CUSTOMER_NUMBER_LENGTH} Stellen lang sein.`)
        .required("Pflichtfeld!"),
    email: yup.string().email("Das Format der E-Mail-Adresse ist ungültig").required("Pflichtfeld!"),
    erp: yup.number().required("Pflichtfeld!"),
    name: yup.string().required("Pflichtfeld!"),
    subdomain: yup.string().required("Pflichtfeld!"),
    type: yup.number().required("Pflichtfeld!"),
    partner: yup.number().required("Pflichtfeld!")
});
