import React, { useContext, useCallback } from "react";
import { GetUserAudits_audits as userAudit } from "src/api/generated/GetUserAudits";
import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import { UI, ScreenSize } from "@wwimmo/ui";
import { RootStoreContext } from "src/stores/RootStore";

interface LogListProps {
    audits: userAudit[];
}

const AUDIT_MAP = {
    10: "Anmelden",
    20: "Abmelden",
    30: "Token aktualisieren",
    40: "Passwort vergessen",
    50: "Passwort ändern",
    60: "Benutzer registrieren",
    70: "Benutzer aktiviert",
    80: "Benutzer deaktiviert",
    90: "Benutzer gesperrt",
    100: "Benutzer entsperrt",
    110: "Benutzer bestätigt",
    120: "Benutzer unbestätigt",
    130: "Benutzer unregistriert",
    140: "Passwort zurücksetzen",
    150: "Anmeldung fehlgeschlagen"
};

const AuditListBase = (props: LogListProps) => {
    const { uiStore } = useContext(RootStoreContext);

    const renderItem = useCallback((audit: userAudit, screenSize: ScreenSize) => {
        const { event, timestamp, ids4_client } = audit;

        const eventLabel = AUDIT_MAP[event as keyof typeof AUDIT_MAP];
        const auditTimestamp = format(new Date(timestamp), "dd.MM.yyy, HH:mm");
        const clientNameLabel = ids4_client?.clientname ?? "";

        let listRows: React.ReactElement[] = [];

        switch (screenSize) {
            case ScreenSize.DESKTOP:
                listRows = [
                    <UI.List.Row key={"r-1"}>
                        <UI.List.Cell key={"c-2"} colspan={1} value={auditTimestamp} />
                        <UI.List.Cell key={"c-3"} colspan={1} value={clientNameLabel} />
                        <UI.List.Cell key={"c-1"} colspan={1} value={eventLabel} />
                    </UI.List.Row>
                ];
                break;

            case ScreenSize.MOBILE:
                listRows = [
                    <UI.List.Row key={"r-1"}>
                        <UI.List.Cell key={"c-1"} colspan={1} value={auditTimestamp} />
                        <UI.List.Cell key={"c-2"} colspan={1} value={eventLabel} />
                    </UI.List.Row>
                ];
                break;
        }

        return <UI.List.Item key={`${audit.timestamp}`} screenSize={screenSize} rows={listRows} />;
    }, []);

    const renderHeader = useCallback((screenSize: ScreenSize) => {
        let headerRows: React.ReactElement[] = [];

        switch (screenSize) {
            case ScreenSize.DESKTOP:
                headerRows = [
                    <UI.List.Row key={"row-1"}>
                        <UI.List.Cell key={"c-1"} colspan={1} value={"Zeitpunkt"} />
                        <UI.List.Cell key={"c-2"} colspan={1} value={"Typ des Clients"} />
                        <UI.List.Cell key={"c-3"} colspan={1} value={"Ereignis"} />
                    </UI.List.Row>
                ];
                break;
            case ScreenSize.MOBILE:
                headerRows = [
                    <UI.List.Row key={"row-1"}>
                        <UI.List.Cell key={"c-1"} colspan={1} value={"Zeitpunkt"} />
                        <UI.List.Cell key={"c-2"} colspan={1} value={"Ereignis"} />
                    </UI.List.Row>
                ];
                break;
        }

        return <UI.List.Header rows={headerRows} sticky={true} top={130} />;
    }, []);

    return (
        <UI.List.BasicList
            screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
            items={props.audits}
            renderItem={renderItem}
            renderHeader={renderHeader}
        />
    );
};

export const AuditList = observer(AuditListBase);
