import React, { useContext, useCallback } from "react";
import { GetFeatureAnnouncementDetails_announcement_announcements_mls as announcementsMls } from "src/api/generated/GetFeatureAnnouncementDetails";
// import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { UI, ScreenSize } from "@wwimmo/ui";
import * as Sentry from "@sentry/react";
import { useParams } from "react-router";
import { Route, selectRoute } from "src/config/routes";
import { v4 as uuidv4 } from "uuid";

interface FeatureAnnouncementContentListProps {
    announcementsMls: announcementsMls[];
}

interface Params {
    announcementid: string;
}

const FeatureAnnouncementContentListBase = (props: FeatureAnnouncementContentListProps) => {
    // const { t } = useTranslation();
    const { uiStore, navStore } = useContext(RootStoreContext);
    const { announcementid } = useParams<Params>();

    let listIdentifier = `featureannouncementcontentlist-${announcementid}`;

    if (!navStore.listMap.has(listIdentifier)) {
        navStore.setListMapValue(listIdentifier, {});
    }

    const listParameters = navStore.getListMapValue(listIdentifier);

    const onRowClick = useCallback(() => {
        navStore.setListMapValue(
            listIdentifier,
            listParameters
                ? { ...listParameters, scrollPosition: window.pageYOffset }
                : { scrollPosition: window.pageYOffset }
        );
    }, [navStore, listParameters, listIdentifier]);

    const renderItem = useCallback(
        (announcementMls: announcementsMls, screenSize: ScreenSize) => {
            let listRows: React.ReactElement[] = [];

            const language = announcementMls.language.charAt(0).toUpperCase() + announcementMls.language.slice(1);

            const titleElements = /<h1>(.*?)<\/h1>/g.exec(announcementMls.content);
            const title = titleElements && titleElements.length > 0 ? titleElements[1] : "";

            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={title} />
                            <UI.List.Cell key={"c-2"} colspan={1} value={language} />
                        </UI.List.Row>
                    ];
                    break;
                case ScreenSize.MOBILE:
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={title} />
                            <UI.List.Cell key={"c-2"} colspan={1} value={language} />
                        </UI.List.Row>
                    ];
                    break;
            }

            const link = `/feature-announcements/${announcementid}/content/${announcementMls.id}`;

            return (
                <UI.List.Item
                    key={announcementMls.id}
                    screenSize={screenSize}
                    rows={listRows}
                    to={link}
                    onClick={onRowClick}
                />
            );
        },
        [announcementid, onRowClick]
    );

    const renderHeader = useCallback((screenSize: ScreenSize) => {
        let headerRows: React.ReactElement[] = [];

        const title = "Titel";
        const language = "Sprache";

        switch (screenSize) {
            case ScreenSize.DESKTOP:
                headerRows = [
                    <UI.List.Row key={"row-1"}>
                        <UI.List.Cell key={"c-1"} colspan={1} value={title} />
                        <UI.List.Cell key={"c-2"} colspan={1} value={language} />
                    </UI.List.Row>
                ];
                break;

            case ScreenSize.MOBILE:
                headerRows = [
                    <UI.List.Row key={"row-1"}>
                        <UI.List.Cell key={"c-1"} colspan={1} value={title} />
                        <UI.List.Cell key={"c-2"} colspan={1} value={language} />
                    </UI.List.Row>
                ];
                break;
        }

        return <UI.List.Header rows={headerRows} sticky={true} top={130} />;
    }, []);

    return (
        <>
            <div>
                <UI.Button
                    label={"+ Inhalte hinzufügen"}
                    to={selectRoute(Route.featureAnnouncementContentDetails, null, {
                        announcementid: announcementid,
                        contentid: uuidv4()
                    })}
                    className={"customer-button mt-2 mt-sm-0 mb-3 ml-auto"}
                    onClick={onRowClick}
                />
            </div>
            <UI.List.BasicList
                screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
                items={props.announcementsMls}
                renderItem={renderItem}
                renderHeader={renderHeader}
                scrollPosition={listParameters?.scrollPosition ?? undefined}
                emptyListMessage="Keine Verbindungen"
            />
        </>
    );
};

export const FeatureAnnouncementContentList = Sentry.withProfiler(observer(FeatureAnnouncementContentListBase));
