import React, { useContext, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { useQuery } from "@apollo/client";
import { GET_SERVICE_CONTENT_DETAILS } from "src/api/service";
import {
    GetServiceContentDetails,
    GetServiceContentDetailsVariables
} from "src/api/generated/GetServiceContentDetails";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { RootStoreContext } from "src/stores/RootStore";
import * as Screens from "src/screens";
import { observer } from "mobx-react-lite";
import { ServiceContentDetailsForm } from "./form/ServiceContentDetailsForm";
import { Route as CustomerRoutes, selectRoute } from "src/config/routes";

interface Params {
    serviceid: string;
    contentid: string;
}

const ServiceContentDetailsBase = () => {
    const { t } = useTranslation();
    const { navStore, uiStore, authStore } = useContext(RootStoreContext);

    const { serviceid, contentid } = useParams<Params>();

    useEffect(() => {
        navStore.setTitle("Service");
        navStore.setBackbutton(
            selectRoute(CustomerRoutes.serviceDetails, null, {
                serviceid
            }),
            "Service"
        );
    }, [navStore, serviceid]);

    const {
        loading,
        error,
        data,
        refetch: refetchServiceContentData
    } = useQuery<GetServiceContentDetails, GetServiceContentDetailsVariables>(GET_SERVICE_CONTENT_DETAILS, {
        variables: {
            contentid
        }
    });

    useEffect(() => {
        if (uiStore.hasNewServiceContentData) {
            refetchServiceContentData();
        }
    }, [refetchServiceContentData, uiStore, uiStore.hasNewServiceContentData]);

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata")} />;
    }

    const userRole = authStore.user ? authStore.user.role : undefined;
    const serviceContentDetails = data.servicecontent.length > 0 ? data.servicecontent[0] : undefined;

    return (
        <ServiceContentDetailsForm
            serviceContentDetails={serviceContentDetails}
            serviceId={serviceid}
            contentId={contentid}
            onUpsertServiceContent={refetchServiceContentData}
            userRole={userRole}
        />
    );
};

export const ServiceContentDetails = Sentry.withProfiler(observer(ServiceContentDetailsBase));
