import React, { useContext, useCallback, FunctionComponent, useState, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { Feature, Partner } from "src/utils/Enums";
import { Role as CustomerRole } from "src/network/User";
import { CustomerDetailsType } from "src/screens/dashboard/customers/details/detail/CustomerDetail";
import { useForm } from "react-hook-form";
import { formatCustomerNumber } from "src/utils/Customer";
import { useMutation } from "@apollo/client";
import { UPDATE_UMS_CUSTOMER_WITH_FEATURES } from "src/api/customer";
import {
    UpdateCustomerWithFeatures,
    UpdateCustomerWithFeaturesVariables
} from "src/api/generated/UpdateCustomerWithFeatures";
import { ums_customerfeatures_insert_input } from "src/api/generated/globalTypes";
import { MessageType } from "src/components/notifications/Notifier";
import { yupResolver } from "@hookform/resolvers/yup";
import { CUSTOMER_SCHEMA } from "src/utils/Schema";
import { NetworkConfig } from "src/network/NetworkConfig";
import { getRoleKey } from "src/network/User";
import { getCustomerHostname } from "src/utils/Customer";
import styles from "./CustomerDetailsForm.module.css";

type CustomerDetailsFormProps = {
    customerDetails: CustomerDetailsType;
    userRole?: CustomerRole;
    setNewCustomerERPCredentials: any;
};

interface CustomerDetailFormFields {
    id: string;
    active: boolean;
    type: number;
    number: string;
    name: string;
    email: string;
    erp: number;
    subdomain: string;
    dns: boolean;
    features: number[];
    remarks: string | null;
    partner: number;
    erpcloud: boolean;
}

const CustomerDetailsFormBase: FunctionComponent<CustomerDetailsFormProps> = (props) => {
    const { t } = useTranslation();

    const [customerIsUpdating, setCustomerIsUpdating] = useState<boolean>(false);

    const { uiStore, authStore } = useContext(RootStoreContext);

    const [isFairwalterPartnerCustomer, setIsFairwalterPartnerCustomer] = useState<boolean>();
    const [isFairwalterErpCustomer, setIsFairwalterErpCustomer] = useState<boolean>();

    const [updateCustomerMutation, { loading: isCustomerMutationLoading }] = useMutation<
        UpdateCustomerWithFeatures,
        UpdateCustomerWithFeaturesVariables
    >(UPDATE_UMS_CUSTOMER_WITH_FEATURES);

    const {
        id,
        active,
        dns,
        email,
        erp,
        features,
        number: customerNumber,
        name: customerName,
        subdomain,
        type,
        remarks,
        partner,
        erpcloud
    } = props.customerDetails;

    const { setNewCustomerERPCredentials } = props;

    const { userRole } = props;

    const { register, handleSubmit, errors, formState, reset, setValue, watch } = useForm<CustomerDetailFormFields>({
        mode: "onBlur",
        resolver: yupResolver(CUSTOMER_SCHEMA),
        defaultValues: {
            id: id,
            active: active,
            type: type,
            number: customerNumber ?? "",
            name: customerName ?? "",
            email: email ?? "",
            erp: erp,
            subdomain: subdomain,
            dns: dns,
            features: features,
            remarks: remarks,
            partner: partner,
            erpcloud: erpcloud
        }
    });

    const { dirtyFields } = formState;
    const watchFeatures = watch("features");
    const watchPartner = watch("partner");

    useEffect(() => {
        const selectedPartner = watchPartner;

        if (Number(selectedPartner) === Partner.FAIRWALTER) {
            setIsFairwalterPartnerCustomer(true);
            setIsFairwalterErpCustomer(true);
        } else {
            setIsFairwalterPartnerCustomer(false);
            setIsFairwalterErpCustomer(false);
        }
    }, [watchPartner]);

    const resetForm = useCallback(() => {
        const {
            id,
            active,
            dns,
            email,
            erp,
            features,
            number: customerNumber,
            name: customerName,
            subdomain,
            type,
            remarks,
            partner,
            erpcloud
        } = props.customerDetails;

        reset({
            id: id,
            active: active,
            type: type,
            number: customerNumber ?? "",
            name: customerName ?? "",
            email: email ? email : "",
            erp: erp,
            subdomain: subdomain,
            dns: dns,
            features: features,
            remarks: remarks,
            partner: partner,
            erpcloud: erpcloud
        });
    }, [reset, props.customerDetails]);

    const onFeatureCheckboxChanged = useCallback(
        (e: any) => {
            const clickedCheckboxFeatureNumber = Number(e.target.id.split("-")[1]);
            const isEasyContactFeature = clickedCheckboxFeatureNumber === Feature.EasyContact;
            const isPortalOwnerManagerFeature =
                clickedCheckboxFeatureNumber === Feature["Portal für Bewirtschafter"] ||
                clickedCheckboxFeatureNumber === Feature["Portal für Eigentümer"];
            const checkboxWasDeactivated = !JSON.parse(e.target.checked);

            if (isEasyContactFeature && checkboxWasDeactivated) {
                for (const [key, isSelected] of Object.entries(watchFeatures)) {
                    const featureNumber = Number(key);
                    if (featureNumber === Feature["Service 7000"] && isSelected) {
                        setValue(`features.${Feature["Service 7000"]}`, false);
                    }
                    if (featureNumber === Feature["Yarowa"] && isSelected) {
                        setValue(`features.${Feature["Yarowa"]}`, false);
                    }
                }
            } else if (isPortalOwnerManagerFeature && !checkboxWasDeactivated) {
                setValue(`features.${Feature.Stockwerkeigentümerportal}`, true);
                setValue(`features.${Feature.Mieterportal}`, true);
            }
        },
        [watchFeatures, setValue]
    );

    const renderFeatureCheckboxes = useCallback(() => {
        const isPortalOwnerAndManagerFeatureDeactivated =
            !watchFeatures[Feature["Portal für Bewirtschafter"]] && !watchFeatures[Feature["Portal für Eigentümer"]];
        if (
            isPortalOwnerAndManagerFeatureDeactivated &&
            watchFeatures[Feature.Stockwerkeigentümerportal] &&
            watchFeatures[Feature.Mieterportal]
        ) {
            setValue(`features.${Feature.Stockwerkeigentümerportal}`, false);
            setValue(`features.${Feature.Mieterportal}`, false);
        }

        const featureComponents = [];

        let isEasyContactCheckboxSelected: any = false;

        for (const [key, isSelected] of Object.entries(watchFeatures)) {
            const featureNumber = Number(key);
            if (featureNumber === Feature.EasyContact) {
                isEasyContactCheckboxSelected = isSelected;
            }
        }

        for (const featureNumberStr in Feature) {
            if (isNaN(Number(featureNumberStr))) {
                continue;
            }
            const featureName = Feature[featureNumberStr];
            const featureNumber = Number(featureNumberStr);

            const isFeatureIncluded = features.includes(featureNumber) ? true : false;

            let isDirty = false;
            if (dirtyFields.features) {
                isDirty = dirtyFields.features[featureNumber] ? true : false;
            }

            const isDisabled =
                (featureNumber === Feature["Service 7000"] && !isEasyContactCheckboxSelected) ||
                (featureNumber === Feature["Yarowa"] && !isEasyContactCheckboxSelected);

            if (isFairwalterErpCustomer) {
                if (featureNumber === Feature.Abnahme) {
                    featureComponents.push(
                        <UI.Checkbox
                            key={featureNumber}
                            ref={register}
                            name="features"
                            label={featureName}
                            checkboxValue={featureNumber}
                            defaultChecked={isFeatureIncluded}
                            className={isDirty ? styles.changed : ""}
                            disabled={(userRole && userRole === CustomerRole.CUSTOMERSUPPORT) || isDisabled}
                            onChange={onFeatureCheckboxChanged}
                        />
                    );
                }
            } else {
                featureComponents.push(
                    <UI.Checkbox
                        key={featureNumber}
                        ref={register}
                        name="features"
                        label={featureName}
                        checkboxValue={featureNumber}
                        defaultChecked={isFeatureIncluded}
                        className={isDirty ? styles.changed : ""}
                        disabled={(userRole && userRole === CustomerRole.CUSTOMERSUPPORT) || isDisabled}
                        onChange={onFeatureCheckboxChanged}
                    />
                );
            }
        }

        return <>{featureComponents}</>;
    }, [
        dirtyFields.features,
        features,
        onFeatureCheckboxChanged,
        register,
        userRole,
        watchFeatures,
        setValue,
        isFairwalterErpCustomer
    ]);

    const getFeaturesToUpdate = useCallback(
        (
            originalFeatures: number[],
            currentFeatures: number[]
        ): {
            customerFeaturesToAdd: ums_customerfeatures_insert_input[] | [];
            customerFeaturesToRemove: number[] | [];
        } => {
            if (dirtyFields.features) {
                const currentCustomerFeatures: number[] = [];

                for (const [key, value] of Object.entries(currentFeatures)) {
                    if (value) {
                        currentCustomerFeatures.push(parseInt(key));
                    }
                }

                const customerFeaturesToRemove = originalFeatures.filter(
                    (feature) => !currentCustomerFeatures.includes(feature)
                );

                const customerFeaturesToAdd = currentCustomerFeatures
                    .filter((feature) => !originalFeatures.includes(feature))
                    .map((feature) => {
                        return { customerid: id, feature: feature };
                    });

                return { customerFeaturesToAdd, customerFeaturesToRemove };
            } else {
                return { customerFeaturesToAdd: [], customerFeaturesToRemove: [] };
            }
        },
        [dirtyFields.features, id]
    );

    const getFieldsToUpdate = useCallback((formData: CustomerDetailFormFields) => {
        const fieldsToUpdate: any = { ...formData };

        delete fieldsToUpdate.features;

        const formattedCustomerNumber = formatCustomerNumber(String(formData.number));
        fieldsToUpdate.number = formattedCustomerNumber;
        formData["number"] = formattedCustomerNumber;

        return fieldsToUpdate;
    }, []);

    const callUpdateCustomerCssFunction = useCallback(
        async (customerName: string, subdomain: string) => {
            if (authStore.user && authStore.token) {
                const accessToken = authStore.token;
                const tokenType = authStore.tokenType;
                const role = authStore.user?.role;

                const requestBody = {
                    customername: customerName
                };

                try {
                    const fetchResult = await fetch(`${NetworkConfig.updatecustomercssinfo}/${subdomain}`, {
                        method: "PUT",
                        body: JSON.stringify(requestBody),
                        headers: {
                            Authorization: `${tokenType} ${accessToken}`,
                            "Content-Type": "application/json",
                            "x-hasura-role": getRoleKey(role)
                        }
                    });

                    if (fetchResult.status !== 200) {
                        uiStore.printStatusMessage(
                            "Beim Aktualisieren der CSS Datei ist ein Fehler aufgetreten.",
                            MessageType.ERROR
                        );
                    }
                } catch (fetchError) {
                    uiStore.printStatusMessage(
                        `Beim Aktualisieren der CSS Datei ist ein Fehler aufgetreten: ${fetchError.error}`,
                        MessageType.ERROR
                    );
                }
            }
        },
        [authStore.token, authStore.tokenType, authStore.user, uiStore]
    );

    const getERPTypesByPartner = useCallback((): JSX.Element[] => {
        let erpTypes: JSX.Element[] = [];

        if (isFairwalterPartnerCustomer) {
            const fairwalterOptionElement = (
                <option key="fairwalter" value={5}>
                    Fairwalter
                </option>
            );
            erpTypes.push(fairwalterOptionElement);
        } else {
            const rimor5OptionElement = (
                <option key="rimor5" value={1}>
                    Rimo R5
                </option>
            );
            const immotop2OptionElement = (
                <option key="immotop2" value={2}>
                    ImmoTop2
                </option>
            );
            erpTypes.push(rimor5OptionElement);
            erpTypes.push(immotop2OptionElement);
        }
        return erpTypes;
    }, [isFairwalterPartnerCustomer]);

    const activateDns = useCallback(async () => {
        if (id && subdomain) {
            if (authStore.user && authStore.token) {
                const accessToken = authStore.token;
                const tokenType = authStore.tokenType;
                const role = authStore.user?.role;

                const requestBody = {
                    customerid: id,
                    subdomain: subdomain
                };

                try {
                    const fetchResult = await fetch(NetworkConfig.activateDnsUrl, {
                        method: "POST",
                        body: JSON.stringify(requestBody),
                        headers: {
                            Authorization: `${tokenType} ${accessToken}`,
                            "x-hasura-role": getRoleKey(role)
                        }
                    });

                    if (fetchResult.status === 200) {
                        const hostname = getCustomerHostname(subdomain, partner, true);

                        setNewCustomerERPCredentials({
                            hostname: hostname,
                            password: "gleich wie bisher",
                            username: `erp@${subdomain}.wwportal.ch`,
                            title: "DNS wurde erfolgreich aktiviert"
                        });
                    } else {
                        const response = await fetchResult.json();
                        uiStore.printStatusMessage(
                            `Fehler bei der DNS-Aktivierung: ${response.message}`,
                            MessageType.ERROR
                        );
                    }
                } catch (fetchError) {
                    uiStore.printStatusMessage(`Fehler bei der DNS-Aktivierung:: ${fetchError}`, MessageType.ERROR);
                }
            } else {
                uiStore.printStatusMessage(
                    "Keine Authorisierungs-Informationen vorhanden. Bitte lade die Seite neu.",
                    MessageType.ERROR
                );
            }

            uiStore.setHasNewCustomerData(true);
        }
    }, [
        uiStore,
        id,
        subdomain,
        partner,
        authStore.token,
        authStore.tokenType,
        authStore.user,
        setNewCustomerERPCredentials
    ]);

    const handleFormSubmit = useCallback(
        async (formData: CustomerDetailFormFields) => {
            setCustomerIsUpdating(true);

            const { customerFeaturesToAdd, customerFeaturesToRemove } = getFeaturesToUpdate(
                features,
                formData.features
            );

            const fieldsToUpdate = getFieldsToUpdate(formData);

            const hasActivatedPortalFeatures = customerFeaturesToAdd.find((customerFeature) => {
                if (customerFeature && customerFeature.feature) {
                    return customerFeature.feature >= 30 && customerFeature?.feature <= 40;
                }

                return false;
            });

            if (dirtyFields.name || hasActivatedPortalFeatures) {
                await callUpdateCustomerCssFunction(formData.name, formData.subdomain);
            }

            const hasActivatedEmonitorFeature = customerFeaturesToAdd.find((customerFeature) => {
                if (customerFeature && customerFeature.feature) {
                    return customerFeature.feature === Feature["Vermietungsprozess mit emonitor"];
                }

                return false;
            });

            try {
                await updateCustomerMutation({
                    variables: {
                        customerid: id,
                        updatedFields: fieldsToUpdate,
                        customerFeaturesToAdd: customerFeaturesToAdd,
                        customerFeaturesToRemove: customerFeaturesToRemove
                    }
                });

                if (!props.customerDetails.dns && hasActivatedEmonitorFeature) {
                    uiStore.printStatusMessage("Aktiviere DNS", MessageType.INFO);
                    await activateDns();
                }

                reset(
                    { ...formData },
                    {
                        isDirty: false,
                        dirtyFields: false
                    }
                );

                uiStore.printStatusMessage("Kunde erfolgreich gespeichert", MessageType.INFO);
            } catch (error) {
                uiStore.printStatusMessage(`Fehler beim Updaten des Kunden: ${error.message}`, MessageType.ERROR);
            } finally {
                setCustomerIsUpdating(false);
            }
        },
        [
            features,
            id,
            reset,
            updateCustomerMutation,
            getFeaturesToUpdate,
            getFieldsToUpdate,
            uiStore,
            dirtyFields,
            callUpdateCustomerCssFunction,
            props.customerDetails.dns,
            activateDns
        ]
    );

    setValue("dns", props.customerDetails.dns);

    return (
        <UI.Form>
            <UI.Form.Group>
                <UI.Form.Label>{t("screens.customer_details.form.id")}</UI.Form.Label>
                <UI.InputGroup>
                    <UI.Form.Control
                        ref={register}
                        name="id"
                        as="input"
                        type="text"
                        readOnly={true}
                        disabled={userRole === CustomerRole.CUSTOMERSUPPORT}
                    />
                </UI.InputGroup>
            </UI.Form.Group>
            <UI.Input
                label={t("screens.customer_details.form.status")}
                ref={register}
                name="active"
                as="select"
                className={dirtyFields.active ? styles.changed : ""}
                disabled={userRole === CustomerRole.CUSTOMERSUPPORT}
            >
                <option value="true">{t("customer.status.active")}</option>
                <option value="false">{t("customer.status.blocked")}</option>
            </UI.Input>
            <UI.Input
                label={t("screens.customer_details.form.type")}
                ref={register}
                name="type"
                as="select"
                className={dirtyFields.type ? styles.changed : ""}
                disabled={userRole === CustomerRole.CUSTOMERSUPPORT}
            >
                <option value="1">Prod</option>
                <option value="2">Demo</option>
                <option value="3">Test</option>
            </UI.Input>
            <UI.Input
                label={t("screens.customer_details.form.partner")}
                ref={register}
                name="partner"
                as="select"
                defaultValue={partner}
                className={dirtyFields.partner ? styles.changed : ""}
                disabled={userRole === CustomerRole.CUSTOMERSUPPORT}
            >
                <option value="1">W&W Immo Informatik AG</option>
                <option value="5">Fairwalter</option>
                <option value="9">Extenso</option>
            </UI.Input>
            <UI.Input
                label={t("screens.customer_details.form.customer_number")}
                ref={register}
                name="number"
                type="text"
                className={dirtyFields.number ? styles.changed : ""}
                errorMsg={errors.number ? errors.number.message : undefined}
                disabled={userRole === CustomerRole.CUSTOMERSUPPORT}
            />
            <UI.Input
                label={t("screens.customer_details.form.customer_name")}
                ref={register}
                name="name"
                type="text"
                className={dirtyFields.name ? styles.changed : ""}
                errorMsg={errors.name ? errors.name.message : undefined}
                disabled={userRole === CustomerRole.CUSTOMERSUPPORT}
            />
            <UI.Input
                label={t("screens.customer_details.form.email_address")}
                ref={register}
                name="email"
                type="email"
                className={dirtyFields.email ? styles.changed : ""}
                errorMsg={errors.email ? errors.email.message : undefined}
                disabled={userRole === CustomerRole.CUSTOMERSUPPORT}
            />
            <UI.Input
                label={t("screens.customer_details.form.erp")}
                ref={register}
                name="erp"
                as="select"
                className={dirtyFields.erp ? styles.changed : ""}
                disabled={userRole === CustomerRole.CUSTOMERSUPPORT}
            >
                {getERPTypesByPartner()}
            </UI.Input>
            {userRole ? (
                userRole === CustomerRole.CUSTOMERDEV ||
                userRole === CustomerRole.CUSTOMERADMIN ||
                userRole === CustomerRole.CUSTOMERSUPPORT ? (
                    <UI.Checkbox
                        className={"mb-3"}
                        ref={register}
                        name="erpcloud"
                        label={t("screens.customer_details.form.erpcloud")}
                        disabled={userRole === CustomerRole.CUSTOMERSUPPORT}
                    />
                ) : undefined
            ) : undefined}
            <UI.Input id="features" type="checkbox" label={t("screens.customer_details.form.features")}>
                {renderFeatureCheckboxes()}
            </UI.Input>
            <UI.Form.Group>
                <UI.Form.Label>{t("screens.customer_details.form.dns")}</UI.Form.Label>
                <UI.InputGroup>
                    <UI.Form.Check>
                        <UI.Form.Check.Input
                            ref={register}
                            name="dns"
                            id="dns"
                            type="checkbox"
                            className={dirtyFields.dns ? styles.changed : ""}
                            disabled={true}
                            defaultChecked={props.customerDetails.dns}
                        />
                        <UI.Form.Check.Label htmlFor={"dns"}>Konfiguriert</UI.Form.Check.Label>
                    </UI.Form.Check>
                </UI.InputGroup>
            </UI.Form.Group>
            <UI.Form.Group>
                <UI.Form.Label>{t("screens.customer_details.form.subdomain")}</UI.Form.Label>
                <UI.InputGroup>
                    <UI.Form.Control
                        ref={register}
                        name="subdomain"
                        as="input"
                        type="text"
                        readOnly={true}
                        disabled={userRole === CustomerRole.CUSTOMERSUPPORT}
                    />
                    <UI.InputGroup.Text className="bg-white" id="subdomain-addon">
                        .wwportal.ch
                    </UI.InputGroup.Text>
                </UI.InputGroup>
            </UI.Form.Group>
            <UI.Form.Group controlId="remarks" className="mb-4">
                <UI.Form.Label>{t("screens.customer_details.form.remarks")}</UI.Form.Label>
                <UI.InputGroup>
                    <UI.Form.Control
                        ref={register}
                        name="remarks"
                        as="textarea"
                        type="input"
                        className={dirtyFields.remarks ? styles.changed : ""}
                        disabled={userRole === CustomerRole.CUSTOMERSUPPORT}
                    />
                    {errors.remarks && (
                        <UI.Form.Control.Feedback className="d-block" type="invalid">
                            {errors.remarks}
                        </UI.Form.Control.Feedback>
                    )}
                </UI.InputGroup>
            </UI.Form.Group>

            <UI.Button
                type="submit"
                label={isCustomerMutationLoading || customerIsUpdating ? "Speichert..." : "Speichern"}
                disabled={!formState.isDirty || isCustomerMutationLoading || customerIsUpdating}
                onClick={handleSubmit(handleFormSubmit)}
                className={`${styles.customerFormBtn} customer-button`}
            />
            <UI.Button
                label={"Zurücksetzen"}
                disabled={!formState.isDirty || isCustomerMutationLoading || customerIsUpdating}
                onClick={resetForm}
                className={`${styles.customerFormBtn} customer-button ml-sm-2 mt-2 mt-sm-0`}
            />
            {props.children}
        </UI.Form>
    );
};

export const CustomerDetailsForm = Sentry.withProfiler(CustomerDetailsFormBase);
