import React, { useState, useEffect, useContext, useCallback } from "react";
import { UI } from "@wwimmo/ui";
import { RootStoreContext } from "src/stores/RootStore";
import { NetworkConfig } from "src/network/NetworkConfig";
import { getRoleKey } from "src/network/User";
import {
    GetUmsCustomerDetailsById_ums_customer_details_adminuser,
    GetUmsCustomerDetailsById_userroles
} from "src/api/generated/GetUmsCustomerDetailsById";
import { useForm } from "react-hook-form";
import { MessageType } from "src/components/notifications/Notifier";
import styles from "./CustomerAdminuserInfo.module.css";

interface CustomerAdminuserInfoProps {
    adminuser: GetUmsCustomerDetailsById_ums_customer_details_adminuser | undefined;
    userroleEnumerations: GetUmsCustomerDetailsById_userroles[];
    customerId?: string;
}

interface AdminRolesFields {
    adminroles: number[];
}
const CustomerAdminuserInfo = (props: CustomerAdminuserInfoProps) => {
    const { adminuser, userroleEnumerations, customerId } = props;
    const { register, handleSubmit, reset } = useForm<AdminRolesFields>();

    const { authStore, uiStore } = useContext(RootStoreContext);
    const [copyButton, setCopyButton] = useState("Kopieren");
    const [isChangingAdminState, setIsChangingAdminState] = useState(false);
    const [password, setPassword] = useState<string | null>();
    const [warningMessage, setWarningMessage] = useState<string>("");

    const [umsUserroles, setUmsUserroles] = useState<Map<number | null, { label: string }>>();
    const [adminUserroles, setAdminUserroles] = useState<Array<Number>>();
    const [isAdminActivated, setAdminActivated] = useState<boolean>(adminuser ? adminuser.registered : false);

    useEffect(() => {
        if (userroleEnumerations) {
            setUmsUserroles(getEnumerationMap(userroleEnumerations));
        }
    }, [userroleEnumerations]);

    useEffect(() => {
        if (adminuser?.userroles) {
            const adminUserRoles: Array<Number> = [];
            for (const adminUserRole of adminuser.userroles) {
                adminUserRoles.push(Number(adminUserRole.role));
            }
            setAdminUserroles(adminUserRoles);
        }
    }, [adminuser?.userroles]);

    const getEnumerationMap = (enumerations: Array<GetUmsCustomerDetailsById_userroles>) => {
        const enumerationMap = new Map();

        enumerations.forEach((enumeration) => {
            if (enumeration.key !== null) {
                enumerationMap.set(enumeration.key, {
                    label: enumeration.label
                });
            }
        });

        return enumerationMap;
    };

    const copyPasswordToClipboard = useCallback(() => {
        const elem = document.createElement("textarea");
        elem.value = password ?? "";
        document.body.appendChild(elem);
        elem.select();
        document.execCommand("copy");
        document.body.removeChild(elem);

        setCopyButton("In Zwischenablage gespeichert");

        setTimeout(() => {
            setCopyButton("Kopieren");
        }, 3000);
    }, [password]);

    const activateAdmin = useCallback(
        async (formData: AdminRolesFields) => {
            setIsChangingAdminState(true);

            const rolesToActivate: number[] = [];

            for (const [key, value] of Object.entries(formData.adminroles)) {
                const roleNumber = key;
                const isRoleSelected = value;

                if (isRoleSelected) {
                    rolesToActivate.push(Number(roleNumber));
                }
            }

            if (rolesToActivate.length === 0) {
                setWarningMessage("Du musst mindestens eine Rolle auswählen");
                setIsChangingAdminState(false);
                return;
            } else {
                setWarningMessage("");
            }

            if (authStore.user) {
                const accessToken = authStore.token;
                const tokenType = "Bearer";
                const role = authStore.user?.role;

                const requestBody = {
                    customerid: customerId,
                    roles: rolesToActivate
                };

                try {
                    const fetchResult = await fetch(`${NetworkConfig.activateAdminUrl}`, {
                        method: "POST",
                        body: JSON.stringify(requestBody),
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `${tokenType} ${accessToken}`,
                            "x-hasura-role": getRoleKey(role)
                        }
                    });

                    if (fetchResult.status === 200) {
                        try {
                            const returnedData = await fetchResult.json();
                            const { password } = returnedData.data;
                            setPassword(password);
                        } catch (jsonError) {
                            console.error("jsonError: ", jsonError);
                        } finally {
                            setAdminActivated(true);
                            uiStore.printStatusMessage(
                                "Der Adminbenutzer wurde erfolgreich aktiviert",
                                MessageType.INFO
                            );
                        }
                    } else {
                        const returnValue = await fetchResult.json();
                        uiStore.printStatusMessage(
                            `Es ist ein Fehler aufgetreten: ${returnValue.message}`,
                            MessageType.ERROR
                        );
                    }
                } catch (fetchError) {
                    uiStore.printStatusMessage(`Es ist ein Fehler aufgetreten: ${fetchError.error}`, MessageType.ERROR);
                }
            }
            setIsChangingAdminState(false);
        },
        [authStore.user, authStore.token, uiStore, customerId]
    );

    const deactivateAdmin = useCallback(async () => {
        setIsChangingAdminState(true);

        if (authStore.user) {
            const accessToken = authStore.token;
            const tokenType = "Bearer";
            const role = authStore.user?.role;

            const requestBody = {
                customerid: customerId
            };

            try {
                const fetchResult = await fetch(`${NetworkConfig.deactivateAdminUrl}`, {
                    method: "POST",
                    body: JSON.stringify(requestBody),
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `${tokenType} ${accessToken}`,
                        "x-hasura-role": getRoleKey(role)
                    }
                });

                if (fetchResult.status === 200) {
                    uiStore.printStatusMessage("Der Adminbenutzer wurde erfolgreich deaktiviert", MessageType.INFO);
                    setAdminActivated(false);
                    setPassword("");
                    reset({
                        adminroles: []
                    });
                } else {
                    const returnValue = await fetchResult.json();
                    uiStore.printStatusMessage(
                        `Es ist ein Fehler aufgetreten: ${returnValue.message}`,
                        MessageType.ERROR
                    );
                }
            } catch (fetchError) {
                uiStore.printStatusMessage(`Es ist ein Fehler aufgetreten: ${fetchError.error}`, MessageType.ERROR);
            }
        }
        setWarningMessage("");
        setIsChangingAdminState(false);
    }, [authStore.user, authStore.token, uiStore, reset, customerId]);

    const renderRolesCheckboxes = useCallback(() => {
        const userroleComponents: any = [];

        if (umsUserroles) {
            umsUserroles.forEach(({ label }, key) => {
                const roleName = label;
                const roleNumber = key;

                let hasRole = false;

                if (adminUserroles && roleNumber) {
                    hasRole = adminUserroles.includes(roleNumber) ? true : false;
                }

                userroleComponents.push(
                    <UI.Checkbox
                        key={roleNumber}
                        ref={register}
                        name="adminroles"
                        label={roleName}
                        checkboxValue={roleNumber ?? 0}
                        defaultChecked={hasRole}
                        disabled={isAdminActivated}
                    />
                );
            });
        }

        return <>{userroleComponents}</>;
    }, [adminUserroles, register, umsUserroles, isAdminActivated]);

    return (
        <div>
            <div className={styles.infoBlock}>
                <p className={styles.infoHeading}>Benutzername Administrator</p>
                <p className={styles.infoText}>{adminuser?.name1}</p>
            </div>
            <div className={styles.infoBlock}>
                <div>
                    <p className={`mb-1 ${styles.infoHeading}`}>Zugewiesene Rollen</p>
                    {renderRolesCheckboxes()}
                    <div className="mt-2">
                        {isAdminActivated ? (
                            <UI.Button
                                label="Admin deaktivieren"
                                onClick={deactivateAdmin}
                                disabled={isChangingAdminState}
                                className="customer-button w-100"
                            />
                        ) : (
                            <UI.Button
                                label="Admin aktivieren"
                                onClick={handleSubmit(activateAdmin)}
                                disabled={isChangingAdminState}
                                className="customer-button w-100"
                            />
                        )}
                    </div>
                </div>
                <div>
                    {isChangingAdminState ? (
                        <UI.RotatingSpinner noLogo={true} size={30} className={"align-items-center mt-3"} />
                    ) : password ? (
                        <div className="mt-2">
                            <p className={styles.infoHeading}>Neues Passwort:</p>
                            <p className={styles.infoText}>{password}</p>
                            <div className={`${styles.copyBlock} d-flex`}>
                                <UI.Icon
                                    icon={UI.SVGIcon.Documents}
                                    style={{ cursor: "pointer" }}
                                    onClick={copyPasswordToClipboard}
                                />
                                <div>
                                    <span>{copyButton}</span>
                                </div>
                            </div>
                        </div>
                    ) : undefined}
                </div>
                <p className={`${styles.warningText} mt-2`}>{warningMessage}</p>
            </div>
        </div>
    );
};

export default CustomerAdminuserInfo;
