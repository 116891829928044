import React, { useState, useEffect, useContext, useCallback } from "react";
import { UI } from "@wwimmo/ui";
import { RootStoreContext } from "src/stores/RootStore";
import { NetworkConfig } from "src/network/NetworkConfig";
import { getRoleKey } from "src/network/User";
import classes from "./CustomerLoginInfo.module.css";

interface CustomerLoginInfoProps {
    hostname: string;
    username: string;
    password?: string;
    passwordHidden: boolean;
    customerId?: string;
}

const CustomerLoginInfo = (props: CustomerLoginInfoProps) => {
    const { authStore, uiStore } = useContext(RootStoreContext);
    const [copyButton, setCopyButton] = useState("Kopieren");
    const [isGettingPassword, setIsGettingPassword] = useState(false);
    const [password, setPassword] = useState<string | null>("***********************");
    const [hasReceivedPassword, setHasReceivedPasswort] = useState<boolean>(false);
    const [isGeneratePasswordModalDisplaying, setIsGeneratePasswordModalDisplaying] = useState(false);

    useEffect(() => {
        if (!props.passwordHidden && props.password) {
            setPassword(props.password);
        }
    }, [props]);

    const copyToClipboard = useCallback(() => {
        const elem = document.createElement("textarea");
        const { username, hostname } = props;
        elem.value = `Benutzer=${username}\nPasswort=${password}\nHost=${hostname}`;
        document.body.appendChild(elem);
        elem.select();
        document.execCommand("copy");
        document.body.removeChild(elem);

        setCopyButton("In Zwischenablage gespeichert");

        setTimeout(() => {
            setCopyButton("Kopieren");
        }, 3000);
    }, [password, props]);

    const showErrorNotification = useCallback(
        (errorMsg: string) => {
            uiStore.enqueueSnackbar({
                content: errorMsg,
                options: {
                    type: "error",
                    autoClose: false
                }
            });
        },
        [uiStore]
    );

    const getPassword = useCallback(async () => {
        setIsGeneratePasswordModalDisplaying(false);

        setIsGettingPassword(true);

        if (authStore.user) {
            const accessToken = authStore.token;
            const tokenType = "Bearer";
            const role = authStore.user?.role;

            try {
                const fetchResult = await fetch(`${NetworkConfig.generateNewERPPasswordUrl}/${props.customerId}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `${tokenType} ${accessToken}`,
                        "x-hasura-role": getRoleKey(role)
                    }
                });

                if (fetchResult.status === 200) {
                    try {
                        const returnedData = await fetchResult.json();
                        const { password } = returnedData.data;
                        setPassword(password);
                        setHasReceivedPasswort(true);
                    } catch (jsonError) {
                        console.error("jsonError: ", jsonError);
                    }
                } else {
                    const returnValue = await fetchResult.json();
                    showErrorNotification(returnValue.message);
                }
            } catch (fetchError) {
                showErrorNotification(fetchError.error);
            }
        }

        setIsGettingPassword(false);
    }, [
        authStore.user,
        authStore.token,
        props.customerId,
        showErrorNotification,
        setIsGeneratePasswordModalDisplaying
    ]);

    const onClickGeneratePasswordButton = useCallback(async () => {
        setIsGeneratePasswordModalDisplaying(true);
    }, [setIsGeneratePasswordModalDisplaying]);

    const toggleGeneratePasswordModal = useCallback(() => setIsGeneratePasswordModalDisplaying(false), []);

    const GeneratePasswordButton = (props: any) => {
        return (
            <div>
                <UI.Button
                    label="Neues Passwort generieren"
                    onClick={onClickGeneratePasswordButton}
                    hidden={!props.passwordHidden || !props.customerId}
                    disabled={!props.passwordHidden || isGettingPassword}
                    className="customer-button w-100"
                />

                <UI.Modal
                    backdrop={"static"}
                    title={"Neues Passwort generieren"}
                    show={isGeneratePasswordModalDisplaying}
                    onClose={toggleGeneratePasswordModal}
                >
                    <div>
                        <p>Wollen Sie wirklich das Passwort für den ERP-Benutzer neu generieren?"</p>
                        <UI.Button
                            label={"Ja"}
                            disabled={false}
                            onClick={getPassword}
                            className={`${classes.modalBtn} mr-2 customer-button`}
                            variant="danger"
                        />
                    </div>
                </UI.Modal>
            </div>
        );
    };

    return (
        <div>
            <div className={classes.infoBlock}>
                <p className={classes.infoHeading}>Hostname</p>
                <p className={classes.infoText}>{props.hostname}</p>
            </div>
            <div className={classes.infoBlock}>
                <p className={classes.infoHeading}>Benutzername</p>
                <p className={classes.infoText}>{props.username}</p>
            </div>
            <div className={classes.infoBlock}>
                <p className={classes.infoHeading}>Passwort</p>
                <div className={classes.passwordValue}>
                    {props.passwordHidden ? (
                        isGettingPassword ? (
                            <UI.RotatingSpinner noLogo={true} size={20} className={"align-items-start"} />
                        ) : (
                            <p className={classes.infoText}>{password}</p>
                        )
                    ) : (
                        <p className={classes.infoText}>{password}</p>
                    )}
                </div>
                <GeneratePasswordButton customerId={props.customerId} passwordHidden={props.passwordHidden} />
            </div>
            <div>
                {hasReceivedPassword || !props.passwordHidden ? (
                    <div className={`${classes.copyBlock} d-flex`}>
                        <UI.Icon icon={UI.SVGIcon.Documents} style={{ cursor: "pointer" }} onClick={copyToClipboard} />
                        <div>
                            <span>{copyButton}</span>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

CustomerLoginInfo.defaultProps = {
    passwordHidden: false
};

export default CustomerLoginInfo;
