import { action, computed, makeObservable, observable } from "mobx";
import { RootStore } from "src/stores/RootStore";

export enum IMPORT_USERS_PROCESS {
    START_IMPORT_USERS,
    IMPORT_USERS_RESULT
}

export enum IMPORT_USERS_PROCESS_STEP {
    START_IMPORT_USERS,
    IMPORT_USERS_RESULT
}

export enum IMPORT_USERS_STATUS {
    NONE,
    ACTIVATING,
    SUCCESS,
    FAILURE
}

export interface IInviteUserForm {
    firstname: string;
    lastname: string;
    email: string;
    erpAdmin: boolean;
}

interface IProcessStep {
    stepNumber: number;
    stepType: IMPORT_USERS_PROCESS_STEP;
    next?: () => IProcessStep | null;
    prev?: () => IProcessStep | null;
    parent: IProcess | null;
}

interface IProcess {
    type: IMPORT_USERS_PROCESS;
    processSteps: IProcessStep[];
}

interface IInviteUserProcess {
    stepNumber: number;
    stepType: IMPORT_USERS_PROCESS_STEP;
    processes: IProcess[];
}

export class InviteUserProgressModalStore {
    rootStore: RootStore;

    IMPORT_USERS_MODAL_MAX_NUMBER_OF_STEPS = 2;

    currentProcessType: IMPORT_USERS_PROCESS = IMPORT_USERS_PROCESS.START_IMPORT_USERS;
    currentStepType: IMPORT_USERS_PROCESS_STEP = IMPORT_USERS_PROCESS_STEP.START_IMPORT_USERS;
    currentInviteUserStatus: IMPORT_USERS_STATUS = IMPORT_USERS_STATUS.NONE;
    isInviteUserProgressModalDisplayed = false;
    isInviteUserSuccessfullySend = false;
    usersAllowedToImport = false;
    userimportprocessesId = "";
    customerId = "";
    errorMsgUpdateUserImportDb = "";

    InviteUserProcess: IInviteUserProcess = {
        stepNumber: 1,
        stepType: IMPORT_USERS_PROCESS_STEP.START_IMPORT_USERS,
        processes: [
            {
                type: IMPORT_USERS_PROCESS.IMPORT_USERS_RESULT,
                processSteps: [
                    {
                        stepNumber: 2,
                        stepType: IMPORT_USERS_PROCESS_STEP.IMPORT_USERS_RESULT,
                        next: function () {
                            return this.parent ? this.parent.processSteps[1] : null;
                        },
                        prev: function () {
                            return this.parent ? this.parent.processSteps[0] : null;
                        },
                        parent: null
                    }
                ]
            }
        ]
    };

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        // Assign parents post object creation
        for (let process of this.InviteUserProcess.processes) {
            for (let step of process.processSteps) {
                step.parent = process;
            }
        }

        makeObservable(this, {
            currentProcessType: observable,
            currentStepType: observable,
            currentInviteUserStatus: observable,
            isInviteUserProgressModalDisplayed: observable,
            isInviteUserSuccessfullySend: observable,
            usersAllowedToImport: observable,
            userimportprocessesId: observable,
            customerId: observable,
            errorMsgUpdateUserImportDb: observable,

            getProcess: action,
            resetInviteUserModal: action,
            resetCurrentProcessType: action,
            setCurrentProcessType: action,
            setCurrentStepType: action,
            setCurrentInviteUserStatus: action,
            setisInviteUserProgressModalDisplayed: action,
            setisInviteUserSuccessfullySend: action,
            setUserimportprocessesId: action,
            setCustomerId: action,
            setUsersAllowedToImport: action,
            setErrorMsgUpdateUserImportDb: action,

            processes: computed,
            currentProcess: computed,
            currentStep: computed
        });
    }

    getProcess = (processType: IMPORT_USERS_PROCESS) => {
        return this.InviteUserProcess.processes.find((process) => {
            return process.type === processType;
        })!;
    };

    resetInviteUserModal = () => {
        this.resetCurrentProcessType();
        this.currentInviteUserStatus = IMPORT_USERS_STATUS.NONE;
    };

    resetCurrentProcessType() {
        this.currentProcessType = IMPORT_USERS_PROCESS.IMPORT_USERS_RESULT;
        this.currentStepType = IMPORT_USERS_PROCESS_STEP.IMPORT_USERS_RESULT;
    }

    setCurrentProcessType = (processType: IMPORT_USERS_PROCESS) => {
        this.currentProcessType = processType;
        this.currentStepType = this.getProcess(processType).processSteps[0].stepType;
    };

    setCurrentStepType = (stepType: IMPORT_USERS_PROCESS_STEP) => {
        this.currentStepType = stepType;
    };

    setisInviteUserProgressModalDisplayed = (isModalDisplayed: boolean) => {
        this.isInviteUserProgressModalDisplayed = isModalDisplayed;
    };

    setCurrentInviteUserStatus = (status: IMPORT_USERS_STATUS) => {
        this.currentInviteUserStatus = status;
    };
    setisInviteUserSuccessfullySend = (isInviteUserSuccessfullySend: boolean) => {
        this.isInviteUserSuccessfullySend = isInviteUserSuccessfullySend;
    };

    setUsersAllowedToImport = (usersAllowedToImport: boolean) => {
        this.usersAllowedToImport = usersAllowedToImport;
    };

    setUserimportprocessesId = (userimportprocessesId: string) => {
        this.userimportprocessesId = userimportprocessesId;
    };

    setCustomerId = (customerId: string) => {
        this.customerId = customerId;
    };

    setErrorMsgUpdateUserImportDb = (errorMsgUpdateUserImportDb: string) => {
        this.errorMsgUpdateUserImportDb = errorMsgUpdateUserImportDb;
    };

    /* COMPUTED */
    get processes() {
        return this.InviteUserProcess.processes;
    }

    get currentProcess(): IProcess | undefined {
        return this.InviteUserProcess.processes.find((process) => process.type === this.currentProcessType);
    }

    get currentStep(): IProcessStep | undefined {
        return this.currentProcess?.processSteps.find((step) => step.stepType === this.currentStepType);
    }
}
