import React, { useContext, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { useQuery } from "@apollo/client";
import { GET_FEATURE_ANNOUNCEMENT_CONTENT_DETAILS } from "src/api/featureAnnouncement";
import {
    GetFeatureAnnouncementContentDetails,
    GetFeatureAnnouncementContentDetailsVariables
} from "src/api/generated/GetFeatureAnnouncementContentDetails";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { RootStoreContext } from "src/stores/RootStore";
import * as Screens from "src/screens";
import { observer } from "mobx-react-lite";
import { FeatureAnnouncementContentDetailsForm } from "./form/FeatureAnnouncementContentDetailsForm";
import { Route as CustomerRoutes, selectRoute } from "src/config/routes";

interface Params {
    announcementid: string;
    contentid: string;
}

const FeatureAnnouncementContentDetailsBase = () => {
    const { t } = useTranslation();
    const { navStore, uiStore, authStore } = useContext(RootStoreContext);

    const { announcementid, contentid } = useParams<Params>();

    useEffect(() => {
        navStore.setTitle("Feature Announcement");
        navStore.setBackbutton(
            selectRoute(CustomerRoutes.featureAnnouncementDetails, null, {
                announcementid
            }),
            "Feature Announcement"
        );
    }, [navStore, announcementid]);

    const {
        loading,
        error,
        data,
        refetch: refetchFeatureAnnouncementContentData
    } = useQuery<GetFeatureAnnouncementContentDetails, GetFeatureAnnouncementContentDetailsVariables>(
        GET_FEATURE_ANNOUNCEMENT_CONTENT_DETAILS,
        {
            variables: {
                contentid
            }
        }
    );

    useEffect(() => {
        if (uiStore.hasNewAnnouncementContentData) {
            refetchFeatureAnnouncementContentData();
        }
    }, [refetchFeatureAnnouncementContentData, uiStore, uiStore.hasNewAnnouncementContentData]);

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata")} />;
    }

    const userRole = authStore.user ? authStore.user.role : undefined;
    const featureAnnouncementContentDetails =
        data.announcementcontent.length > 0 ? data.announcementcontent[0] : undefined;

    return (
        <FeatureAnnouncementContentDetailsForm
            featureAnnouncementContentDetails={featureAnnouncementContentDetails}
            announcementId={announcementid}
            contentId={contentid}
            onUpsertFeatureAnnouncementContent={refetchFeatureAnnouncementContentData}
            userRole={userRole}
        />
    );
};

export const FeatureAnnouncementContentDetails = Sentry.withProfiler(observer(FeatureAnnouncementContentDetailsBase));
