import React, { useCallback, useState } from "react";
import { Bar as BarChartjs, HorizontalBar as HorizontalBarChartjs } from "react-chartjs-2";
import { ChartConfig } from "src/config/charts";
import { ChartDataType, formatValue } from "src/components/tiles/Tile";

interface BarProps {
    data: BarData;
    colors: string[];
}

interface BarData {
    labels: string[];
    datasets: {
        label: string;
        data: number[];
        backgroundColor?: string;
        pointRadius?: number;
    }[];
    valuetype: ChartDataType;
}

export const VerticalBar = (props: BarProps) => {
    const [dimension, setDimension] = useState({
        width: undefined,
        height: undefined
    });
    const ref = useCallback((node) => {
        if (node !== null) {
            setDimension({
                width: node.getBoundingClientRect().width,
                height: node.getBoundingClientRect().height
            });
        }
    }, []);
    const options = {
        legend: {
            display: false
        },
        cutoutPercentage: 70,
        animation: {
            animateScale: true,
            animateRotate: true,
            duration: ChartConfig.animationDuration
        },
        tooltips: {
            enabled: false
        },
        hover: {
            mode: undefined
        },
        scales: {
            yAxes: [
                {
                    scaleLabel: {
                        display: false,
                        labelString: "in %"
                    },
                    ticks: {
                        beginAtZero: true,
                        maxTicksLimit: 5,
                        suggestedMax: 10,
                        // Include a dollar sign in the ticks
                        callback: function (value: number, index: number, values: any) {
                            return formatValue(value, props.data.valuetype);
                        }
                    }
                }
            ],
            xAxes: [
                {
                    ticks: {
                        maxTicksLimit: 7,
                        maxRotation: 0,
                        minRotation: 0
                    }
                }
            ]
        }
    };

    props.data.datasets.map((dataset) => {
        if (!dataset.backgroundColor) dataset.backgroundColor = props.colors.length !== 0 ? props.colors[0] : "red";
        if (!dataset.pointRadius) dataset.pointRadius = 5;
        return dataset;
    });

    return (
        <div className="Bar" ref={ref}>
            {dimension.width && dimension.height && (
                <BarChartjs height={dimension.height} width={dimension.width} data={props.data} options={options} />
            )}
        </div>
    );
};

export const HorizontalBar = (props: BarProps) => {
    const [dimension, setDimension] = useState({
        width: undefined,
        height: undefined
    });
    const ref = useCallback((node) => {
        if (node !== null) {
            setDimension({
                width: node.getBoundingClientRect().width,
                height: node.getBoundingClientRect().height
            });
        }
    }, []);
    const options = {
        legend: {
            display: false
        },
        cutoutPercentage: 70,
        animation: {
            animateScale: true,
            animateRotate: true,
            duration: ChartConfig.animationDuration
        },
        tooltips: {
            enabled: false
        },
        hover: {
            mode: undefined
        },
        scales: {
            xAxes: [
                {
                    scaleLabel: {
                        display: false,
                        labelString: "in %"
                    },
                    ticks: {
                        beginAtZero: true,
                        maxTicksLimit: 5,
                        suggestedMax: 10,
                        // Include a dollar sign in the ticks
                        callback: function (value: number, index: number, values: any) {
                            return formatValue(value, props.data.valuetype);
                        }
                    }
                }
            ],
            yAxes: [
                {
                    ticks: {
                        maxRotation: 0,
                        minRotation: 0
                    }
                }
            ]
        }
    };

    props.data.datasets.map((dataset) => {
        if (!dataset.backgroundColor) dataset.backgroundColor = props.colors.length !== 0 ? props.colors[0] : "red";
        if (!dataset.pointRadius) dataset.pointRadius = 5;
        return dataset;
    });

    return (
        <div className="Bar" ref={ref}>
            {dimension.width && dimension.height && (
                <HorizontalBarChartjs
                    width={dimension.width}
                    height={dimension.height}
                    data={props.data}
                    options={options}
                />
            )}
        </div>
    );
};
