import React, { useContext, useCallback } from "react";
import { GetCustomerLogs_logs as customerLog } from "src/api/generated/GetCustomerLogs";
import { format } from "date-fns";
import { NetworkConfig } from "src/network/NetworkConfig";
import { ColorStyle } from "src/utils/Colors";
import { UI, ScreenSize } from "@wwimmo/ui";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";

const STICKY_HEADER_OFFSET = 130;

interface LogListProps {
    logs: customerLog[];
}

const LogBaseList = (props: LogListProps) => {
    const { uiStore } = useContext(RootStoreContext);

    const renderItem = useCallback((log: customerLog, screenSize: ScreenSize) => {
        const { file } = log;

        const logDate = format(new Date(file?.filedate), "dd.MM.yyy, HH:mm");
        const logInsertedDate = format(new Date(file?.inserted), "dd.MM.yyy");

        let listRows: React.ReactElement[] = [
            <UI.List.Row key={"r-1"}>
                <UI.List.Cell key={"c-1"} colspan={0.6} value={logDate} />
                <UI.List.Cell key={"c-2"} colspan={0.4} value={logInsertedDate} />
            </UI.List.Row>
        ];

        const logFileUrlLink = NetworkConfig.datafileUrl + file?.id;
        const logFileDownloadIcon = (
            <a href={logFileUrlLink} className="link" rel="noopener noreferrer" target="_blank">
                <UI.Icon icon={UI.SVGIcon.Documents} color={ColorStyle("primary")} />
            </a>
        );

        return <UI.List.Item key={`${file?.id}`} screenSize={screenSize} rows={listRows} icon={logFileDownloadIcon} />;
    }, []);

    const renderHeader = useCallback((screenSize: ScreenSize) => {
        let headerRows: React.ReactElement[] = [
            <UI.List.Row key={"row-1"}>
                <UI.List.Cell key={"c-1"} colspan={0.6} value={"Datum"} />
                <UI.List.Cell key={"c-2"} colspan={0.4} value={"Hochgeladen am"} />
            </UI.List.Row>
        ];

        return <UI.List.Header rows={headerRows} sticky={true} top={STICKY_HEADER_OFFSET} />;
    }, []);

    return (
        <UI.List.BasicList
            screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
            items={props.logs}
            renderItem={renderItem}
            renderHeader={renderHeader}
        />
    );
};

export const LogList = observer(LogBaseList);
