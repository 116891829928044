import { observer } from "mobx-react";
import React, { useContext, useCallback } from "react";
import { Redirect, Route, RouteProps } from "react-router";
import { Auth } from "src/screens/auth";
import { RootStoreContext } from "src/stores/RootStore";
import { Role as CustomerRole } from "src/network/User";
interface PrivateRouteProps {
    allowedRoles?: CustomerRole[];
}

export const PrivateRouteBase = ({
    children,
    component,
    allowedRoles,
    render,
    ...rest
}: RouteProps & PrivateRouteProps) => {
    const { authStore } = useContext(RootStoreContext);

    const renderComponent = useCallback(
        () => (authStore.isLoggedIn === true ? children : <Redirect to="/" />),
        [authStore.isLoggedIn, children]
    );

    if (!authStore.isLoggedIn) {
        authStore.setEntryPath(`${window.location.pathname}${window.location.search}`);
        authStore.setEntryUri(window.location.href);
        return <Route {...rest} component={Auth.Login} />;
    }

    if (allowedRoles && authStore.user) {
        authStore.user.availableRoles.forEach((availableRole) => {
            if (allowedRoles?.includes(availableRole)) {
                return <Redirect to="/" />;
            }
        });
    }

    const routerComponent = component ? component : undefined;

    if (routerComponent) {
        return <Route {...rest} component={routerComponent} />;
    } else {
        if (render) {
            return <Route {...rest} render={render} />;
        } else {
            return <Route {...rest} render={renderComponent} />;
        }
    }
};

export const PrivateRoute = observer(PrivateRouteBase);
