export const isUUID = (uuid: string) => {
    let s = uuid.match("^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$");
    if (s === null) {
        return false;
    }
    return true;
};

export const isNumeric = (n: any) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
};

export const getFormattedVersionNumber = (unformattedVersionNumber: string): string => {
    const patchVersion = Number(unformattedVersionNumber.slice(-4));
    const minorVersion = Number(unformattedVersionNumber.slice(-7, -4));
    const majorVersion = Number(unformattedVersionNumber.slice(0, -7));

    return `${majorVersion}.${minorVersion}.${patchVersion}`;
};
