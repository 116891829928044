import { observable, action, makeObservable } from "mobx";
import { persist } from "mobx-persist";
import { Config } from "src/config/config";
import { Customer, Back } from "src/types";

export interface ListParameters {
    searchQuery?: string;
    scrollPosition?: number;
    paginationPage?: number;
    filterValue?: string;
    sortValue?: string;
}
export class NavigationStore {
    @persist("object") @observable customer: Customer = {
        name: "",
        userName: "",
        email: ""
    };
    @persist @observable closeNavTarget = "";
    @persist @observable currentTitle = "";
    @persist @observable appheaderTitle = "";
    @persist @observable currentSubtitle: string | null = null;
    @persist @observable currentOwnerId: string | null = null;
    @persist("object") @observable back: Back | null = null;
    @observable.ref actionButtonLeft: React.ReactNode | null = null;
    @observable.ref actionButtonMiddle: React.ReactNode | null = null;
    @observable.ref actionButtonRight: React.ReactNode | null = null;
    @observable listMap: Map<string, ListParameters> = new Map();

    constructor() {
        makeObservable(this);
    }

    @action
    setListMapValue = (listIdentifier: string, value: ListParameters) => {
        this.listMap.set(listIdentifier, value);
    };

    @action
    getListMapValue = (key: string) => {
        return this.listMap.get(key);
    };

    @action
    setActionButtonMiddle = (actionButton: React.ReactNode | null) => {
        this.actionButtonMiddle = actionButton;
    };
    @action
    setActionButtonLeft = (actionButton: React.ReactNode | null) => {
        this.actionButtonLeft = actionButton;
    };
    @action
    setActionButtonRight = (actionButton: React.ReactNode | null) => {
        this.actionButtonRight = actionButton;
    };

    @action
    setCurrentSubTitle = (currentSubTitle: string | null) => {
        this.currentSubtitle = currentSubTitle;
    };

    @action
    setCurrentOwnerId = (currentOwnerId: string | null) => {
        this.currentOwnerId = currentOwnerId;
    };

    @action
    setBackbutton = (target: string | null, label?: string) => {
        //Remove Backbutton
        if (target === null) {
            this.back = null;
            return;
        }
        this.back = { label: label || "", to: target };
    };

    @action
    setTitle = (newTitle: string) => {
        const titles: string[] = [];
        if (this.customer.name) {
            titles.push(this.customer.name);
        }
        if (newTitle) {
            titles.push(newTitle);
        } else {
            titles.push(Config.appname);
        }
        this.currentTitle = titles.join(" - ");
        this.appheaderTitle = newTitle;
    };

    @action
    setTabTitle = (newTitle: string) => {
        this.currentTitle = newTitle;
    };

    @action
    setCustomer = (newCustomer: Customer) => {
        this.customer = newCustomer;
    };

    @action
    setCloseNavTarget = (newCloseNavTarget: string) => {
        this.closeNavTarget = newCloseNavTarget;
    };

    @action
    resetActionButtons = () => {
        this.actionButtonRight = null;
        this.actionButtonLeft = null;
        this.actionButtonMiddle = null;
    };

    @action
    reset = () => {
        this.customer = {
            name: "",
            userName: "",
            email: ""
        };
        this.closeNavTarget = "";
        this.currentTitle = "";
        this.appheaderTitle = "";
        this.currentSubtitle = null;
        this.currentOwnerId = null;
        this.back = null;
    };
}
