import React, { useContext, useCallback, useEffect, useState } from "react";
import {
    GetCustomerInvoices_costtype_enumeration as costTypeEnumeration,
    GetCustomerInvoices_invoices as customerInvoice
} from "src/api/generated/GetCustomerInvoices";
import { format } from "date-fns";
import { UI, ScreenSize } from "@wwimmo/ui";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { formatCurrency } from "src/utils/Currency";
import styles from "./InvoiceList.module.css";

const STICKY_HEADER_OFFSET = 130;

interface InvoiceListProps {
    invoices: customerInvoice[];
    costTypeEnumeration: costTypeEnumeration[];
}

const InvoiceListBase = (props: InvoiceListProps) => {
    const { uiStore } = useContext(RootStoreContext);

    const [costTypeEnumerationStatus, setCostTypeEnumerationStatus] = useState<Map<number | null, { label: string }>>();

    useEffect(() => {
        if (props.costTypeEnumeration) {
            setCostTypeEnumerationStatus(getEnumerationMap(props.costTypeEnumeration));
        }
    }, [props.costTypeEnumeration]);

    const getEnumerationMap = (enumerations: Array<costTypeEnumeration>) => {
        const enumerationMap = new Map();

        enumerations.forEach((enumeration) => {
            if (enumeration.key !== null) {
                enumerationMap.set(enumeration.key, {
                    label: enumeration.label
                });
            }
        });

        return enumerationMap;
    };

    const renderItem = useCallback(
        (invoice: customerInvoice, screenSize: ScreenSize) => {
            const { id, invoiced, date, costtype, costperunit, count } = invoice;

            const totalCosts = count ? count * costperunit : 0;

            const invoiceDate = format(new Date(date), "MM.yyyy");

            let invoicedDate = "";

            if (invoiced) {
                invoicedDate = format(new Date(invoiced), "dd.MM.yyy");
            }

            const costType = costTypeEnumerationStatus?.get(costtype)?.label;

            let listRows: React.ReactElement[] = [];

            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell key={"c-1"} colspan={0.5} value={invoiceDate} />
                            <UI.List.Cell key={"c-1"} colspan={1} value={costType} />
                            <UI.List.Cell key={"c-2"} colspan={0.5} value={count} />
                            <UI.List.Cell key={"c-3"} colspan={0.5} value={formatCurrency(costperunit)} />
                            <UI.List.Cell key={"c-4"} colspan={0.5} value={formatCurrency(totalCosts)} />
                            <UI.List.Cell key={"c-5"} colspan={1} value={invoicedDate} />
                        </UI.List.Row>
                    ];
                    break;

                case ScreenSize.MOBILE:
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell key={"c-1"} colspan={0.4} value={invoiceDate} />
                            <UI.List.Cell key={"c-2"} colspan={1.2} value={costType} />
                            <UI.List.Cell key={"c-3"} colspan={0.4} value={invoicedDate} />
                        </UI.List.Row>,
                        <UI.List.Row key={"r-2"}>
                            <UI.List.Cell key={"c-1"} colspan={0.4} value={count} />
                            <UI.List.Cell key={"c-2"} colspan={1.2} value={formatCurrency(costperunit)} />
                            <UI.List.Cell key={"c-3"} colspan={0.4} value={formatCurrency(totalCosts)} />
                        </UI.List.Row>
                    ];
                    break;
            }

            return <UI.List.Item key={id} screenSize={screenSize} rows={listRows} />;
        },
        [costTypeEnumerationStatus]
    );

    const renderHeader = useCallback((screenSize: ScreenSize) => {
        let headerRows: React.ReactElement[] = [];

        switch (screenSize) {
            case ScreenSize.DESKTOP:
                headerRows = [
                    <UI.List.Row key={"row-1"}>
                        <UI.List.Cell key={"c-1"} colspan={0.5} value={"Monat"} />
                        <UI.List.Cell key={"c-3"} colspan={1} value={"Kostentyp"} />
                        <UI.List.Cell key={"c-2"} colspan={0.5} value={"Anzahl"} />
                        <UI.List.Cell key={"c-4"} colspan={0.5} value={"Preis"} />
                        <UI.List.Cell key={"c-5"} colspan={0.5} value={"Kosten Total"} />
                        <UI.List.Cell key={"c-6"} colspan={1} value={"Abgerechnet"} />
                    </UI.List.Row>
                ];
                break;
            case ScreenSize.MOBILE:
                headerRows = [
                    <UI.List.Row key={"row-1"} className="header-row-1">
                        <UI.List.Cell key={"c-1"} colspan={0.4} value={"Monat"} />
                        <UI.List.Cell key={"c-4"} colspan={1.2} value={"Kostentyp"} />
                        <UI.List.Cell key={"c-5"} colspan={0.4} value={"Abgerechnet"} />
                    </UI.List.Row>,
                    <UI.List.Row key={"r-2"} className="header-row-2">
                        <UI.List.Cell key={"c-1"} colspan={0.4} value={"Anzahl"} />
                        <UI.List.Cell key={"c-2"} colspan={1.2} value={"Preis"} />
                        <UI.List.Cell key={"c-3"} colspan={0.4} value={"Kosten Total"} />
                    </UI.List.Row>
                ];
                break;
        }

        return (
            <UI.List.Header
                rows={headerRows}
                sticky={true}
                top={STICKY_HEADER_OFFSET}
                className={styles.invoiceHeader}
            />
        );
    }, []);

    return (
        <UI.List.BasicList
            screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
            items={props.invoices}
            renderItem={renderItem}
            renderHeader={renderHeader}
        />
    );
};

export const InvoiceList = observer(InvoiceListBase);
