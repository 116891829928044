import React, { useContext, useEffect, useState, useCallback } from "react";
import { ProgressBar } from "react-bootstrap";
import * as Sentry from "@sentry/react";
import { UI } from "@wwimmo/ui";
import { Feature, Partner } from "src/utils/Enums";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { Route, selectRoute } from "src/config/routes";
import { CustomerERPConnectionInfo } from "src/screens/dashboard/customers/customer/erpconnectioninfo/CustomerERPConnectionInfo";
import { getRoleKey, Role as CustomerRole } from "src/network/User";
import { useForm } from "react-hook-form";
import { formatCustomerNumber, getEnvironment } from "src/utils/Customer";
import { yupResolver } from "@hookform/resolvers/yup";
import { CUSTOMER_SCHEMA } from "src/utils/Schema";
import { observer } from "mobx-react-lite";
import styles from "./CustomerAdd.module.css";
import { NetworkConfig } from "src/network/NetworkConfig";

const LOADING_DURATION = 35;
interface NewCustomerCredentials {
    hostname: string;
    username: string;
    password: string;
}

interface CustomerAddFormFields {
    email: string;
    erp: number;
    name: string;
    number: string;
    partner: number;
    subdomain: string;
    type: number;
    features: number[];
    erpcloud: boolean;
}

const CustomerAddBase = () => {
    const { register, handleSubmit, errors, setValue, watch } = useForm<CustomerAddFormFields>({
        resolver: yupResolver(CUSTOMER_SCHEMA)
    });

    const watchFeatures = watch("features");
    const watchPartner = watch("partner");

    const { t } = useTranslation();
    const { navStore, authStore, uiStore } = useContext(RootStoreContext);

    const [progress, setProgress] = useState(0);
    const [newCustomerERPCredentials, setNewCustomerERPCredentials] = useState<NewCustomerCredentials>();
    const [isFairwalterPartnerCustomer, setIsFairwalterPartnerCustomer] = useState<boolean>();
    const [isFairwalterErpCustomer, setIsFairwalterErpCustomer] = useState<boolean>();

    useEffect(() => {
        navStore.setTitle(t("screens.add_customer.title"));
        navStore.setBackbutton(selectRoute(Route.dashboard), t("common.customers"));
    }, [navStore, t]);

    useEffect(() => {
        const selectedPartner = watchPartner;

        if (Number(selectedPartner) === Partner.FAIRWALTER) {
            setIsFairwalterPartnerCustomer(true);
            setIsFairwalterErpCustomer(true);
        } else {
            setIsFairwalterPartnerCustomer(false);
            setIsFairwalterErpCustomer(false);
        }
    }, [watchPartner]);

    const showErrorNotification = useCallback(
        (errorMsg: string, closeAfterMs: number | null) => {
            uiStore.enqueueSnackbar({
                content: errorMsg,
                options: {
                    type: "error",
                    autoClose: closeAfterMs ?? false
                }
            });
        },
        [uiStore]
    );

    const onAddCustomer = useCallback(
        async (formData) => {
            // Start Loading Process
            setProgress(1);

            const interval = setInterval(() => {
                setProgress((prevState) => prevState + 1);
            }, 1000);

            try {
                if (authStore.user && authStore.token) {
                    const accessToken = authStore.token;
                    const tokenType = authStore.tokenType;
                    const role = authStore.user?.role;

                    const { name, number, email, subdomain, type, erp, partner, features, erpcloud } = formData;
                    const customerFeatures: number[] = [];

                    for (const [key, value] of Object.entries(features)) {
                        if (value) {
                            customerFeatures.push(parseInt(key));
                        }
                    }

                    const requestBody = {
                        version: "1.0",
                        name: name,
                        number: formatCustomerNumber(String(number)),
                        email: email,
                        subdomain: subdomain,
                        type: type,
                        erp: erp,
                        features: customerFeatures,
                        partner: partner,
                        erpcloud: erpcloud
                    };

                    try {
                        const fetchResult = await fetch(NetworkConfig.createCustomerUrl, {
                            method: "POST",
                            body: JSON.stringify(requestBody),
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `${tokenType} ${accessToken}`,
                                "x-hasura-role": getRoleKey(role)
                            }
                        });

                        if (fetchResult.status === 200) {
                            try {
                                const returnedCustomer = await fetchResult.json();
                                const { Password, Username, Hostname } = returnedCustomer.data;
                                setNewCustomerERPCredentials({
                                    hostname: `https://${Hostname}`,
                                    password: Password,
                                    username: Username
                                });
                            } catch (jsonError) {
                                console.log("jsonError: ", jsonError);
                            }
                        } else {
                            const returnValue = await fetchResult.json();
                            showErrorNotification(returnValue.message, null);
                        }
                    } catch (fetchError) {
                        showErrorNotification(fetchError.error, null);
                    }
                }

                uiStore.setHasNewCustomerData(true);
            } finally {
                clearInterval(interval);
                setProgress(0);
            }
        },
        [authStore.user, authStore.token, authStore.tokenType, showErrorNotification, uiStore]
    );

    const createSubdomainSuggestion = useCallback(
        (e: any) => {
            let ValidDomainPart = "";
            const blackListDomains = ["hotmail", "bluewin", "gmail", "outlook", "gmx", "solnet"];

            let emailValue = e.target.value;

            if (emailValue.length > 0 && emailValue.indexOf("@") !== -1) {
                const emailParts = emailValue.split("@");
                const afterAtSignPart = emailParts[1];

                if (afterAtSignPart.length > 0 && afterAtSignPart.indexOf(".") !== -1) {
                    let domainPart = afterAtSignPart.split(".")[0].toLowerCase();

                    if (!blackListDomains.includes(domainPart)) {
                        ValidDomainPart = domainPart;
                    }
                }
            }
            if (isFairwalterPartnerCustomer) {
                let env = getEnvironment() === "prod" ? "" : "-" + getEnvironment();
                setValue("subdomain", "fairwalter" + env + "-" + ValidDomainPart);
            } else {
                setValue("subdomain", ValidDomainPart);
            }
        },
        [setValue, isFairwalterPartnerCustomer]
    );

    const setSubdomainToLowercase = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            e.target.value = e.target.value.replaceAll(".", "-");
            setValue("subdomain", e.target.value.toLowerCase());
        },
        [setValue]
    );

    const isLoading = () => {
        return progress > 0;
    };

    const onFeatureCheckboxChanged = useCallback(
        (e: any) => {
            const clickedCheckboxFeatureNumber = Number(e.target.id.split("-")[1]);
            const isEasyContactFeature = clickedCheckboxFeatureNumber === Feature.EasyContact;
            const isPortalOwnerManagerFeature =
                clickedCheckboxFeatureNumber === Feature["Portal für Bewirtschafter"] ||
                clickedCheckboxFeatureNumber === Feature["Portal für Eigentümer"];
            const checkboxWasDeactivated = !JSON.parse(e.target.checked);

            if (isEasyContactFeature && checkboxWasDeactivated) {
                for (const [key, isSelected] of Object.entries(watchFeatures)) {
                    const featureNumber = Number(key);
                    if (featureNumber === Feature["Service 7000"] && isSelected) {
                        setValue(`features.${Feature["Service 7000"]}`, false);
                    }
                }
            } else if (isPortalOwnerManagerFeature && !checkboxWasDeactivated) {
                setValue(`features.${Feature.Stockwerkeigentümerportal}`, true);
                setValue(`features.${Feature.Mieterportal}`, true);
            }
        },
        [watchFeatures, setValue]
    );

    const getERPTypesByPartner = useCallback((): JSX.Element[] => {
        let erpTypes: JSX.Element[] = [];

        if (isFairwalterPartnerCustomer) {
            const fairwalterOptionElement = (
                <option key="fairwalter" value={5}>
                    Fairwalter
                </option>
            );
            erpTypes.push(fairwalterOptionElement);
        } else {
            const rimor5OptionElement = (
                <option key="rimor5" value={1}>
                    Rimo R5
                </option>
            );
            const immotop2OptionElement = (
                <option key="immotop2" value={2}>
                    ImmoTop2
                </option>
            );
            erpTypes.push(rimor5OptionElement);
            erpTypes.push(immotop2OptionElement);
        }
        return erpTypes;
    }, [isFairwalterPartnerCustomer]);

    const renderFeatureCheckboxes = useCallback(() => {
        const isPortalOwnerAndManagerFeatureDeactivated =
            watchFeatures &&
            !watchFeatures[Feature["Portal für Bewirtschafter"]] &&
            !watchFeatures[Feature["Portal für Eigentümer"]];
        if (isPortalOwnerAndManagerFeatureDeactivated && watchFeatures[Feature.Stockwerkeigentümerportal]) {
            setValue(`features.${Feature.Stockwerkeigentümerportal}`, false);
            setValue(`features.${Feature.Mieterportal}`, false);
        }

        const featureComponents = [];

        for (const featureNumberStr in Feature) {
            if (isNaN(Number(featureNumberStr))) {
                continue;
            }
            const featureName = Feature[featureNumberStr];
            const featureNumber = Number(featureNumberStr);

            if (isFairwalterErpCustomer) {
                if (featureNumber === Feature.Abnahme) {
                    featureComponents.push(
                        <UI.Checkbox
                            key={featureNumber}
                            ref={register}
                            name="features"
                            label={featureName}
                            checkboxValue={featureNumber}
                            onChange={onFeatureCheckboxChanged}
                        />
                    );
                }
            } else {
                featureComponents.push(
                    <UI.Checkbox
                        key={featureNumber}
                        ref={register}
                        name="features"
                        label={featureName}
                        checkboxValue={featureNumber}
                        onChange={onFeatureCheckboxChanged}
                    />
                );
            }
        }

        return <>{featureComponents}</>;
    }, [onFeatureCheckboxChanged, register, setValue, watchFeatures, isFairwalterErpCustomer]);

    const renderCustomerForm = () => {
        const userRole = authStore.user ? authStore.user.role : undefined;

        return (
            <UI.Card flex>
                <UI.Form onSubmit={handleSubmit(onAddCustomer)}>
                    <UI.Input
                        label={t("screens.customer_details.form.type")}
                        ref={register}
                        name="type"
                        defaultValue={2}
                        as="select"
                        readOnly={isLoading()}
                    >
                        <option value="1">Prod</option>
                        <option value="2">Demo</option>
                        <option value="3">Test</option>
                    </UI.Input>
                    <UI.Input
                        label={t("screens.customer_details.form.partner")}
                        ref={register}
                        name="partner"
                        as="select"
                        readOnly={isLoading()}
                    >
                        <option value="1">W&W Immo Informatik AG</option>
                        <option value="5">Fairwalter</option>
                        <option value="9">Extenso</option>
                    </UI.Input>
                    <UI.Input
                        label={t("screens.customer_details.form.customer_number")}
                        ref={register}
                        name="number"
                        type="number"
                        errorMsg={errors.number ? errors.number.message : undefined}
                        readOnly={isLoading()}
                    />
                    <UI.Input
                        label={t("screens.customer_details.form.customer_name")}
                        ref={register}
                        name="name"
                        type="text"
                        errorMsg={errors.name ? errors.name.message : undefined}
                        readOnly={isLoading()}
                    />
                    <UI.Input
                        label={t("screens.customer_details.form.email_address")}
                        ref={register}
                        name="email"
                        type="email"
                        onBlur={createSubdomainSuggestion}
                        errorMsg={errors.email ? errors.email.message : undefined}
                        readOnly={isLoading()}
                    />
                    <UI.Input
                        label={t("screens.customer_details.form.erp")}
                        ref={register}
                        name="erp"
                        defaultValue={1}
                        as="select"
                        readOnly={isLoading()}
                    >
                        {getERPTypesByPartner()}
                    </UI.Input>
                    {userRole ? (
                        userRole === CustomerRole.CUSTOMERDEV || userRole === CustomerRole.CUSTOMERADMIN ? (
                            <UI.Checkbox
                                className={"mb-3"}
                                ref={register}
                                name="erpcloud"
                                label={t("screens.customer_details.form.erpcloud")}
                            />
                        ) : undefined
                    ) : undefined}

                    <UI.Form.Group>
                        <UI.Form.Label>{t("screens.customer_details.form.subdomain")}</UI.Form.Label>
                        <UI.InputGroup>
                            <UI.Form.Control
                                ref={register}
                                name="subdomain"
                                as="input"
                                type="text"
                                readOnly={isLoading()}
                                onInput={setSubdomainToLowercase}
                            />
                            <UI.InputGroup.Text className="bg-white" id="subdomain-addon">
                                .wwportal.ch
                            </UI.InputGroup.Text>
                            {errors.subdomain && (
                                <UI.Form.Control.Feedback className="d-block" type="invalid">
                                    {errors.subdomain?.message}
                                </UI.Form.Control.Feedback>
                            )}
                        </UI.InputGroup>
                    </UI.Form.Group>
                    <UI.Input id="features" type="checkbox" label={t("screens.customer_details.form.features")}>
                        {renderFeatureCheckboxes()}
                    </UI.Input>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <UI.Button
                            type="submit"
                            label={progress > 0 ? "Wird gespeichert..." : "Speichern"}
                            disabled={isLoading()}
                            className={`customer-button ${styles.customerAddButton}`}
                        />

                        {isLoading() ? (
                            <ProgressBar
                                style={{ width: "70%" }}
                                variant="info"
                                animated
                                now={(100 * progress) / LOADING_DURATION}
                            />
                        ) : null}
                    </div>
                </UI.Form>
            </UI.Card>
        );
    };

    return (
        <React.Fragment>
            <UI.Container className="pt-4">
                <UI.Row>
                    <UI.Col md={3}></UI.Col>
                    <UI.Col md={6}>
                        {newCustomerERPCredentials ? (
                            <CustomerERPConnectionInfo
                                {...newCustomerERPCredentials}
                                title="Kunde wurde erfolgreich angelegt"
                            />
                        ) : (
                            renderCustomerForm()
                        )}
                    </UI.Col>
                    <UI.Col md={3}></UI.Col>
                </UI.Row>
            </UI.Container>
        </React.Fragment>
    );
};

export const CustomerAdd = Sentry.withProfiler(observer(CustomerAddBase));
