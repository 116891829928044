import React, { useContext, useCallback } from "react";
import { GetCustomerFaultyFiles_faulty_files as faultyFile } from "src/api/generated/GetCustomerFaultyFiles";
import { ColorStyle } from "src/utils/Colors";
import { UI, ScreenSize } from "@wwimmo/ui";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router";
import styles from "./FaultyFileList.module.css";

interface LogListProps {
    faultyFiles: faultyFile[];
    deleteFileMutation: any;
    isFileDeleting: boolean;
}

interface Params {
    customerid: string;
}

const FaultyFileListBase = (props: LogListProps) => {
    const { customerid } = useParams<Params>();
    const { uiStore } = useContext(RootStoreContext);
    const { faultyFiles, deleteFileMutation, isFileDeleting } = props;

    const onDelete = useCallback(
        (fileId: string) => (e: React.MouseEvent) => {
            deleteFileMutation({
                variables: {
                    customerid: customerid,
                    fileid: fileId
                }
            });
        },
        [customerid, deleteFileMutation]
    );

    const renderItem = useCallback(
        (file: faultyFile, screenSize: ScreenSize) => {
            const { id, name, error, extension } = file;

            const fileName = `${name}${extension ? `.${extension}` : ""}`;

            let listRows: React.ReactElement[] = [];

            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell key={"c-1"} colspan={0.6} value={id} />
                            <UI.List.Cell key={"c-2"} colspan={0.4} value={fileName} />
                            <UI.List.Cell key={"c-3"} colspan={0.4} value={error} />
                        </UI.List.Row>
                    ];
                    break;
                case ScreenSize.MOBILE:
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={fileName} />
                        </UI.List.Row>
                    ];
                    break;
            }

            const idString = String(id);

            const faultyFileDeleteIcon = (
                <UI.Icon
                    icon={UI.SVGIcon.Close}
                    size="small"
                    color={ColorStyle("#FF0000")}
                    onClick={onDelete(idString)}
                    className={styles.removeIcon}
                />
            );

            return (
                <UI.List.Item key={`${file?.id}`} screenSize={screenSize} rows={listRows} icon={faultyFileDeleteIcon} />
            );
        },
        [onDelete]
    );

    const renderHeader = useCallback((screenSize: ScreenSize) => {
        let headerRows: React.ReactElement[] = [];

        switch (screenSize) {
            case ScreenSize.DESKTOP:
                headerRows = [
                    <UI.List.Row key={"row-1"}>
                        <UI.List.Cell key={"c-1"} colspan={0.6} value={"ID"} />
                        <UI.List.Cell key={"c-2"} colspan={0.4} value={"Name"} />
                        <UI.List.Cell key={"c-3"} colspan={0.4} value={"Error"} />
                    </UI.List.Row>
                ];
                break;
            case ScreenSize.MOBILE:
                headerRows = [
                    <UI.List.Row key={"row-1"}>
                        <UI.List.Cell key={"c-1"} colspan={1} value={"Name"} />
                    </UI.List.Row>
                ];
                break;
        }

        return <UI.List.Header rows={headerRows} sticky={true} top={130} />;
    }, []);

    const modalText = (
        <div className="d-flex align-items-center">
            <span>Datei wird gelöscht</span>
            <UI.RotatingSpinner noLogo={true} size={20} className={"align-items-start"} />
        </div>
    );

    return (
        <>
            <UI.List.BasicList
                screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
                items={faultyFiles}
                renderItem={renderItem}
                renderHeader={renderHeader}
            />
            <UI.Modal backdrop={"static"} title={"Fehlerhafte Dateien"} show={isFileDeleting} size="lg" centered={true}>
                {modalText}
            </UI.Modal>
        </>
    );
};

export const FaultyFileList = observer(FaultyFileListBase);
