import React, { useContext, useCallback } from "react";
import { GetUserDetails_user as user_details } from "src/api/generated/GetUserDetails";
import { dateFormat } from "src/utils/Date";
import { RolesMap } from "src/utils/Enums";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { UI, ScreenSize } from "@wwimmo/ui";
import styles from "./UserDetailList.module.css";

interface UserDetailListProps {
    user: user_details;
    scrollPos?: number;
}

interface UserInfoItem {
    label?: string;
    content?: any;
    link?: string;
    onClick?: any;
    className?: string;
}

const UserDetailListBase = (props: UserDetailListProps) => {
    const { navStore, uiStore } = useContext(RootStoreContext);

    const setBackbutton = useCallback(() => {
        navStore.setBackbutton(`/users/${props.user.customerid}/${props.user.id}`, "Benutzer-Details");
    }, [navStore, props.user.customerid, props.user.id]);

    const UserDetailCheckbox = (props: { checked: boolean; className?: string }) => (
        <input
            type="checkbox"
            name="registered"
            readOnly
            disabled
            checked={props.checked ? true : false}
            className={props.className}
        />
    );

    const registeredAtLabel = props.user.registereddate ? (
        <span>
            <span className={styles.registeredAtText}>Registriert am: </span>
            {dateFormat(new Date(props.user.registereddate), "dd.MM.yyyy, HH:mm")}
        </span>
    ) : (
        ""
    );

    const userName =
        props.user.name1 && props.user.name2 ? `${props.user.name1} ${props.user.name2}` : props.user.name1;

    const userDetailItems: UserInfoItem[] = [
        {
            label: "Benutzer ID",
            content: props.user.id
        },
        {
            label: "Name",
            content: userName
        },
        {
            label: "E-Mail-Adresse",
            content: props.user.email
        },
        {
            label: "Registrierungscode",
            content: props.user.registrationcode
        },
        {
            label: "Registriert",
            content: (
                <>
                    <UserDetailCheckbox
                        checked={props.user.registered ? props.user.registered : false}
                        className={"mr-2"}
                    />{" "}
                    {registeredAtLabel}{" "}
                </>
            )
        },
        {
            label: "Aktiviert",
            content: <UserDetailCheckbox checked={props.user.emailverified ? props.user.emailverified : false} />
        },
        {
            label: "Gesperrt",
            content: (
                <UserDetailCheckbox
                    checked={props.user.registered === false ? false : props.user.activated ? false : true}
                />
            )
        },
        {
            label: "System-Benutzer",
            content: <UserDetailCheckbox checked={props.user.system ? props.user.system : false} />
        },
        {
            label: "ERP Cloud User",
            content: <UserDetailCheckbox checked={props.user.erpcloud ? props.user.erpcloud : false} />
        },
        {
            label: "ERP ID",
            content: props.user.erpid
        },
        {
            onClick: setBackbutton,
            link: `/customers/${props.user.customerid}`,
            label: "Kunde",
            content: props.user.customer.name
        }
    ];

    if (props.user.userroles.length > 0) {
        const userRolesArray = [...props.user.userroles];

        const userRoles = userRolesArray
            .sort((roleA, roleB) => {
                const roleAName = RolesMap[roleA.role as keyof typeof RolesMap];
                const roleBName = RolesMap[roleB.role as keyof typeof RolesMap];

                return roleAName.localeCompare(roleBName);
            })
            .map(({ role }, i) => {
                return <span key={`role-${i}`}>{RolesMap[role as keyof typeof RolesMap]}</span>;
            });

        userDetailItems.push({
            className: props.user.userroles.length > 1 ? "multi-line" : "",
            label: props.user.userroles.length > 1 ? "Rollen" : "Rolle",
            content: userRoles
        });
    }

    const userSecurityItems: UserInfoItem[] = [
        {
            onClick: setBackbutton,
            link: `/users/${props.user.customerid}/${props.user.id}/audits`,
            label: "Audit",
            content: "Sicherheitsrelevante Aktivitäten"
        }
    ];

    const renderItem = useCallback((item: UserInfoItem, screenSize: ScreenSize) => {
        let listRows: React.ReactElement[] = [];

        if (item.content || item.link) {
            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell key={"c-1"} colspan={0.2} value={item.label} className={"bold"} />
                            <UI.List.Cell key={"c-2"} colspan={0.8} value={item.content} />
                        </UI.List.Row>
                    ];
                    break;
                case ScreenSize.MOBILE:
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell key={"c-1"} colspan={0.4} value={item.label} className={"bold"} />
                            <UI.List.Cell
                                key={"c-2"}
                                colspan={0.7}
                                value={item.content}
                                className={styles.userDetailContent}
                            />
                        </UI.List.Row>
                    ];
                    break;
            }

            return (
                <UI.List.Item
                    key={item.label}
                    screenSize={screenSize}
                    rows={listRows}
                    className={item.className}
                    to={item.link}
                    onClick={item.onClick}
                />
            );
        }
    }, []);

    return (
        <>
            <UI.List.BasicList
                screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
                items={userDetailItems}
                renderItem={renderItem}
                title={"Benutzer-Details"}
            />
            <UI.List.BasicList
                screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
                items={userSecurityItems}
                renderItem={renderItem}
                title={"Sicherheit und Datenschutz"}
            />
        </>
    );
};

export const UserDetailList = observer(UserDetailListBase);
