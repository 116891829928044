export enum ErpType {
    "Rimo R5" = 1,
    "ImmoTop2" = 2,
    "Fairwalter" = 5
}

export enum CustomerType {
    Prod = 1,
    Demo = 2,
    Test = 3
}

export enum Feature {
    "Abnahme" = 20,
    "Portal für Bewirtschafter" = 30,
    "Portal für Eigentümer" = 31,
    "Stockwerkeigentümerportal" = 32,
    "Mieterportal" = 33,
    "EasyContact" = 50,
    "Service 7000" = 51,
    "Yarowa" = 52,
    "Ticketing" = 55,
    "Kredi Flow" = 60,
    "Vermietungsprozess mit emonitor" = 70
}

export enum Partner {
    WW = 1,
    FAIRWALTER = 5,
    EXTENSO = 9
}

const ERP = "Erp";
const INSPECTION_USER = "Abnahme";
const INSPECTION_ADMIN = "Abnahme-Administrator";
const MANAGER = "Portal für Bewirtschafter";
const OWNER = "Portal für Eigentümer";
const COOWNER = "Portal für Stockwerkeigentümer";
const TENANT = "Mieterportal";
const AUDITOR = "Portal für Revisor";
const SYSTEM_ADMIN = "Administrator";
const ADMIN = "Bewirtschafter mit Administratorrechten im Portal";
const WAP2_MIGRATION = "WAP2 Vorlage Migration";
const UCCMADMIN = "User & Customer Communication (intern W&W)";
const ECSERVICE = "EasyContact Service";
const ECMANAGER = "EasyContact Bewirtschafter";
const ECADMIN = "EasyContact Administrator";
const CUSTOMERADMIN = "Benutzer für Administration (Kundenentwicklung)";
const CUSTOMERDEV = "Benutzer für Entwicklung (Kundenentwicklung)";
const CUSTOMERSUPPORT = "Benutzer für Support (Kundenentwicklung)";
const BASEDATAAPIREADER = "Benutzer für API Reader (Base Data) ";
const ERPUSER = "Benutzer im ERP";
const ERPADMIN = "Administrator im ERP";

export const RolesMap = {
    1: ERP,
    10: SYSTEM_ADMIN,
    11: CUSTOMERADMIN,
    12: CUSTOMERDEV,
    13: CUSTOMERSUPPORT,
    14: UCCMADMIN,
    20: INSPECTION_USER,
    21: INSPECTION_ADMIN,
    30: MANAGER,
    31: OWNER,
    32: COOWNER,
    33: TENANT,
    36: AUDITOR,
    39: ADMIN,
    40: WAP2_MIGRATION,
    50: ECSERVICE,
    51: ECMANAGER,
    52: ECADMIN,
    60: BASEDATAAPIREADER,
    70: ERPUSER,
    79: ERPADMIN
};

export enum AnnouncementTargetProduct {
    "Abnahme App" = 1,
    "Portal" = 2
}

export enum AnnouncementState {
    neu = 10,
    publiziert = 20,
    beendet = 30,
    deaktiviert = 99
}

export enum ServiceType {
    Feature = 10,
    Integration = 20
}

export enum ServiceState {
    vesteckt = 0,
    Preview = 10,
    Beta = 20,
    Release = 30
}

export enum ServiceEvent {
    aktiviert = 10,
    deaktiviert = 20,
    angesehen = 30
}
