import { Switch, useLocation } from "react-router";
import * as Sentry from "@sentry/react";
import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "src/stores/RootStore";
import { Route } from "src/config/routes";
import { PrivateRoute } from "src/components/routes/PrivateRoutes";
import { PublicRoute } from "src/components/routes/PublicRoutes";
import { Header } from "src/components/header";
import { Auth } from "src/screens/auth";
import { CustomerRoutes } from "src/screens/dashboard/customers/customer/CustomerRoutes";
import { NetworkConfig } from "src/network/NetworkConfig";

export const RoutesBase = () => {
    const { uiStore } = useContext(RootStoreContext);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    if (location.pathname === Route.register) {
        const authRegisterRedirectUrl = new URL(`${NetworkConfig.authWebsiteUrl}/register`);
        const subdomain = NetworkConfig.customerAuthSubdomain;
        const hasCustomerSpecificSubdomain = subdomain !== "prod" && subdomain !== "dev" && subdomain !== "test";

        if (hasCustomerSpecificSubdomain) {
            queryParams.set("subdomain", subdomain);
        }

        if (Array.from(queryParams).length > 0) {
            for (const param of queryParams) {
                authRegisterRedirectUrl.searchParams.set(param[0], param[1]);
            }
        }
        window.location.replace(authRegisterRedirectUrl.href);
    }

    return (
        <main className="d-flex flex-column" style={{ minHeight: "100vh", background: uiStore.backgroundColor }}>
            <Header.Appheader />
            <div className="flex-grow-1 d-flex flex-column" style={{ marginTop: uiStore.headerHeight }}>
                <Switch>
                    <PublicRoute path={Route.logout} component={Auth.Logout} />
                    <PrivateRoute path="/" component={CustomerRoutes} />
                </Switch>
            </div>
        </main>
    );
};

export const Routes = Sentry.withProfiler(observer(RoutesBase));
