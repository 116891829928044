import React, { useContext, useCallback, FunctionComponent, useState, useRef, ChangeEvent } from "react";
import * as Sentry from "@sentry/react";
import { UI } from "@wwimmo/ui";
import { RootStoreContext } from "src/stores/RootStore";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { Role as CustomerRole, getRoleKey } from "src/network/User";
import { UPSERT_UCCM_SERVICE, DELETE_SERVICE } from "src/api/service";
import { UpsertUccmService, UpsertUccmServiceVariables } from "src/api/generated/UpsertUccmService";
import { DeleteService, DeleteServiceVariables } from "src/api/generated/DeleteService";
import { MessageType } from "src/components/notifications/Notifier";
import {
    GetServiceDetails_service as uccmService,
    GetServiceDetails_servicestates as uccmServiceStates,
    GetServiceDetails_servicetypes as umsServiceTypes,
    GetServiceDetails_features as umsFeatures
} from "src/api/generated/GetServiceDetails";
import styles from "./ServiceForm.module.css";
import { selectRoute, Route } from "src/config/routes";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { FORM_STATUS } from "src/utils/Form";
import { useSaveUnsavedChangesInForm } from "src/hooks/save-unsaved-changes-in-form/useSaveUnsavedChangesInForm";
import { useSaveFormByShortcut } from "src/hooks/form/useSaveFormByShortcut";
import { ServiceContentList } from "src/screens/dashboard/services/details/content/list/ServiceContentList";
import { ServiceType } from "src/utils/Enums";
import { NetworkConfig } from "src/network/NetworkConfig";
import { getEnvironment } from "src/utils/Customer";
import { ColorStyle } from "src/utils/Colors";
import { resizeImageFile } from "src/utils/Image";

type ServiceFormProps = {
    serviceDetails?: uccmService;
    serviceId: string;
    userRole?: CustomerRole;
    onUpsertService: any;
    serviceStates: uccmServiceStates[];
    serviceTypes: umsServiceTypes[];
    features: umsFeatures[];
};

interface ServiceFormFields {
    id: string;
    name: string;
    type: number;
    state: number;
    chargeable: boolean;
    pricelink: string;
    remarks: string;
    enableable: boolean;
    feature: number;
}

enum ServiceStates {
    HIDDEN = 0,
    PREVIEW = 10,
    BETA = 20,
    RELEASE = 30
}

interface MediaFile {
    fileName: string;
    fileId: string;
    fileExtension: string;
    fileUrl: string;
}

interface FileHTMLInputEvent extends ChangeEvent {
    target: HTMLInputElement & EventTarget;
}

const ServiceFormBase: FunctionComponent<ServiceFormProps> = (props) => {
    const { uiStore, authStore } = useContext(RootStoreContext);
    const history = useHistory();
    const [isNewService, setIsNewService] = useState<boolean>(props.serviceDetails ? false : true);
    const [currentStatus, setCurrentStatus] = useState<FORM_STATUS>(FORM_STATUS.NONE);
    const [isUploadingMediaFile, setIsUploadingMediaFile] = useState<boolean>(false);

    const { serviceId, serviceDetails, userRole, onUpsertService, serviceStates, features } = props;

    const [upsertUccmServiceMutation] = useMutation<UpsertUccmService, UpsertUccmServiceVariables>(
        UPSERT_UCCM_SERVICE,
        {
            onCompleted: () => {
                uiStore.printStatusMessage("Service wurde erfolgreich gespeichert", MessageType.INFO);
            }
        }
    );

    const [deleteServiceMutation] = useMutation<DeleteService, DeleteServiceVariables>(DELETE_SERVICE, {
        onCompleted: () => {
            uiStore.printStatusMessage("Das Service wurde erfolgreich gelöscht", MessageType.INFO);
        }
    });

    const onDeleteService = useCallback(async () => {
        await deleteServiceMutation({
            variables: {
                serviceid: serviceId
            }
        });

        uiStore.setHasNewServiceData(true);
        history.replace(selectRoute(Route.services, null));
    }, [serviceId, deleteServiceMutation, history, uiStore]);

    const serviceDeleteDialog = (
        <UI.ConfirmationDialog
            key="serviceDeleteDialog"
            buttonText="Löschen"
            modalTitle="Service löschen"
            confirmationQuestion="Willst du das Service wirklich löschen?"
            inProgressText="Service wird gelöscht..."
            onConfirmation={onDeleteService}
            buttonClasses={`${styles.FormButton} ${styles.Delete} customer-button ml-sm-2 mt-2 mt-sm-0`}
            isDangerousOperation
        />
    );

    const { register, handleSubmit, formState, reset, errors } = useForm<ServiceFormFields>({
        mode: "onBlur",
        defaultValues: {
            id: serviceId,
            name: serviceDetails?.name ?? "",
            type: serviceDetails?.type ?? 0,
            state: serviceDetails?.state ?? ServiceStates.HIDDEN,
            chargeable: serviceDetails?.chargeable,
            pricelink: serviceDetails?.pricelink ?? "",
            remarks: serviceDetails?.remarks ?? "",
            enableable: serviceDetails?.enableable,
            feature: serviceDetails?.feature ?? undefined
        }
    });

    const { dirtyFields, isDirty } = formState;

    const [mediaFiles, setMediaFiles] = useState<Array<MediaFile>>(
        props.serviceDetails
            ? props.serviceDetails.logo
                  .filter((file) => file.language === "de")
                  .map((file) => {
                      return {
                          fileId: file.id,
                          fileName: file.name,
                          fileExtension: file.extension,
                          fileUrl: `https://${getEnvironment()}.wwportal.ch/files/services/${serviceId}/de/${file.id}.${
                              file.extension
                          }`
                      };
                  })
            : []
    );

    const refSubmitButtom = useRef<HTMLButtonElement>(null);
    const hiddenMediaInputButton = useRef<HTMLInputElement>(null);

    const saveForm = useCallback(() => {
        refSubmitButtom?.current?.click();
    }, []);

    useSaveFormByShortcut({ saveFormFunction: saveForm, isFormDirty: isDirty });

    const { saveFormModal, closeSaveFormModalAndGoBack } = useSaveUnsavedChangesInForm({
        saveFormFunction: saveForm,
        currentFormStatus: currentStatus,
        isFormDirty: isDirty
    });

    const resetForm = useCallback(
        (e: any) => {
            e.preventDefault();

            if (props.serviceDetails) {
                reset({
                    id: serviceId,
                    name: serviceDetails?.name ?? "",
                    type: serviceDetails?.type ?? 10,
                    state: serviceDetails?.state ?? ServiceStates.HIDDEN,
                    chargeable: serviceDetails?.chargeable,
                    pricelink: serviceDetails?.pricelink ?? "",
                    remarks: serviceDetails?.remarks ?? "",
                    enableable: serviceDetails?.enableable,
                    feature: serviceDetails?.feature ?? undefined
                });
            } else {
                reset({
                    id: serviceId,
                    name: "",
                    type: 10,
                    state: ServiceStates.HIDDEN,
                    chargeable: false,
                    pricelink: "",
                    remarks: "",
                    enableable: false,
                    feature: undefined
                });
            }
        },
        [
            reset,
            serviceId,
            props.serviceDetails,
            serviceDetails?.name,
            serviceDetails?.type,
            serviceDetails?.state,
            serviceDetails?.chargeable,
            serviceDetails?.pricelink,
            serviceDetails?.remarks,
            serviceDetails?.enableable,
            serviceDetails?.feature
        ]
    );

    const handleFormSubmit = useCallback(
        async (formData: ServiceFormFields) => {
            setCurrentStatus(FORM_STATUS.SAVING_FORM);

            if (Number(formData.type) === 0) {
                uiStore.printStatusMessage("Bitte wähle ein Typ aus", MessageType.ERROR);
                return;
            }

            const updatedServiceDetails: any = {
                ...formData,
                name: formData.name,
                type: Number(formData.type),
                state: isNewService ? ServiceStates.HIDDEN : Number(formData.state),
                pricelink: formData.pricelink,
                remarks: formData.remarks,
                feature: formData.feature
            };

            if (formData.id === "") {
                delete updatedServiceDetails.id;
            }

            try {
                await upsertUccmServiceMutation({
                    variables: {
                        service: updatedServiceDetails
                    }
                });

                onUpsertService();

                if (isNewService) {
                    uiStore.setHasNewServiceData(true);
                    setIsNewService(false);
                }

                reset(
                    {
                        ...formData
                    },
                    {
                        isDirty: false,
                        dirtyFields: false
                    }
                );
            } catch (error) {
                uiStore.printStatusMessage(
                    `Fehler bei der Aktualisierung der Verbindungsdaten: ${error.message}`,
                    MessageType.ERROR
                );
            } finally {
                setCurrentStatus(FORM_STATUS.NONE);
                closeSaveFormModalAndGoBack();
            }
        },
        [isNewService, onUpsertService, reset, uiStore, upsertUccmServiceMutation, closeSaveFormModalAndGoBack]
    );

    const getServiceStateOptionElements = useCallback((): JSX.Element[] => {
        const serviceStateOptionElements = serviceStates.map((serviceState) => {
            return (
                <option key={serviceState.id} value={serviceState.key ?? 0}>
                    {serviceState.label}
                </option>
            );
        });

        return serviceStateOptionElements;
    }, [serviceStates]);

    const getServiceFeaturesOptionElements = useCallback((): JSX.Element[] => {
        const serviceStateOptionElements = features.map((feature) => {
            return (
                <option key={feature.id} value={feature.key ?? 0}>
                    {feature.label}
                </option>
            );
        });

        return serviceStateOptionElements;
    }, [features]);

    const serviceForm = (
        <UI.Form>
            <UI.Input label={"Service ID"} ref={register} name="id" type="text" readOnly={true} />
            <UI.Input
                label={"Name"}
                ref={register({ required: true })}
                name="name"
                errorMsg={errors.name ? "Pflichtfeld!" : undefined}
                className={dirtyFields.name ? styles.changed : ""}
                type="text"
                disabled={userRole && userRole === CustomerRole.CUSTOMERSUPPORT}
            />
            <UI.Input
                label={"Typ"}
                ref={register}
                name="type"
                as="select"
                className={dirtyFields.type ? styles.changed : ""}
                defaultValue={10}
                disabled={userRole && userRole === CustomerRole.CUSTOMERSUPPORT}
            >
                <option value={0}>Typ auswählen</option>
                <option value={ServiceType.Feature}>Feature</option>
                <option value={ServiceType.Integration}>Integration</option>
            </UI.Input>
            <UI.Input
                label={"Status"}
                ref={register}
                name="state"
                as="select"
                className={dirtyFields.state ? styles.changed : ""}
                disabled={userRole && userRole === CustomerRole.CUSTOMERSUPPORT && isNewService}
            >
                {isNewService ? (
                    <>
                        <option value={ServiceStates.HIDDEN}>versteckt</option>
                    </>
                ) : (
                    getServiceStateOptionElements()
                )}
            </UI.Input>

            <UI.Input
                label={"URL zu Preisslider"}
                ref={register}
                name="pricelink"
                type="text"
                className={dirtyFields.pricelink ? styles.changed : ""}
                disabled={userRole && userRole === CustomerRole.CUSTOMERSUPPORT}
            />

            <UI.Input
                label={"Bemerkungen"}
                ref={register}
                name="remarks"
                type="text"
                as="textarea"
                className={dirtyFields.remarks ? styles.changed : ""}
                disabled={userRole && userRole === CustomerRole.CUSTOMERSUPPORT}
            />

            <UI.Input
                label={"Features"}
                ref={register}
                name="feature"
                as="select"
                className={dirtyFields.feature ? styles.changed : ""}
                disabled={userRole && userRole === CustomerRole.CUSTOMERSUPPORT && isNewService}
            >
                {isNewService ? (
                    <>
                        <option value={ServiceStates.HIDDEN}>versteckt</option>
                    </>
                ) : (
                    getServiceFeaturesOptionElements()
                )}
            </UI.Input>

            <UI.Checkbox
                key={"chargeable"}
                ref={register}
                name="chargeable"
                label={"Kostenpflichtig"}
                checkboxValue={dirtyFields.chargeable ? 1 : 0}
                defaultChecked={false}
                className={dirtyFields.chargeable ? styles.changed : ""}
                disabled={userRole && userRole === CustomerRole.CUSTOMERSUPPORT}
            />

            <UI.Checkbox
                key={"enableable"}
                ref={register}
                name="enableable"
                label={"Aktivierbar"}
                checkboxValue={dirtyFields.enableable ? 1 : 0}
                defaultChecked={false}
                className={dirtyFields.enableable ? styles.changed : ""}
                disabled={userRole && userRole === CustomerRole.CUSTOMERSUPPORT}
            />

            <UI.Button
                type="submit"
                label={currentStatus === FORM_STATUS.SAVING_FORM ? "Speichert..." : "Speichern"}
                disabled={!formState.isDirty || currentStatus === FORM_STATUS.SAVING_FORM}
                onClick={handleSubmit(handleFormSubmit)}
                className={`${styles.FormButton} customer-button`}
                ref={refSubmitButtom}
            />
            <UI.Button
                label={"Zurücksetzen"}
                disabled={!formState.isDirty || currentStatus === FORM_STATUS.SAVING_FORM}
                onClick={resetForm}
                className={`${styles.FormButton} customer-button ml-sm-2 mt-2 mt-sm-0`}
            />
            {serviceDetails?.state === ServiceStates.HIDDEN ? serviceDeleteDialog : undefined}
            {props.children}
        </UI.Form>
    );

    const onClickUploadMedia = useCallback((e: any) => {
        e.preventDefault();
        hiddenMediaInputButton?.current?.click();
    }, []);

    const isImage = useCallback((extension: string) => {
        if (
            extension === "jpg" ||
            extension === "jpeg" ||
            extension === "gif" ||
            extension === "png" ||
            extension === "svg"
        ) {
            return true;
        } else {
            return false;
        }
    }, []);

    const onHandleChangeHiddenInput = useCallback(
        async (event: FileHTMLInputEvent) => {
            setIsUploadingMediaFile(true);

            if (event?.target?.files) {
                const files = Array.from(event.target.files);

                if (files.length > 0) {
                    const file: File = files[0];
                    const extension = file.name.substring(file.name.indexOf(".") + 1);

                    if (isImage(extension)) {
                        if (authStore.user && authStore.token) {
                            const accessToken = authStore.token;
                            const tokenType = authStore.tokenType;
                            const role = authStore.user?.role;

                            try {
                                let oldLogoDeleted = false;
                                let oldFileId = "";
                                if (mediaFiles !== undefined && mediaFiles.length > 0) {
                                    oldFileId = mediaFiles[0].fileId;
                                    const fetchResultDelete = await fetch(
                                        `${NetworkConfig.deleteServiceFileUrl}/${oldFileId}?lang=de`,
                                        {
                                            method: "PUT",
                                            body: JSON.stringify({}),
                                            headers: {
                                                "Content-type": "multipart/form-data",
                                                Authorization: `${tokenType} ${accessToken}`,
                                                "x-hasura-role": getRoleKey(role)
                                            }
                                        }
                                    );

                                    if (fetchResultDelete.status === 200) {
                                        oldLogoDeleted = true;
                                        const newMediaFilesArray = mediaFiles.filter(
                                            (file) => file.fileId !== oldFileId
                                        );
                                        setMediaFiles(newMediaFilesArray);
                                    } else {
                                        oldLogoDeleted = false;
                                    }
                                } else {
                                    oldLogoDeleted = true;
                                }
                                if (oldLogoDeleted) {
                                    let formData = new FormData();

                                    let resizedFile = await resizeImageFile(file);

                                    formData.append(resizedFile.name, resizedFile);

                                    const fetchResult = await fetch(
                                        `${NetworkConfig.uploadServiceLogoUrl}/${serviceId}?lang=de`,
                                        {
                                            method: "POST",
                                            body: formData,
                                            headers: {
                                                "Content-type": "multipart/form-data",
                                                Authorization: `${tokenType} ${accessToken}`,
                                                "x-hasura-role": getRoleKey(role)
                                            }
                                        }
                                    );

                                    if (fetchResult.status === 200) {
                                        try {
                                            const result = await fetchResult.json();

                                            setMediaFiles((oldMediaFiles) => {
                                                const fileId = result.data.FileId;
                                                const fileName = result.data.FileName;
                                                const fileExtension = result.data.FileExtension;
                                                const islogo = result.data.islogo;

                                                return [
                                                    ...oldMediaFiles,
                                                    {
                                                        fileId,
                                                        fileName,
                                                        fileExtension,
                                                        islogo,
                                                        fileUrl: `https://${getEnvironment()}.wwportal.ch/files/services/${serviceId}/de/${fileId}.${fileExtension}`
                                                    }
                                                ];
                                            });
                                        } catch (jsonError) {
                                            console.log("jsonError: ", jsonError);
                                            setIsUploadingMediaFile(false);
                                        }

                                        uiStore.printStatusMessage("Datei erfolgreich hochgeladen", MessageType.INFO);
                                    } else {
                                        const returnValue = await fetchResult.json();
                                        uiStore.printStatusMessage(returnValue.message, MessageType.ERROR);
                                    }
                                }
                            } catch (fetchError) {
                                uiStore.printStatusMessage(fetchError.error, MessageType.ERROR);
                            } finally {
                                setIsUploadingMediaFile(false);
                            }
                        }
                    } else {
                        setIsUploadingMediaFile(false);
                    }
                }
            }
        },
        [serviceId, authStore.token, authStore.tokenType, authStore.user, uiStore, mediaFiles, isImage]
    );

    const onDeleteMedia = useCallback(
        (fileId: string) => async () => {
            if (authStore.user && authStore.token) {
                const accessToken = authStore.token;
                const tokenType = authStore.tokenType;
                const role = authStore.user?.role;

                try {
                    const fetchResult = await fetch(`${NetworkConfig.deleteServiceFileUrl}/${fileId}?lang=de`, {
                        method: "PUT",
                        body: JSON.stringify({}),
                        headers: {
                            "Content-type": "multipart/form-data",
                            Authorization: `${tokenType} ${accessToken}`,
                            "x-hasura-role": getRoleKey(role)
                        }
                    });

                    if (fetchResult.status === 200) {
                        uiStore.printStatusMessage("Datei erfolgreich gelöscht", MessageType.INFO);
                        const newMediaFilesArray = mediaFiles.filter((file) => file.fileId !== fileId);
                        setMediaFiles(newMediaFilesArray);
                    } else {
                        const returnValue = await fetchResult.json();
                        uiStore.printStatusMessage(returnValue.message, MessageType.ERROR);
                    }
                } catch (fetchError) {
                    uiStore.printStatusMessage(fetchError.error, MessageType.ERROR);
                }
            }
        },
        [authStore.token, authStore.tokenType, authStore.user, uiStore, mediaFiles]
    );

    const renderMediaInformation = (mediaFile: MediaFile, index: number) => {
        return (
            <div key={mediaFile.fileId}>
                {index > 0 ? <hr className={styles.ImageDivider} /> : undefined}

                {isImage(mediaFile.fileExtension) ? (
                    <div>
                        <img className="w-100" src={mediaFile.fileUrl} alt="Media-Preview" />
                    </div>
                ) : undefined}

                <div className="my-2">FileId: {mediaFile.fileId}</div>
                <div className="my-2">FileName: {mediaFile.fileName}</div>
                <div className="d-flex">
                    <div className="d-flex align-items-center">
                        <UI.Icon
                            onClick={onDeleteMedia(mediaFile.fileId)}
                            icon={UI.SVGIcon.CloseCircle}
                            color={ColorStyle("black")}
                        />
                        <div>Löschen</div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <React.Fragment>
            <UI.Container className="pt-4">
                <UI.Row>
                    <UI.Col md={9}>
                        <UI.Card>
                            {saveFormModal}
                            {serviceForm}
                        </UI.Card>
                        {serviceDetails?.service_mls ? (
                            <UI.Card title="Inhalte">
                                <ServiceContentList serviceMls={serviceDetails?.service_mls} />
                            </UI.Card>
                        ) : undefined}
                    </UI.Col>
                    <UI.Col md={3}>
                        <div className={`${styles.MediaContainer} p-3`}>
                            <div className="mb-2">
                                {props.serviceDetails?.type === ServiceType.Feature
                                    ? "Feature Logo"
                                    : "Integration Logo"}
                            </div>
                            <UI.Button
                                label={isUploadingMediaFile ? "Wird hochgeladen" : "Logo auswählen"}
                                disabled={isUploadingMediaFile}
                                onClick={onClickUploadMedia}
                                className="customer-button w-100"
                            />
                            <input
                                ref={hiddenMediaInputButton}
                                type="file"
                                className="d-none"
                                onChange={onHandleChangeHiddenInput}
                            />
                        </div>
                        <div className={`${styles.MediaContainer} mt-3 p-3`}>
                            <div>{mediaFiles.map(renderMediaInformation)}</div>
                        </div>
                    </UI.Col>
                </UI.Row>
            </UI.Container>
        </React.Fragment>
    );
};

export const ServiceForm = Sentry.withProfiler(observer(ServiceFormBase));
