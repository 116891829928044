import React, { useContext } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { UI } from "@wwimmo/ui";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "src/stores/RootStore";
import { ColorStyle } from "src/utils/Colors";
import "./Backnavigation.css";

export const BacknavigationBase = () => {
    const { navStore } = useContext(RootStoreContext);

    if (!navStore.back) {
        return <></>;
    } else {
        return (
            <LinkContainer className="Backnavigation cursor-pointer" to={navStore.back.to}>
                <span>
                    <UI.Icon icon={UI.SVGIcon.ChevronLeft} color={ColorStyle("white")} />
                    {navStore.back.label}
                </span>
            </LinkContainer>
        );
    }
};

export const Backnavigation = observer(BacknavigationBase);
