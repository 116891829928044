import React, { useContext, useCallback } from "react";
import { GetUsers_users as umsUser } from "src/api/generated/GetUsers";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { Route } from "src/config/routes";
import { observer } from "mobx-react-lite";
import { UI, ScreenSize, DropdownItem } from "@wwimmo/ui";

interface UserListProps {
    users: umsUser[];
    sortItems: DropdownItem[];
    onSearch: (searchArguments: { searchQuery?: string; filterItemValue?: string; sortItemValue?: string }) => void;
    onChangeSort: (searchArguments: { searchQuery?: string; filterItemValue?: string; sortItemValue?: string }) => void;
    searchAppendElement?: React.ReactNode;
}

const UserList = (props: UserListProps) => {
    const { t } = useTranslation();

    const { uiStore, navStore } = useContext(RootStoreContext);

    const listIdentifier = "userlist";

    if (!navStore.listMap.has(listIdentifier)) {
        navStore.setListMapValue(listIdentifier, {});
    }

    const listParameters = navStore.getListMapValue(listIdentifier);

    let currentSearchQuery: string | undefined = undefined;

    if (listParameters && listParameters.searchQuery) {
        currentSearchQuery = listParameters.searchQuery;
    }

    let currentSortQuery: string | undefined = undefined;

    if (listParameters && listParameters.sortValue) {
        currentSortQuery = listParameters.sortValue;
    }

    const onRowClick = useCallback(() => {
        navStore.setListMapValue(
            listIdentifier,
            listParameters
                ? { ...listParameters, scrollPosition: window.pageYOffset }
                : { scrollPosition: window.pageYOffset }
        );

        navStore.setBackbutton(Route.users, t("common.users"));
    }, [navStore, t, listParameters]);

    const onPageChanged = useCallback(
        (currentPage: number) => {
            navStore.setListMapValue(
                listIdentifier,
                listParameters
                    ? { ...listParameters, scrollPosition: window.pageYOffset, paginationPage: currentPage }
                    : { scrollPosition: window.pageYOffset, paginationPage: currentPage }
            );
        },
        [navStore, listParameters]
    );

    const renderItem = useCallback(
        (user: umsUser, screenSize: ScreenSize) => {
            let listRows: React.ReactElement[] = [];

            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={user.name ? user.name : ""} />
                            <UI.List.Cell key={"c-2"} colspan={1} value={user.email ? user.email : ""} />
                            <UI.List.Cell key={"c-3"} colspan={1} value={user.customername ? user.customername : ""} />
                            <UI.List.Cell key={"c-4"} colspan={0.3} value={user.registered ? "Ja" : "Nein"} />
                            <UI.List.Cell key={"c-5"} colspan={0.3} value={user.activated ? "Ja" : "Nein"} />
                            <UI.List.Cell key={"c-6"} colspan={0.3} value={user.system ? "Ja" : "Nein"} />
                            <UI.List.Cell key={"c-7"} colspan={0.3} value={user.blocked ? "Ja" : "Nein"} />
                            <UI.List.Cell key={"c-8"} colspan={0.3} value={user.erpcloud ? "Ja" : "Nein"} />
                        </UI.List.Row>
                    ];
                    break;
                case ScreenSize.MOBILE:
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={user.name ? user.name : ""} />
                        </UI.List.Row>
                    ];
                    break;
            }

            const link = `/users/${user.customerid}/${user.userid}`;

            return (
                <UI.List.Item
                    key={`${user.userid}-${user.customerid}`}
                    screenSize={screenSize}
                    rows={listRows}
                    to={link}
                    onClick={onRowClick}
                />
            );
        },
        [onRowClick]
    );

    const renderHeader = useCallback(
        (screenSize: ScreenSize) => {
            let headerRows: React.ReactElement[] = [];

            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    headerRows = [
                        <UI.List.Row key={"row-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={t("user.name")} />
                            <UI.List.Cell key={"c-2"} colspan={1} value={t("user.email")} />
                            <UI.List.Cell key={"c-3"} colspan={1} value={t("user.customer")} />
                            <UI.List.Cell key={"c-4"} colspan={0.3} value={t("user.registered")} />
                            <UI.List.Cell key={"c-5"} colspan={0.3} value={t("user.activated")} />
                            <UI.List.Cell key={"c-6"} colspan={0.3} value={t("user.system")} />
                            <UI.List.Cell key={"c-7"} colspan={0.3} value={t("user.locked")} />
                            <UI.List.Cell key={"c-8"} colspan={0.3} value={"ERP Cloud"} />
                        </UI.List.Row>
                    ];
                    break;
                case ScreenSize.MOBILE:
                    headerRows = [
                        <UI.List.Row key={"row-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={t("user.name")} />
                        </UI.List.Row>
                    ];
                    break;
            }

            return <UI.List.Header rows={headerRows} sticky={true} top={130} />;
        },
        [t]
    );

    const initialSortItem = props.sortItems.find((item) => item.value === currentSortQuery);

    return (
        <UI.List.BasicList
            screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
            items={props.users}
            renderItem={renderItem}
            renderHeader={renderHeader}
            scrollPosition={listParameters?.scrollPosition ?? undefined}
            itemsPerPage={25}
            initialPage={listParameters?.paginationPage ?? undefined}
            onPageChanged={onPageChanged}
            onSearch={props.onSearch}
            initialSearchQuery={currentSearchQuery}
            sortItems={props.sortItems}
            initialSortItem={initialSortItem}
            onChangeSort={props.onChangeSort}
        />
    );
};

export default observer(UserList);
