export enum Role {
    NONE = "none",
    MANAGER = "manager",
    OWNER = "owner",
    CUSTOMERADMIN = "customeradmin",
    CUSTOMERDEV = "customerdev",
    CUSTOMERSUPPORT = "customersupport",
    UCCMADMIN = "uccmadmin"
}

export enum ErpType {
    RIMO = 1,
    IT2 = 2,
    FAIRWALTER = 5
}

interface RoleMapEntry {
    key: string;
    role: Role;
    rootRoute: string;
    profile: string;
    label: string;
    roleid: number;
}

export const RolesMap: Array<RoleMapEntry> = [
    {
        key: "customeradmin",
        role: Role.CUSTOMERADMIN,
        rootRoute: "/customers",
        profile: "x-hasura-30",
        label: "Kundenadministrator",
        roleid: 11
    },
    {
        key: "customerdev",
        role: Role.CUSTOMERDEV,
        rootRoute: "/customers",
        profile: "x-hasura-30",
        label: "Kundenadministrator Entwickler",
        roleid: 12
    },
    {
        key: "customersupport",
        role: Role.CUSTOMERSUPPORT,
        rootRoute: "/customers",
        profile: "x-hasura-30",
        label: "Support",
        roleid: 13
    },
    {
        key: "uccmadmin",
        role: Role.UCCMADMIN,
        rootRoute: "/feature-announcements",
        profile: "x-hasura-30",
        label: "UCCMADMIN",
        roleid: 14
    },
    {
        key: "manager",
        role: Role.MANAGER,
        rootRoute: "/manager",
        profile: "x-hasura-30",
        label: "Bewirtschafter",
        roleid: 30
    },
    { key: "owner", role: Role.OWNER, rootRoute: "/owner", profile: "x-hasura-31", label: "Eigentümer", roleid: 31 },
    { key: "none", role: Role.NONE, rootRoute: "/", profile: "", label: "", roleid: 0 }
];

const getValidRole = (role: string): Role => {
    const validRole = RolesMap.find((r) => r.key === role);
    return validRole ? validRole.role : Role.NONE;
};

const getValidRoleId = (role: string): number => {
    const validRole = RolesMap.find((r) => r.key === role);
    return validRole ? validRole.roleid : 0;
};

const getProfile = (role: Role): string => {
    const profile = RolesMap.find((r) => r.role === role);
    return profile ? profile.profile : "";
};

export const getRoleKey = (role: Role | undefined) => {
    const currentRole = RolesMap.find((r) => r.role === role);
    return currentRole ? currentRole.key : "";
};

export const getRoleLabel = (role: Role) => {
    return RolesMap.find((r) => r.role === role)?.label || "Unbekannt";
};

export class User {
    role: Role = Role.NONE;
    availableRoles: Role[] = [];
    availableCustomerManagementRoles: Role[] = [];
    availableRoleIds: number[] = [];
    userid?: string;
    username?: string;
    customerid?: string;
    ownerids: string[] = [];
    erpType?: ErpType;
    customerNumber?: string;
    isUccmAdmin: boolean = false;

    userIdCidaas: string = ""; // sub
    identityIdCidaas: string = "";

    error(message: string) {
        console.error(message);
    }

    async initOld(access_token: string) {
        if (access_token) {
            const token = await this.parseJwt(access_token);

            this.username = token.name;
            if (token.hasOwnProperty("hasura")) {
                const hasura = token["hasura"];

                if (hasura.hasOwnProperty("x-hasura-03")) {
                    this.userid = hasura["x-hasura-03"];
                } else {
                    this.error("no userid found!");
                    this.userid = "unknown";
                }

                if (hasura.hasOwnProperty("x-hasura-allowed-roles")) {
                    this.availableRoles = hasura["x-hasura-allowed-roles"].filter(
                        (role: string) => getValidRole(role) !== Role.NONE
                    );
                } else {
                    this.error("x-hasura-allowed-roles claim not found");
                    this.availableRoles = [];
                }

                if (hasura.hasOwnProperty("x-hasura-default-role")) {
                    this.role = getValidRole(hasura["x-hasura-default-role"]);
                } else {
                    this.error("x-hasura-default-role claim not found");
                    this.role = Role.NONE;
                }

                if (this.role === Role.CUSTOMERSUPPORT) {
                    if (hasura.hasOwnProperty("x-hasura-98")) {
                        const erp = hasura["x-hasura-98"];
                        this.erpType = erp;
                    }

                    if (hasura.hasOwnProperty("x-hasura-99")) {
                        const customerNumber = hasura["x-hasura-99"];
                        this.customerNumber = customerNumber;
                    }
                }

                if (this.availableRoles.includes(Role.UCCMADMIN)) {
                    this.isUccmAdmin = true;
                }

                this.customerid = hasura.hasOwnProperty("x-hasura-01") ? hasura["x-hasura-01"] : undefined;

                this.ownerids = [];
                if (this.role === Role.OWNER) {
                    const profile = getProfile(this.role);
                    this.ownerids = hasura[profile].split(",");
                }
            } else {
                this.error("no hasura claim found!");
            }
        }
    }

    async init(access_token: string, userIdCidaas: string, identityIdCidaas: string) {
        if (access_token) {
            const token = await this.parseJwt(access_token);

            this.userIdCidaas = userIdCidaas;
            this.identityIdCidaas = identityIdCidaas;

            this.username = token.email;
            this.customerid = token.hasOwnProperty("xhasura01") ? token["xhasura01"] : undefined;
            this.userid = token.hasOwnProperty("xhasura03") ? token["xhasura03"] : undefined;

            if (token.hasOwnProperty("roles")) {
                const roles = token["roles"];

                if (roles.length > 0) {
                    this.availableRoles = roles.filter((role: string) => getValidRole(role) !== Role.NONE);

                    this.availableCustomerManagementRoles = roles
                        .filter((role: string) => {
                            const roleId = getValidRoleId(role);

                            if (roleId >= 11 && roleId < 20) {
                                return true;
                            } else {
                                return false;
                            }
                        })
                        .sort((roleA: string, roleB: string) => {
                            const roleIdA = getValidRoleId(roleA);
                            const roleIdB = getValidRoleId(roleB);

                            return roleIdA - roleIdB;
                        })
                        .map((role: string) => getValidRole(role));

                    this.availableRoleIds = roles.map((role: string) => getValidRoleId(role));
                } else {
                    this.error("x-hasura-allowed-roles claim not found");
                    this.availableRoles = [];
                    this.availableRoleIds = [];
                    this.availableCustomerManagementRoles = [];
                }

                if (this.role === Role.CUSTOMERSUPPORT) {
                    if (token.hasOwnProperty("xhasura98")) {
                        const erp = token["xhasura98"];
                        this.erpType = erp;
                    }

                    if (token.hasOwnProperty("xhasura99")) {
                        const customerNumber = token["xhasura99"];
                        this.customerNumber = customerNumber;
                    }
                }

                if (this.availableRoles.includes(Role.UCCMADMIN)) {
                    this.isUccmAdmin = true;
                }

                if (this.availableCustomerManagementRoles.length > 0) {
                    this.role = getValidRole(this.availableCustomerManagementRoles[0]);
                } else if (this.availableRoles.length > 0) {
                    this.role = getValidRole(this.availableRoles[0]);
                } else {
                    this.error("no default role could be determined");
                    this.role = Role.NONE;
                }
            } else {
                this.error("no 'roles' claim found!");
            }
        }
    }

    private async parseJwt(token: string) {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split("")
                .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
                .join("")
        );

        return JSON.parse(jsonPayload);
    }
}
