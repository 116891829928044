import React, { useContext, useCallback } from "react";
import { GetUmsCustomers_customers as umsCustomer } from "src/api/generated/GetUmsCustomers";
import { useTranslation } from "react-i18next";
import { ErpType, CustomerType } from "src/utils/Enums";
import { RootStoreContext } from "src/stores/RootStore";
import { Route } from "src/config/routes";
import { observer } from "mobx-react-lite";
import { UI, ScreenSize } from "@wwimmo/ui";

interface CustomerListProps {
    umsCustomers: umsCustomer[];
    onSearch: (searchArguments: { searchQuery?: string; filterItemValue?: string; sortItemValue?: string }) => void;
    searchAppendElement?: React.ReactNode;
}

const CustomerList = (props: CustomerListProps) => {
    const { t } = useTranslation();
    const { uiStore, navStore } = useContext(RootStoreContext);

    const listIdentifier = "customerlist";

    if (!navStore.listMap.has(listIdentifier)) {
        navStore.setListMapValue(listIdentifier, {});
    }

    const listParameters = navStore.getListMapValue(listIdentifier);

    let currentSearchQuery: string | undefined = undefined;

    if (listParameters && listParameters.searchQuery) {
        currentSearchQuery = listParameters.searchQuery;
    }

    const onRowClick = useCallback(() => {
        navStore.setListMapValue(
            listIdentifier,
            listParameters
                ? { ...listParameters, scrollPosition: window.pageYOffset }
                : { scrollPosition: window.pageYOffset }
        );

        navStore.setBackbutton(Route.customers, t("common.customers"));
    }, [navStore, t, listParameters]);

    const onPageChanged = useCallback(
        (currentPage: number) => {
            navStore.setListMapValue(
                listIdentifier,
                listParameters
                    ? { ...listParameters, scrollPosition: window.pageYOffset, paginationPage: currentPage }
                    : { scrollPosition: window.pageYOffset, paginationPage: currentPage }
            );
        },
        [navStore, listParameters]
    );

    const renderItem = useCallback(
        (customer: umsCustomer, screenSize: ScreenSize) => {
            const userCount = customer.users_aggregate.aggregate?.count
                ? customer.users_aggregate.aggregate?.count.toString()
                : "0";

            let listRows: React.ReactElement[] = [];

            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1.2} value={customer.name ? customer.name : ""} />
                            <UI.List.Cell key={"c-2"} colspan={0.5} value={customer.number ? customer.number : ""} />
                            <UI.List.Cell key={"c-3"} colspan={0.5} value={userCount} />
                            <UI.List.Cell key={"c-4"} colspan={0.5} value={CustomerType[customer.type]} />
                            <UI.List.Cell key={"c-5"} colspan={0.5} value={ErpType[customer.erp]} />
                        </UI.List.Row>
                    ];
                    break;
                case ScreenSize.MOBILE:
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={customer.name ? customer.name : ""} />
                        </UI.List.Row>
                    ];
                    break;
            }

            const link = `/customers/${customer.id}`;

            return (
                <UI.List.Item
                    key={customer.id}
                    screenSize={screenSize}
                    rows={listRows}
                    to={link}
                    onClick={onRowClick}
                />
            );
        },
        [onRowClick]
    );

    const renderHeader = useCallback(
        (screenSize: ScreenSize) => {
            let headerRows: React.ReactElement[] = [];

            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    headerRows = [
                        <UI.List.Row key={"row-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1.2} value={t("customer.name")} />
                            <UI.List.Cell key={"c-2"} colspan={0.5} value={t("customer.customer_number")} />
                            <UI.List.Cell key={"c-3"} colspan={0.5} value={t("customer.users_amount")} />
                            <UI.List.Cell key={"c-4"} colspan={0.5} value={t("customer.type")} />
                            <UI.List.Cell key={"c-5"} colspan={0.5} value={t("customer.erp")} />
                        </UI.List.Row>
                    ];
                    break;

                case ScreenSize.MOBILE:
                    headerRows = [
                        <UI.List.Row key={"row-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1.2} value={t("customer.name")} />
                        </UI.List.Row>
                    ];
                    break;
            }

            return <UI.List.Header rows={headerRows} sticky={true} top={130} />;
        },
        [t]
    );

    return (
        <UI.List.BasicList
            screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
            items={props.umsCustomers}
            renderItem={renderItem}
            renderHeader={renderHeader}
            scrollPosition={listParameters?.scrollPosition ?? undefined}
            itemsPerPage={25}
            initialPage={listParameters?.paginationPage ?? undefined}
            onPageChanged={onPageChanged}
            onSearch={props.onSearch}
            searchAppendElement={props.searchAppendElement}
            initialSearchQuery={currentSearchQuery}
        />
    );
};

export default observer(CustomerList);
