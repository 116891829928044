import React, { useContext, useEffect, useCallback, useState } from "react";
import * as Sentry from "@sentry/react";
import { useQuery } from "@apollo/client";
import { GET_USER_DETAIL } from "src/api/user";
import { GetUserDetails, GetUserDetailsVariables } from "src/api/generated/GetUserDetails";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { RootStoreContext } from "src/stores/RootStore";
import * as Screens from "src/screens";
import { MessageType } from "src/components/notifications/Notifier";
import { UserDetailList } from "src/screens/dashboard/users/user/detail-list/UserDetailList";
import { observer } from "mobx-react-lite";
import { Role as CustomerRole, getRoleKey } from "src/network/User";
import styles from "./UserDetails.module.css";
import { NetworkConfig } from "src/network/NetworkConfig";

interface Params {
    customerid: string;
    userid: string;
}

enum UserUpdateAction {
    LOCK = "LOCK",
    UNLOCK = "UNLOCK",
    ACTIVATE = "ACTIVATE",
    DEACTIVATE = "DEACTIVATE",
    RESET = "RESET"
}

const UserDetailsBase = () => {
    const { t } = useTranslation();
    const { navStore, uiStore, authStore } = useContext(RootStoreContext);
    const { customerid, userid } = useParams<Params>();
    const [isUserResetModalDisplaying, setIsUserResetModalDisplaying] = useState(false);
    const [userStateIsUpdating, setUserStateIsUpdating] = useState(false);
    const [userIsReseting, setUserIsReseting] = useState(false);

    useEffect(() => {
        // if the navigation comes from inside the 'users' path set the backbutton to the user-list
        if (navStore.back && navStore.back.to.includes("/users")) {
            navStore.setTitle("Benutzer");
            navStore.setBackbutton("/users", "Benutzer");
        }
    }, [navStore]);

    const {
        loading: user_loading,
        error: user_error,
        data: user_data,
        refetch: refetchUserDetails
    } = useQuery<GetUserDetails, GetUserDetailsVariables>(GET_USER_DETAIL, {
        variables: {
            customerid: customerid,
            userid: userid
        }
    });

    useEffect(() => {
        navStore.setTitle(t("common.user"));

        if (user_data?.user && user_data.user.length > 0 && user_data.user[0].name1) {
            const user = user_data.user[0];
            const userName = user.name1 && user.name2 ? `${user.name1} ${user.name2}` : user.name1;
            navStore.setTitle(userName);
        }
    }, [user_data, navStore, t]);

    const updateUserStatus = useCallback(
        async (userUpdateAction: UserUpdateAction) => {
            setUserStateIsUpdating(true);

            const user = user_data?.user[0];

            if (authStore.user && user) {
                const accessToken = authStore.token;
                const tokenType = "Bearer";
                const role = authStore.user?.role;

                let action = undefined;
                let actionText = undefined;

                switch (userUpdateAction) {
                    case UserUpdateAction.LOCK:
                        action = UserUpdateAction.LOCK.toString();
                        actionText = "gesperrt";
                        break;
                    case UserUpdateAction.UNLOCK:
                        action = UserUpdateAction.UNLOCK.toString();
                        actionText = "entsperrt";
                        break;
                    case UserUpdateAction.ACTIVATE:
                        action = UserUpdateAction.ACTIVATE.toString();
                        actionText = "aktiviert";
                        break;
                    case UserUpdateAction.DEACTIVATE:
                        action = UserUpdateAction.DEACTIVATE.toString();
                        actionText = "deaktiviert";
                        break;
                    case UserUpdateAction.RESET:
                        action = UserUpdateAction.RESET.toString();
                        actionText = "zurückgesetzt";
                        break;
                }

                const requestBody = {
                    action: action,
                    registrationcode: user.registrationcode,
                    email: user.email ?? "",
                    userid: user.id,
                    customerid: user.customerid
                };

                try {
                    const fetchResult = await fetch(`${NetworkConfig.updateUserStatusUrl}`, {
                        method: "POST",
                        body: JSON.stringify(requestBody),
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `${tokenType} ${accessToken}`,
                            "x-hasura-role": getRoleKey(role)
                        }
                    });

                    if (fetchResult.status === 200) {
                        uiStore.printStatusMessage(`Der Benutzer wurde erfolgreich ${actionText}`, MessageType.INFO);
                    } else {
                        const returnValue = await fetchResult.json();

                        uiStore.printStatusMessage(
                            `Beim Aktualisieren des Benutzerstatus ist ein Fehler aufgetreten: ${returnValue.message}`,
                            MessageType.ERROR
                        );
                    }
                } catch (fetchError: any) {
                    const errorMessage = fetchError.error ?? fetchError.message ?? fetchError;
                    const error = `Beim Aktualisieren des Benutzerstatus ist ein Fehler aufgetreten: ${errorMessage}`;
                    uiStore.printStatusMessage(error, MessageType.ERROR);

                    console.error(error);
                }
            }

            setUserStateIsUpdating(false);
        },
        [authStore.token, authStore.user, uiStore, user_data?.user]
    );

    // Ein Benutzer wird aktiviert, indem man ihn im Cidaas die E-Mail-Adresse aktivert und vice versa
    const toggleActiveUser = useCallback(async () => {
        if (user_data && user_data.user) {
            if (user_data.user[0].emailverified) {
                await updateUserStatus(UserUpdateAction.DEACTIVATE);
            } else {
                await updateUserStatus(UserUpdateAction.ACTIVATE);
            }
            refetchUserDetails();
            uiStore.setHasNewUserData(true);
        }
    }, [user_data, refetchUserDetails, uiStore, updateUserStatus]);

    // Ein Benutzer wird gesperrt, indem man ihn in Cidaas deaktiviert und vice versa
    const toggleLockUser = useCallback(async () => {
        if (user_data && user_data.user) {
            if (user_data.user[0].activated) {
                await updateUserStatus(UserUpdateAction.LOCK);
            } else {
                await updateUserStatus(UserUpdateAction.UNLOCK);
            }
            refetchUserDetails();
            uiStore.setHasNewUserData(true);
        }
    }, [user_data, refetchUserDetails, uiStore, updateUserStatus]);

    const resetUser = useCallback(async () => {
        if (user_data && user_data.user) {
            setUserIsReseting(true);
            await updateUserStatus(UserUpdateAction.RESET);

            setUserIsReseting(false);
            setIsUserResetModalDisplaying(false);
            refetchUserDetails();
            uiStore.setHasNewUserData(true);
        }
    }, [user_data, updateUserStatus, refetchUserDetails, uiStore]);

    const inviteUser = useCallback(async () => {
        if (user_data && user_data.user && user_data.user.length > 0) {
            const resendInvitationEndpointUrl = `${NetworkConfig.resendInvitation}`;

            if (authStore.user && authStore.token) {
                const accessToken = authStore.token;
                const tokenType = authStore.tokenType;
                const role = authStore.user?.role;
                const body = {
                    invitationid: user_data.user[0].invitationid,
                    email: user_data.user[0].email
                };

                try {
                    const fetchResult = await fetch(resendInvitationEndpointUrl, {
                        method: "POST",
                        body: JSON.stringify(body),
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `${tokenType} ${accessToken}`,
                            "x-hasura-role": getRoleKey(role)
                        }
                    });

                    if (fetchResult.status === 200) {
                        uiStore.printStatusMessage(
                            `Einladungsemail wurde an ${user_data.user[0].email} gesendet`,
                            MessageType.INFO
                        );
                    } else {
                        const response = await fetchResult.json();

                        uiStore.printStatusMessage(
                            `Es ist ein Fehler aufgetreten: ${response.message}`,
                            MessageType.ERROR
                        );
                    }
                } catch (fetchError) {
                    uiStore.printStatusMessage(`Es ist ein Fehler aufgetreten: ${fetchError}`, MessageType.ERROR);
                }
            } else {
                uiStore.printStatusMessage(
                    "Keine Authorisierungs-Informationen vorhanden. Bitte lade die Seite neu.",
                    MessageType.ERROR
                );
            }
        }
    }, [user_data, uiStore, authStore]);

    const toggleResetUserModal = useCallback(() => setIsUserResetModalDisplaying((isDisplaying) => !isDisplaying), []);

    const userRole = authStore.user ? authStore.user.role : undefined;

    const UserLockButton = () => {
        const isRegisteredButNotActivated =
            userRole === CustomerRole.CUSTOMERSUPPORT && user.registered && !user.activated;
        const isNotRegisteredOrUpdating =
            !user.registered || userStateIsUpdating || userIsReseting || userRole !== CustomerRole.CUSTOMERDEV;

        const isDisabled = isRegisteredButNotActivated ? false : isNotRegisteredOrUpdating;

        return (
            <UI.Button
                label={user.activated ? "Sperren" : "Entsperren"}
                type="button"
                onClick={toggleLockUser}
                disabled={isDisabled}
                className="mb-2 mr-2 customer-button"
            />
        );
    };

    const UserActivateButton = () => {
        return (
            <UI.Button
                label={user.emailverified ? "Deaktivieren" : "Aktivieren"}
                type="button"
                onClick={toggleActiveUser}
                disabled={
                    !user.registered || userStateIsUpdating || userIsReseting || userRole !== CustomerRole.CUSTOMERDEV
                }
                className="mb-2 mr-2 customer-button"
            />
        );
    };

    const UserResetButton = () => {
        const isNotRegisteredOrUpdating = !user.registered || userIsReseting || userStateIsUpdating;
        const isDisabled =
            userRole === CustomerRole.CUSTOMERSUPPORT
                ? isNotRegisteredOrUpdating
                : userRole !== CustomerRole.CUSTOMERDEV;
        return (
            <>
                <UI.Button
                    label={userIsReseting ? "Wird zurückgesetzt" : "Zurücksetzen"}
                    className="mb-2 mr-2 customer-button"
                    onClick={toggleResetUserModal}
                    disabled={isDisabled}
                />

                <UI.Modal
                    backdrop={"static"}
                    title={"Benutzer zurücksetzen"}
                    show={isUserResetModalDisplaying}
                    onClose={toggleResetUserModal}
                >
                    <div>
                        <p>Willst du den Benutzer wirklich zurücksetzen?</p>

                        {userIsReseting ? (
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <span>Benutzer wird zurückgesetzt...</span>
                                <UI.RotatingSpinner noLogo={true} size={35} className={"align-items-start"} />
                            </div>
                        ) : (
                            <UI.Button
                                label={"Ja"}
                                disabled={userIsReseting}
                                onClick={resetUser}
                                className={`${styles.modalBtn} mr-2 customer-button`}
                                variant="danger"
                            />
                        )}
                    </div>
                </UI.Modal>
            </>
        );
    };

    const UserInviteButton = () => {
        const isUnactivatedERPCloudUser =
            user_data?.user && user_data.user[0].erpcloud === true && user_data.user[0].emailverified === false;
        const isAllowedRole =
            userRole === CustomerRole.CUSTOMERSUPPORT ||
            userRole === CustomerRole.CUSTOMERDEV ||
            userRole === CustomerRole.CUSTOMERADMIN;

        if (!isUnactivatedERPCloudUser || !isAllowedRole || user.registered) {
            return null;
        }

        return (
            <UI.Button
                label={"Einladung senden"}
                type="button"
                onClick={inviteUser}
                className="mb-2 mr-2 customer-button"
                disabled={user_data.user[0].state !== 3 || user_data.user[0].invitationid === null}
            />
        );
    };

    if (user_loading) {
        return <Screens.Loading />;
    }
    if (user_error) {
        return <Screens.Error message={user_error.message} networkError={user_error.networkError} />;
    }
    if (!user_data) {
        return <Screens.Error message={t("error.nodata")} />;
    }

    if (user_data.user === null) {
        return <Screens.Error message={t("error.emptydata")} />;
    }

    const user = user_data.user[0];

    return (
        <React.Fragment>
            <UI.Container className="pt-4">
                <UI.Row>
                    <UI.Col md={{ span: 8, offset: 2 }}>
                        <UI.Card>
                            <UI.Form className={styles.UserDetailForm}>
                                <UserDetailList user={user} />
                                <div className={`${styles.userDetailButtons} mt-md-5 mt-4`}>
                                    <UserLockButton />
                                    <UserActivateButton />
                                    <UserResetButton />
                                    <UserInviteButton />
                                </div>
                            </UI.Form>
                        </UI.Card>
                    </UI.Col>
                </UI.Row>
            </UI.Container>
        </React.Fragment>
    );
};

export const UserDetails = Sentry.withProfiler(observer(UserDetailsBase));
