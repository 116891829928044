import i18n from "i18next";
import XHR from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import translationDE from "src/assets/locales/de/translation.json";
import disclaimersDE from "src/assets/locales/de/disclaimers.json";

const resources = {
    de: {
        translation: translationDE,
        disclaimers: disclaimersDE
    }
};

i18n.use(XHR)
    .use(initReactI18next)
    .init({
        resources,
        lng: "de",
        debug: false,
        load: "languageOnly",
        interpolation: {
            escapeValue: false
        }
    });
export const getLang = (): string => {
    return i18n.language.split("-")[0];
};

export default i18n;
