import React, { useContext, useCallback } from "react";
import { UI } from "@wwimmo/ui";
import { RootStoreContext } from "src/stores/RootStore";
import { useTranslation } from "react-i18next";
import styles from "./InviteUser.module.css";
import { InviteUserProgressModal } from "./invite-user-modal/InviteUserProgressModal";
import { CustomerDetailsType } from "src/screens/dashboard/customers/details/detail/CustomerDetail";

interface InviteUserProps {
    customerDetails?: CustomerDetailsType;
}

const InviteUser = (props: InviteUserProps) => {
    const { t } = useTranslation();
    const { inviteUserProgressModalStore } = useContext(RootStoreContext);

    const onClickUserImportButton = useCallback(() => {
        inviteUserProgressModalStore.setisInviteUserProgressModalDisplayed(true);
    }, [inviteUserProgressModalStore]);

    return (
        <>
            <UI.Button
                label={t("invite_user.title")}
                className={styles.buttonUserImport}
                onClick={onClickUserImportButton}
            />
            <InviteUserProgressModal customerDetails={props.customerDetails} />
        </>
    );
};

export default InviteUser;
