import React, { useState, useCallback } from "react";
import { Doughnut as DoughnutChartjs } from "react-chartjs-2";
import { ChartConfig } from "src/config/charts";

interface DoughnutProps {
    data: DoughnutData;
}
export interface DoughnutHint {
    title: string;
    label: string;
}
interface DoughnutData {
    labels: string[];
    datasets: {
        label: string;
        data: number[];
        backgroundColor: string[];
        hint?: DoughnutHint[];
    }[];
    centerText?: string;
}

export const Doughnut = (props: DoughnutProps) => {
    const [dimension, setDimension] = useState({
        width: undefined,
        height: undefined
    });
    const { data } = props;
    const ref = useCallback((node) => {
        if (node !== null) {
            setDimension({
                width: node.getBoundingClientRect().width,
                height: node.getBoundingClientRect().height
            });
        }
    }, []);
    const options = {
        legend: {
            display: false
        },
        cutoutPercentage: 70,
        animation: {
            animateScale: true,
            animateRotate: false,
            duration: ChartConfig.animationDuration
        },
        tooltips: {
            enabled: true,
            displayColors: false,
            callbacks: {
                title: function (tooltipItem: any, data: any) {
                    const hint = data.datasets[0].hint[tooltipItem[0].index];
                    return hint.title;
                },
                label: (tooltipItem: any, data: any) => {
                    const hint = data.datasets[0].hint[tooltipItem.index];
                    return hint.label;
                }
            }
        },
        hover: {
            mode: undefined
        }
    };

    const center = props.data.centerText ? <h1 className="Overlay">{props.data.centerText}</h1> : React.Fragment;
    const bullet = (index: number) => {
        const size = { width: 10, height: 10 };
        return (
            <svg {...size}>
                <rect {...size} fill={props.data.datasets[0].backgroundColor[index] || "black"} />
            </svg>
        );
    };
    const newData = { ...data };
    newData.datasets.forEach((dataset) => {
        if (dataset.data.length === 1) {
            // Only ONE value available. Add rest of 100.
            dataset.data.push(dataset.data[0] - 100);
        }
    });
    return (
        <div className="Doughnut">
            <div className="Container" ref={ref}>
                {center}
                {dimension.width && dimension.height && (
                    <DoughnutChartjs
                        height={dimension.height}
                        width={dimension.width}
                        data={newData}
                        options={options}
                    />
                )}
            </div>
            <div className="DoughnutLegend">
                <ul>
                    {newData.labels.map((legend: string, index: number) => (
                        <li key={`legend-${index}`}>
                            {bullet(index)} &nbsp;
                            {legend}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};
