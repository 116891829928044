import { observable, action, runInAction, makeObservable } from "mobx";
import { ColorStyle } from "src/utils/Colors";
import { Notification } from "src/types";
import { MessageType } from "src/components/notifications/Notifier";

const headerHeights = {
    mobile: 100,
    desktop: 80
};

const STATUS_MESSAGE_DEFAULT_CLOSE_TIME = 3000;

export class UIStore {
    @observable showNavigationBar = true;
    @observable isMobile = false;
    @observable showSearchbar = false;
    @observable closeAppbar = false;
    @observable headerHeight = 80;
    @observable subHeaderHeight = 51;
    @observable backgroundColor = ColorStyle("white");
    @observable shouldShowLoginNotification: boolean = false;
    @observable notifications: Notification[] = [];
    @observable displayedNotifications: number[] = [];
    @observable hasNewCustomerData: boolean = false;
    @observable hasNewUserData: boolean = false;
    @observable hasNewConnectionData: boolean = false;
    @observable hasNewAnnouncementData: boolean = false;
    @observable hasNewAnnouncementContentData: boolean = false;
    @observable hasNewServiceData: boolean = false;
    @observable hasNewServiceContentData: boolean = false;

    constructor() {
        runInAction(() => {
            window.addEventListener("resize", this.onWindowResize);
            this.setIsMobile((this.isMobile = window.innerWidth < 768));
            this.setHeaderHeight();
        });

        makeObservable(this);
    }

    @action
    setHasNewConnectionData = (hasNewConnectionData: boolean) => {
        this.hasNewConnectionData = hasNewConnectionData;
    };

    @action
    setHasNewCustomerData = (hasNewCustomerData: boolean) => {
        this.hasNewCustomerData = hasNewCustomerData;
    };

    @action
    setHasNewUserData = (hasNewUserData: boolean) => {
        this.hasNewUserData = hasNewUserData;
    };

    @action
    setHasNewAnnouncementData = (hasNewAnnouncementData: boolean) => {
        this.hasNewAnnouncementData = hasNewAnnouncementData;
    };

    @action
    setHasNewAnnouncementContentData = (hasNewAnnouncementContentData: boolean) => {
        this.hasNewAnnouncementContentData = hasNewAnnouncementContentData;
    };

    @action
    setHasNewServiceData = (hasNewServiceData: boolean) => {
        this.hasNewServiceData = hasNewServiceData;
    };

    @action
    setHasNewServiceContentData = (hasNewServiceContentData: boolean) => {
        this.hasNewServiceContentData = hasNewServiceContentData;
    };

    @action
    setSearchbar = (toggle: boolean) => {
        this.setHeaderHeight(toggle ? headerHeights.desktop : undefined);
        this.showSearchbar = toggle;
    };

    @action
    setIsMobile = (isMobile: boolean) => {
        this.isMobile = isMobile;
    };

    @action
    setHeaderHeight(overwriteHeight?: number) {
        this.headerHeight =
            overwriteHeight !== undefined
                ? overwriteHeight
                : this.isMobile
                ? headerHeights.mobile
                : headerHeights.desktop;
    }

    @action
    setCloseAppBar = (closeAppBar: boolean) => {
        this.closeAppbar = closeAppBar;
    };

    @action
    setBackgroundColor = (backgroundColor: string) => {
        this.backgroundColor = backgroundColor;
    };

    @action
    setShouldShowLoginNotification = (shouldShow: boolean) => {
        this.shouldShowLoginNotification = shouldShow;
    };

    @action
    enqueueSnackbar = (note: Notification) => {
        this.notifications.push({
            key: new Date().getTime() + Math.random(),
            ...note
        });
    };

    @action
    removeSnackbar = (note: Notification) => {
        this.notifications = this.notifications.filter((not) => not.key !== note.key);
    };

    @action
    storeDisplayedNotification = (key: number) => {
        this.displayedNotifications = [...this.displayedNotifications, key];
    };

    @action
    private onWindowResize = () => {
        this.setIsMobile(window.innerWidth < 768);
        this.setHeaderHeight();
    };

    @action
    reset = () => {
        this.shouldShowLoginNotification = false;
        this.notifications = [];
        this.displayedNotifications = [];
    };

    @action
    printStatusMessage = (message: string, messageType: MessageType) => {
        this.enqueueSnackbar({
            content: message,
            options: {
                type: messageType,
                autoClose: messageType === MessageType.ERROR ? false : STATUS_MESSAGE_DEFAULT_CLOSE_TIME
            }
        });
    };
}
