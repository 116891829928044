import React, { useCallback } from "react";
import { InfoContainer } from "src/screens/dashboard/customers/details/detail/info-container/InfoContainer";
import {
    GetUmsCustomerDetailsById_ums_customer_details_file_usage,
    GetUmsCustomerDetailsById_ums_customer_details_files_with_error_count
} from "src/api/generated/GetUmsCustomerDetailsById";
import { dateFormat } from "src/utils/Date";
import { Link } from "react-router-dom";

interface CustomerFileUsageProps {
    customerFileUsage?: GetUmsCustomerDetailsById_ums_customer_details_file_usage[];
    filesWithErrors?: GetUmsCustomerDetailsById_ums_customer_details_files_with_error_count;
}

interface FileUsageInfoType {
    fileCount?: string | null;
    usedKb?: string | null;
    udpated?: string | null;
}

enum FILE_USAGE {
    FILE_COUNT = "FILES.FILE_COUNT",
    USED_KB = "FILES.USED_KB"
}

const CustomerFileUsage = (props: CustomerFileUsageProps) => {
    const getFormattedFileSize = (fileSize: string): string => {
        let fileSizeInt: number = 0;
        let fileSizeUnit: string = "KB";

        fileSizeInt = parseInt(fileSize);

        if (fileSizeInt / 1000000 >= 1) {
            fileSizeInt /= 1000000;
            fileSizeUnit = "GB";
        } else if (fileSizeInt / 1000 >= 1) {
            fileSizeInt /= 1000;
            fileSizeUnit = "MB";
        }

        return `${fileSizeInt} ${fileSizeUnit}`;
    };

    const getFileUsageInfo = useCallback(
        (fileUsage: GetUmsCustomerDetailsById_ums_customer_details_file_usage[]): FileUsageInfoType => {
            const fileCount: GetUmsCustomerDetailsById_ums_customer_details_file_usage = fileUsage.filter(
                (item) => item.key === FILE_USAGE.FILE_COUNT
            )[0];

            const usedKb: GetUmsCustomerDetailsById_ums_customer_details_file_usage = fileUsage.filter(
                (item) => item.key === FILE_USAGE.USED_KB
            )[0];

            let usedFileSize = undefined;

            if (usedKb.value) {
                usedFileSize = getFormattedFileSize(usedKb.value);
            }

            return {
                fileCount: fileCount.value,
                usedKb: usedFileSize,
                udpated: dateFormat(new Date(fileCount.updated), "dd.MM.yyyy, HH:mm:ss")
            };
        },
        []
    );

    const { customerFileUsage, filesWithErrors } = props;

    let fileUsageData = undefined;

    if (customerFileUsage && customerFileUsage.length > 0) {
        fileUsageData = getFileUsageInfo(customerFileUsage);
    }

    let hasFilesWithErrors: boolean = false;

    if (
        filesWithErrors &&
        filesWithErrors.aggregate &&
        filesWithErrors.aggregate.count &&
        filesWithErrors.aggregate.count > 0
    ) {
        hasFilesWithErrors = true;
    }

    if (!fileUsageData && !hasFilesWithErrors) {
        return <></>;
    }

    let customerLogLink = undefined;

    if (hasFilesWithErrors) {
        customerLogLink = <Link to={"faulty-files"}>{"Ansehen >"}</Link>;
    }

    const fileUsageInfoItems = [
        { infoHeading: "Anzahl Dateien", infoValue: fileUsageData?.fileCount },
        { infoHeading: "Benötigter Speicherplatz", infoValue: fileUsageData?.usedKb },
        { infoHeading: "Aktualisiert am", infoValue: fileUsageData?.udpated },
        { infoHeading: "Dateien mit Fehlern", infoValue: customerLogLink }
    ];

    return <InfoContainer infoItems={fileUsageInfoItems} />;
};

export default CustomerFileUsage;
