import React, { useContext, useEffect, useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { UI } from "@wwimmo/ui";
import { Patterns } from "src/utils/Patterns";
import { runInAction } from "mobx";
import { selectRoute } from "src/config/routes";
import { RootStoreContext } from "src/stores/RootStore";
import { Route } from "src/config/routes";
import { Loading } from "src/screens/loading/Loading";
import { RouterProps } from "react-router";
import i18next from "i18next";

enum CHANGESTATES {
    PENDING = 0,
    ACTIVE = 1,
    DONE = 2
}

interface PasswordChangeForm {
    oldPassword: string;
    newPassword: string;
    newPasswordConfirm: string;
}

export const PasswordChange = (props: RouterProps) => {
    const { t } = useTranslation();
    const { navStore, uiStore, authStore } = useContext(RootStoreContext);
    const [changeState, setChangeState] = useState<CHANGESTATES>(CHANGESTATES.PENDING);
    const [error, setError] = useState<Error | undefined>(undefined);

    useEffect(() => {
        runInAction(() => {
            navStore.setTitle(t("screens.auth.password.change.title"));
            navStore.setBackbutton(selectRoute(Route.profile), t("screens.account.title"));
        });
    }, [navStore, uiStore, t]);

    const { register, handleSubmit, watch, errors } = useForm<PasswordChangeForm>();

    const onSubmit = async (formData: PasswordChangeForm) => {
        setChangeState(CHANGESTATES.ACTIVE);
        let hasPasswordChangeError = false;

        try {
            await authStore.authClient.passwordChange(formData.oldPassword, formData.newPassword);
        } catch (error) {
            hasPasswordChangeError = true;

            error.message = i18next.exists(`error.${error.message}`)
                ? t(`error.${error.message}`)
                : t("screens.auth.password.change.error");

            setError(error);
        }

        if (hasPasswordChangeError) {
            setChangeState(CHANGESTATES.PENDING);
        } else {
            setChangeState(CHANGESTATES.DONE);
        }
    };

    const onClose = useCallback(() => {
        uiStore.setCloseAppBar(false);

        if (navStore.closeNavTarget !== "") {
            props.history.push(navStore.closeNavTarget);
        }
    }, [uiStore, navStore.closeNavTarget, props.history]);

    const content = () => {
        if (changeState === CHANGESTATES.ACTIVE) {
            return <Loading noLogo noBackgroundChange />;
        } else if (changeState === CHANGESTATES.DONE) {
            return (
                <>
                    <div>
                        <Trans>screens.auth.password.change.success</Trans>
                        <UI.Button className="mt-4" label={t("labels.close")} onClick={onClose} />
                    </div>
                </>
            );
        }
        return (
            <UI.Form onSubmit={handleSubmit(onSubmit)}>
                <UI.Form.Group>
                    <UI.Form.Label>
                        <Trans>screens.auth.password.old</Trans>
                    </UI.Form.Label>
                    <UI.Form.Control
                        name="oldPassword"
                        type="password"
                        isInvalid={errors.oldPassword !== undefined}
                        placeholder={t("screens.auth.password.old_placeholder")}
                        ref={register({ required: true })}
                    />
                </UI.Form.Group>
                <UI.Form.Group>
                    <UI.Form.Label>
                        <Trans>screens.auth.password.new</Trans>
                    </UI.Form.Label>
                    <UI.Form.Control
                        name="newPassword"
                        type="password"
                        isInvalid={errors.newPassword !== undefined}
                        placeholder={t("screens.auth.password.new_placeholder")}
                        ref={register({ required: true, pattern: Patterns.PASSWORD })}
                    />
                    {errors.newPassword && (
                        <UI.Form.Text className="text-danger">
                            <Trans>screens.auth.password.invalid</Trans>
                        </UI.Form.Text>
                    )}
                </UI.Form.Group>
                <UI.Form.Group>
                    <UI.Form.Label>
                        <Trans>screens.auth.password.new_confirm</Trans>
                    </UI.Form.Label>
                    <UI.Form.Control
                        name="newPasswordConfirm"
                        type="password"
                        isInvalid={errors.newPasswordConfirm !== undefined}
                        placeholder={t("screens.auth.password.new_confirm_placeholder")}
                        ref={register({ validate: (value) => value === watch("newPassword") })}
                    />
                    {errors.newPasswordConfirm && (
                        <UI.Form.Text className="text-danger">
                            <Trans>screens.auth.password.new_mismatch</Trans>
                        </UI.Form.Text>
                    )}
                </UI.Form.Group>
                <UI.Button className="mt-4" type="submit" label={t("labels.save")} />
                {error && (
                    <UI.Alert variant="danger" className="mt-2">
                        {error.message}
                    </UI.Alert>
                )}
            </UI.Form>
        );
    };

    return (
        <UI.Container className="pt-4">
            <UI.Row>
                <UI.Col xs={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
                    <UI.Card title={t("screens.auth.password.change.title")}>{content()}</UI.Card>
                </UI.Col>
            </UI.Row>
        </UI.Container>
    );
};
