import React, { useContext, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { useQuery } from "@apollo/client";
import { GET_FEATURE_ANNOUNCEMENT_DETAILS } from "src/api/featureAnnouncement";
import {
    GetFeatureAnnouncementDetails,
    GetFeatureAnnouncementDetailsVariables
} from "src/api/generated/GetFeatureAnnouncementDetails";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { RootStoreContext } from "src/stores/RootStore";
import * as Screens from "src/screens";
import { FeatureAnnouncementForm } from "./form/FeatureAnnouncementForm";
import { observer } from "mobx-react-lite";
import { Route as CustomerRoutes, selectRoute } from "src/config/routes";

interface Params {
    announcementid: string;
}

const FeatureAnnouncementDetailsBase = () => {
    const { t } = useTranslation();
    const { navStore, uiStore, authStore } = useContext(RootStoreContext);

    const { announcementid } = useParams<Params>();

    useEffect(() => {
        navStore.setTitle("Feature Announcement Details");
        navStore.setBackbutton(selectRoute(CustomerRoutes.featureAnnouncements, null), "Feature Announcements");
    }, [navStore]);

    const {
        loading,
        error,
        data,
        refetch: refetchFeatureAnnouncementData
    } = useQuery<GetFeatureAnnouncementDetails, GetFeatureAnnouncementDetailsVariables>(
        GET_FEATURE_ANNOUNCEMENT_DETAILS,
        {
            variables: {
                announcementid
            }
        }
    );

    useEffect(() => {
        if (uiStore.hasNewAnnouncementData) {
            refetchFeatureAnnouncementData();
        }
    }, [refetchFeatureAnnouncementData, uiStore, uiStore.hasNewAnnouncementData]);

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata")} />;
    }

    const userRole = authStore.user ? authStore.user.role : undefined;
    const featureAnnouncementDetails = data.announcement.length > 0 ? data.announcement[0] : undefined;

    return (
        <FeatureAnnouncementForm
            featureAnnouncementDetails={featureAnnouncementDetails}
            announcementId={announcementid}
            onUpsertFeatureAnnouncement={refetchFeatureAnnouncementData}
            userRole={userRole}
            announcementStates={data.announcementstates}
            customerOptions={data.customeroptions}
        />
    );
};

export const FeatureAnnouncementDetails = Sentry.withProfiler(observer(FeatureAnnouncementDetailsBase));
